import { combineReducers } from 'redux';
import { createSelector } from '@reduxjs/toolkit';
import { debug, get, getWithoutWarning } from '../common/util';
import { post } from './api'
import passGLMapEventToFlight from '../common/utils/passGLMapEventToFlight';

// Actions
import {
  ACTIVATE_PROJECT,
  DEACTIVATE_PROJECT,
  REQUEST_PROJECTS,
  RECEIVE_PROJECTS,
  RECEIVE_PROJECTS_ERROR,
  LOG_OUT,
  CLEAR_PROJECTS,
  REQUEST_PROJECT,
  RECEIVE_PROJECT,
  RECEIVE_PROJECT_ERROR,
  UPDATE_PROJECT_REGIONS,
} from './actions';
import { spinDecrement, spinIncrement } from './exportSlice';

const NOOP = () => {};

export const SURVEY_TYPE = 'Survey';

/*
TODO: This code is in our standard projects reducer but not used yet on Regrid
Before enabling, we should: 
* Use the site domain from a settings file rather than hard-coding it
* Check and update getUrlRaw; can we use one of our standard API functions instead?

export const fetchProjectsFromUserGroups = ({ afterFail, } = {}) => (dispatch, getState) => {
  const state = getState()
  const user = getUser(state)
  let userToken = get(user, 'token')
  if (!userToken) return
  let userGroups = get(user, 'groups')

  // Loop over the user's groups and fetch the projects for each group
  // TODO: use baseurl from settings
  userGroups.map(({ id, slug, }) => {
    return getUrlRaw('https://' + slug + '.regrid.com/maps.json?token=' + userToken, {
      start: () => {
        dispatch({ type: REQUEST_PROJECTS, groupId: id, })
      },
      done: data => {
        dispatch({ type: RECEIVE_PROJECTS, data: data, groupId: id, })
      },
      fail: err => {
        // TOOD: maybe we want to show an alert here?
        console.log('fail err', err)
        dispatch({ type: RECEIVE_PROJECTS_ERROR, groupId: id, })
        if (afterFail) afterFail(err)
      },
    })
  })
}
*/


/**
 * Fetch a map by id
 * Pass safe = true to avoid marking the map as loading or failed.
 * This is useful if you think map data may be loaded already and you don't want to risk marking it
 * as "loading" or "failed" if the user doesn't have an internet connection.
 */
// used in project.js only
/*
TODO: This code is in our standard projects reducer but not used yet on Regrid
Before enabling, we should: 
* Check and update getUrl; can we use one of our standard API functions instead?

const fetchProject = (id, { safe = false, finished = false, } = {}) => (
  dispatch,
  getState,
) => {
  const state = getState()
  const token = getUser(state).token
  let start = () => {
    dispatch({
      type: REQUEST_PROJECT,
      id,
    })
  }

  const done = data => {
    dispatch({
      type: RECEIVE_PROJECT,
      data,
      id,
    })

    if (finished) {
      finished(data)
    }
  }

  let fail = error => {
    dispatch({
      type: RECEIVE_PROJECT_ERROR,
      id,
      error,
    })
  }

  if (safe) {
    start = NOOP
    fail = NOOP
  }

  let path = `/m/${id}.json`

  getUrl(token, path, start, done, fail)
}
*/

// used in FocusAreaLayerContainer.js
export const fetchProjectIfNeeded = id => (dispatch, getState) => {
  if (id) {
    const project = getProject(getState(), id)
    let projectHasError = getWithoutWarning(project, 'hasError')
    let projectErrorMessage = getWithoutWarning(project, 'error', 'message')
    if (!project || (projectHasError && projectErrorMessage != 'Not found')) {
      return dispatch(fetchProject(id))
    }
  }
}

// Are we loading any groups?
export const areProjectsLoading = state => {
  let loadingIds = get(state, 'projects', 'loading')
  if (Array.isArray(loadingIds)) {
    return loadingIds.length > 0
  }

  return false
}

export const getProject = (state, projectId) => {
  // There is a chance it hasn't loaded at this time.
  return get(state, 'projects', 'byId', projectId)
}

export const getActiveProjectId = state => {
  return getWithoutWarning(state, 'projects', 'active')
}

export const getActiveProject = state => {
  // There is a chance it hasn't loaded at this time.
  const activeProjectId = getActiveProjectId(state)
  if (activeProjectId) {
    return getProject(state, activeProjectId)
  }
  return null
}

export const getActiveProjectQuery = state => {
  if (state.projects.active !== null) {
    const project = getActiveProject(state)
    if (project) {
      return project.query
    }
  }
  return null
}

export const getActiveProjectPath = state => {
  if (state.projects.active !== null) {
    const project = getActiveProject(state)
    if (project) {
      return project.path
    }
  }
  return null
}

export const getActiveProjectName = state => {
  if (state.projects.active !== null) {
    const project = getActiveProject(state)
    if (project) {
      return project.name
    }
  }
  return null
}

export const getActiveProjectRootName = state => {
  if (state.projects.active !== null) {
    const project = getActiveProject(state)
    if (project) {
      return project.root.name
    }
  }
  return null
}

export const getActiveProjectSlug = state => {
  if (state.projects.active !== null) {
    const project = getActiveProject(state)
    if (project) {
      return project.slug
    }
  }
  return null
}

export const getActiveProjectRootPath = state => {
  if (state.projects.active !== null) {
    const project = getActiveProject(state)
    if (project) {
      return project.root.path
    }
  }
  return null
}

//combines project root place and context
export const getActiveProjectHeadline = state => {
  if (state.projects.active !== null) {
    const project = getActiveProject(state)
    if (project) {
      const headline = (project.root?.headline && project.root?.context?.headline) ? `${project.root?.headline}, ${project.root?.context?.headline}` : ''
      return headline
    }
  }
  return null
}

export const getActiveProjectRootHeadline = state => {
  if (state.projects.active !== null) {
    const project = getActiveProject(state)
    if (project) {
      return project.root?.headline
    }
  }
  return null
}

export const getActiveProjectRootContextHeadline = state => {
  if (state.projects.active !== null) {
    const project = getActiveProject(state)
    if (project) {
      return project.root?.context?.headline
    }
  }
  return null
}

export const activeProjectPresent = state => {
  return state.projects.active !== null
}

export const canQCActiveProject = state => {
  const project = getActiveProject(state)
  if (!project) return false
  return get(project, 'permissions', 'qc')
}

export const getActiveSources = state => {
  // TODO add tests
  let allSources = getWithoutWarning(getActiveProject(state), 'sources')

  if (allSources) {
    return Object.values(allSources)
  } else {
    return []
  }
}

export const getActiveSurvey = state => {
  // TODO add tests
  let allSources = getActiveSources(state)
  if (allSources) {
    // The first source in a project may not be a survey.
    let activeSurvey = allSources.find(source => source.type === SURVEY_TYPE)

    return activeSurvey
  } else {
    return null
  }
}

const isSurveyProject = project => {
  let projectSources = get(project, 'sources')
  if (!projectSources) {
    return false
  }

  let sources = Object.values(projectSources)
  return sources.find(source => source.type === SURVEY_TYPE)
}

export const updateProjectRegions = (projectId, regions) => (dispatch) => {
  dispatch({
    type: UPDATE_PROJECT_REGIONS,
    id: projectId,
    regions: regions,

  })
}

// memoized selector
export const getActiveProjectRegions = createSelector(
  [getActiveProject], 
  (project) => {
    if (!project) return false;
    return get(project, 'regions');
  }
);

// used in vector maps while the Print function still uses raster tiles
export const getActiveProjectStyleHandler = createSelector(
  [getActiveProject], 
  (project) => {
    if (!project) return false;
    return get(project, 'style_handler');
  }
);

export const changeProjectRoot = (activeProjectPath, newPath) => (dispatch) => {
  const start = () => {
    dispatch(spinIncrement())
  }
  const done = () => {
    // make sure Project Home in Project Settings is updated because we are still using project_home.js right now
    passGLMapEventToFlight('mapp:project:change-root', { path: newPath });
    passGLMapEventToFlight('project:save');
    dispatch(spinDecrement())
  };

  const fail = (err) => {
    console.error(`There was a problem changing the project root: ${err}`);
    dispatch(spinDecrement)
  };

  post(
    `${activeProjectPath}/settings/set_root.json`,
    { path: newPath },
    { done, fail }
  ).catch((error) => {
    fail(error);
  });
};

// Return a list of source IDs attached to a map
export const byId = (state = {}, action) => {
  switch (action.type) {
    case LOG_OUT:
    case CLEAR_PROJECTS:
      return {}
    case RECEIVE_PROJECTS: {
      // Remove any source that's in this incoming group
      const projectsInOtherGroups = Object.values(state).filter(({ group_id, }) => {
        return group_id !== action.groupId
      })

      // Add a loadedAt date to every survey
      var loadedAt = Date.now()
      const newProjects = action.data.map(project => {
        project.loadedAt = loadedAt
        return project
      })

      let newProjectList = [...projectsInOtherGroups, ...newProjects,]

      // Ensure we only have survey projects in the list
      newProjectList = newProjectList.filter(isSurveyProject)

      // Key the list by id
      const projectsById = {}
      newProjectList.forEach(project => {
        projectsById[project.id] = project
      })

      return projectsById
    }
    case REQUEST_PROJECT:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          id:        action.id,
          hasError:  false,
          isLoading: true,
        },
      }
    case RECEIVE_PROJECT:
      return {
        ...state,
        [action.id]: {
          ...action.data,
          hasError:  false,
          isLoading: false,
        },
      }
    case RECEIVE_PROJECT_ERROR:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          error:     action.error,
          hasError:  true,
          isLoading: false,
        },
      }
    case UPDATE_PROJECT_REGIONS:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          regions: action.regions,
        }
      }
    default:
      return state
  }
}

export const active = (state = null, action) => {
  switch (action.type) {
    case ACTIVATE_PROJECT:
      return action.id
    case DEACTIVATE_PROJECT:
      if (state == action.id) {
        return null
      } else {
        return state
      }
    case LOG_OUT:
    case CLEAR_PROJECTS:
      return null
    default:
      return state
  }
}

// Store the IDs of the groups we are loading sources for
export const loading = (state = [], action) => {
  switch (action.type) {
    case REQUEST_PROJECTS:
      return [...state, action.groupId,]
    case RECEIVE_PROJECTS:
    case RECEIVE_PROJECTS_ERROR:
      return state.filter(groupId => groupId !== action.groupId)
    case LOG_OUT:
    case CLEAR_PROJECTS:
      return []
    default:
      return state
  }
}

const projectsReducer = combineReducers({
  active,
  byId,
  loading,
})

export default projectsReducer
