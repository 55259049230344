import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Layer, Source, useMap } from 'react-map-gl'
import mapSettings from '../mapSettings'
import useParcelBounds from '../hooks/useParcelBounds'
import {
  getParcelDimensions,
  createLabelFeatures,
} from '../MapControls/MapOptions/DrawTools/drawUtils'
// Redux
import { getSatelliteLayerRendered, getMapColor } from 'ducks/mapProperties'
import { getSelectedParcelFeature } from 'ducks/parcels'
import { getCurrentFloatLayer } from 'ducks/mapProperties'

/**
 * SelectedParcelLayer displays the selected parcel on the map.
 *
 * Retrieves the selected parcel from the redux state, processes geometry,
 * and prepares midpoint coordinates for potential labels by FloatLabelsLayer.
 * It visualizes the parcel's boundary based on the current map style.
 *
 * If the active float layer is 'float-dimensions', it populates data
 * for the FloatLabelsLayer component to display labels as a conditional <Source>.
 *
 * @component
 * @returns {React.Element} - Components for the selected parcel visualization and label data.
 */
const SelectedParcelLayer = () => {
  const activeFloatLayerId = useSelector(getCurrentFloatLayer)
  const selectedParcel = useSelector(getSelectedParcelFeature)
  const isSatellite = useSelector(getSatelliteLayerRendered)
  const colorType = isSatellite
    ? mapSettings.editableStyleTypes.satelliteSelectedParcel
    : mapSettings.editableStyleTypes.streetSelectedParcel
  const currentColor = useSelector(getMapColor(colorType))
  const { current: map } = useMap()
  const [midpointsData, setMidpointsData] = useState(null)

  const layerLineStyle = {
    id: mapSettings.layerIds.parcelSelected,
    type: 'line',
    source: mapSettings.sourceIds.parcelSelected,
    paint: {
      'line-color': currentColor,
      'line-width': 5,
    },
  }

  useEffect(() => {
    // component FloatLabelsLayer adds the parcel dimension labels <Layer /> to the conditional source 'midpoints-source' below.
    if (!map) return
    if (
      activeFloatLayerId === 'float-dimensions-ft' ||
      activeFloatLayerId === 'float-dimensions-m'
    ) {
      if (!selectedParcel?.geometry?.coordinates) {
        debug('Dimension - selectedParcel does not have coordinates')
        return
      }
      getDimensions(selectedParcel)
    }
  }, [selectedParcel, activeFloatLayerId])

  const getDimensions = async (parcel) => {
    if (!parcel?.properties?.path) return
    try {
      const newParcelData = await getParcelDimensions(parcel?.properties?.path)
      if (!newParcelData) {
        debug(`Error fetching dimensions for parcel ${parcel?.properties?.path}`)
        return
      }
      const formattedSegmentLengths = newParcelData?.lengths.map((segment) => {
        if (activeFloatLayerId === 'float-dimensions-ft') {
          // returns integer
          return `${numberWithCommas(Math.round(segment.ft))} ft`;
        } else {
          // returns float to 1 decimal place
          const precision = 0.1;
          const value = Math.round(segment.m / precision) * precision;
          return `${numberWithCommas(value, 1)} m`;
        }
      });

      const midpointCoords = newParcelData?.lengths.map((segment) => {
        return segment.coord;
      });
      const labelFeatures = createLabelFeatures(
        midpointCoords,
        formattedSegmentLengths
      );

      setMidpointsData({
        type: 'FeatureCollection',
        features: labelFeatures,
      });
    } catch (error) {
      debug('getDimensions error:', error);
    }
  }

  useParcelBounds(selectedParcel, map)

  return (
    <>
      <Source
        id={mapSettings.sourceIds.parcelSelected}
        type="geojson"
        data={selectedParcel ?? mapSettings.emptyGeoJSON}
      >
        <Layer {...layerLineStyle} />
      </Source>
      {midpointsData && (
        <Source
          id="midpoints-source"
          type="geojson"
          data={midpointsData}
        ></Source>
      )}
    </>
  )
}

export default SelectedParcelLayer
