import React, { useEffect, useState } from 'react'
import { useMap } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { thirdPartyLayers } from '../Layers/thirdPartyLayers'
import mapSettings from '../mapSettings'
// Redux
import { getCurrentFloatLayer, getThirdPartyLayer } from 'ducks/mapProperties'

const ZoomInNotifierGL = () => {
  const [isVisible, setIsVisible] = useState(true)
  const currentFloatLayer = useSelector(getCurrentFloatLayer)
  const currentThirdPartyLayer = useSelector(getThirdPartyLayer)
  const currentZoom = useSelector((state) => state.mapProperties.zoomLevel)
  const mapAvailable = useSelector((state) => state.mapProperties.mapAvailable)
  const { current: map } = useMap()

  useEffect(() => {
    // const displayNotifier =
    //   currentZoom && currentZoom < mapSettings.parcelMinZoom

    if (mapAvailable) {
      setIsVisible(true)
      // const timer = setTimeout(() => setIsVisible(false), 6000)
      // return () => clearTimeout(timer) // clear timer on unmount
    }
  }, [mapAvailable, currentFloatLayer, currentThirdPartyLayer, currentZoom])
  
  if (!mapAvailable) {
    return null
  }

  const notifierClassName = `zoom-notifier-gl ${
    isVisible ? '' : 'fade-out'
  }`

  const displayNotifier = currentZoom && currentZoom < mapSettings.parcelMinZoom
  const thirdPartyLayer =
    thirdPartyLayers.find((layer) => layer.id === currentThirdPartyLayer) || {}

  const layerMinZoom =
    thirdPartyLayer?.sourceOpts?.minzoom ||
    thirdPartyLayer?.opts?.minZoom ||
    null
  // e.g. layers.js floatLayers[{opts{minZoom: 17}}]

  if (thirdPartyLayer && layerMinZoom && currentZoom < layerMinZoom) {
    return (
      <div className={notifierClassName}>
        <span className="zoom-notifier-text centered">
          <i className="fas fa-search-plus margin-right-md"></i>
          {`Zoom in to see ${thirdPartyLayer.name}`}
        </span>
      </div>
    )
  } else if (displayNotifier) {
    return (
      <div className={notifierClassName}>
        <span className="zoom-notifier-text centered">
          <i className="fas fa-search-plus margin-right-md"></i>
          Zoom in to see parcels
        </span>
      </div>
    )
  } else if (
    currentFloatLayer &&
    (currentFloatLayer === 'float-parcel' ||
      currentFloatLayer === 'float-owner') &&
    currentZoom < mapSettings.rasterMaxZoom
  ) {
    return (
      <div className={notifierClassName}>
        <span className="zoom-notifier-text centered">
          <i className="fas fa-search-plus margin-right-md"></i>
          {currentFloatLayer === 'float-owner'
            ? 'Zoom in to see owner names'
            : 'Zoom in to see parcel numbers'}
        </span>
      </div>
    )
  } else {
    return null
  }
}

export default ZoomInNotifierGL
