import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FocusAreaListItemGL from './FocusAreaListItemGL'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchFocusAreas,
  getAvailableFocusAreas,
  getFocusAreasStatus,
} from '../../ducks/focusAreas'

/*
Tool to add existing focus areas to a project
*/
const FocusAreaAdderGL = ({
  focusAreasAttachedToProject,
  passEventToFlight,
  project,
  handleClose,
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const availableFocusAreas = useSelector(getAvailableFocusAreas)
  const focusAreasStatus = useSelector(getFocusAreasStatus)

  useEffect(() => {
    if (focusAreasStatus === 'loading') {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [availableFocusAreas.length, focusAreasStatus])

  useEffect(() => {
    dispatch(fetchFocusAreas())
  }, [project?.id])

  if (isLoading) {
    return (
      <p>
        <i className="fas fa-spin fa-spinner"></i> Loading focus areas
      </p>
    )
  }

  if (!project || !project?.permissions?.update) {
    return null
  }

  if (project && project?.permissions?.update && !availableFocusAreas.length) {
    return (
      <div className="focus-areas-list margin-btm-lg">
        <h5>
          Available Focus Areas:{' '}
          <span className="badge margin-right-md">0</span>
        </h5>
        <p className="small">
          Using the draw tool, you can create a custom area boundary called a{' '}
          <b>Focus Area</b>. This Focus Area then determines what data you see
          in different parts of the tool.
        </p>
        <p className="small">
          While a Focus Area is set as your Working Boundary, the Overview
          panel, Feed, List, Filter, and Export will return data that falls
          within the boundaries of your drawn Focus Area.
        </p>
      </div>
    )
  }

  if (availableFocusAreas.length) {
    debug('availableFocusAreas true: ', availableFocusAreas)
    // Only show the focus areas not already on the map
    let focusAreasNotYetOnProject = availableFocusAreas.filter(
      (neighborhood) => {
        const exists = focusAreasAttachedToProject.list.find((existing) => {
          return existing.path == neighborhood.path
        })
        return !exists
      }
    )

    const sortRegionsAlphabetically = (a, b) => {
      if (a.headline.toLowerCase() < b.headline.toLowerCase()) {
        return -1
      }
      if (a.headline.toLowerCase() > b.headline.toLowerCase()) {
        return 1
      }
      return 0
    }

    focusAreasNotYetOnProject = focusAreasNotYetOnProject.sort(
      sortRegionsAlphabetically
    )

    const folder = 'Focus Areas'
    const areas = focusAreasNotYetOnProject.map((area) => (
      <FocusAreaListItemGL
        key={area.path}
        area={area}
        passEventToFlight={passEventToFlight}
        folder={folder}
        projectPath={project.path}
      />
    ))

    return (
      <div className="focus-area-list margin-btm-lg">
        {areas && (
          <>
            <h5>
              Available Focus Areas:{' '}
              <span className="badge">{areas.length}</span>
            </h5>
            <p className="italic small">
              Add a focus area to{' '} 
              <span className='bold wrap-word'>
                {project.name}
              </span>
            </p>
            {areas}
          </>
        )}
      </div>
    )
  }
}

FocusAreaAdderGL.propTypes = {
  focusAreasAttachedToProject: PropTypes.object.isRequired,
  passEventToFlight: PropTypes.func.isRequired,
  project: PropTypes.object,
}

export default FocusAreaAdderGL
