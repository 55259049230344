import React, { useState } from 'react';
import useDependencyEffect from 'common/hooks/useDependencyEffect';
import { useSelector, useDispatch } from 'react-redux';
import useDebounce from 'common/hooks/useDebounce';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { colorPickerValueToRgba } from '../../../mapUtilities'

// Redux
import { getMapColor, setMapColor } from 'ducks/mapProperties';

const MapColorPicker = ({ colorType, description }) => {

  const dispatch = useDispatch();
  const colorValue = useSelector(getMapColor(colorType));
  const [colorPickerValue, setColorPickerValue] = useState(colorValue);
  const debouncedColorValue = useDebounce(colorPickerValue, 250);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);  // Toggle ChromePicker

  const [displayedColor, setDisplayedColor] = useState(colorValue);


  // Debounce so we don't overwhelm reducer
  // when scrolling around on color picker
  useDependencyEffect(() => {
    dispatch(setMapColor({ color: debouncedColorValue, colorType }));
  }, [debouncedColorValue]);


  const handleChange = (colorPickerValue) => {
    const rgbaValue = colorPickerValueToRgba(colorPickerValue.rgb);
    setColorPickerValue(rgbaValue);
    setDisplayedColor(colorPickerValue.hex);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker); 
  };

  const handleClose = () => {
    setDisplayColorPicker(false); 
  };

  return (
    <div className='color-picker-wrapper' id={colorType}>
      <span className='caption'>{description}</span>
      <div className="color-block-container rounded"
      onClick={handleClick} 
      >
          <div 
          className="color-block rounded"
          style={{ backgroundColor: colorValue }} 
        />
        <div className="color-code">{displayedColor}</div>
        <i className="fas fa-edit"/>
      </div>

      {displayColorPicker && (
        <>
          <div className="chrome-picker-wrapper">
            <ChromePicker
              color={colorValue} 
              onChangeComplete={handleChange}
            />
          </div>
          <div className="chrome-picker-overlay" onClick={handleClose}></div>
        </>
      )}
    </div>
  );
};

MapColorPicker.propTypes = {
  colorType:    PropTypes.string.isRequired,
  description:  PropTypes.string.isRequired,
};

export default MapColorPicker;