import React, { Component } from 'react';
import { CYCLES } from 'common/settings';
import PropTypes from 'prop-types'
//import _ from 'lodash';

const PlanCycle = props => {
  const { ANNUAL, MONTHLY } = CYCLES;
  let savings = props.prices.monthly * 12 - props.prices.annual

  return <>
    <h3>Billing Schedule</h3>
    <div className='margin-btm-md'><span className="bold">Step 2 of 2:</span> Name your account and enter payment details.</div>
    <div className='row' style={{ marginBottom: '1em' }}>
      <div className='col-md-6'>
        <button
          title={props.annualDisabled ? "Annual subscriptions are unavailable for your selected plan." : ""}
          disabled={props.annualDisabled}
          className={'btn btn-block ' + (props.selectedCycle === ANNUAL ? 'btn-success selected' : 'btn-default')}
          onClick={e => { props.onChangeCycle(ANNUAL, e) }}>
          Annual
        </button>
      </div>
      <div className='col-md-6'>
        <button
          className={'btn btn-block ' + (props.selectedCycle === MONTHLY ? 'btn-success selected' : 'btn-default')}
          onClick={e => { props.onChangeCycle(MONTHLY, e) }}>
          Monthly
        </button>
      </div>
    </div>
    {!props.annualDisabled && (
      <p className='centered'>
        {savings > 0 && (
          <span>
            <i className='fas fa-bulb' />
            Save ${numberWithCommas(savings)}/year with an annual subscription!
          </span>
        )}
      </p>
    )}
  </>
}


PlanCycle.propTypes = {
  selectedCycle:  PropTypes.string.isRequired,
  prices:         PropTypes.object.isRequired,
  onChangeCycle:  PropTypes.func.isRequired,
  annualDisabled: PropTypes.bool,
}

export default PlanCycle;
