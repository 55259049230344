import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { get, post } from 'ducks/api';

const FocusAreaListItem = ({area, active=false, passEventToFlight, folder, projectPath}) => {
  let [isLoading, setIsLoading] = useState(false);


  // Handle attaching the focus area to the project
  const addFocusArea = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const done = response => {
      setIsLoading(false);
      passEventToFlight('project:loaded', response.map);
      passEventToFlight('mapp:regions:reload-focus-areas', response.map);
    };

    const fail = (message) => {
      alert("We're sorry, we were not able to add that focus area")
      setIsLoading(false);
    }

    post(`${projectPath}/settings/add_focus.json`, {
      folder,
      path:   area.path,
    }, { done, fail });
  };

  const removeFocusArea = (event) => {
    event.preventDefault();

    // Optional confirm popup; 
    // This action is easy to undo and the popup is intrusive. Maybe the wrong decision.
    // const proceed = confirm('Are you sure you want to remove this focus area from the project?');
    // if(!proceed) return;

    setIsLoading(true);

    const done = response => {
      setIsLoading(false);
      if(response.status === 'ok') {
        debug('FocusAreaAdded got response', response);
        passEventToFlight('project:loaded', response.map);
        passEventToFlight('mapp:regions:reload-focus-areas', response.map);
      }
    };

    const fail = (message) => {
      alert("We're sorry, we were not able to remove that focus area")
      setIsLoading(false);
    }

    post(`${projectPath}/settings/remove_focus.json`, {
      path:   area.path,
      folder
    }, { done, fail });
  };

  // Show either the "add to project" or "remove from project" button
  let action = addFocusArea
  let actionButton = (<a href="#" className="toggle-source">
      <i className="fas fa-plus add" title="Add to project"></i>
    </a>);

  if (active) {
    action = null
    actionButton = <a className="toggle-source" onClick={removeFocusArea}>
      <i className="fas fa-times" title="Remove from project"></i>
    </a>
  }

  if (isLoading) {
    action = null
    actionButton = <a className="toggle-source">
      <i className="fas fa-spinner fa-spin"></i>
    </a>;
  }

  return (<div className={"list-box" + (active ? ' selected' : '')} onClick={action} >
    <h2>
      <img src={`${area.path}/icon.png?size=50`} height="25" width="25" alt="" />
      {area.headline}
    </h2>
    <div className='metadata'>
      {area.acres} acres - created {area.created_at}
    </div>
    {actionButton}
  </div>)
}

FocusAreaListItem.propTypes = {
  active:      PropTypes.bool,
  area:        PropTypes.object.isRequired,
  passEventToFlight:   PropTypes.func.isRequired,
  projectPath: PropTypes.string.isRequired,
};

export default FocusAreaListItem