import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PLANS, CYCLES } from 'common/settings'
// Components
import PlanSelector from 'components/Payment/PlanSelector'
import ApiTierSelector from 'components/Payment/ApiTierSelector'
import PlanOptions from 'components/Payment/PlanOptions'
import PlanCompare from 'components/Payment/PlanCompare'
import ApiCompare from 'components/Payment/ApiCompare'
import ApiSandboxCard from './ApiSandboxCard'
import FaqAccordion from './FaqAccordion'

// Shared component for new platform and self serve api customers
// subscriptions#new && subscriptions/new_api
class Signup extends Component {
  constructor(props) {
    super(props)
    // Scroll to comparison chart element onClick
    this.scrollChartRef = React.createRef()

    this.state = {
      paymentMethod: null,
      user: props.user,
      groupName: '',
      submitting: false,
      error: null,
      selectedPlan: (props.offer && props.offer.plan) || props.initialPlan,
      selectedCycle: (props.offer && props.offer.cycle) || props.initialCycle,
      selectedApiTier: props.offer && props.offer.api_access,
      trialSelected: false,
      agree: false,
      numberOfUsers: props.offer?.users || 2, // Must have a minimum of 2 seats on per seat plans
      goals: '',
      isGroupDupe: false,
    }

    this.handlers = {
      plan: this.onClickPlan.bind(this),
      cycle: this.onClickCycle.bind(this),
      apiTier: this.onClickApiTier.bind(this),
      signedIn: this.onSignedIn.bind(this),
      validCard: this.onValidCard.bind(this),
      confirmed: this.onConfirmed.bind(this),
      groupName: this.onChangeGroupName.bind(this),
      goals: this.onChangeGoals.bind(this),
      trial: this.onChangeTrial.bind(this),
      agree: this.onChangeAgree.bind(this),
      users: this.onChangeNumberOfUsers.bind(this),
      onBlurIsGroupDupe: this.onBlurIsGroupDupe.bind(this),
      scrollToChart: this.scrollToChart.bind(this),
    }
  }

  render() {
    let planChosen = this.state.selectedPlan //&& (!this.props.api || this.state.selectedApiTier);
    let marketingRoot = marketingURL()
    let linkTarget = 'llext'
    let registeredUser = this.props.user || false
    let paidUser = false
    let currentPlan = null
    const sandboxUser = this.props?.sandboxUser || false
    if (registeredUser) {
      paidUser = this.props.user.has_paid_account || false
    }
    const currentApiPlan = this.props?.currentGroup?.api_access
      ? _.capitalize(this.props.currentGroup.api_access)
      : null
    const expiredTrial = sandboxUser && currentApiPlan !== 'Sandbox'
    // let isStarter = (this.state.selectedPlan == PLANS.FREE);
    if (registeredUser && !paidUser) {
      currentPlan = PLANS.FREE // Starter aka Free
    } else if (registeredUser && paidUser && this.props.currentGroup) {
      currentPlan = this.props.currentGroup.subtier
      // 'level1' => Pro, 'team_flex' => Team
    }

    return (
      <div id={this.props.baseName} className="vltan">
        <div id={this.props.baseName + '-in'}>
          <div className="container">
            <div className="row mx-auto" style={{ marginBottom: '2em' }}>
              <div className="col-md-12">
                {this.props.showTitle && <h1>{this.props.showTitle}</h1>}

                {this.props.currentGroup && (
                  <div className="centered plans-notify alert alert-warning">
                    <p className="lead bold">Your current Regrid account: </p>
                    <span className="plans-notify-plan museo">
                      {this.props.currentGroup.name}
                    </span>
                    {this.props.api && currentApiPlan && (
                      <p className="lead bold">
                        {`API Access: ${currentApiPlan}`}
                      </p>
                    )}
                    <p className="lead">
                      Please{' '}
                      <a
                        href={
                          this.props.currentGroup.path + '/settings/billing'
                        }
                      >
                        view and manage your subscription here
                      </a>
                      .
                      <br />
                      You can use this page to open a new account.
                    </p>
                  </div>
                )}

                {registeredUser ? (
                  registeredUser && paidUser ? (
                    <></>
                  ) : (
                    <div className="centered plans-notify">
                      <div className="lead alert alert-warning">
                        <p className="lead bold">
                          Your current Regrid account level:
                        </p>
                        <span className="plans-notify-plan museo">Starter</span>
                      </div>
                      <p className="lead"></p>
                    </div>
                  )
                ) : (
                  <></>
                )}

                <div className="centered" style={{ marginBottom: '2em' }}>
                  {this.props.api ? null : (
                    <>
                      <a
                        // TODO: Where should this button direct to?
                        href={marketingRoot + 'saas'}
                        className="btn btn-info btn-round"
                      >
                        About the mapping platform
                      </a>{' '}
                      <div
                        onClick={this.handlers.scrollToChart}
                        className="btn btn-info btn-round"
                      >
                        Compare subscription levels
                      </div>
                    </>
                  )}
                </div>

                {this.props.offer && (
                  <>
                    <h3 className="special centered">
                      <span className="special-offer">
                        Special offer activated:{' '}
                        {this.props.offer.price
                          ? '$' + numberWithCommas(this.props.offer.price)
                          : 'free'}{' '}
                        for the {this.props.offer.name} plan!
                      </span>
                      <br />
                    </h3>
                    <p className="lead centered">
                      <span className="special-offer">
                        {this.props.api
                          ? 'Select a plan to redeem it'
                          : 'Please scroll down to redeem it'}
                      </span>
                      <br />
                      <i className="fas fa-hand-point-down" />
                    </p>
                  </>
                )}

                {this.props.api && !sandboxUser && !currentApiPlan && (
                  <div className="row tile-row margin-btm-lg">
                    <ApiSandboxCard plans={this.props.plans} />
                  </div>
                )}
                <PlanSelector
                  plans={this.props.plans}
                  showApi={this.props.api}
                  selectedPlan={this.state.selectedPlan}
                  currentPlan={currentPlan}
                  currentApiPlan={currentApiPlan}
                  onClickPlan={this.handlers.plan}
                  onClickCycle={this.handlers.cycle}
                  offer={this.props.offer}
                  defaultPlacePath={this.props.defaultPlacePath}
                  registeredUser={registeredUser}
                  hasPaidAccount={paidUser}
                  sandboxUser={sandboxUser}
                />

                {!this.props.api && (
                  <p className="margin-btm-none padding-top-lg">
                    <a
                      className="btn btn-lg provision-link"
                      href="#"
                      onClick={() => {
                        //open zendesk chat
                        window.zE('messenger', 'open')
                        let launcher = $('.mapboxgl-ff iframe#launcher')
                        //change visibility to show
                        if (launcher) {
                          debug('chat launcher found. making sure visible')
                          launcher.css('visibility', 'visible')
                        } else {
                          debug('chat launcher not found')
                        }
                      }}
                    >
                      Want to provision Regrid property app accounts for your
                      entire team? <br></br>Contact us for bulk discount.
                    </a>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container" id="plan-options">
          {planChosen && (
            <PlanOptions
              user={this.state.user}
              onSignedIn={this.handlers.signedIn}
              stripeKey={this.props.stripeKey}
              selectedPlan={this.state.selectedPlan}
              selectedCycle={this.state.selectedCycle}
              selectedApiTier={this.state.selectedApiTier}
              numberOfUsers={this.state.numberOfUsers}
              trialSelected={this.state.trialSelected}
              agree={this.state.agree}
              group={this.usingOffer() && this.props.offer.group}
              groupName={this.state.groupName}
              goals={this.state.goals}
              onChangeCycle={this.handlers.cycle}
              onChangeGroupName={this.handlers.groupName}
              onChangeGoals={this.handlers.goals}
              onChangeTrial={this.handlers.trial}
              onChangeAgree={this.handlers.agree}
              onChangeNumberOfUsers={this.handlers.users}
              onConfirmed={this.handlers.confirmed}
              onValidCard={this.handlers.validCard}
              plans={this.props.plans}
              submitting={this.state.submitting}
              error={this.state.error}
              offer={this.props.offer}
              isGroupDupe={this.state.isGroupDupe}
              onBlurIsGroupDupe={this.handlers.onBlurIsGroupDupe}
              registeredUser={registeredUser}
              hasPaidAccount={paidUser}
              isApi={this.props.api}
            />
          )}
        </div>

        {/* Plan or API Comparison Chart */}
        {!this.props.api ? (
          <>
            <div
              id="plan-compare"
              className="container margin-top-lg"
              ref={this.scrollChartRef}
            >
              <PlanCompare
                plans={this.props.plans}
                millionsUS={this.props.millions_us} // Clerk.million_parcels('US')
                millionsCA={this.props.millions_ca} // Clerk.million_parcels('CA')
              />
            </div>
          </>
        ) : (
          <>
            <h2 className="h2 bold centered">Compare Self-Serve Plans</h2>
            <div
              id="plan-compare"
              className="container"
              ref={this.scrollChartRef}
            >
              <ApiCompare
                plans={this.props.plans}
                currentApiPlan={currentApiPlan}
                onClickPlan={this.handlers.plan}
                sandboxUser={sandboxUser}
                expiredTrial={expiredTrial}
              />
            </div>
            {/* FAQ - self serve api only */}
            <div>
              <FaqAccordion />
            </div>
          </>
        )}
      </div>
    )
  }

  componentDidMount() {
    $('.plan li.feature')
      .tooltip({
        trigger: 'hover',
        html: true,
      })
      .click(function (e) {
        var $f = $(e.currentTarget)
        $('.plan li.feature').not($f).tooltip('hide')
        setTimeout(function () {
          $f.tooltip('hide')
        }, 6000)
      })

    var initialPlan = $.bbq.getState('p')
    if (initialPlan) {
      // Preselect a plan if arriving via specific link; scroll down if not already a member
      this.onClickPlan(null, initialPlan, !this.props.currentGroup)
    }
    /*if(this.props.offer) {
      this.scrollDown()
    }*/
  }

  onClickPlan(e, plan, scroll = true) {
    if (e) {
      e.preventDefault()
    }
    let numberOfUsers = 2
    let cycle =
      this.state.selectedCycle ||
      (this.props.currentGroup && this.props.currentGroup.tier) ||
      CYCLES.MONTHLY
    let apiTier = this.state.selectedApiTier

    if (plan === PLANS.PRO) {
      numberOfUsers = 1
    }
    if (plan === PLANS.TEAM_FLEX && this.props?.offer?.users) {
      numberOfUsers = this.props.offer.users
    }

    const planDetails = _.find(this.props.plans, { plan: plan })
    // Automatically swap back to monthly billing when selecting a per seat plan
    if (planDetails?.per_seat_pricing) {
      cycle = CYCLES.MONTHLY
    } else if (planDetails?.api) {
      // scroll = false // API plans don't have tiers anymore
      if (apiTier && !planDetails?.levels[apiTier]) {
        // If e.g. you picked Premium and change to a plan w/o a premium option, fix it
        apiTier = _.keys(planDetails?.levels)[0]
      }
    } else if (plan === 'api_sandbox') {
      scroll = false
    }

    this.setState(
      {
        selectedPlan: plan,
        selectedCycle: cycle,
        selectedApiTier: apiTier,
        numberOfUsers,
      },
      () => {
        this.measureCheckout(1)
        if (this.state.user) {
          this.measureCheckout(2)
        }
      }
    )

    // Hide the rest of the page, scroll down to the business
    if (scroll) this.scrollDown()
  }

  scrollDown() {
    setTimeout(() => {
      // Make sure react has rendered the DOM so we can scroll to it
      const optSection = document.getElementById('plan-options')
      if (optSection) {
        optSection.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }
    }, 100)
  }

  scrollToChart() {
    this.scrollChartRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  onClickCycle(cycle, e) {
    this.setState({ selectedCycle: cycle })
    e.preventDefault()
  }

  onClickApiTier(e, tier) {
    let self = this
    this.setState({ selectedApiTier: tier }, () => {
      self.scrollDown()
    })
    let planOptionsEle = document.getElementById('plan-options')
    planOptionsEle.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
    e.preventDefault()
  }

  onSignedIn(user) {
    this.measureCheckout(2)
    this.setState({ user: user })
  }

  onBlurIsGroupDupe(e) {
    e.preventDefault()
    let opts = { query: this.state.groupName, exact: true }
    LL.Ajax.get('/search/groups.json?' + $.param(opts))
      .then((response) => {
        this.setState({ isGroupDupe: response.length > 0 })
      })
      .catch((msg) => {
        debug(msg)
      })
  }

  onChangeGroupName(e) {
    this.setState({ groupName: e.target.value })
  }

  onChangeGoals(e) {
    this.setState({ goals: e.target.value })
  }

  onChangeTrial(e) {
    this.setState({ trialSelected: e.target.checked })
  }

  onChangeAgree(e) {
    this.setState({ agree: e.target.checked })
  }

  onChangeNumberOfUsers(numberOfUsers) {
    this.setState({ numberOfUsers })
    if (this.state.selectedPlan === PLANS.PRO && numberOfUsers > 1) {
      this.onClickPlan(null, PLANS.TEAM_FLEX, false)
    }
    if (this.state.selectedPlan === PLANS.TEAM_FLEX && numberOfUsers == 1) {
      this.onClickPlan(null, PLANS.PRO, false)
    }
  }

  // A callback for EC, doesn't
  onValidCard() {
    this.measureCheckout(3)
  }

  isPerSeatPlan() {
    const selectedPlan = this.props.plans.find(
      (p) => p.plan === this.state.selectedPlan
    )
    return selectedPlan?.per_seat_pricing || false
  }

  // We have a valid card saved with stripe; submit the form to server to finalize
  onConfirmed(paymentMethodId, onError) {
    if (this.state.submitting) {
      return
    }
    this.setState({ submitting: true })
    this.measureCheckout(4)

    let self = this
    let opts = {
      plan:               this.state.selectedPlan,
      cycle:              this.state.selectedCycle,
      payment_method_id:  paymentMethodId,
      group_name:         this.state.groupName,
      offer:              this.props.offer,
      api_access:         this.state.selectedApiTier,
      trial:              this.props.api && this.state.trialSelected,
      goals:              this.state.goals,
    }
    if (this.isPerSeatPlan()) {
      opts.users = this.state.numberOfUsers
      if (this.props?.offer)
        opts.per_seat_price = this.props.offer.per_seat_price
    }
    const planName = this.props.plans.find(
      (p) => p.plan === this.state.selectedPlan
    ).name
    LL.Ajax.postSpin('/subscriptions.json', opts)
      .then((response) => {
        debug(response)
        this.onSubscriptionCreated(response)
        recordSpEvent(
          window.data.sp_categories.subscription,
          `Purchased ${planName} Plan`
        )
      })
      .catch((msg) => {
        self.setState({ error: msg, submitting: false })
        if (onError) {
          onError.apply(null, [msg])
        }
      })
  }

  onSubscriptionCreated(response) {
    // Google-track the transaction and use a callback to go as soon as finished, but also
    // use setTimeout as a fallback (if eg GA is blocked)
    let alreadyCalled = false
    let allDone = function () {
      if (alreadyCalled) {
        return
      }
      alreadyCalled = true
      window.location = response.path
    }
    this.measurePurchase(response.charge_id, response.amount, allDone)
    setTimeout(allDone, 2000)
  }

  // Do the selected options match a valid & active offer code?
  usingOffer() {
    return (
      this.props.offer &&
      this.props.offer.plan === this.state.selectedPlan &&
      this.props.offer.cycle === this.state.selectedCycle
    )
  }

  // GA tracking stuff:
  // Format a product object for GA EC
  ecProduct() {
    let plan = this.state.selectedPlan
    let cycle = this.state.selectedCycle
    let item = _.find(this.props.plans, { plan: plan })
    return {
      id: `${plan}-${cycle}`,
      name: item.name,
      price: item.prices[cycle],
      category: 'subscriptions',
    }
  }

  measureCheckout(step) {
    debug('ec:checkout step ' + step)
    let product = this.ecProduct()
    debug('ec:addProduct', product)
    xga('ec:addProduct', product)
    xga('ec:setAction', 'checkout', { step: step })
    trackEvent('plans', 'checkout-step', _.toString(step)) // needed or EC doesn't send
  }

  measurePurchase(txnId, amount, callback) {
    let product = this.ecProduct()
    debug('ec:addProduct', product)
    xga('ec:addProduct', product)
    var opts = {
      id: txnId,
      revenue: _.toString(amount) + '.00',
    }
    debug('ec:purchase', opts)
    xga('ec:setAction', 'purchase', opts)
    trackGoogleTagManagerEvent({
      event: 'plan_purchased',
      revenue: amount,
      product: product.id,
      product_category: product.category,
    })

    trackEvent('plans', 'payment-success', null, null, callback) // needed or EC doesn't send
  }
}

Signup.propTypes = {
  showTitle: PropTypes.string,
  stripeKey: PropTypes.string.isRequired,
  plans: PropTypes.array.isRequired,
  initialPlan: PropTypes.string,
  initialCycle: PropTypes.string,
  user: PropTypes.object,
  currentGroup: PropTypes.object,
  offer: PropTypes.object,
  defaultPlacePath: PropTypes.string,
  api: PropTypes.bool,
  baseName: PropTypes.string.isRequired,
}

export default Signup
