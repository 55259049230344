import React, { useEffect, useState } from 'react'
import withStore from '../../withStore'
import { useDispatch, useSelector } from 'react-redux'
import { setMapNavOpen, setLogoMenuOpen } from 'ducks/mapControls'
import { handleModalVisibility } from '../../../common/util'

const MapToolsButton = () => {
  const dispatch = useDispatch()
  const [isHovered, setHovered] = useState(false)
  const isMapNavOpen = useSelector((state) => state.mapControls.mapNavOpen)
  const isLogoMenuOpen = useSelector((state) => state.mapControls.logoMenuOpen)

  useEffect(() => {
    handleModalVisibility('mapNavOpen', isMapNavOpen)
  }, [])

  const handleToggle = () => {
    if (isMapNavOpen) {
      dispatch(setMapNavOpen(false))
      handleModalVisibility('mapNavOpen', false)
    } else {
      dispatch(setMapNavOpen(true))
      handleModalVisibility('mapNavOpen', true)
    }

    if (isLogoMenuOpen) {
      // close logo menu in either case
      dispatch(setLogoMenuOpen(false))
    }
  }

  return (
    <>
      <div
        id="map-tools-button"
        className=""
        onClick={() => handleToggle()}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onTouchStart={() => setHovered(true)}
        onTouchEnd={() => setHovered(false)}
      >
        <div
          className={`map-options-btn ${
            isMapNavOpen || isHovered ? 'plan-color-accent' : ''
          } ${isMapNavOpen ? ' active' : ''}`}
        >
          <span>
            <i className="fa fa-tools"></i>
          </span>
          <span>Tools</span>
        </div>
      </div>
    </>
  )
}

export default withStore(MapToolsButton)
