import React from 'react';
import PropTypes from 'prop-types';

const BookmarkRow = ({
  bookmark,
  handleAddressClick,
  handleCopyClick,
  handleDeleteClick,
  handleFollowClick,
}) => {

  return (
    <div className="bookmark-row" data-id={bookmark.id}>
      <a href='#' onClick={(e) => handleAddressClick(e, bookmark)}>{bookmark.address}</a>
      <div className="bookmark-row-btns">
        {false && window.data.flags.following && 
          <i onClick={() => handleFollowClick(bookmark.id, bookmark.path, bookmark.source_id, !bookmark.followed)}
            className={`fas fa-bell ${bookmark.followed ? '' : 'inactive'}`}
            title={bookmark.followed ? 'Currently following, click to stop' : 'Follow for notifications'}
            />
        }
        <i onClick={() => handleCopyClick(bookmark.path)} className="fas fa-copy" title="Copy bookmark to clipboard"></i>
        <i onClick={() => handleDeleteClick(bookmark.path, bookmark.source_id)} className="fas fa-trash" title="Remove"></i>
      </div>
    </div>
  );
};

BookmarkRow.propTypes = {
  bookmark:           PropTypes.object.isRequired,
  handleAddressClick: PropTypes.func.isRequired,
  handleCopyClick:    PropTypes.func.isRequired,
  handleDeleteClick:  PropTypes.func.isRequired,
  handleFollowClick:  PropTypes.func.isRequired,
};

export default BookmarkRow;
