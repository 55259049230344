import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { handleClickTabPane } from '../../../common/util'
import mapSettings from '../mapSettings'
import withStore from '../../withStore'

// Redux
import { getWorkingBoundaryPath } from 'ducks/boundaries'
import { getBoundaryParents, getWorkingBoundaryName } from 'ducks/boundaries'
import { zoomToBoundary } from '../mapUtilities'

const WorkingBoundaryBreadcrumbs = () => {
  const [glMap, setGLMap] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const workingBoundaryPath = useSelector(getWorkingBoundaryPath)
  const parentBounds = useSelector((state) =>
    getBoundaryParents(state, workingBoundaryPath)
  )
  const activeBoundaryName = useSelector((state) =>
    getWorkingBoundaryName(state, workingBoundaryPath)
  )
  const mapAvailable = useSelector((state) => state.mapProperties.mapAvailable)

  // Normalize and split the path into segments
  const segments = workingBoundaryPath && workingBoundaryPath
    .replace(/^\/+|\/+$/g, '') // Remove leading/trailing slashes
    .split('/')
    .filter(Boolean) // Remove any empty strings

  // Handle cases that aren't in the 'parents' element
  // - 1 segment => World or Country
  // - 2 segments => State (e.g., /us/mi)
  // - 3 segments => County (e.g., /us/mi/wayne)
  const isWorldOrCountry = segments && segments.length === 1
  const isStateOrHigher = segments && segments.length <= 2
  const country = segments && segments[0] && segments[0].length === 2 
    ? segments[0] 
    : null

  useEffect(() => {
    // Loading state only dependent on workingBoundaryPath
    if (workingBoundaryPath && isStateOrHigher) {
      setIsLoading(false)
    } else if (workingBoundaryPath && !isStateOrHigher && parentBounds?.length > 0) {
      setIsLoading(false)
    }
  }, [parentBounds, workingBoundaryPath, isStateOrHigher])

  useEffect(() => {
    if (mapAvailable) {
      // since MapBreadcrumbs is outside the Map tree, window.data.mapbox.currentMap is a workaround for access to the mapbox object
      // see GLMap component for more info
      setGLMap(window.data.mapbox.currentMap)
    }
  }, [mapAvailable])

  useEffect(() => {
    if (country) {
      setSelectedCountry(country)
    }
  }, [country])

  const onClickBreadcrumb = (requestedPath) => {
    if (requestedPath) {
      // TODO: if subboundary style is 'none', set to 'neighborhoods'
      // see regions.js onClickedBreadcrumb
      zoomToBoundary(requestedPath, glMap)
    }
  }

  const handleClickTab = (tabPane) => {
    handleClickTabPane(tabPane)
  }

  const handleCountryChange = (country) => {
    setSelectedCountry(country)
    if (country) {
      onClickBreadcrumb(`/${country}`)
    }
  }

  // --- Loading ---
  if (isLoading || !mapSettings?.countryLabels) {
    return (
      <div>
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    )
  }

  // --- Render ---
  return (
    <div id="working-boundary-breadcrumbs">
      <div className="breadcrumb-group small">
        {/* Country crumb as a dropdown. */}
        <div className="btn-group">
          <button
            type="button"
            id="country-dropdown-toggle"
            className="btn btn-default btn-xs dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="">
              <i
                className={`fa-solid fa-earth-americas ${
                  isWorldOrCountry ? '' : 'margin-right-md'
                }`}
              ></i>
            </span>
            {!isWorldOrCountry && (
              <span className="bold">{selectedCountry?.toUpperCase()}</span>
            )}
          </button>
          <ul id="country-dropdown" className="dropdown-menu">
            {Object.entries(mapSettings.countryLabels).map(([code, label]) => (
              <li key={code}>
                <a
                  href="#"
                  className="bold"
                  onClick={(e) => {
                    e.preventDefault()
                    handleCountryChange(code)
                  }}
                >
                  {label}
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* Parent boundaries */}
        {parentBounds.map((parent, idx) => (
          <span key={idx} onClick={() => onClickBreadcrumb(parent.path)}>
            <i className="fas fa-angle-right"></i>
            <span className="crumb">{parent.name}</span>
          </span>
        ))}

        {/* Active boundary */}
        <span>
          <i className="fas fa-angle-right"></i>
          <a
            href="#"
            className="active-place bold"
            onClick={() => handleClickTab('overview')}
          >
            {activeBoundaryName}
          </a>
        </span>
      </div>
    </div>
  )
}

export default withStore(WorkingBoundaryBreadcrumbs)