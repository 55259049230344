import React from 'react'

/**
 * ImageCounter component displays the number of free images viewed by the user 
 * out of the total available.
 * It only renders if the imagery is metered and user limits are provided.
 * 
 * @param {Object} props - The component props
 * @param {boolean} props.isMetered - indicates if the user is metered (Starter plan)
 * @param {Object} props.userLimits - user's image viewing limits, redux state
 * @param {number} props.userLimits.used - number of images the user has viewed
 * @param {number} props.userLimits.total - total number of images the user can view
 * @returns {JSX.Element|null} The rendered component or null if conditions are not met
 */
const ImageCounter = ({ isMetered, userLimits }) => {
  if (!isMetered || !userLimits) return null

  return (
    <div className="imagery-counter small rounded">
      <span className="imagery-counter-used">
        {`Free images viewed: ${userLimits.used} / ${userLimits.total}`}
      </span>
    </div>
  )
}

export default ImageCounter