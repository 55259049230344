import React from 'react'
import { useSelector } from 'react-redux'
import { Layer, Source } from 'react-map-gl'
import mapSettings from '../mapSettings'

// Redux
import { getMapColor, getSatelliteLayerRendered } from 'ducks/mapProperties'

/**
 * This component is responsible for rendering vector parcel layers on the map.
 * It uses the current map color setting to style the layers.
 *
 * @component
 * @param {string} tokenParam string that represents url query param related to mapbox token. 
 * @returns {React.Element} Returns a Source component that includes FloatLabelsLayer and underlying Layer line and fill.
 */
const VectorParcelLayer = ({tokenParam}) => {
  const { editableStyleTypes, layerIds, sourceIds, rasterMaxZoom } = mapSettings
  const isSatellite = useSelector(getSatelliteLayerRendered)
  const colorType = isSatellite
    ? editableStyleTypes.satelliteParcelLines
    : editableStyleTypes.streetParcelLines
  const currentColor = useSelector(getMapColor(colorType))

  const vectorParcelLineLayer = {
    id: layerIds.vectorParcelsOutline,
    type: 'line',
    source: sourceIds.vectorParcels,
    'source-layer': 'parcels',
    minzoom: rasterMaxZoom,
    paint: {
      'line-color': currentColor,
      'line-width': ['interpolate', ['linear'], ['zoom'], 13, 0.5, 17, 3],
    },
  }

  // Used for parcel hover and clicks
  const vectorParcelInteractionLayer = {
    id: layerIds.vectorParcelsInteraction,
    type: 'fill',
    source: sourceIds.vectorParcels,
    'source-layer': 'parcels',
    paint: {
      'fill-opacity': 0,
    },
  }

  return (
    <>
      <Source
        id={sourceIds.vectorParcels}
        type="vector"
        url={`${window.data.tileserver}/api/v1/parcels?format=mvt&${tokenParam}`}
        promoteId="fid" // sets internal feature id to tileserver fid
        minzoom={rasterMaxZoom}
      >
        <Layer {...vectorParcelLineLayer} />
        <Layer {...vectorParcelInteractionLayer} />
      </Source>
    </>
  )
}

export default VectorParcelLayer
