import React, { useState, useEffect, useRef } from 'react'
import { handleClickTabPane } from 'common/util'
import ReactTooltip from 'react-tooltip'
import InfoTooltip from 'components/Utilities/InfoTooltip'
import { PLAN_NAMES } from 'common/settings'
const ParcelLimitsInfoCard = ({ used, limit, level, hasViewsRemaining }) => {
  const reactMapComponent = window.data.flags.react_map_component; 
  const appConfigLimits =  window.data.limits;
  const handleSignup = (e) => {
    e.preventDefault()
    if (reactMapComponent) {
      handleClickTabPane('signup-signin') // New UI panels for signup + upgrade
    } else {
      window.location = '/plans' // Old UI, either anon or starter
    }
  }
  // Redirects based on plans
  const handleUpgrade = (nextLevel) => {
    const groupPath = window.data?.group?.path || ""; // Ensure it's not undefined
  
    const planRoutes = {
      Pro: "/plans#p=level1",
      Team: `${groupPath}/settings/billing?plan_change=team_flex`,
    };
  
    window.location = planRoutes[nextLevel] || "/plans";
  };

  const upgradeOptions = {
    Anon: { nextLevel: `free ${PLAN_NAMES.FREE}`, limit: appConfigLimits.antiscrape_free },
    Starter: { nextLevel: PLAN_NAMES.PRO, limit: appConfigLimits.antiscrape_pro },
    Pro: { nextLevel: PLAN_NAMES.TEAM, limit: appConfigLimits.antiscrape_team  },
    Team: null,
  };

  const upgradeMessages = Object.fromEntries(
    Object.entries(upgradeOptions).map(([level, data]) => [
      level,
      data
        ? `Upgrade to a ${data.nextLevel} account to increase your daily limit to ${data.limit}, or come back tomorrow when your counter resets.`
        : `Contact support to discuss increasing your daily lookup limits, or come back tomorrow when your counter resets.`
    ])
  );


  return (
    <div className="margin-btm-md rounded">
      <div className='centered'>
        <div className="alert plan-color relative">
          {!hasViewsRemaining && (
            <div className="parcel-limits">
              <span><strong>Property Lookup Daily Limit Reached</strong></span>
              <InfoTooltip
            id='limits'
            tip={upgradeMessages[level] || "You can either upgrade to a higher plan or come back tomorrow when your counter resets."}
            circleSize="fa-xs"
            place="right"
            type="dark"
            delayShow={250}
            className="parcel-details-tooltip"
          />
            </div>
          )}
          <div>
            <span className="medium">
              Property details viewed: <strong>{used}/{limit}</strong>
            </span>
  
            {[PLAN_NAMES.ANON, PLAN_NAMES.FREE, PLAN_NAMES.PRO, PLAN_NAMES.TEAM].map((accountLevel) => {
              if (level !== accountLevel) return null;
              const { nextLevel, limit: nextLimit } = upgradeOptions[accountLevel] || {};
  
              if (accountLevel === PLAN_NAMES.TEAM && !hasViewsRemaining) {
                return (
                  <div key={accountLevel} className="small">
                    <span className="small parcel-limits">
                      Please use the ‘Contact Support’ option under the <i className="fas fa-question-circle"></i> button found in the lower right to discuss your options for
                      increased lookups with our friendly Support team, or come back tomorrow when your counter resets.
                    </span>
                  </div>
                );
              }
              return hasViewsRemaining ? (
                <div className="margin-top-sm"  key={accountLevel}>
                  <span className="small">
                    <a 
                    href="#" 
                    id="limit-signup-link" 
                    className="underline" 
                    target="_blank"
                    onClick={accountLevel === PLAN_NAMES.ANON ? handleSignup : () => handleUpgrade(nextLevel)}>
                      Get a {nextLevel} account
                    </a>
                  </span>
                  <span className='small'><br />to increase your limit to {nextLimit}/day.</span>
                  <br />
                </div>
              ) : (
                <div key={accountLevel}>
                  <div className="centered small">
                    <a
                      className="btn btn-round btn-primary btn-sm"
                      href="#"
                      role="button"
                      target="_blank"
                      id="limit-signup-link"
                      onClick={accountLevel === PLAN_NAMES.ANON ? handleSignup : () => handleUpgrade(nextLevel)}
                    >
                      Upgrade to {nextLevel}
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};


export default ParcelLimitsInfoCard
