import React from 'react'
import SupportMapImage from '../../../../assets/images/support-map.jpg'
const videoTourUrl = 'https://youtu.be/2uGqIrcUFUY'
const supportUrl = 'https://support.regrid.com/property-app/section/property-web-app'
const marketingRoot = 'https://regrid.com/parcels'

/**
 * MapHelpContent component displays the help content for the map.
 *
 * @component
 * @param {Function} props.handleClose - The function to handle the close event and relevant modals.
 * @returns {JSX.Element} The rendered MapHelpContent component.
 */
const MapHelpContent = ({ handleClose }) => (
  <div className="help-modal rounded-lg shadow-gl padded-lg medium">
    <div className="map-options-title-row padding-top-md">
      <h4 className="map-options-title">We put land on the map.</h4>
      <div className="close" onClick={handleClose}>
        <i className="fa fa-times"></i>
      </div>
    </div>
    <div className="map-options-content margin-btm-lg">
      <p className="small">
        Jump right in to every property boundary in the United States: Lot lines, owners, addresses, buildings, land use, location, and more.
      </p>
      <p className="small">
        It doesn't matter whether it's a house or a forest; residential or commercial; publicly or privately owned. You'll find it all here.
      </p>
      <p className="small">
        PS: Want to stream Regrid's nationwide dataset into your own maps, apps, and business processes?{' '}
        <a href={marketingRoot}
          id='help-modal-marketing-link'
          target="_blank"
          rel="noopener noreferrer"
        >
          We are at your service 
          <i className="fa fa-external-link small margin-left-sm"></i>
        </a>.
      </p>

      <img
        src={SupportMapImage}
        alt="Regrid satellite map image"
        className="margin-top-md margin-btm-md"
      />

      {/* <div className="centered padded">
        <button
          // Announcement logic with user hiding preference. Commented out for future use.
          className="btn btn-xs btn-round"
          onClick={() => {
            localStorage.setItem('regridHideHelpOnLoad', true)
            handleClose()
          }}
        >
          Got it, don't show on load
        </button>
      </div> */}

      <div>
        <h5>Help Materials</h5>
        <ul>
          <li>
            <a
              href={supportUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Support Articles
            </a>
          </li>

          <li>
            <a
              href={videoTourUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Tutorial Videos
            </a>
          </li>

          <li>
            <a
              href="#"
              onClick={() => {
                //open zendesk chat
                window.zE('messenger', 'open')
                let launcher = $('.mapboxgl-ff iframe#launcher')
                //change visibility to show
                if (launcher) {
                  debug('chat launcher found. making sure visible')
                  launcher.css('visibility', 'visible')
                } else {
                  debug('chat launcher not found')
                }
                handleClose()
              }}
            >
              Contact Support
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default MapHelpContent
