import React, { forwardRef } from 'react'

/** 
 * Renders a panel with other properties at the same location.
 * 
 * @component
 * @param {Object} props - component props
 * @param {Array} props.otherProperties - array of other properties at the location
 * @param {Function} props.onToggle - fn to toggle state and animate the caret
 * @param {boolean} props.isOpen - whether the panel is open
 * @returns {JSX.Element} Rendered component
 */
const ParcelOthers = forwardRef(({ otherProperties, onToggle, isOpen }, ref) => {
  const { othersPanelRef } = ref
  return (
    <div
      id="property-others"
      ref={othersPanelRef}
      className="panel panel-default margin-btm-md"
    >
      <div
        className={`panel-heading${!isOpen ? ' collapsed' : ''}`}
        role="tab"
        id="heading-other-properties"
        data-toggle="collapse"
        href="#collapse-other-properties"
        aria-expanded={isOpen}
        aria-controls="collapse-other-properties"
        onClick={() => onToggle('dd-other-properties', !isOpen)}
      >
        <h4 className="panel-title">
          <button className="no-btn width-100">
            <span className="pull-left">Other Records at Location</span>
            <i
              id="caret-dd-other-properties"
              className={`fa fa-angle-down pull-right ${
                isOpen ? 'rotate-0' : 'rotate-180'
              }`}
            />
          </button>
        </h4>
      </div>
      <div
        id="collapse-other-properties"
        className={`panel-collapse collapse${isOpen ? ' in' : ''}`}
        role="tabpanel"
        aria-labelledby="heading-other-properties"
        aria-expanded={isOpen}
      >
        <div className="panel-body">
          <p className="small">
            <span>{`Additional property records at this location: `}</span>
            <span className="bold">{`(${otherProperties.length})`}</span>
          </p>
          <ul className="padding-btm-lg">
            {otherProperties.map((property, index) => (
              <li key={index}>
                <a href={property?.path} className="others-here">
                  {property?.headline}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
})

export default ParcelOthers
