import { createSlice } from "@reduxjs/toolkit";


function checkGuestStatus(groups, group) {
  // if no group, user is not viewing a project
  if (!group || !group.id || !group.name) return false; 
  // if group, but no groups, user is a guest to this group
  if (!groups || !groups.length) return true; 
  // check if user has this group in their list of groups they are a part of
  return !groups.some((g) => g.id === group.id && g.name === group.name)
}

const isPaidUser = window.data.has_sitecontrol ?? false;
const isMobile = window.matchMedia("(max-width: 768px)").matches
const isGuest = checkGuestStatus(window?.data?.groups, window?.data?.group)

const initialState = {
  logoMenuOpen: false,
  mapNavOpen: !isMobile && (isPaidUser || isGuest),
  waterfallModalOpen: isGuest ? true : false,
  layersModalOpen: false,
  legendModalOpen: false,
  helpModalOpen: false,
  drawControlOpen: false,
  zoomDisplayOpen: false,
}

// Reducer and actions 
const mapControlsSlice = createSlice({
  name: 'mapControls',
  initialState,
  reducers: {
    closeAllModals: (state) => {
      // close all modals listed in initial state
      return initialState
    },
    closeOtherModals: (state, action) => {
      // close modals other than the modals array (action.payload)
      const modalsToKeepOpen = action.payload // ARRAY

      Object.keys(state).forEach((key) => {
        if (
          typeof state[key] === 'boolean' &&
          key.endsWith('Open') &&
          !modalsToKeepOpen.includes(key) &&
          state[key] !== modalsToKeepOpen[key]
        ) {
          state[key] = false
        }
      })
    },
    setLogoMenuOpen: (state, action) => {
      state.logoMenuOpen = action.payload
    },
    setMapNavOpen: (state, action) => {
      state.mapNavOpen = action.payload
    },
    setWaterfallModalOpen: (state, action) => {
      state.waterfallModalOpen = action.payload
    },
    setLayersModalOpen: (state, action) => {
      state.layersModalOpen = action.payload
    },
    setLegendModalOpen: (state, action) => {
      state.legendModalOpen = action.payload
    },
    setHelpModalOpen: (state, action) => {
      state.helpModalOpen = action.payload
    },
    setDrawControlOpen: (state, action) => {
      state.drawControlOpen = action.payload
    },
    setZoomDisplayOpen: (state, action) => {
      state.zoomDisplayOpen = action.payload
    },
  },
})

export const {
  closeAllModals,
  closeOtherModals,
  setLogoMenuOpen,
  setMapNavOpen,
  setWaterfallModalOpen,
  setLayersModalOpen,
  setLegendModalOpen,
  setHelpModalOpen,
  setDrawControlOpen,
  setZoomDisplayOpen,
} = mapControlsSlice.actions

export default mapControlsSlice.reducer