import React, { Component } from 'react'
import PropTypes from 'prop-types'

class CardForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting:     false,
      cardholderName: props.user ? props.user.name : '',
    }

    this.onClickConfirm = this.onClickConfirm.bind(this)
    this.onChangeCardholderName = this.onChangeCardholderName.bind(this)
    this.paymentMethodHandler = this.paymentMethodHandler.bind(this)
  }
  
  render() {
    let error = this.props.error || this.state.error || this.state.groupNameError

    return (
      <div className='card-form'>
        <div className='row' style={{ marginTop: '2em' }}>
          <div className='col-md-12 form-group'>
            <label>Cardholder name*</label>
            <input
              type='text'
              name='cardholder_name'
              value={this.state.cardholderName}
              placeholder='Cardholder name'
              className='form-control input-lg'
              onChange={this.onChangeCardholderName}
              />
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12 form-group'>
            <label>Payment details*</label>
            <div id='card-element'/>
            {error &&
              <p className='alert alert-warning card-errors' role='alert'>{error}</p>
            }
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 form-group'>
            <label>What are you trying to accomplish with Regrid?</label>
            <input
              type='text'
              name='goals'
              value={this.props.goals}
              className='form-control input-lg'
              onChange={this.props.onChangeGoals}
              />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 form-group'>
            <div className="small subtle">*required</div>
          </div>
        </div>

        {this.props.agreement}

        <div className='centered' style={{ marginTop: '1em' }}>
          <button
            className='btn btn-lg btn-primary btn-round'
            disabled={this.props.disabled || this.state.cardholderName.length < 3}
            onClick={this.onClickConfirm}>
            {this.props.buttonLabel || 'Confirm Order'}
          </button>
        </div>
      </div>
    )
  }

  componentDidMount() {
    this.initializeStripe()
  }

  onChangeCardholderName(e) {
    this.setState({
      cardholderName: e.target.value
    })
  }

  initializeStripe() {
    this.stripe = Stripe(this.props.stripeKey)
    var elements = this.stripe.elements()

    var styles = {
      base: {
        color: 'rgb(85,85,85)',
        backgroundColor: 'white',
        fontFamily: 'Lato, sans-serif',
        fontSize: '18px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#888',
        },
        ':-webkit-autofill': {
          color: '#e39f48',
        },
      },
      invalid: {
        color: '#E25950',
        '::placeholder': {
          color: '#FFCCA5',
        },
      },
    }
    var classes = {
      focus: 'focus',
      empty: 'empty',
      invalid: 'invalid',
    }

    let self = this
    this.cardElement = elements.create('card', { style: styles, classes: classes });
    this.cardElement.mount("#card-element");
    this.cardElement.addEventListener('change', (event) => {
      self.setState({ error: event.error && event.error.message })
      if(event.complete && self.props.onValidCard) {
        self.props.onValidCard()
      }
    });

  }


  // Submit card to stripe for validation...
  onClickConfirm(e) {
    if(this.state.error || this.state.submitting) { return }

    if(!this.props.validGroupName) {
      // We only want to show this after attempting to confirm, so keep it as a separate error state
      this.setState({ groupNameError: 'That account name must not be a URL, is too short, reserved, or needs at least one letter: please try another one.' })
      return
    } else {
      // But clear it and proceed when attempting again with a good one
      this.setState({ groupNameError: null })
    }

    this.setState({ submitting: true })

    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.cardElement,
      billing_details: {
        name: this.state.cardholderName,
      }
    }).then(this.paymentMethodHandler)
  }

  // On response from stripe about the card, we can submit it to our server to process
  paymentMethodHandler(result) {
    if(result.error) {
      this.setState({
        error: result.error.message,
        submitting: false
      })
    } else {
      // Bubble up the PM ID to Signup
      let self = this
      this.props.onConfirmed(result.paymentMethod.id, (msg) => {
        // Takes an error handler callback
        self.setState({
          error: msg,
          submitting: false
        })
      })
    }
  }
}

CardForm.propTypes = {
  stripeKey:      PropTypes.string.isRequired,
  onConfirmed:    PropTypes.func.isRequired,
  onValidCard:    PropTypes.func,
  onChangeGoals:  PropTypes.func,
  goals:          PropTypes.string,
  disabled:       PropTypes.bool,
  buttonLabel:    PropTypes.string,
  validGroupName: PropTypes.bool.isRequired,
  error:          PropTypes.string,  // from outside
  agreement:      PropTypes.element,
}

export default CardForm
