import React from 'react';
import { Layer, Source } from 'react-map-gl';

const CustomRasterLayer = ({ layer }) => {

    const layerOpts = {
      id: `${layer.id}-layer`,
      source: `${layer.id}-source`,
      ...layer.opts
    }

  return (
    <>
      <Source
        id={`${layer.id}-source`}
        type='raster'
        tiles={[ layer.url ]}
        >
          <Layer {...layerOpts} />
      </Source>
    </>
  );
};

export default CustomRasterLayer;