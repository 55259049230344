import { get } from './api';


// Actions
import {
  RECEIVE_CART,
  RECEIVE_CART_ERROR,
  REQUEST_CART,
} from './actions';


// Requests
export const fetchCart = () => dispatch => {
  const start = () => {
    dispatch({
      type: REQUEST_CART
    });
  };

  const done = (data) => {
    dispatch({
      type: RECEIVE_CART,
      data,
    });
  };

  const fail = (error) => {
    dispatch({
      type: RECEIVE_CART_ERROR,
      error
    });
  };

  get('/orders/cart.json', { start, done, fail });
};

export const fetchCartIfNeeded = () => (dispatch, getState) => {
  const cart = getState().cart;

  if(!cart.isLoading && !cart.hasOwnProperty('total')) {
    dispatch(fetchCart());
  }
};

export const updateCart = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_CART,
    data
  });
};

// Helpers
export const getCart = state => {
  return state.cart || {};
};

// Reducers
export const cart = (state = { items: {} }, action) => {
  switch (action.type) {
    case RECEIVE_CART:
      return {
        ...action.data,
        isLoading: false
      };
    case RECEIVE_CART_ERROR:
      return {
        ...state,
        message:   action.error,
        error:     true,
        isLoading: false
      };
    case REQUEST_CART:
      return {
        items:     state.items,
        isLoading: true,
      };
    default:
      return state;
  }
};

export default cart;
