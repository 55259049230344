import React, { useState, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import withStore from 'components/withStore'
import InfoTooltip from 'components/Utilities/InfoTooltip'
import { handleClickTabPane } from '../../common/util'
import { getWithDataset, updateWithDataset } from 'ducks/exportSlice'

// needs a save function
const FilterList = memo(function _FilterList({
  projectQuery,
  currentQuery,
  passEventToFlight,
  updateFilters,
  saveNeeded,
  projectSources,
  coverageSchema,
  handleDropdown,
  activeSourceNames,
}) {
  const dispatch = useDispatch()
  const withDataset = useSelector(getWithDataset)

  const [listState, setListState] = useState({
    multiLocation: false,
    changeLocation: false,
    changeNumLocation: false,
  })

  // New Project or No filters saved:
  // projectQuery: null
  // currentQuery: null

  let currentLocations = currentQuery?.parcel
    ? Object.keys(currentQuery?.parcel)
    : []
  let projectLocations = projectQuery?.parcel
    ? Object.keys(projectQuery?.parcel)
    : []

  let projectLocKey = projectLocations[0] ? projectLocations[0] : null
  let currentLocKey = currentLocations[0] ? currentLocations[0] : null

  let currentFilters =
    currentLocKey && !_.isUndefined(currentQuery?.parcel[currentLocKey])
      ? currentQuery?.parcel[currentLocKey]
      : {}
  let projectFilters =
    projectLocKey && !_.isUndefined(projectQuery?.parcel[projectLocKey])
      ? projectQuery?.parcel[projectLocKey]
      : {}

  let currentFilterKeys = _.isEmpty(currentFilters)
    ? []
    : Object.keys(currentFilters)
  let projectFilterKeys = _.isEmpty(projectFilters)
    ? []
    : Object.keys(projectFilters)

  let addedFilterKeys = []
  let addedFilterVals = []
  let removedFilterKeys = []
  let removedFilterVals = []

  let sourceIds = projectSources?.map((source) => source?.id)
  let hasSources = sourceIds?.length > 0 ? true : false

  useEffect(() => {
    checkLocations()
    if (!listState.multiLocation) {
      updateLocation()
    }
  }, [projectQuery, currentQuery])

  const handleClickTab = (tabPane) => {
    handleClickTabPane(tabPane)
  }

  const handleToggleData = () => {
    // passEventToFlight('export:with-dataset', !withDataset)
    dispatch(updateWithDataset(!withDataset))
  }

  const checkLocations = () => {
    // Check if parcel location has changed
    if (
      !_.isEqual(currentLocations, projectLocations) &&
      currentLocations?.length === projectLocations?.length &&
      !listState.changeLocation
    ) {
      setListState({ ...listState, changeLocation: true })
    }
    // Check if more than one location
    if (
      currentLocations?.length > 1 ||
      (projectLocations?.length > 1 && !listState.multiLocation)
    ) {
      setListState({ ...listState, multiLocation: true })
    }
    // Check if parcel locations have been added or subtracted
    if (
      currentLocations?.length !== projectLocations?.length &&
      !listState.changeNumLocation
    ) {
      setListState({ ...listState, changeNumLocation: true })
    }
  }

  const updateLocation = () => {
    // Check if the filter keys changed
    let keysMatch = _.isEqual(currentFilterKeys, projectFilterKeys)
    if (keysMatch) {
    } else {
      addedFilterKeys = _.concat(
        addedFilterKeys,
        _.difference(currentFilterKeys, projectFilterKeys)
      )
      removedFilterKeys = _.concat(
        removedFilterKeys,
        _.difference(projectFilterKeys, currentFilterKeys)
      )
    }

    // Check if the filter vals changed
    for (const filterKey in currentFilters) {
      let valsMatch = _.isEqual(
        projectFilters[filterKey],
        currentFilters[filterKey]
      )
      if (!valsMatch) {
        addedFilterVals = _.concat(
          addedFilterVals,
          _.difference(currentFilters[filterKey], projectFilters[filterKey])
        )
        removedFilterVals = _.concat(
          removedFilterVals,
          _.difference(projectFilters[filterKey], currentFilters[filterKey])
        )
      }
    }
    let updateNeeded = false
    // Check diffs and print
    if (addedFilterKeys?.length > 0) {
      updateNeeded = true
    } else if (removedFilterKeys?.length > 0) {
      updateNeeded = true
    } else if (addedFilterVals?.length > 0) {
      updateNeeded = true
    } else if (removedFilterVals?.length > 0) {
      updateNeeded = true
    }

    // Update ExportPane state with any unsaved filters for polling.
    let unsavedFiltersObj = {
      keysAdded: addedFilterKeys,
      keysRemoved: removedFilterKeys,
      valsAdded: addedFilterVals,
      valsRemoved: removedFilterVals,
    }
    if (updateNeeded) {
      updateFilters(unsavedFiltersObj)
    }
  }

  const handleSave = () => {
    passEventToFlight('project:save')
  }

  if (
    (projectQuery === null || _.isEmpty(_.omit(projectQuery, 'operation'))) &&
    (currentQuery === null || _.isEmpty(_.omit(currentQuery, 'operation')))
  ) {
    // New projects or projects with no current && no saved filters
    return (
      <>
        <div className="exp-filters padded rounded">
          <div
            className="exp-filters-label"
            type="button"
            data-toggle="collapse"
            data-target="#exp-filters-empty"
            aria-expanded="true"
            aria-controls="exp-filters-empty"
            onClick={handleDropdown}
          >
            <p className="bold">{`Filters Applied (0)`}</p>
            <i className="fa fa-caret-up rotate-0" aria-hidden="true"></i>
          </div>
          <div id="exp-filters-empty" className="collapse in">
            <div className="centered">
              <p className="small bold">No filters applied.</p>
              <p className="small">
                Filtering within a project helps narrow your list of properties.{' '}
                <a
                  href="https://support.regrid.com/property-app/filter-data"
                  target="_blank"
                  rel="noopener noreferrer"
                >{` Read more about the Filter tool here`}</a>
                .
              </p>
              <a
                className="btn btn-primary btn-round"
                href="#"
                onClick={() => handleClickTab('query')}
              >
                Add or Edit Filter
              </a>
            </div>
          </div>
        </div>
      </>
    )
  }

  let sourceValsLength = document.querySelectorAll(
    '.exp-dataset-ul .exp-filters-val-ul li'
  ).length

  return (
    <>
      <div className='exp-filters padded rounded plan-color'>
        <div
          className="exp-filters-label"
          type="button"
          data-toggle="collapse"
          data-target="#exp-filters-index"
          aria-expanded="true"
          aria-controls="exp-filters-index"
          onClick={handleDropdown}
        >
          <h4 className="bold">Filters Applied</h4>
          <i className="fa fa-caret-down" aria-hidden="true"></i>
        </div>
        {projectSources?.length > 0 && (
          <div className="exp-toggle">
            <span className="exp-toggle-label text-right tooltip-parent">
              Only My Data
            </span>
            <div className="exp-toggle-label centered">
              <label>
                <input
                  type="checkbox"
                  checked={!withDataset}
                  onChange={handleToggleData}
                />
                <span className="exp-toggle-display" />
              </label>
            </div>
            <span className="exp-toggle-label text-left tooltip-parent">
              All Data
              <a
                className="tooltip-link"
                href="https://support.regrid.com/property-app/export-data"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InfoTooltip
                  id="all-data-tip"
                  tip="You're seeing this toggle because you have one or more datasets attached to this project. If 'My Data' is selected, your export will contain only properties that are matched to your datasets. If you select 'All Data' all properties that are within the selected geography and any applied base parcel data filters will be included in your export."
                  circleSize="fa-xs"
                  place="right"
                  type="dark"
                  delayShow={250}
                />
              </a>
            </span>
          </div>
        )}
        <div
          id="exp-filters-index"
          className="collapse in "
          style={{ borderTop: '1px solid #1b365d' }}
        >
          <h5 className="medium">
            {`Base Parcel Data Filters (${currentFilterKeys.length})`}
          </h5>
          <p className="small italic subtle">
            Regrid's comprehensive{' '}
            <a
              href="https://regrid.com/parcels"
              target="_blank"
              rel="noopener noreferrer"
            >
              property dataset
            </a>
          </p>
          {
            <ul id="exp-filters-ul">
              {currentFilterKeys?.map((currentKey) => {
                let currentVals = currentFilters[currentKey]
                return (
                  <li className="exp-filters-li" key={`li-${currentKey}`}>
                    <span
                      className="exp-filters-key small"
                      key={`span-key-${currentKey}`}
                    >
                      {coverageSchema[currentKey]?.human}
                    </span>
                    <span
                      className="exp-filters-value"
                      key={`span-ul-${currentKey}`}
                    >
                      <ul className="exp-filters-val-ul">
                        {_.isArray(currentVals) &&
                          currentVals?.map((val) => {
                            return (
                              <li key={`li-${val}`}>
                                <span
                                  className="exp-filters-value small"
                                  key={`span-val-${val}`}
                                >
                                  {_.capitalize(val)}
                                </span>
                              </li>
                            )
                          })}
                        {_.isPlainObject(currentVals) &&
                          Object.keys(currentVals)?.map((val) => {
                            return (
                              <li key={`li-${val}`}>
                                <span
                                  className="exp-filters-value small"
                                  key={`span-val-${val}`}
                                >
                                  {`${_.capitalize(val)}: ${currentVals[val]}`}
                                </span>
                              </li>
                            )
                          })}
                      </ul>
                    </span>
                  </li>
                )
              })}
            </ul>
          }
          {hasSources &&
            sourceIds?.map((sourceId, index) => {
              if (!currentQuery?.sourceId && projectQuery === null) {
                // projectQuery is null when there are no filters saved
                return <React.Fragment key={`filter-list-fragment-${index}`} />
              }

              let sourceQuery = currentQuery?.[sourceId]
                ? currentQuery[sourceId]
                : projectQuery[sourceId]

              if (_.isUndefined(sourceQuery)) {
                // sourceQuery is undefined when projectQuery is not null but there are no filters saved for the source
                return <React.Fragment key={`filter-list-fragment-${index}`} />
              }

              let sourceKeys = Object.keys(sourceQuery)
              return (
                <React.Fragment key={`filter-list-fragment-${index}`}>
                  {index === 0 ? ( // only show the header once
                    <h5 className="padding-top-lg medium">
                      {`Dataset Filters ${
                        sourceValsLength ? `(${sourceValsLength})` : '(0)'
                      }`}
                    </h5>
                  ) : (
                    <></>
                  )}

                  <p className="small italic subtle truncate">
                    {activeSourceNames[sourceId]}
                  </p>
                  <ul id="exp-filters-ul" className="exp-dataset-ul">
                    {sourceKeys?.map((sourceKey, index) => {
                      let sourceVals = sourceQuery[sourceKey]
                        ? sourceQuery[sourceKey]
                        : []
                      return (
                        <li
                          className="exp-filters-li"
                          key={`li-${sourceKey}-${index}`}
                        >
                          <span
                            className="exp-filters-key small"
                            key={`span-key-${sourceKey}`}
                          >
                            {sourceKey}
                          </span>
                          <span
                            className="exp-filters-value"
                            key={`span-value-ul`}
                          >
                            <ul
                              key={`exp-filters-val-ul-${sourceKey}`}
                              className="exp-filters-val-ul"
                            >
                              {_.isArray(sourceVals) &&
                                sourceVals?.map((val, index) => {
                                  // return active and added filter vals
                                  return (
                                    <li key={`li-${val}-${index}`}>
                                      <span
                                        className="exp-filters-value small"
                                        key={`span-key-${val}`}
                                      >
                                        {_.capitalize(val)}
                                      </span>
                                    </li>
                                  )
                                })}
                              {_.isPlainObject(sourceVals) &&
                                Object.keys(sourceVals)?.map((val, index) => {
                                  return (
                                    <li key={`li-${val}-${index}`}>
                                      <span
                                        className="exp-filters-value small"
                                        key={`span-key-${val}`}
                                      >
                                        {`${_.capitalize(val)}: ${
                                          sourceVals[val]
                                        }`}
                                      </span>
                                    </li>
                                  )
                                })}
                            </ul>
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </React.Fragment>
              )
            })}
        </div>
        <div className="exp-filters-footer border-top">
          {saveNeeded ? (
            <div className="exp-filters-ref centered">
              <div className="exp-filters-ref-msg small">
                <i className="fas fa-circle small"></i>
                <span className="italic margin-btm-0">{`This project has some filters applied, but not yet saved. Click 'Save Filter' button to make sure you're seeing the latest.`}</span>
              </div>
              <a
                className="exp-review-btn btn btn-primary btn-round btn-sm"
                onClick={handleSave}
              >
                Save Filters
              </a>
            </div>
          ) : (
            <div className="centered">
              <p className="small padding-btm-md">
                Add a filter to your project to narrow your list of properties.{' '}
                <a
                  href="https://support.regrid.com/property-app/filter-data"
                  target="_blank"
                  rel="noopener noreferrer"
                >{` Read more about the Filter tool here`}</a>
                .
              </p>
              <a
                className="btn btn-primary btn-round btn-sm"
                href="#"
                onClick={() => handleClickTab('query')}
              >
                Add Filter
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  )
})

export default withStore(FilterList)
