import React from 'react';

const Footer = () => {

  return (
    <div className="subtle" style={{ marginTop: '2em' }}>
      <i className="fas fa-info-circle" /> <b>How does this work?</b>
      <p className="small">
        When we decide what color to make a parcel, we run through this list of rules in order until finding a parcel with matching data fields. That will be the parcel's color on the map, <i>even if it matches rules lower on the list</i>. If the default color is enabled, any parcels not matching a rule will have that color.
      </p>
    </div>
  );
};

export default Footer;
