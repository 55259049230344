import React from 'react'
import { useSelector } from 'react-redux'

/**
 * The HoverInfo component renders hover information based on provided data.
 *
 * @param {Object} hoverData - The hover data including title, subtitle, and visibility status.
 * @param {string} hoverData.title - The main title to display.
 * @param {string} hoverData.subtitle - The subtitle to display.
 * @param {Array} hoverData.coords - The coordinates to display.
 * @param {boolean} hoverData.visible - Visibility flag for hover information.
 *
 * @returns {JSX.Element} Rendered hover information.
 */
const HoverInfo = ({ hoverData }) => {
  const drawActive = useSelector((state) => state.mapProperties.drawActive)
  if (!hoverData.title) {
    hoverData.title = "Address Not Available"
  }
  const large =
    hoverData.visible && hoverData.title.length && !hoverData.subtitle.length
      ? ' large'
      : ''
  const coordinates = hoverData.coords



  {/* No need to make the address large */}
  return (
    <div className={`hover-info ${hoverData.visible ? '' : 'hide'}`}>
      <span id="hover-info-title" className={`title${large}`}>
        {hoverData.title}
      </span>
      <span id="hover-info-subtitle" className="subtitle">
        {hoverData.subtitle}
      </span>
      {drawActive && coordinates.length > 0 && (
        <span id="hover-info-coords" className="subtitle">
          <strong>Lat:</strong> {coordinates[0].toFixed(5)},{' '}
          <strong>Lng:</strong> {coordinates[1].toFixed(5)}
        </span>
      )}
    </div>
  )
}

export default HoverInfo
