import { convertToUSCurrency, formattedNumber } from 'common/util'

const CURRENCY = 'currency'
const NUMBER = 'number'
const PERCENTAGE = 'percent'

export const fieldsToFormat = {
  improvval: CURRENCY,
  landval: CURRENCY,
  parval: CURRENCY,
  agval: CURRENCY,
  saleprice: CURRENCY,
  median_household_income: CURRENCY,
  population_density: NUMBER,
  highest_parcel_elevation: NUMBER,
  lowest_parcel_elevation: NUMBER,
  population_density: NUMBER,
  transmission_line_distance: NUMBER,
  housing_growth_past_5_years: PERCENTAGE,
  housing_growth_next_5_years: PERCENTAGE,
  population_growth_past_5_years: PERCENTAGE,
  population_growth_next_5_years: PERCENTAGE,
  household_income_growth_next_5_years: PERCENTAGE,
  taxamt: NUMBER,
  recrdareano: NUMBER,
  gisacre: NUMBER,
  ll_gisacre: NUMBER,
  ll_gissqft: NUMBER,
  ll_gissqm: NUMBER,
  ll_bldg_footprint_sqft: NUMBER,
  ll_bldg_count: NUMBER,
  lat: NUMBER,
  lon: NUMBER,
}

export const formatParcelValue = (field, value) => {
  if (fieldsToFormat[field] === CURRENCY && !isNaN(value)) {
    return convertToUSCurrency(value)
  } else if (fieldsToFormat[field] === NUMBER && !isNaN(value)) {
    return formattedNumber(value)
  } else if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No'
  } else if (fieldsToFormat[field] === PERCENTAGE && !_.isNil(value)) {
    return `${value}%`
  } else {
    return value
  }
}

export const addressFields = [
  'parcelnumb',
  'address',
  'address2',
  'scity',
  'county',
  'state2',
  'szip',
  'urbanization',
  'll_uuid',
]

export const datasetMatchingFields = [
  'placekey',
  'precisely_id'
]

export const areaDemographicsFields = [
  {
    subhead: 'Current Statistics',
    fields: [
      'housing_affordability_index',
      'population_density',
      'median_household_income',
    ],
  },
  {
    subhead: 'Historic Trends',
    fields: [
      'housing_growth_past_5_years',
      'population_growth_past_5_years',
    ],
  },
  {
    subhead: 'Forecasted Trends',
    fields: [
      'housing_growth_next_5_years',
      'population_growth_next_5_years',
      'household_income_growth_next_5_years',
    ],
  },
]

export const riskFactorFields = [
  'fema_nri_risk_rating',
  {
    subhead: 'FEMA Flood Data',
    fields: [
      'fema_flood_zone',
      'fema_flood_zone_subtype',
      'fema_flood_zone_raw',
      'fema_flood_zone_data_date'
    ],
  },
]

export const hiddenMailingAddessFields = [
  'mailadd',
  'mail_address2',
  'careof',
  'mail_addno',
  'mail_addpref',
  'mail_addstr',
  'mail_addsttyp',
  'mail_addstsuf',
  'mail_unit',
  'mail_city',
  'mail_state2',
  'mail_zip',
  'mail_urbanization',
  'mail_country',
]

export const ownerFields = [
  'eo_owner',
  'eo_deedowner',
  'owner',
  'owner2',
  'owner3',
  'owner4',
  'ownfrst',
  'ownlast',
  'owntype',
  'subsurfown',
  'subowntype',
  {
    subhead: 'Mailing Address',
    fields: [
      'mailadd',
      'formatted_mailing_address',
      'mail_address2',
      'careof',
      'mail_addno',
      'mail_addpref',
      'mail_addstr',
      'mail_addsttyp',
      'mail_addstsuf',
      'mail_unit',
      'mail_city',
      'mail_state2',
      'mail_zip',
      'mail_urbanization',
      'mail_country',
    ],
  },
]

export const propertyFields = [
  'saleprice',
  'saledate',
  {
    subhead: 'County Provided Values',
    fields: ['parvaltype', 'parval', 'improvval', 'landval', 'agval'],
  },
  {
    subhead: 'Tax Information',
    fields: ['taxamt', 'taxyear'],
  },
]

export const zoningFields = [
  'zoning',
  'zoning_description',
  'zoning_type',
  'zoning_subtype',
  'zoning_id',
  'zoning_code_link',
  'usecode',
  'usedesc',
  {
    subhead: 'Standardized Land Use Codes',
    fields: [
      ['lbcs_activity', 'lbcs_activity_desc'],
      ['lbcs_function', 'lbcs_function_desc'],
      ['lbcs_structure', 'lbcs_structure_desc'],
      ['lbcs_site', 'lbcs_site_desc'],
      ['lbcs_ownership', 'lbcs_ownership_desc'],
    ],
  },
  {
    subhead: 'Residential & Vacancy Indicators',
    fields: [
      'rdi',
      'usps_vacancy',
      'usps_vacancy_date',
      'dpv_status',
      'dpv_codes',
      'dpv_notes',
      'dpv_type',
      'cass_errorno',
    ],
  },
  {
    subhead: 'Cropland Data Layer Information',
    fields: [
      'cdl_values',
      'cdl_year',
      'cdl_majority_category',
      'cdl_majority_percent',
      'cdl_date',
      'cdl_raw',
    ],
  },
]

export const structureFields = [
  'struct',
  'structno',
  'multistruct',
  'yearbuilt',
  'numstories',
  'numunits',
  'structstyle',
  'recrdareatx',
  'recrdareano',
  'll_assessor_data_date',
  {
    subhead: 'Regrid Calculated Data',
    fields: ['ll_bldg_count', 'll_bldg_footprint_sqft', 'll_address_count'],
  },
]

export const geoFields = [
  'gisacre',
  'deeded_acres',
  'sqft',
  'neighborhood',
  'neighborhood_code',
  'geoid',
  'location_name',
  'coordinates',
  'lat',
  'lon',
  'transmission_line_distance',
  'roughness_rating',
  'highest_parcel_elevation',
  'lowest_parcel_elevation',
  'admin2',
  'admin3',
  {
    subhead: 'Opportunity Zones',
    fields: ['qoz', 'qoz_tract'],
  },
  {
    subhead: 'Census Geographies',
    fields: ['census_block', 'census_blockgroup', 'census_tract', ],
  },
  {
    subhead: 'Regrid Calculated Data',
    fields: ['ll_gisacre'],
  },
  {
    subhead: 'Plat, Block, Lot, Legal Data',
    fields: [
      'legaldesc',
      'plat',
      'book',
      'page',
      'block',
      'lot',
      'subdivision',
    ],
  },
]
// Fields we have marked not to render in "Additional Fields"
export const noShowFields = [
  'original_address',
  'saddno',
  'saddpref',
  'saddstr',
  'saddsttyp',
  'saddstsuf',
  'sunit',
  'city',
  'address_source',
  'ogc_fid',
  'll_stack_uuid',
  'path',
  'admin3_slug',
  'admin1',
  'admin2_slug',
  'admin0',
  'll_gisacre',
  'll_gissqft',
  'll_last_refresh',
  'll_updated_at',
  'll_gissqm'
  

]

export const noDataFields = ['eo_owner', 'eo_deedowner']

export const dropdownSections = [
  {
    id: 'dd-parcel-address',
    title: 'Parcel Details',
    fields: addressFields,
    isOpen: true,
  },
  {
    id: 'dd-owner-information',
    title: 'Owner Information',
    fields: ownerFields,
    isOpen: true,
  },
  {
    id: 'dd-property-sales-value',
    title: 'Property Sales & Value',
    fields: propertyFields,
    isOpen: true,
  },
  {
    id: 'dd-zoning-land-use-vacancy',
    title: 'Zoning, Land Use & Vacancy',
    fields: zoningFields,
    isOpen: true,
  },
  {
    id: 'dd-structure-details',
    title: 'Structure Details',
    fields: structureFields,
    isOpen: true,
  },
  {
    id: 'dd-area-demographics',
    title: 'Area Demographics',
    fields: areaDemographicsFields,
    isOpen: true,
  },
  {
    id: 'dd-geographic-information',
    title: 'Geographic Information',
    fields: geoFields,
    isOpen: true,
  },
  {
    id: 'dd-risk-factors',
    title: 'Risk Factors',
    fields: riskFactorFields,
    isOpen: true,
  },
  {
    id: 'dd-dataset-matching',
    title: 'Dataset Matching',
    fields: datasetMatchingFields,
    isOpen: true,
  },
]

/* Render fields w/in ParcelHighlights component, which maps in the field value(s) */
export const parcelHighlightsFields = {
  address: {
    display: true,
    premium: false,
    label: 'Full Address',
    copyButton: true,
    addonPanel: false,
  },
  measurements: {
    display: true,
    premium: false,
    label: 'Measurements',
    copyButton: false,
    addonPanel: false,
  },
  owner: {
    display: true,
    premium: false,
    label: 'Owner',
    copyButton: false,
    addonPanel: false,
  },
  zoning: {
    display: true,
    premium: false,
    label: 'Zoning type',
    copyButton: false,
    addonPanel: false,
  },
  landuse: {
    display: true,
    premium: true,
    label: 'Land Use',
    copyButton: false,
    addonPanel: false,
  },
  structureCount: {
    display: true, // if struct is false, ParcelHighlights.jsx won't display
    premium: true,
    label: 'Structures on property',
    copyButton: false,
    addonPanel: false,
  },
}

/**
 * `mapFieldToAddon` is an object that maps field names to keys in the `addonData` object.
 * Each key in `mapFieldToAddon` is a field name,
 * and the corresponding value is a key in `addonData`.
 * Used by `addonFields` to get the full list of addon fields.
 * Used by `getAddonDataByField(field)` to get the associated addon data.
 */
export const mapFieldToAddon = {
  eo_owner: 'enhancedOwner',
  eo_deedowner: 'enhancedOwner',
  zoning_type: 'zoning',
  zoning_subtype: 'zoning',
  zoning_id: 'zoning',
  zoning_code_link: 'zoning',
  ll_bldg_count: 'buildings',
  ll_bldg_footprint_sqft: 'buildings',
  ll_address_count: 'addresses',
}

export const addonFields = Object.keys(mapFieldToAddon)

export const addonData = {
  enhancedOwner: {
    title: 'Enhanced Ownership',
    description:
      "Regrid's Enhanced Ownership add-on is the go-to choice for businesses needing the latest and most thorough ownership data, powered by daily updates. First Owner and Deeded Owner are shown here in the Regrid Property App; with the full Enhanced Ownership product (available only via annual data license), we also offer:",
    productLink: 'https://regrid.com/enhanced-property-ownership',
    listItems: [
      'Second Owner',
      'Third Owner',
      'Fourth Owner',
      'Enhanced Owner Mailing Address',
    ],
    schemaLink:
      'https://support.regrid.com/parcel-data/enhanced-ownership-schema',
  },
  zoning: {
    title: 'Standardized Zoning',
    description:
      "Discover the power of Regrid's Standardized Zoning geospatial layer matched to nationwide parcel data, and unlock a deeper understanding of land use. In addition to the zoning fields available here in the Regrid Property App, the full Standardized Zoning dataset along with zoning boundaries (available only via annual data license) also includes key data points such as:",
    productLink: 'https://regrid.com/zoning',
    listItems: [
      'Minimum lot area',
      'Minimum lot width',
      'Max building height',
      'Max Floor Area Ratio (FAR)',
      'Max dwelling unit density',
      'Setback requirements',
      'Open space requirements',
      'Building % Coverage requirements',
    ],
    schemaLink: 'https://support.regrid.com/parcel-data/zoning-schema',
  },
  buildings: {
    title: 'Matched Building Footprints',
    description:
      "Get deeper understanding of what’s on a parcel  with 186+ million building footprints matched with Regrid's premium parcel data. In addition to the building count and square footage fields available here in the Regrid Property App, the full Matched Building Footprints dataset, along with the building polygons (available only via annual data license) also includes key data points such as:",
    productLink: 'https://regrid.com/building-footprints',
    listItems: [
      'Building Unique IDs',
      'Structure Unique IDs',
      'Largest building on parcel indicator',
      'Building centroid lat/longs',
      'WKT field for drawing your own building layer',
    ],
    schemaLink: 'https://support.regrid.com/parcel-data/buildings-schema',
  },
  addresses: {
    title: 'Matched Secondary Addresses',
    description:
      "Gain unparalleled address coverage and accuracy with Regrid's Matched Secondary Addresses product. In addition to the total count of addresses associated with a parcel available here in the Regrid Property App, the full Matched Secondary Addresses dataset (only available via license) also includes such key data points as:",
    productLink: 'https://regrid.com/validated-us-addresses',
    listItems: [
      'Unit address field',
      'Full set of address fields for each matched address',
      'Regrid Parcel UUID for easy joining',
      'USPS vacancy flag',
      'USPS no delivery flag (nostat)',
      'DPV (delivery type)',
      'Geocode type',
    ],
    schemaLink: 'https://support.regrid.com/parcel-data/address-schema',
  },
}
