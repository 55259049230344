import React, { Component } from 'react'

const PlanCompare = ({ plans, millionsUS, millionsCA }) => {
  let marketingRoot = marketingURL()
  let supportRoot = window.data.support_url
  let checkCircle = <i className="fas fa-check-circle"></i>
  let planStarter = plans[0]
  let planPro = plans[1]
  let planTeam = plans[2]
  let linkTarget = 'llext' // opens a new tab that gets reused
  const limits = window.data.limits

  return (
    <div className="row mx-auto" style={{ marginBottom: '2em' }}>
      <div className="col-md-12">
        <div className="plan-compare-row">
          <table className="table plan-compare-table">
            <thead>
              <tr>
                <th className="col-md-4 lead sans th-first" scope="col">
                  Feature / Details
                </th>
                <th className="col-md-2 lead sans plan-col-free" scope="col">
                  <span>{planStarter.name}</span>
                  <span className="th-subtitle">{`$${planStarter.prices.monthly} (No card required)`}</span>
                </th>
                <th className="col-md-2 lead sans plan-col-pro" scope="col">
                  <span>{planPro.name}</span>
                  <span className="th-subtitle">{`$${planPro.prices.monthly}/month or $${planPro.prices.annual}/year`}</span>
                </th>
                <th className="col-md-2 lead sans plan-col-team" scope="col">
                  <span>{planTeam.name}</span>
                  <span className="th-subtitle">{`$${
                    planTeam.prices.monthly
                  }/month${
                    planTeam.per_seat_pricing ? ' per user' : ''
                  }`}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  <a
                    href={`${marketingRoot}parcels`}
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    {millionsUS}+ Million Parcels in US
                    <br />
                    {millionsCA}+ Million Parcels in Canada
                  </a>
                </td>
                <td className="col-md-2 plan-col-free">{checkCircle}</td>
                <td className="col-md-2 plan-col-pro">{checkCircle}</td>
                <td className="col-md-2 plan-col-team">{checkCircle}</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  <a
                    href={`${marketingRoot}apps`}
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    Regrid Mobile App
                  </a>
                </td>
                <td className="col-md-2 plan-col-free">{checkCircle}</td>
                <td className="col-md-2 plan-col-pro">{checkCircle}</td>
                <td className="col-md-2 plan-col-team">{checkCircle}</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  <a
                    href={`${marketingRoot}tutorials/create-a-project`}
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    Projects
                  </a>
                </td>
                <td className="col-md-2 plan-col-free"></td>
                <td className="col-md-2 plan-col-pro">5</td>
                <td className="col-md-2 plan-col-team">Unlimited</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  Seats per Account
                </td>
                <td className="col-md-2 plan-col-free">1</td>
                <td className="col-md-2 plan-col-pro">1</td>
                <td className="col-md-2 plan-col-team">Multiple</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  <a
                    href={`${marketingRoot}tutorials/filter-data`}
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    Filter Data{' '}
                  </a>
                  &
                  <a
                    href={`${marketingRoot}tutorials/styling-map-data`}
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Style Maps
                  </a>
                </td>
                <td className="col-md-2 plan-col-free"></td>
                <td className="col-md-2 plan-col-pro">{checkCircle}</td>
                <td className="col-md-2 plan-col-team">{checkCircle}</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  <a
                    href={`${marketingRoot}tutorials/creating-a-survey`}
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    Collect Your Own Data
                  </a>
                </td>
                <td className="col-md-2 plan-col-free"></td>
                <td className="col-md-2 plan-col-pro">{checkCircle}</td>
                <td className="col-md-2 plan-col-team">{checkCircle}</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  Premium Data Fields
                  <span className="small">
                    <a
                      href={`${marketingRoot}land-use-codes`}
                      target={linkTarget}
                      rel="noopener noreferrer"
                    >
                      1. Standardized Land Use Codes
                    </a>
                  </span>
                  <span className="small">
                    <a
                      href={`${marketingRoot}vacancy`}
                      target={linkTarget}
                      rel="noopener noreferrer"
                    >
                      2. Vacancy & Residential Indicators
                    </a>
                  </span>
                  <span className="small">
                    <a
                      href={`${marketingRoot}buildings`}
                      target={linkTarget}
                      rel="noopener noreferrer"
                    >
                      3. Buildings Count & Footprint Sqft
                    </a>
                  </span>
                </td>
                <td className="col-md-2 plan-col-free"></td>
                <td className="col-md-2 plan-col-pro">View Only</td>
                <td className="col-md-2 plan-col-team">{checkCircle}</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  <a
                    href={`${supportRoot}/property-app/follow-property`}
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    Follow Properties
                  </a>
                </td>
                <td className="col-md-2 plan-col-free"></td>
                <td className="col-md-2 plan-col-pro">100</td>
                <td className="col-md-2 plan-col-team">Unlimited</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  Download Rows of Parcel Data
                </td>
                <td className="col-md-2 plan-col-free"></td>
                <td className="col-md-2 plan-col-pro">5,000</td>
                <td className="col-md-2 plan-col-team">5,000</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  <a
                    href={`${marketingRoot}tutorials/focus-area-measure-tools`}
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    Draw Custom Focus Areas
                  </a>
                </td>
                <td className="col-md-2 plan-col-free"></td>
                <td className="col-md-2 plan-col-pro">5</td>
                <td className="col-md-2 plan-col-team">Unlimited</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  <a
                    href={`${marketingRoot}tutorials/import-a-spreadsheet`}
                    target={linkTarget}
                    rel="noopener noreferrer"
                  >
                    Import Spreadsheet Data
                  </a>
                </td>
                <td className="col-md-2 plan-col-free"></td>
                <td className="col-md-2 plan-col-pro">{checkCircle}</td>
                <td className="col-md-2 plan-col-team">{checkCircle}</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  Print Maps (PDF)
                </td>
                <td className="col-md-2 plan-col-free"></td>
                <td className="col-md-2 plan-col-pro">{checkCircle}</td>
                <td className="col-md-2 plan-col-team">{checkCircle}</td>
              </tr>
              <tr>
                <td className="col-md-4 td-first" scope="row">
                  Property Lookups per Day
                </td>
                <td className="col-md-2 plan-col-free">{limits.antiscrape_free} </td>
                <td className="col-md-2 plan-col-pro">1,000</td>
                <td className="col-md-2 plan-col-team">2,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PlanCompare
