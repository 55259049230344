import React from 'react'
import { useSelector } from 'react-redux'
import InfoTooltip from 'components/Utilities/InfoTooltip'
import { getSpinCount, getCurrentCount } from 'ducks/exportSlice'
import { getActiveProjectRootName } from 'ducks/projects'
import {
  getWorkingBoundaryName, // headline (pretty name) of the boundary being used for Export
  getWorkingBoundaryPath, // path of the boundary being used for Export
  getCurrentMapBoundaryName, // headline (pretty name) of the boundary that the map extent is currently focused on
} from 'ducks/boundaries'

/**
 * UI component for the stats/boundaries subsection of the export pane
 * 
 * @component
 * @param {boolean} unsavedBoundary Is there an unsaved boundary?
 * @param {boolean} pendingSave - Is a save pending?
 * @param {Function} handleSaveRoot - Is a save pending?
 * @returns {React.Element} 
 */

const ExportStats = ({
  unsavedBoundary, //boolen
  pendingSave,
  handleSaveRoot,
}) => {
  const showSpinner = useSelector(getSpinCount)
  const projectRootName = useSelector(getActiveProjectRootName)
  const workingBoundaryPath = useSelector(getWorkingBoundaryPath)
  const workingBoundaryName = useSelector((state) =>
    getWorkingBoundaryName(state, workingBoundaryPath)
  )
  const currentMapBoundaryName = useSelector(getCurrentMapBoundaryName)
  const currentCount = useSelector(getCurrentCount)

  return (
    <div className="exp-stats padded rounded">
      <div className="exp-stats-label">
        <h4 className="bold">Boundary</h4>
      </div>
      <div className="project-geography">
        <span className="stats-label tooltip-parent">
          Working Boundary
          <a
            className="tooltip-link"
            href="https://www.youtube.com/watch?v=bkxA5Q7hPVo&t=46s"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InfoTooltip
              id="select-geography-tip"
              tip="Select a smaller geography by going to the Bounds dropdown, or a larger one by clicking the Breadcrumbs in the bar up top. Read our documentation about boundaries and breadcrumbs by clicking here."
              circleSize="fa-xs"
              place="right"
              type="dark"
              delayShow={250}
            />
          </a>
        </span>
        {projectRootName && (
          <p className={`stats-value${unsavedBoundary ? '' : ''}`}>
            {workingBoundaryName ? workingBoundaryName : projectRootName}
          </p>
        )}
      </div>
      <div className="current-records">
        <span className="stats-label tooltip-parent">
          Properties Currently Selected
          <a
            className="tooltip-link"
            href="https://support.regrid.com/property-app/draw-focus-areas"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InfoTooltip
              id="property-selected-tip"
              tip="You can narrow your selected properties further by using our Filter tool (see below), or by drawing a Focus Area. Read our documentation about Focus Areas by clicking here."
              circleSize="fa-xs"
              place="right"
              type="dark"
              delayShow={250}
            />
          </a>
        </span>
        {showSpinner ? (
          <span className="stats-value">
            <div className="">
              <i className="fas fa-spinner fa-spin" />
            </div>
          </span>
        ) : (
          <span className="stats-value">{numberWithCommas(currentCount)}</span>
        )}
      </div>
      {unsavedBoundary ? (
        <div className="current-geography centered">
          <div className="geography-warning padding-top-md">
            <i className="fas fa-circle small"></i>
            <span className="italic small">
              Project home boundary is{' '}
              <em className="italic">{projectRootName}</em>.
            </span>
          </div>
          <div className="geography-warning tooltip-parent padding-btm-md">
            <span className="italic small">
              Set <em className="italic">{currentMapBoundaryName}</em> as your
              project home?
            </span>
            <InfoTooltip
              id="boundary-save-tip"
              tip="Exports pull from your Project's Home boundary. Click Save Boundary to change Project Home to the boundary you currently see on the map."
              circleSize="fa-xs"
              place="right"
              type="dark"
              delayShow={250}
            />
          </div>
          <a
            className={`btn btn-primary btn-round btn-sm${
              pendingSave ? ' disabled' : ''
            }`}
            onClick={handleSaveRoot}
          >
            {!pendingSave ? 'Update Project Boundary' : 'Saving...'}
          </a>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default ExportStats
