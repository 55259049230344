import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getWorkingBoundaryPath } from 'ducks/boundaries';
import { addBeingEditedRule } from 'ducks/styles';
import { getPlaceParcelPath } from 'ducks/boundaries';
import settings from 'common/settings';

const AddRuleBtn = () => {

  
  const dispatch = useDispatch();
  const workingBoundaryPath = useSelector(getWorkingBoundaryPath);
  const [isClicked, setIsClicked] = useState(false);

  // This should be the filterPath on the parcel source, NOT the boundary.parcel_path (#4809)
  //const activeParcelPath = useSelector(state => getPlaceParcelPath(state, workingBoundaryPath));

  const handleClick = () => {
    
    var newRule = {
      field:     null,
      source:    null,
      predicate: 'is',
      value:     null,
      fill:      settings.styles.defaultFillColor,
      line:      settings.styles.defaultLineColor,
    };

    dispatch(addBeingEditedRule(newRule));

    setIsClicked(true);
  };

  return (
    <div className="centered">
      <a className="add-rule btn btn-primary btn-md" onClick={handleClick}>
        <i className="fas fa-plus" />
        {isClicked ? ' Add Another Style Rule' : ' Add a Style Rule'}
      </a>
    </div>
  );
};

export default AddRuleBtn;
