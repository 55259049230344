import React, {memo} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import classNames from 'common/utils/classNames';

const InfoTooltip = memo(function _InfoTooltip({ id, tip, circleSize=null, ...styleProps}) {

  // See https://github.com/wwayne/react-tooltip#options for the different styling options on ReactTooltip.

  return (
    <>
      {/* Adjust icons size by passing in the appropriate FA size class (https://fontawesome.com/v5.15/how-to-use/on-the-web/styling/sizing-icons)  */}
      <i className={classNames('fas', 'fa-info-circle', 'margin-xs', circleSize)} data-tip={tip} data-for={id} />
      <ReactTooltip
        id={id}
        {...styleProps}
        html={true}
        effect="solid"
        delayHide={200}
        delayUpdate={200}
      />
    </>
  );
});

InfoTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  // tip: PropTypes.string.isRequired, // tip can also allow html
  circleSize: PropTypes.string
};

export default InfoTooltip;
