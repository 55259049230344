import React from 'react'
import PropertyImage from './PropertyImage'
import ImageSignupNotice from './PropertyImage/ImageSignupNotice'

/**
 * PropertyImageWrapper component conditionally renders either the 
 * PropertyImage component or the ImageSignupNotice component based on 
 * the presence of a currentUserID (indicating a logged-in user).
 * 
 * @param {Object} props - component props
 * @param {string} props.birdseye - URL for birdseye imagery
 * @param {string} props.streetview - URL for streetview imagery
 * @param {string} props.currentUserID - ID of the current user
 * @param {boolean} props.reactMapComponent - indicates MapboxGL or Leaflet map
 * @returns {JSX.Element} The rendered component
 */
const PropertyImageWrapper = ({ birdseye, streetview, currentUserID, reactMapComponent }) => {
  debug('PI Wrapper rendered', birdseye, streetview, currentUserID, reactMapComponent)

  if (!currentUserID) {
    debug('PI Wrapper: rendering PI Signup')
    return (
      <ImageSignupNotice reactMapComponent={reactMapComponent} />
    )
  } else {
    debug('PI Wrapper: rendering PI component')
    return (
      <PropertyImage
        birdseye={birdseye}
        streetview={streetview}
        currentUserID={currentUserID}
        reactMapComponent={reactMapComponent}
      />
    )
  }
}

export default PropertyImageWrapper