import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'common/utils/classNames';
import coordinatesImg from '@/assets/draw/coordinates.jpg';
import { setDrawActive, setDrawMode, setCurrentDrawTool } from 'ducks/mapProperties';

const MeasureCoords = ({ handleClose }) => {
  const dispatch = useDispatch()
  const drawActive = useSelector((state) => state.mapProperties.drawActive)
  const currentTool = useSelector(
    (state) => state.mapProperties.currentDrawTool
  )
  const isCurrentTool = currentTool === 'measureCoords'
  const [hovered, setHovered] = useState(false)

  const measureCoordsClasses = classNames(
    'measure-coords',
    'modal-tile',
    'shadow-gl-light',
    {
      active: isCurrentTool,
    }
  )

  const handleMeasureCoordsClick = () => {
    if (drawActive && isCurrentTool) {
      // If currently drawing, stop drawing
      dispatch(setDrawActive(false))
      dispatch(setDrawMode(null))
      dispatch(setCurrentDrawTool(null))
    } else {
      // Start drawing a line for measurement
      dispatch(setDrawActive(true))
      dispatch(setDrawMode('draw_point'))
      dispatch(setCurrentDrawTool('measureCoords'))
      handleClose()
    }
  }

  return (
    <div
      className={
        measureCoordsClasses + (isCurrentTool || hovered ? ' plan-color-glow' : '')
      }
      onClick={handleMeasureCoordsClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
    >
      <div className="modal-tile-img">
        <img src={coordinatesImg} alt="Measure Coordinates" />
      </div>
      <div
        className={`modal-tile-detail draw-tool-detail ${
          isCurrentTool ? 'plan-color-accent white' : ''
        }`}
      >
        <span className="md bold">
          <i className="fa fa-crosshairs margin-right-md"></i>
          Coordinates
        </span>
        <div className="small">
          Click on the map to get the latitude/longitude coordinates of a point.
        </div>
      </div>
    </div>
  )
};

export default MeasureCoords;