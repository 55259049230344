import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'common/utils/classNames'
import { isNullOrUndefined } from 'common/util'
import {
  formatParcelValue,
  addonFields,
  mapFieldToAddon,
  addonData,
  noDataFields,
} from '../propertySettings'
import InfoTooltip from 'components/Utilities/InfoTooltip'
import ParcelAddonPanel from './ParcelAddonPanel'
import ParcelFieldBadge from './ParcelFieldBadge'
import UnitSelectorDropdown from './UnitSelectorDropdown'
import {
  convertValue
} from 'components/Map/MapControls/MapOptions/DrawTools/drawUtils'
import {
  setUserPreference,
  getUsersPreference,
} from 'common/utils/UserPreferences'
/**
 * Renders a single field in the parcel details section.
 *
 * @component
 * @param {Object} props - component props
 * @param {string} props.field - field to render
 * @param {string} props.fieldSecondary - (optional) secondary field to render
 * @param {Object} props.parcel - full parcel object
 * @returns {JSX.Element} Rendered component
 */
const ParcelDetailsField = ({ field, fieldSecondary = null, parcel }) => {
  const [showAddonPanel, setShowAddonPanel] = useState(false)
  let label = parcel.key[field]

    /* 
      Parcel Highlights needs some exceptions to work here since 
      field.key (address) has a label of Parcel address here but it should be Full Address &
      field.key (measurements) returns null so it needs to be assigned to one of its base values [ll_gissqft]
      */

  if (field === 'Full Address' || field === 'Measurements') {
    label = field;
    field = field === 'Full Address' ? 'address' : 'll_gissqft';
  }
  
  let value = parcel.fields[field] // allowed values

  let valueSecondary = fieldSecondary ? parcel.fields[fieldSecondary] : null // allowed values
  let addonDataKey = null
  let badgeType = null // 'team', 'pro', 'addon'
  let populatedLink = '/plans'
  let calculatedAreainAcres = null

  const isFieldPremium = !!parcel.premium_field_metadata[field]
  let isUsa = isPathUSA(parcel?.path)
  const [unitValue, setUnitValue] = useState(calculatedAreainAcres);
  const [unit, setUnit] = useState("acres");

  // a premium field with a permitted value, e.g. ['eo_owner'] => 'No Data'
  const isPaidUser = window.data.has_sitecontrol
  const fieldHasValueAndIsPremium = isFieldPremium && (field === "coordinates" || !isNullOrUndefined(value));
  const isLocked = isUsa 
  ? isFieldPremium && !fieldHasValueAndIsPremium 
  : fieldHasValueAndIsPremium && !isPaidUser;
  const referenceLink = parcel.reference_links[field]
  const toolTip = parcel.tooltips[field]
  const upsellLink = parcel.reference_links['plans_link']
  const isAddonField = addonFields.includes(field)
  const isException = noDataFields?.includes(field)
  const planName = window.data?.plan_simple?.toLowerCase() || null
  const unitPref = localStorage.getItem('unitPreference') ?? 'acres';
  


  /**
   * Get the addon data key for a field
   * @function getAddonDataKey
   * @param {string} field - field to check for a value
   * @returns {string} - Addon data key
   */
  const getAddonDataKey = (field) => {
    const key = mapFieldToAddon[field]
    if (!key) {
      debug(`No addonDataKey found for field: ${field}`)
      return null
    }
    return key
  }

  /**
   * Check if a field has content to render
   * @function checkRenderField
   * @param {string} field - field to check for a value
   * @param {string} secondaryField - (optional) secondary field to check for a value
   * @param {object} parcel - parcel object
   * @returns {boolean} - Returns true if field(s) have values
   */
  const checkRenderField = (field, secondaryField, parcel) => {
    if (field && secondaryField) {
      // e.g. ['lbcs_activity', 'lbcs_activity_desc']
      const fieldValExists =
        !isNullOrUndefined(parcel.fields[field]) ||
        !isNullOrUndefined(parcel.premium_field_metadata[field])
      const secondaryValExists =
        !isNullOrUndefined(parcel.fields[secondaryField]) ||
        !isNullOrUndefined(parcel.premium_field_metadata[secondaryField])
      return fieldValExists && secondaryValExists
    } else if (field === 'coordinates') {
      return (
        !isNullOrUndefined(parcel.fields['lat']) &&
        !isNullOrUndefined(parcel.fields['lon']) &&
        !isNullOrUndefined(value)
      ) // 'coordinates' => `${lat}, ${lon}`
    } else if (field) {
      return (
        !isNullOrUndefined(parcel.fields[field]) ||
        !isNullOrUndefined(parcel.premium_field_metadata[field])
      )
    }
    return false
  }

  /**
   * Format the value for display
   * @function formatValue
   * @param {object} props - field, fieldSecondary, value, valueSecondary, parcel
   * @returns {JSX.Element} - Formatted value
   */
  const formatValue = ({
    field,
    fieldSecondary,
    value,
    valueSecondary,
    parcel,
  }) => {
    let combinedValue = null

    if ((value || valueSecondary) && fieldSecondary) {
      const formattedValue = formatParcelValue(field, value)
      const formattedSecondValue = formatParcelValue(
        fieldSecondary,
        valueSecondary
      )
      combinedValue =
        !isNullOrUndefined(formattedValue) &&
        !isNullOrUndefined(formattedSecondValue) ? (
          <span style={{ flex: 3 }}>
            {formattedValue + ' : ' + formattedSecondValue}
          </span>
        ) : null
    }

    if (combinedValue) {
      value = combinedValue
    } else if (!combinedValue && !fieldSecondary) {
      value = formatParcelValue(field, value)
    }
    // Callback to handle unit change
    const handleUnitChange = ({ unitValue, unit }) => {
            let convertedValue = convertValue(
        'area',
        calculatedAreainAcres,
        unit,
        'acres'
      )
      setUnitValue(convertedValue)
      setUnit(unit);
      if (window.data.signed_in){
        localStorage.setItem('unitPreference', unit)
      }
    };

    useEffect(() => {
      const unitPref = localStorage.getItem('unitPreference');
      if (unitPref && unitPref !== unit) {
        setUnit(unitPref);
      }
    }, []);
    

    /** Formatting exceptions based on field */
    switch (field) {
      case 'coordinates': // `${lat}, ${lon}`
        value =
          !isNullOrUndefined(parcel.fields['lat']) &&
          !isNullOrUndefined(parcel.fields['lon'])
            ? `${parcel.fields['lat']}, ${parcel.fields['lon']}`
            : null
        break
      case 'zoning_code_link':
        if (parcel.fields.zoning_code_link) {
          value = (
            <a
              href={parcel.fields.zoning_code_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Ordinances
            </a>
          )
        }
        break
      case 'll_uuid':
        value = parcel.fields.ll_uuid
        break
      case 'formatted_mailing_address':
        value = parcel.fields.formatted_mailing_address
        value = (
          <span>
            {value[0]} <br/> {value[1]}
          </span>
        )
        break
      case 'll_gisacre':
        calculatedAreainAcres = parcel.fields.ll_gisacre
        let convertedValue = convertValue('area', parcel.fields.ll_gisacre, unitPref,'acres')
        value = (
          <div>
            <UnitSelectorDropdown unitValue={unitValue ?? convertedValue} unit={unit} onUnitChange={handleUnitChange} />
          </div>
        )
        break
      default:
        break
    }
    
    return value
  }

  /**
   * Get the badge type for a field value
   * @function getBadgeType
   * @param {string} fieldId - field to check for a badge
   * @returns {string} - 'team' or 'pro'
   */
  const getBadgeType = (fieldId) => {
    const fieldType = parcel?.premium_field_metadata[fieldId]?.tier || null
    if (fieldType === 'team') {
      return 'team'
    } else if (fieldType === 'premium') {
      return 'pro'
    }
    return null
  }

  /**
   * Get the populated upsell link based on the badge and account types
   * @function populatedUpsellLink
   * @param {string} badge - badge type
   * @returns {string} - Populated upsell link
   */
  const populatedUpsellLink = (badge) => {
    if (planName === 'pro') {
      // User already has an account, direct to billing page
      const groupPath =
        `${window.data?.group?.path}/settings/billing`
      return `${groupPath}?plan_change=team_flex`
    } else if (!planName || planName !== 'pro' || planName !== 'team') {
      // User does not have an account, direct to plans page with plan selected
      return `${upsellLink}#p=${badge === 'team' ? 'team_flex' : 'level1'}`
    }
  }
  /**
   * Get the value tag for a field
   * @function getValueTag
   * @param {object} props - field, value, isLocked, isException, parcel, isAddonField, upsellLink, showAddonPanel, setShowAddonPanel
   * @returns {JSX.Element} - Value tag
   */
  const getValueTag = ({
    field,
    value,
    isLocked,
    isException,
    parcel,
    isAddonField,
    upsellLink,
    showAddonPanel,
    setShowAddonPanel,
    populatedLink,
  }) => {
        if (isLocked) {
          value = (
        <span className="unlock-text" style={{ flex: 3 }}>
          {`Unlock with 
            ${
              parcel.premium_field_metadata[field].tier === 'team'
                ? ' Team'
                : ' Pro'
            }
          `}
        </span>
      )
      return (
        <div className="value">
          <div className="flex-row-between">
            <a
              href={populatedLink}
              target="_blank"
              className="small margin-btm-none unlock-link"
            >
              <span style={{ flex: 3 }}>{value}</span>
              <ParcelFieldBadge
                fieldId={field}
                badgeType={badgeType}
                fieldType={parcel.premium_field_metadata[field]?.tier || null}
                showAddonPanel={showAddonPanel}
                setShowAddonPanel={setShowAddonPanel}
                isException={isException}
                plan={planName}
                populatedLink={populatedLink}
              />
            </a>
          </div>
          {showAddonPanel && isAddonField && (
            <ParcelAddonPanel
              addonField={field}
              addonDataKey={addonDataKey}
              setShowAddonPanel={setShowAddonPanel}
              addonData={addonData}
            />
          )}
        </div>
      )
    } else if (!isLocked && isAddonField) {
      return (
        <div className="value">
          <div className="flex-row-between">
            <span style={{ flex: 3 }}>{value}</span>
            {isException && (
              <ParcelFieldBadge
                fieldId={field}
                badgeType={badgeType}
                fieldType={parcel.premium_field_metadata[field]?.tier || null}
                showAddonPanel={showAddonPanel}
                setShowAddonPanel={setShowAddonPanel}
                isException={isException}
                plan={planName}
                populatedLink={populatedLink}
              />
            )}
          </div>
          {showAddonPanel && isAddonField && (
            <ParcelAddonPanel
              addonField={field}
              addonDataKey={addonDataKey}
              setShowAddonPanel={setShowAddonPanel}
              addonData={addonData}
            />
          )}
        </div>
      )
    } else if (!isLocked && !isAddonField && !isNullOrUndefined(value)) {
      return (
        <div className="value">
          <div className="flex-row-between">
            <span style={{ flex: 3 }}>{value}</span>
            {isException && badge}
          </div>
        </div>
      )
    }
    return null
  }

  /**
   * Get the label tag for a field
   * @function getLabelTag
   * @param {object} props - label, toolTip, referenceLink, field
   * @returns {JSX.Element}
   */
  const getLabelTag = ({ label, toolTip, referenceLink, field }) => {
    if (field === 'll_uuid') {
      // Temp exception for 'll_uuid' label, change readable from 'LL_UUID'
      label = 'Regrid UUID'
    } else if (field === 'coordinates') {
      label = 'Centroid Coordinates'
    } else if (field === 'll_gissqm' || field === 'll_gisacre') {
      label = 'Calculated Parcel Area'
    }
    const fieldType = parcel?.premium_field_metadata[field]?.tier || null

    const renderLabel = (label, content) => (
      <div className="key subtle small" style={{ flex: 3 }}>
        {label} {content}
      </div>
    )

    const renderBadge = () => (
      <ParcelFieldBadge
        fieldId={field}
        badgeType="addon"
        fieldType={fieldType}
        showAddonPanel={showAddonPanel}
        setShowAddonPanel={setShowAddonPanel}
        isException={isException}
        plan={planName}
        populatedLink={populatedLink}
      />
    )

    let content
    if (toolTip && referenceLink) {
      content = (
        <a href={referenceLink} target="llext">
          <InfoTooltip
            id={field}
            tip={toolTip}
            circleSize="fa-xs"
            place="right"
            type="dark"
            delayShow={250}
            className="parcel-details-tooltip"
          />
        </a>
      )
    } else if (!toolTip && referenceLink) {
      content = (
        <a href={referenceLink} target="llext">
          <i className="fas fa-xs fa-info-circle" />
        </a>
      )
    } else if (toolTip && !referenceLink) {
      content = (
        <InfoTooltip
          id={field}
          tip={toolTip}
          circleSize="fa-xs"
          place="right"
          type="dark"
          delayShow={250}
          className="parcel-details-tooltip"
        />
      )
    }

    return (
      <>
        {renderLabel(label, content)}
        {isAddonField && renderBadge()}
      </>
    )
  }

  /** ----------------- RENDER ----------------- */

  /** Addon data key */
  if (isAddonField) {
    addonDataKey = getAddonDataKey(field)
  }

  /** Badge Type */
  badgeType = getBadgeType(field)

  /** Populated upsell link */
  populatedLink = populatedUpsellLink(badgeType)

  /** Formatted value for display */
  value = formatValue({
    field,
    fieldSecondary,
    value,
    valueSecondary,
    parcel,
  })

  /** Do not render if field is locked with no value or is locked value. */
  const hasValueOrLockedValue = checkRenderField(field, fieldSecondary, parcel)
  if (!hasValueOrLockedValue) return null

  /** Value Tag */
  const valueTag = getValueTag({
    field,
    value,
    isLocked,
    isException,
    parcel,
    isAddonField,
    upsellLink,
    showAddonPanel,
    setShowAddonPanel,
    populatedLink,
  })

  /** Label Tag */
  const labelTag = getLabelTag({
    label,
    toolTip,
    referenceLink,
    field,
  })

  return (
    <div className={classNames('field', { premium: isFieldPremium })}>
      <div className="field-label">{labelTag}</div>
      <div className="field-value">{valueTag}</div>
    </div>
  )
}

ParcelDetailsField.propTypes = {
  field: PropTypes.string.isRequired,
  fieldSecondary: PropTypes.string,
  parcel: PropTypes.object.isRequired,
}

export default ParcelDetailsField
