import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Rule from '../Components/Rules/Rule';
import withStore from '../../withStore';

import { getActiveRules, setBeingEditedRules, setExistingRules, ensureSourceConsistency } from 'ducks/styles';
import { fetchActiveFilters, getActiveFilters, areFiltersLoading } from 'ducks/filters';
import { getPlaceParcelPath } from 'ducks/boundaries';
import { getWorkingBoundaryPath } from 'ducks/boundaries';
import { getActiveSourceIds } from 'ducks/sources';

const RulesContainer = ({ passEventToFlight }) => {

  const beingEditedRules = useSelector(state => state.styles.beingEditedRules);
  const activeRules = useSelector(getActiveRules);
  const workingBoundaryPath = useSelector(getWorkingBoundaryPath);
  const parcelPath = useSelector(state => getPlaceParcelPath(state, workingBoundaryPath));
  const filters = useSelector(state => getActiveFilters(state, parcelPath));
  const activeSourceIds = useSelector(getActiveSourceIds);
  const dispatch = useDispatch();

  // Loads up saved styles
  useEffect(() => {
    dispatch(setExistingRules());
  }, []);

  // Renders applied styles and current project styles when loaded
  useEffect(() => {
    passEventToFlight('tiles:set-style', { styles: activeRules });
  }, [activeRules]);

  // Fetches filters on mount and other state updates
  useEffect(() => {
    dispatch(fetchActiveFilters(parcelPath));
  }, [parcelPath, activeSourceIds]);

  // When we remove a source from the map/project state, we need to remove its styles too
  useEffect(() => {
    dispatch(ensureSourceConsistency(activeSourceIds));
  }, [activeSourceIds]);


  // Drag and Drop Logic
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedOverItem, setDraggedOverItem] = useState(null);

  const onDragStart = (e, idx) => {
    setDraggedItem(beingEditedRules[idx]);
    e.dataTransfer.effectAllowed = 'move';
  };

  const onDragOver = (e, idx) => {
    e.preventDefault();
    setDraggedOverItem(beingEditedRules[idx]);

    // If the item is dragged over itself, ignore
    if(draggedItem === draggedOverItem) {
      return;
    }

    // Filter out the currently dragged item
    let items = beingEditedRules.filter(item => item !== draggedItem);

    // Add the dragged item after the dragged over item
    items.splice(idx, 0, draggedItem);
    dispatch(setBeingEditedRules(items));
  };

  const onDragEnd = () => {
    setDraggedItem(null);
    setDraggedOverItem(null);
  };

 return (
  <>
    {filters ? (
      <div className="rules-container">
        {beingEditedRules.map((rule, idx) => (
          <Rule
            key={idx}
            id={idx}
            field={rule.field}
            source={rule.source}
            predicate={rule.predicate}
            value={rule.value}
            path={rule.path}
            fill={rule.fill}
            line={rule.line}
            filters={filters}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
          />
        ))}
      </div>
    ) : (
      <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
    )}
  </>
 )
};

RulesContainer.propTypes = {
  passEventToFlight: PropTypes.func.isRequired
};

export default withStore(RulesContainer);
