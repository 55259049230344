import {
  SET_BOOKMARKS,
  BOOKMARKS_FETCHED_INITIAL,
  //BOOKMARK_ADDED,
  BOOKMARK_REMOVED,
  BOOKMARK_UPDATED,
  FOLLOW_REMOVED,
} from './actions';
import { createReducer } from '@reduxjs/toolkit';


export const setBookmarks = (data) => (dispatch) => {
    dispatch({
      type: SET_BOOKMARKS,
      data
    });
};

export const fetchBookmarks = () => (dispatch) => {
  fetch('/profile/follows.json')
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: SET_BOOKMARKS,
        parcels: data.follows.parcels,
        places:  data.follows.places,
      });
      dispatch({
        type: BOOKMARKS_FETCHED_INITIAL,
        data: true
      })
    })
    .catch(err => debug(`Bookmarks fetch failed: ${err}`));
};

export const removeBookmark = (path, sourceId, callback) => (dispatch) => {
  fetch(`${path}/bookmark.json`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      bookmark: 'remove',
      source_id: sourceId
    })
  })
  .then(res => res.json())
  .then(data => {
    dispatch({
      type:     BOOKMARK_REMOVED,
      sourceId: sourceId,
      path:     path
    });
    callback.success();
  })
  .catch(err => {
    debug(`Bookmark removal failed: ${err}`);
    callback.failed();
  });
};

export const removeFollow = (followId, callback) => (dispatch) => {
  fetch(`/notify/follows/${followId}.json`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: FOLLOW_REMOVED,
        followId: followId,
      });
      callback.success();
    })
    .catch(err => {
      debug(`Unfollow failed: ${err}`);
      callback.failed();
    });

};

/*export const removeAllBookmarks = (sourceId, callback) => (dispatch) => {
  fetch('/profile/bookmarks.json', {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ source_id: sourceId })
  })
  .then(res => res.json())
  .then(data => {
    dispatch({
      type: SET_BOOKMARKS,
      data: data.bookmarks
    });
    callback.success();
  })
  .catch(err => {
    debug(`Removal of all bookmarks failed: ${err}`);
    callback.failed();
  });
};*/


export const followBookmark = (path, sourceId, callback) => (dispatch) => {
  fetch(`/notify/follows.json`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      path:      path,
      source_id: sourceId,
    })
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type:     BOOKMARK_UPDATED,
        bookmark: data.bookmark,
      });
      callback.success();
    })
    .catch(err => {
      debug(`Bookmark removal failed: ${err}`);
      callback.failed();
    });
};

export const unfollowBookmark = (blextId, callback) => (dispatch) => {
  fetch(`/notify/follows/${blextId}.json`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(data => {
      dispatch({
        type: BOOKMARK_UPDATED,
        bookmark: data.bookmark,
      });
      callback.success();
    })
    .catch(err => {
      debug(`Bookmark removal failed: ${err}`);
      callback.failed();
    });
};



export const getBookmarkSources = (state) => {
  return state.bookmarks.bookmarkSources;
};

export const getFollowedPlaces = (state) => {
  return state.bookmarks.followedPlaces;
};

export const getBookmarksNeedInitialize = (state) => {
  return !state.bookmarks.fetchedInitial;
};

export const hasBookmarksAvailable = (state) => {
  if(state.bookmarks.bookmarkSources.length > 0) {
    return state.bookmarks.bookmarkSources.some((b_source) => b_source.bookmarks.length > 0);
  }
  return null;
};

export const hasFollowedPlaces = (state) => {
  return state.bookmarks.followedPlaces && state.bookmarks.followedPlaces.length > 0;
};

export const getNumberOfBookmarks = (state) => {
  if(hasBookmarksAvailable(state)) {
    return state.bookmarks.bookmarkSources.reduce((acc, b_source) => acc + b_source.bookmarks.length, 0);
  }
  return null;
};

const initialState = {
  bookmarkSources: [],
  fetchedInitial: false
};


// Note: this uses redux toolkit's 'createReducer' (with immer) to simplify state manipulation
// https://redux.js.org/usage/structuring-reducers/immutable-update-patterns#simplifying-immutable-updates-with-redux-toolkit

export const bookmarks = createReducer(initialState, {
  SET_BOOKMARKS: (state, action) => {
    state.bookmarkSources = action.parcels;
    state.followedPlaces  = action.places;
  },

  BOOKMARKS_FETCHED_INITIAL: (state, action) => {
    state.fetchedInitial = action.data;
  },

  BOOKMARK_ADDED: (state, action) => {
    var { sourceId, bookmarkData, source } = action;

    // Put it in the right source in the list
    var sourceEntry = state.bookmarkSources.find(s => s.source_id == sourceId);

    if(isNullOrUndefined(sourceEntry)) {
      // We don't have this source yet, create an entry
      sourceEntry = {
        source_id:  source.id,
        name:       source.name,
        bookmarks:  []
      };
      state.bookmarkSources.push(sourceEntry);
    }
    
    sourceEntry.bookmarks.unshift(bookmarkData);
  },

  BOOKMARK_REMOVED: (state, action) => {
    var { sourceId, path } = action;
    var sourceEntry = state.bookmarkSources.find(s => s.source_id == sourceId);
    if(sourceEntry) {
      sourceEntry.bookmarks = sourceEntry.bookmarks.filter((item, index) => item.path !== path);
    }
  },

  FOLLOW_REMOVED: (state, action) => {
    var { followId } = action;
    state.followedPlaces = _.reject(state.followedPlaces, { id: followId });
  },

  // For follow/unfollow updates, we receive the bookmark slim_json again
  BOOKMARK_UPDATED: (state, action) => {
    let { bookmark } = action
    var sourceEntry = state.bookmarkSources.find(s => s.source_id == bookmark.source_id);
    debug('mark_updated sourceEntry:')
    debug(sourceEntry)
    if(sourceEntry) {
      let oldIndex = sourceEntry.bookmarks.findIndex((b) => b.id === bookmark.id)
      if(oldIndex > -1) {
        // Drop-in replacement
        debug(`found old mark at ${oldIndex}`)
        sourceEntry.bookmarks[oldIndex] = bookmark
      } else {
        debug('did not find old mark')
      }
    }
  },
});

export default bookmarks;