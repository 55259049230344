import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { post } from 'ducks/api';
import FocusAreaListItemGL from './FocusAreaListItemGL';

const FocusAreaListGL = ({focusAreasAttachedToProject, passEventToFlight, project}) => {

  if (_.isEmpty(project)) {
    return ( null )
  }

  if (!focusAreasAttachedToProject || focusAreasAttachedToProject.list.length === 0) {
    return (
      <div className='margin-btm-lg'>
        <h5>
          Project focus areas: <span className="badge margin-left-md">0</span>
        </h5>
        <p className="italic small">
          No focus areas attached to project: {project.name}
        </p>
      </div>
    )
  }

  const regionList = focusAreasAttachedToProject.list.map(area => <FocusAreaListItemGL
    key={area.path}
    area={area}
    projectPath={project.path}
    passEventToFlight={passEventToFlight}
    folder={'Focus Areas'}
    active={true}
  />);

  return (
    <div className="margin-btm-lg">
      <h5>
        Project focus areas:{' '}
        <span className="badge">{regionList.length}</span>
      </h5>
      {regionList}
    </div>
  )
};

FocusAreaListGL.propTypes = {
  passEventToFlight:  PropTypes.func.isRequired,
  project:            PropTypes.object,
};

export default FocusAreaListGL;
