import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { map } from 'lodash';

const DEFAULT_NEXT_QUESTION = '__DEFAULT__'
const END_OF_SURVEY = '__END__'

const SEAnswer = props => {

  // Decide what value to use for the "next question" selector
  let nextQuestion = DEFAULT_NEXT_QUESTION;
  if(props.next) { 
    if(props.next.index === END_OF_SURVEY)  {
      nextQuestion = END_OF_SURVEY
    } else { 
      nextQuestion = props.next.disambiguation || DEFAULT_NEXT_QUESTION
    }
  } 

  return <>
    <div className='answer row'>
      <div className='col-md-6'>
        <input type='text' name='label' className='form-control'
          placeholder='Enter an answer' maxLength='500'
          value={props.label}
          data-question-index={props.questionIndex}
          data-answer-index={props.index}
          onChange={props.handlers.changeAnswerText}
          />
      </div>
      <div className='col-md-4'>
        <select className="form-control"
          value={nextQuestion}
          onChange={props.handlers.changeAnswerNext}
          data-question-index={props.questionIndex}
          data-answer-index={props.index}>
          <option value="__DEFAULT__">Continue to next question</option>
          {map(props.nextQuestions, (nq, i) => (
            <option key={i} value={nq.disambiguation}>{nq.question}</option>
          ))}
          <option value='__END__'>End survey</option>
        </select>
      </div>
      <div className='col-md-2 right'>
        <a href='#' title='Move this answer one position up' tabIndex='-1'
          data-question-index={props.questionIndex}
          data-answer-index={props.index}
          data-direction='up'
          onClick={props.handlers.moveAnswer}>
          <i className='fas fa-arrow-up' aria-hidden='true'/>
        </a>
        <a href='#' title='Move this answer one position down' tabIndex='-1'
          data-question-index={props.questionIndex}
          data-answer-index={props.index}
          data-direction='down'
          onClick={props.handlers.moveAnswer}>
          <i className='fas fa-arrow-down' aria-hidden='true'/>
        </a>
        <a href='#' title='Remove this answer' tabIndex='-1'
          onClick={e => { props.handlers.removeAnswer(e, props.questionIndex, props.index) }}>
          <i className='fas fa-trash' aria-hidden='true'/>
        </a>
      </div>
    </div>
  </>
};


SEAnswer.propTypes = {
  label:          PropTypes.string.isRequired,
  column:         PropTypes.string,
  handlers:       PropTypes.objectOf(PropTypes.func).isRequired,
  index:          PropTypes.number.isRequired,
  questionIndex:  PropTypes.number.isRequired,
  nextQuestions:  PropTypes.array.isRequired,
  next:           PropTypes.object,
}

export default SEAnswer;
