// Bookmarks + Follows
export const SET_BOOKMARKS = 'SET_BOOKMARKS';
export const BOOKMARKS_FETCHED_INITIAL = 'BOOKMARKS_FETCHED_INITIAL';
export const BOOKMARK_ADDED = 'BOOKMARK_ADDED';
export const BOOKMARK_REMOVED = 'BOOKMARK_REMOVED';
export const BOOKMARK_UPDATED = 'BOOKMARK_UPDATED';
export const FOLLOW_REMOVED = 'FOLLOW_REMOVED';

// Boundaries
export const RECEIVE_BOUNDARIES = 'RECEIVE_BOUNDARIES';
export const REQUEST_BOUNDARIES = 'REQUEST_BOUNDARIES';
export const UPDATE_BOUNDARY_STYLE = 'UPDATE_BOUNDARY_STYLE';
export const UPDATE_BOUNDARY_CHILDREN = 'UPDATE_BOUNDARY_CHILDREN';
export const UPDATE_WORKING_BOUNDARY = 'UPDATE_WORKING_BOUNDARY';
export const UPDATE_CURRENT_MAP_BOUNDARY = 'UPDATE_CURRENT_MAP_BOUNDARY';

// Cart
export const RECEIVE_CART = 'RECEIVE_CART';
export const RECEIVE_CART_ERROR = 'RECEIVE_CART_ERROR';
export const REQUEST_CART = 'REQUEST_CART';

// Filters & query
export const RECEIVE_FILTERS = 'RECEIVE_FILTERS';
export const REQUEST_FILTERS = 'REQUEST_FILTERS';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const REQUEST_QUERY_LAYER = 'REQUEST_QUERY_LAYER'
export const RECEIVE_QUERY_LAYER = 'RECEIVE_QUERY_LAYER'
export const TILES_RELOAD = 'TILES_RELOAD'

// Map Properties
export const RECEIVE_MAP_PROPERTIES = 'RECEIVE_MAP_PROPERTIES';
export const RECEIVE_ZOOM_LEVEL = 'RECEIVE_ZOOM_LEVEL';
export const RECEIVE_FLOAT_LAYER = 'RECEIVE_FLOAT_LAYER';
export const VECTOR_LAYER_RENDERED = 'VECTOR_LAYER_RENDERED';
export const SET_BASE_LAYER_TYPE = 'SET_BASE_LAYER_TYPE';
export const SET_THIRD_PARTY_LAYER = 'SET_THIRD_PARTY_LAYER';
export const SET_BUILDINGS_LAYER_RENDER = 'SET_BUILDINGS_LAYER_RENDER';
export const SET_OWNER_NAME_LAYER_RENDER = 'SET_OWNER_NAME_LAYER_RENDER';
export const SET_PARCEL_NUMBER_LAYER_RENDER = 'SET_PARCEL_NUMBER_LAYER_RENDER';
export const SET_MAP_COLORS = 'SET_MAP_COLORS';
export const SET_MAP_COLOR = 'SET_MAP_COLOR';
export const RECEIVE_PANE = 'RECEIVE_PANE';
export const RENDER_MAP = 'RENDER_MAP';
export const SET_TYPEAHEAD_PLACE = 'SET_TYPEAHEAD_PLACE';
// TODO would tools be better as a dedicated slice of state? 
export const SET_DRAW_ACTIVE = 'SET_DRAW_ACTIVE';
export const SET_DRAW_MODE = 'SET_DRAW_MODE';
export const SET_MEASURING_DISTANCE = 'SET_MEASURING_DISTANCE';
export const SET_MEASURING_AREA = 'SET_MEASURING_AREA';
export const SET_MEASURING_COORDS = 'SET_MEASURING_COORDS';
export const SET_DRAWING_FOCUS = 'SET_DRAWING_FOCUS';
export const SET_CURRENT_DRAW_TOOL = 'SET_CURRENT_DRAW_TOOL';
export const SET_UNIT_AREA = 'SET_UNIT_AREA';
export const SET_UNIT_DISTANCE = 'SET_UNIT_DISTANCE';
export const ZOOM_TO_SOURCE_EXTENT = 'ZOOM_TO_SOURCE_EXTENT';

// Parcelverse
export const RECEIVE_PARCELVERSE_DATA = 'RECEIVE_PARCELVERSE_DATA';
export const RECEIVE_PARCELVERSE_DATA_ERROR = 'RECEIVE_PARCELVERSE_DATA_ERRO';
export const REQUEST_PARCELVERSE_DATA = 'REQUEST_PARCELVERSE_DAT';

// Projects
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const ACTIVATE_PROJECT = 'ACTIVATE_PROJECT';
export const DEACTIVATE_PROJECT = 'DEACTIVATE_PROJECT';
export const REQUEST_PROJECT = 'REQUEST_PROJECT';
export const RECEIVE_PROJECT = 'RECEIVE_PROJECT';
export const RECEIVE_PROJECT_ERROR = 'RECEIVE_PROJECT_ERROR';
export const REQUEST_PROJECTS = 'REQUEST_PROJECTS';
export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS';
export const RECEIVE_PROJECTS_ERROR = 'RECEIVE_PROJECTS_ERROR';
export const UPDATE_PROJECT_REGIONS = 'UPDATE_PROJECT_REGIONS';

// Parcels
// If you tap a place, you might have multiple parcels or points at that location:
export const SET_PARCEL_OPTIONS = 'SET_PARCEL_OPTIONS';
export const CLEAR_PARCEL_OPTIONS = 'CLEAR_PARCEL_OPTIONS';
export const LOAD_PARCEL_OPTIONS = 'LOAD_PARCEL_OPTIONS';
export const LOAD_PARCEL_OPTIONS_ERROR = 'LOAD_PARCEL_OPTIONS_ERROR';

// You will select and manipulate single parcels
export const SELECT_PARCEL = 'SELECT_PARCEL';
export const SELECT_ANOTHER_PARCEL = 'SELECT_ANOTHER_PARCEL';
export const DESELECT_PARCEL = 'DESELECT_PARCEL';
export const REQUEST_PARCEL = 'REQUEST_PARCEL';
export const RECEIVE_PARCEL = 'RECEIVE_PARCEL';

// Places
export const RECEIVE_CONTEXT = 'RECEIVE_CONTEXT';

// Sources
export const ACTIVATE_SOURCE = 'ACTIVATE_SOURCE';
export const ACTIVATE_SOURCES = 'ACTIVATE_SOURCES';
export const DEACTIVATE_SOURCE = 'DEACTIVATE_SOURCE';
export const REQUEST_PLACE_SOURCES = 'REQUEST_PLACE_SOURCES';
export const RECEIVE_PLACE_SOURCES = 'RECEIVE_PLACE_SOURCES';
export const REQUEST_SOURCE = 'REQUEST_SOURCE';
export const RECEIVE_SOURCE = 'RECEIVE_SOURCE';
export const RECEIVE_SOURCE_ERROR = 'RECEIVE_SOURCE_ERROR';

//Styles
export const SET_STYLES = 'SET_STYLES';
export const ADD_BEING_EDITED_RULE = 'ADD_BEING_EDITED_RULE';
export const SET_BEING_EDITED_RULES = 'SET_BEING_EDITED_RULES';
export const SET_ACTIVE_RULES = 'SET_ACTIVE_RULES';
export const SET_DEFAULT_RULE = 'SET_DEFAULT_RULE';
export const RESET_RULES = 'RESET_RULES';

// Users
export const LOG_OUT = 'LOG_OUT';