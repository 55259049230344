import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setImageLimits } from '../../../ducks/userProperties'
import { every, isEqual } from 'lodash'
import withStore from '../../../components/withStore'
// Assets
import fallbackBirdseye from '../../../../assets/images/fallback/bing-birdseye.jpg'
import fallbackStreetview from '../../../../assets/images/fallback/bing-streetside.jpg'

// Components
import ImageCounter from './PropertyImage/ImageCounter'
import ImageToggle from './PropertyImage/ImageToggle'
import ImageMeteredNotice from './PropertyImage/ImageMeteredNotice';
import ImageDisplay from './PropertyImage/ImageDisplay';

/**
 * PropertyImage component displays property images with toggling functionality 
 * between streetview and birdseye views.
 * It manages the state of image blobs, fetches images as needed, 
 * and handles user permissions and limits.
 * 
 * @param {Object} props - component props, passed through Flight.js component
 * @param {string} props.birdseye - URL, PropertiesController#proxy_birdseye
 * @param {string} props.streetview - URL, PropertiesController#proxy_streetside
 * @param {Object} props.reactMapComponent - true if MapboxGL, false if Leaflet
 * @returns {JSX.Element} PropertyImage component
 */
const PropertyImage = ({ birdseye, streetview, currentUserID, reactMapComponent }) => {
  /**
   * State variables to manage the image blobs for streetview and birdseye views.
   * Blobs are null if fetch is needed, and false if the image is not available.
   *
   * @type {string|boolean|null} streetviewBlob - URL of the streetview image
   * or null if fetch is needed, false if not available
   * @type {string|boolean|null} birdseyeBlob - URL of the birdseye image
   * or null if fetch is needed, false if not available
   */
  const [streetviewBlob, setStreetviewBlob] = useState(null)
  const [birdseyeBlob, setBirdseyeBlob] = useState(null)
  const [birdseyeAngle, setBirdseyeAngle] = useState(0)
  const [currentlyShowing, setCurrentlyShowing] = useState('streetview')
  const [userShowStreetview, setUserShowStreetview] = useState(false)
  const [userShowBirdseye, setUserShowBirdseye] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [isLoadingLimits, setIsLoadingLimits] = useState(false)

  // Only false if image and limits are both loaded
  const isLoading = isLoadingImage || isLoadingLimits

  /**
   * User image limits from Redux store.
   * @type {Object}
   * @property {boolean} allowed - Indicates if imagery is allowed
   * @property {boolean} metered - Indicates if imagery is metered
   * @property {string} need - Indicates if an upgrade or signup is needed
   * @property {number} remaining - Number of free remaining images
   * @property {number} total - Number of total images
   * @property {number} used - Number of used free images
   */
  const userLimits = useSelector(
    (state) => state.userProperties.imageLimits,
    isEqual
  )

  /* Refs to track currently viewed property */
  const prevBirdseyeRef = useRef()
  const prevStreetviewRef = useRef()

  /* Refs to track previous birdseye angle */
  const prevBirdseyeAngleRef = useRef()

  const dispatch = useDispatch()
  let hasStreetview = streetviewBlob !== null && streetviewBlob !== false
  let hasBirdseye = birdseyeBlob !== null && birdseyeBlob !== false
  let noImages = streetviewBlob === false && birdseyeBlob === false
  let isMetered = userLimits?.metered ?? null
  let isAllowed = userLimits?.allowed ?? null
  let needUpgrade = userLimits?.need === 'upgrade' ?? null
  let needRegister = userLimits?.need === 'register' ?? null
  let hasFreeRemaining = userLimits?.remaining > 0 ?? null

  /**
   * Fetches an image from the given URL and sets the image blob URL.
   *
   * @param {string} type - streetview or birdseye, informs url and setImgCallback
   * @param {boolean} showRequest - user requested the image (Starter plan)
   */
  const fetchImage = async (type, showRequest = false) => {
    let url = type === 'streetview' ? streetview : birdseye
    if (!url) return // URL is not available from props

    let hasAngle =
      type === 'birdseye' && birdseyeAngle !== prevBirdseyeAngleRef.current
    if (hasAngle) {
      url = `${birdseye}?angle=${birdseyeAngle}`
    }

    // Set the callback function based on the image type
    let setImgCallback =
      type === 'streetview' ? setStreetviewBlob : setBirdseyeBlob

    // Check if the image is already available
    let isNeeded =
      type === 'streetview' ? streetviewBlob === null : birdseyeBlob === null

    // Check if the user is allowed to view the image
    let readyToFetch = false
    if (isNeeded && !isMetered) {
      readyToFetch = true // Fetch if pro user
    } else if (isNeeded && isMetered && isAllowed && showRequest) {
      readyToFetch = true // Fetch if metered, allowed, and requested by user
    } else if (hasAngle) {
      readyToFetch = true // Fetch if birdseye and angle has changed
    }

    if (readyToFetch) {
      setIsLoadingImage(true) // Set loading state

      try {
        const response = await fetch(url, { credentials: 'include' }) // Fetch the image
        if (response.ok && response.status === 200) {
          // Blob prevents a second request to the endpoint with the img src.
          const imageBlob = await response.blob() // Convert response to a Blob
          const imageUrl = URL.createObjectURL(imageBlob) // Create local URL for the image
          setImgCallback(imageUrl)
          if (showRequest || isMetered === false) {
            // Set user requested state and kick off image limits fetch only if successful
            type === 'streetview'
              ? setUserShowStreetview(true)
              : setUserShowBirdseye(true)
          }
          // Update the birdseye angle ref if it has changed
          if (hasAngle) {
            prevBirdseyeAngleRef.current = birdseyeAngle
          }
          return
        } else if (response.status === 204) {
          setImgCallback(false) // false if no img
        } else {
          setImgCallback(false) // false if no img
        }
      } catch (error) {
        debug('fetchImage error:', error)
        setImgCallback(false) // false if no img
      } finally {
        // Will run regardless of whether the return statement is hit
        setIsLoadingImage(false) // Reset loading state
      }
    }
  }

  /**
   * Fetches the user's image limits from the server and updates the Redux store.
   * Sets the limits loading state while fetching.
   */
  const fetchImageLimits = async () => {
    setIsLoadingLimits(true)

    try {
      const response = await fetch(`/users/${currentUserID}/img_limits`)
      let limits = await response.json()
      if (!isEqual(limits, userLimits)) {
        dispatch(setImageLimits(limits))
      }
    } catch (error) {
      debug('PI fetchImageLimits error:', error)
    } finally {
      setIsLoadingLimits(false)
    }
  }

  /**
   * Resets the property state when the property changes.
   * If this is the first load, prevStreetviewRef.current will be undefined.
   * @param {string} bird - birdseye URL
   * @param {string} street - streetview URL
   */
  const resetPropertyState = (bird, street) => {
    if (prevStreetviewRef.current !== undefined) {
      if (currentlyShowing === 'birdseye') {
        // Reset to default streetview
        setCurrentlyShowing('streetview')
      }
      // Reset image blobs
      setStreetviewBlob(null)
      setBirdseyeBlob(null)

      // Reset state if free user, to prompt user to 'Show image'
      if (userShowStreetview || userShowBirdseye) {
        setUserShowStreetview(false)
        setUserShowBirdseye(false)
      }
    }

    // Update refs to track the current property
    prevBirdseyeRef.current = bird
    prevStreetviewRef.current = street

    // Reset birdseye angle
    if (birdseyeAngle !== 0) {
      setBirdseyeAngle(0)
      prevBirdseyeAngleRef.current = 0
    }
  }

  /* Fetch image limits on mount and when the user requests to view an image */
  useEffect(() => {
    if (isMetered === false) return // return if paid user, no limits needed

    // Check userLimits are null, initialState from Redux
    const userLimitsNull = (limits) => {
      return _.every(limits, (value) => value === null)
    }

    // Set user requested state and kick off image limits fetch only if successful
    if (userShowStreetview || userShowBirdseye || userLimitsNull(userLimits)) {
      fetchImageLimits()
    }
  }, [userShowStreetview, userShowBirdseye])

  /* Update local state variables based on user limits */
  useEffect(() => {
    if (userLimits) {
      isMetered = userLimits?.metered ?? null
      isAllowed = userLimits?.allowed ?? null
      needUpgrade = userLimits?.need === 'upgrade' ?? null
      needRegister = userLimits?.need === 'register' ?? null
      hasFreeRemaining = userLimits?.remaining > 0 ?? null
    }
  }, [userLimits])

  /** Updates to streetview & birdseye URLs indicate a new property. */
  useEffect(() => {
    resetPropertyState(birdseye, streetview)
  }, [birdseye, streetview])

  /* Fetch image if not metered and the view changes. */
  useEffect(() => {
    if (isMetered === false) {
      fetchImage(currentlyShowing, true)
    }
  }, [currentlyShowing, isMetered])

  /* Fetch image if the birdseye angle changes. */
  useEffect(() => {
    if (reactMapComponent && hasBirdseye) {
      // Birdseye angle ref is only set if rotate is successful
      fetchImage('birdseye')
    }
  }, [birdseyeAngle])

  /**
   * Handles the toggle between streetview and birdseye views.
   * @param {string} type - streetview or birdseye
   */
  const handleToggle = (type) => {
    setCurrentlyShowing(type)
  }

  /* Handles the upgrade to Pro button click and redirects to the plans page. */
  const handleUpgrade = () => {
    window.location = '/plans'
  }

  /**
   * Handles the show image button click.
   * Fetches the image if not metered or if user is allowed.
   */
  const handleShowImg = () => {
    if (!isMetered || isAllowed) {
      fetchImage(currentlyShowing, true)
    }
  }

  /* Check if the current view is unavailable */
  let isCurrentUnavailable =
    (currentlyShowing === 'streetview' && !hasStreetview) ||
    (currentlyShowing === 'birdseye' && !hasBirdseye)

  /* Check if the current type is shown and the user requested it */
  let isTypeShown =
    currentlyShowing === 'streetview' ? userShowStreetview : userShowBirdseye

  /* Check if the current type has not been requested yet */
  let isTypeUnrequested =
    !isLoading &&
    ((currentlyShowing === 'streetview' && streetviewBlob === null) ||
      (currentlyShowing === 'birdseye' && birdseyeBlob === null))

  /* Ensure the last metered image will show */
  let isLastMetered =
    !isLoading &&
    ((currentlyShowing === 'streetview' && streetviewBlob) ||
      (currentlyShowing === 'birdseye' && birdseyeBlob))

  return (
    <>
      <ImageDisplay
        type="streetview"
        href={streetview}
        srcBlob={streetviewBlob}
        isMetered={isMetered}
        isTypeShown={isTypeShown}
        currentlyShowing={currentlyShowing}
        fallbackImg={fallbackStreetview}
        anchorTarget="llstreetside"
        birdseyeAngle={birdseyeAngle}
        setBirdseyeAngle={setBirdseyeAngle}
      />
      <ImageDisplay
        type="birdseye"
        href={
          birdseyeAngle > 0 ? `${birdseye}?angle=${birdseyeAngle}` : birdseye
        }
        srcBlob={birdseyeBlob}
        isMetered={isMetered}
        isTypeShown={isTypeShown}
        currentlyShowing={currentlyShowing}
        fallbackImg={fallbackBirdseye}
        anchorTarget="llbirdseye"
        birdseyeAngle={birdseyeAngle}
        setBirdseyeAngle={setBirdseyeAngle}
      />

      {isLoading && (
        <div className="imagery-disabled flex-row-center imagery-loading">
          <i className="fas fa-spinner fa-spin large" aria-hidden="true"></i>
        </div>
      )}

      {!isLoading && (
        <>
          <ImageMeteredNotice
            isAllowed={isAllowed}
            isMetered={isMetered}
            isTypeShown={isTypeShown}
            isLastMetered={isLastMetered}
            isTypeUnrequested={isTypeUnrequested}
            isCurrentUnavailable={isCurrentUnavailable}
            needUpgrade={needUpgrade}
            handleUpgrade={handleUpgrade}
            handleShowImg={handleShowImg}
          />
          <ImageToggle
            streetview={streetview}
            birdseye={birdseye}
            noImages={noImages}
            currentlyShowing={currentlyShowing}
            handleToggle={handleToggle}
          />
          <ImageCounter isMetered={isMetered} userLimits={userLimits} />
        </>
      )}
    </>
  )
}

export default withStore(PropertyImage)