import React from 'react'
import PropTypes from 'prop-types'

const SEControls = props => (
  <div className={'survey-controls pull-right' + (props.disabled ? ' disabled' : '')}>
    <a onClick={props.handlers.save}
       className='btn btn-primary'
       href='#'
       disabled={props.isSaveDisabled}>
       Save Survey Questions
    </a>
    {!props.isNew &&
      <a onClick={props.handlers.revert} className='btn btn-default' href='#'
        title="Undo recent changes and load the last saved version of the survey questions">
        Reset to Saved Version
      </a>
    }
    <div className='btn-group'>
      <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        More Actions & Presets <span className="caret"></span>
      </button>
      <ul className="dropdown-menu">
        <li><a onClick={props.handlers.new} href='#'
          title="Start a fresh set of questions for this survey. Existing questions will be erased, so make sure this survey doesn't have data posted to it!">
          Start from Scratch</a></li>
        <li><a onClick={props.handlers.copy} href='#'
          title="Copy an existing question set into this one. If this survey has been posted to, you probably don't want to do this!">
          Copy from Another Survey</a></li>
        <li><a onClick={e => { props.handlers.preset(e, 'simple')  }} href='#' data-preset='simple'
          title="Load a simple set of standard property survey questions">
          Use Structure Survey Template - Short</a></li>
        <li><a onClick={e => { props.handlers.preset(e, 'revised') }} href='#' data-preset='revised'
          title="Load a set of standard property survey questions">
          Use Structure Survey Template - Standard</a></li>
        <li><a onClick={e => { props.handlers.preset(e, 'realty') }} href='#' data-preset='realty'
          title="Load a set of real estate survey questions">
          Use Real Estate Survey Template</a></li>
      </ul>
    </div>
  </div>
)

SEControls.propTypes = {
  handlers:       PropTypes.objectOf(PropTypes.func).isRequired,
  isSaveDisabled: PropTypes.bool.isRequired,
}

export default SEControls;
