import { get, post } from './api'
import { getActiveSourceIds, isPlace } from './sources'
// Actions
import {
  RECEIVE_FILTERS,
  REQUEST_FILTERS,
  UPDATE_QUERY,
  REQUEST_QUERY_LAYER,
  RECEIVE_QUERY_LAYER,
  TILES_RELOAD,
} from './actions'

export const normalizePlaceFilters = (json, placePath) => {
  // Organize the list by key
  let filters = {}
  json.sources.forEach((sourceFilters) => {
    sourceFilters.hasFilters = true
    if (placePath !== undefined && sourceFilters.key === 'parcel') {
      sourceFilters.id = sourceFilters.path
      sourceFilters.source = 'parcel'

      // We need to add the place path and source type to each option
      // for ReactSelect
      sourceFilters.options = sourceFilters.options.map((option) => {
        return {
          ...option,
          source: 'parcel',
          path: sourceFilters.path,
        }
      })

      filters[placePath] = sourceFilters
    } else {
      sourceFilters.id = sourceFilters.key

      // We need to add the source ID to each option for ReactSelect
      sourceFilters.options = sourceFilters.options.map((option) => {
        return {
          ...option,
          source: sourceFilters.key,
        }
      })

      filters[sourceFilters.key] = sourceFilters
    }
  })
  return filters
}

export const normalizeSourceFilters = (json, id) => {
  return {
    [id]: {
      id,
      ...json.filters,
      hasFilters: true,

      // We need to add the source ID to each option for ReactSelect
      options: json.filters.options.map((option) => {
        return {
          ...option,
          source: id,
        }
      }),
    },
  }
}

// API
const fetchFilters = (id) => (dispatch) => {
  const place = isPlace(id)
  let normalizer = normalizeSourceFilters
  if (place) {
    normalizer = normalizePlaceFilters
  }

  const start = () => {
    dispatch({
      type: REQUEST_FILTERS,
      id,
    })
  }

  const done = (json) => {
    dispatch({
      type: RECEIVE_FILTERS,
      data: normalizer(json, id),
      id,
    })
  }

  const fail = () => {
    dispatch({
      type: RECEIVE_FILTERS,
      data: {
        [id]: {
          id,
          isLoading: false,
          hasFilters: false,
        },
      },
      id,
    })
  }

  let url = `/sources/${id}/filters.json`
  if (place) {
    url = `${id}/filters.json`
  }
  get(url, { start, done, fail })
}

export const fetchFiltersIfNeeded = (id) => (dispatch, getState) => {
  const filters = getState().filters

  if (!filters[id]) {
    return dispatch(fetchFilters(id))
  }
}

export const fetchActiveFilters = (placePath) => (dispatch, getState) => {
  const state = getState()
  if (placePath) {
    dispatch(fetchFiltersIfNeeded(placePath))
  }
  const activeSourceIds = getActiveSourceIds(state)
  activeSourceIds.forEach((id) => {
    dispatch(fetchFiltersIfNeeded(id))
  })
}

export const buildTilesQueryLayer =
  (query, fields = {}) =>
  (dispatch, getState) => {
    debug('Filter buildTilesQueryLayer:', query)
    if (!query || !window?.data) return

    const tilesDomain = window.data.tileserver

    let token
    if (window.data.tile_token) {
      token = `userToken=${window.data.tile_token}`
    } else if (window.data.group_token) {
      token = `token=${window.data.group_token}`
    }

    const url = `${tilesDomain}/api/v1/sources?${token}`

    const body = {
      fields: fields,
      query: query,
      styles: null,
    }

    // TODO: this sort of "start, done, fail" boilerplate can be abstracted with RTK createAsyncThunk
    let spinId
    const start = () => {
      spinId = LL.Spinner.start()
      dispatch({
        type: REQUEST_QUERY_LAYER,
      })
    }
    const done = (data) => {
      dispatch({
        type: RECEIVE_QUERY_LAYER,
        data,
      })
      LL.Spinner.stop(spinId)
    }
    const fail = (error) => {
      debug(error)
    }

    // TODO: track latest request and clear existing query tile layer (if any)
    post(url, body, { start, done, fail }, false) // includeCredentials = false to avoid CORS error
  }

/**
 * Get just the root path for the parcels for a place
 * Useful for building buildQueryFromActiveSources
 * @param  {Object} state     Redux getState
 * @param  {String} placePath
 * @return {String|undefined}           Root path, eg /us/mi/wayne
 */
export const getPlaceBasePath = (state, placePath) => {
  const placeFilters = getFilter(state, placePath)
  return placeFilters.path
}

export const getActiveFilters = (state, placePath) => {
  const filters = []
  if (placePath) {
    filters.push(getFilter(state, placePath))
  }
  const activeSourceIds = getActiveSourceIds(state)
  activeSourceIds.forEach((id) => {
    filters.push(getFilter(state, id))
  })

  return filters
}

export const getFilter = (state, id) => {
  if (state.filters[id]) {
    return state.filters[id]
  } else {
    return { id }
  }
}

export const getQueryLayerPrintUrl = (state) => {
  return state.filters.queryLayer?.printUrl
}

export const hasFilters = (filters) => {
  return filters.some((filter) => {
    return filter.hasFilters === true
  })
}

export const areFiltersLoading = (filters) => {
  const values = Object.values(filters)

  return values?.some((value) => {
    return value.isLoading === true
  })
}

export const onlyWithFilters = (filters) => {
  return filters.filter((filter) => {
    return filter.hasFilters
  })
}

// Reducers
/**
 * Store places by their path
 */
export const filters = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_FILTERS:
      return {
        ...state,
        [action.id]: {
          id: action.id,
          isLoading: true,
        },
      }
    case RECEIVE_FILTERS:
      return {
        ...state,
        ...action.data,
      }
    case UPDATE_QUERY:
      return {
        ...state,
        currentQuery: action.data,
      }
    case REQUEST_QUERY_LAYER:
      return {
        ...state,
        queryLayer: {
          isLoading: true,
        },
      }
    case RECEIVE_QUERY_LAYER:
      // we get a vector url from tileserver, but need to drop the /{z}/{x}/{y} portion and everything after
      const url = `${action.data?.vector[0].replace(
        /\/\{z\}.*$/,
        ''
      )}?format=mvt`
      return {
        ...state,
        queryLayer: {
          isLoading: false,
          id: action.data.id,
          url: url,
          type: 'vector',
          printUrl: action.data?.tiles[0],
        },
      }
    case TILES_RELOAD:
      // this cache bust on the query layer url should refresh the tiles
      // useful for following and surveys, when new items are added.
      // the tiles:reload event is sent by the asset pipeline JS
      const currentDate = new Date()
      const unix = Math.floor(currentDate.getTime() / 1000)
      const cacheBustUrl = `${state.queryLayer.url}&ms=${unix}`
      return {
        ...state,
        queryLayer: {
          isLoading: false,
          id: state.queryLayer.id,
          url: cacheBustUrl,
          type: 'vector',
          printUrl: state.queryLayer.printUrl,
        },
      }
    default:
      return state
  }
}

export default filters
