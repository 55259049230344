import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'common/utils/classNames';
import measureDistanceImg from '@/assets/draw/measure-distance.jpg';
import { setDrawActive, setDrawMode, setCurrentDrawTool } from 'ducks/mapProperties';

const MeasureDistance = ({ handleClose }) => {
  const dispatch = useDispatch()
  const drawActive = useSelector((state) => state.mapProperties.drawActive)
  const currentTool = useSelector(
    (state) => state.mapProperties.currentDrawTool
  )
  const isCurrentTool = currentTool === 'measureDistance'
  const [hovered, setHovered] = useState(false)

  const measureDistanceClasses = classNames(
    'measure-distance',
    'modal-tile',
    'shadow-gl-light',
    {
      active: isCurrentTool,
    }
  )

  const handleMeasureClick = () => {
    if (drawActive && isCurrentTool) {
      // If currently drawing, stop drawing
      dispatch(setDrawActive(false))
      dispatch(setDrawMode(null))
      dispatch(setCurrentDrawTool(null))
    } else {
      // Start drawing a line for measurement
      dispatch(setDrawActive(true))
      dispatch(setDrawMode('draw_line_string'))
      dispatch(setCurrentDrawTool('measureDistance'))
      handleClose()
    }
  }

  return (
    <div
      className={
        measureDistanceClasses +
        (isCurrentTool || hovered ? ' plan-color-glow' : '')
      }
      onClick={handleMeasureClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
    >
      <div className="modal-tile-img">
        <img src={measureDistanceImg} alt="Measure Distance" />
      </div>
      <div
        className={`modal-tile-detail draw-tool-detail ${
          isCurrentTool ? 'plan-color-accent white' : ''
        }`}
      >
        <span className="md bold">
          <i className="fa fa-ruler margin-right-md"></i>
          Measure Distance
        </span>
        <div className="small">
          Click on the map to measure the distance between points.
        </div>
      </div>
    </div>
  )
};

export default MeasureDistance;