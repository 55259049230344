/* For the API Sandbox and API Enterprise plans, we need to be able to display the plan details in the Payment component. This should is kept separate from the ApiAccess file and SubsciptionsController#react_api_plans because it does not inform purchase behavior and is only used for display purposes. */

export const apiSandboxData = {
  name: 'Sandbox',
  display_name: 'Free Trial',
  alternate_name: 'Regrid Interactive API Sandbox',
  prices: {
    annual: null,
    monthly: 0,
  },
  features: {
    // TODO features may not be necessary
    '30 day Trial API token': '30 day Trial API token',
    '7 counties': '7 counties',
    '2,000 parcel records': '2,000 parcel records',
    '10,000 tiles': '10,000 tiles',
    'Premium Parcel Schema': 'Premium Parcel Schema',
    'No credit card required': 'No credit card required',
  },
  included: [
    '30 day Trial API token',
    '7 counties',
    '2,000 parcel records',
    '10,000 tiles',
    'Premium Parcel Schema',
    'No credit card required',
  ],
  description: [
    // 'Take a stroll through our data using our custom Regrid Interactive API Sandbox. You are able to access all our API endpoints and our Exclusive Datasets.',
    'Experience Regrid’s parcel data for free with the Regrid Parcel Data API Sandbox.',
  ],
  plan: 'api_sandbox',
  limits: {
    results: 2000,
    tiles: 10000,
    subscription: '30 days',
    schema: ['Standard', 'Premium'],
  },
  properties: {
    matched_buildings: true,
    matched_addresses: true,
    enhanced_ownership: true,
    standardized_zoning: true,
  },
}

export const apiEnterpriseData = {
  display_name: 'Enterprise API',
  name: 'Enterprise',
  subscription: 'Annual',
  levels: {
    standard: {
      results: null,
      unit_price_results: 0.0001,
      tiles: null,
      unit_price_tiles: 0.0001,
    },
  },
  properties: {
    contact_link: 'https://regrid.com/api#contact',
    schema_types: ['Standard', 'Premium'],
    technical_support: true,
    volume_pricing: true,
    matched_buildings: true,
    matched_addresses: true,
    typeahead_search: true,
    enhanced_ownership: true,
    standardized_zoning: true,
  },
}
