import React from 'react'
import { useSelector } from 'react-redux'
import { Layer, Source } from 'react-map-gl'
import withStore from 'components/withStore'

// Components
import CustomRasterLayer from './CustomRasterLayer'

// Redux
import {
  getActiveThirdPartyLayerObj,
  getSatelliteLayerRendered,
} from 'ducks/mapProperties'
import mapSettings from '../mapSettings'
import FemaLayer from './FemaLayer'
import ZoningLayer from './ZoningLayer'
import ElevationLayer from './ElevationLayer'
import WetlandsLayer from './WetlandsLayer'
import EsriEnrichmentsLayers from './EsriEnrichmentsLayers'

const CustomVectorLayer = ({ layer }) => {
  const isSatellite = useSelector(getSatelliteLayerRendered)
  const layerStyle = mapSettings.thirdPartyLayers.customVectorLayer
  const sourceOpts = layer?.sourceOpts || {}
  const hasLabelLayer = layer?.labelOpts || false

  const layerOpts = {
    id: `${layer.id}-layer`,
    type: 'line',
    source: `${layer.id}-source`,
    'source-layer': `${layer.dataLayer}`,
    paint: {
      'line-color': '#9a689c',
      'line-color': layerStyle.lineColor,
      'line-width': layerStyle.lineWidth,
    },
    ...layer.opts,
  }

  // add label layer for elevation
  const labelLayer = hasLabelLayer
    ? {
        id: `${layer.id}-label-layer`,
        type: 'symbol',
        source: `${layer.id}-source`,
        'source-layer': `${layer.dataLayer}`,
        layout: {
          'text-field': layer?.labelOpts.textField || '',
          'text-font': ['Arial Unicode MS Bold'],
          'text-size': layerStyle.labelTextSize,
          'symbol-placement': 'line', // Align symbols to the line
          'symbol-spacing': 350, // Distance between two symbol anchors in pixels
          'text-offset': [0, -0.5], // [x,y] Offset upwards from the line in em units
        },
        paint: {
          'text-color': layerStyle.labelTextColor,
          'text-halo-color': layerStyle.labelHaloColor,
          'text-halo-width': layerStyle.labelHaloWidth,
          'text-halo-blur': layerStyle.labelHaloBlur,
        },
      }
    : null

  return (
    <>
      <Source
        id={`${layer.id}-source`}
        type="vector"
        promoteId="fid" // sets internal feature id to tileserver fid
        {...sourceOpts}
      >
        <Layer {...layerOpts} />
        {hasLabelLayer && <Layer {...labelLayer} />}
      </Source>
    </>
  )
}

const CustomWMSLayer = ({ layer }) => {
  const layerOpts = {
    id: `${layer.id}-layer`,
    source: `${layer.id}-source`,
    ...layer.opts,
  }

  return (
    <>
      <Source
        id={`${layer.id}-source`}
        type="raster"
        tiles={[layer.url]}
        tileSize={256}
      >
        <Layer {...layerOpts} />
      </Source>
    </>
  )
}

const ThirdPartyLayer = () => {
  // key is used to force a re-render and update Source when layer changes
  const activeLayer = useSelector(getActiveThirdPartyLayerObj)
  let layer = null

  if (activeLayer?.type === 'mvt') {
    const layerClasses = {
      'flood': FemaLayer,
      'elevation-ft': ElevationLayer,
      'elevation-m': ElevationLayer,
      'zoning': ZoningLayer,
      'wetlands': WetlandsLayer,
      'population_density': EsriEnrichmentsLayers,
      'population_growth': EsriEnrichmentsLayers,
      'housing_affordability': EsriEnrichmentsLayers,
    }

    const LayerType = layerClasses[activeLayer.id] || CustomVectorLayer
    layer = <LayerType key={activeLayer.id} layer={activeLayer} />
  }

  if (activeLayer?.type === 'raster') {
    layer = <CustomRasterLayer key={activeLayer.id} layer={activeLayer} />
  }

  if (activeLayer?.type === 'wms') {
    // esri type 'Dynamic Map Service' is raster, but not tiled. Converted to wms
    layer = <CustomWMSLayer key={activeLayer.id} layer={activeLayer} />
  }

  return <>{layer}</>
}

export default withStore(ThirdPartyLayer)
