const headers = {
  // Authorization: 'Basic ' + new Buffer('love:landia').toString('base64'),
}

const creds = {
  credentials: 'include',
}

// TODO: how did this work before?
const settings = {
  api: {
    base: '',
  },
}

const noop = () => {}

/**
 * Get the json but keep the response status so we can check for errors later
 */
const parseJson = response => {
  return new Promise(resolve =>
    response.json().then(json =>
      resolve({
        status: response.status,
        ok:     response.ok,
        json,
      })
    )
  )
}

const api = (url, options = {}) => {
  options = {
    ...options,
    headers: {
      ...options.headers,
      ...headers,
    },
    ...creds,
  }

  return new Promise((resolve, reject) => {
    fetch(window.location.protocol + '//'+ window.data.host + url, options)
      .then(parseJson)
      .then(response => {
        if(response.ok) {
          return resolve(response.json)
        } else{
          return reject(response)
        }
      })
  })
}

export const get = (path, { start = noop, done = noop, fail = noop, } = {}) => {
  start()
  return api(path)
    .then(done)
    .catch(error => {
      console.log(error)
      if(fail) {
        fail(error.json)
      }
    })
}

export const post = (
  path,
  body,
  { start = noop, done = noop, fail = noop, } = {},
  includeCredentials = true
) => {
  start()

  return fetch(path, {
    method:  'POST',
    headers: {
      Accept:         'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      ...headers,
    },
    ...(includeCredentials ? creds : null),
    body: JSON.stringify(body),
  })
    .then(response => response.json())
    .then(done)
    .catch(error => {
      if(fail) {
        fail(error)
      }
    })
}

export const put = (
  path,
  body,
  { start = noop, done = noop, fail = noop, } = {}
) => {
  start()

  return fetch(path, {
    method:  'PUT',
    headers: {
      Accept:         'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      ...headers,
    },
    ...creds,
    body: JSON.stringify(body),
  }).then(response => response.json())
    .then(response => {
      if(response.error || response.errors) {
        // TODO: There has to be a better way to catch errors earlier.
        // But I can't figure out how to parse the json in the catch stage
        // For an example:
        // https://github.com/loveland/loveland-App-React-Native/pull/820/files
        fail(response);
      } else {
        done(response);
      }
    })
    .catch(fail)
}

const makeParams = params => {
  return Object.keys(params)
    .map(key => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
    })
    .join('&')
}

export const postForm = (path, body, start, done, fail) => {
  start()

  return fetch(path, {
    method:  'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      ...headers,
    },
    ...creds,
    body: makeParams(body),
  })
    .then(response => response.json())
    .then(done)
    .catch(error => {
      // TODO add our own error handling
      if(fail) {
        fail(error)
      }
    })
}
