import React from 'react';
import PropTypes from 'prop-types';

import RuleColorSwatch from './RuleColorSwatch';
import InfoTooltip from 'components/Utilities/InfoTooltip';

const LabeledRuleColorSwatch = ({
  currentColor,
  type,
  handleChange,
}) => {

  let label = 'Fill';
  let tipId = 'rule-fill-tip';
  let tip = 'Color that will fill the matching parcels';

  if(type === 'line') {
    label = 'Line';
    tipId = 'rule-line-tip';
    tip = 'Color that will outline the matching parcels';
  }

  return (
    <div className="labeled-color margin-btm-md labeled-rule-color-swatch">
      <RuleColorSwatch
        currentColor={currentColor}
        type={type}
        handleChange={handleChange}
      />
      <span className='margin-left-md margin-right-md'>{label}: </span>
      <InfoTooltip
        id={tipId}
        tip={tip}
        place='right'
        type='dark'
        delayShow={500}
      />
    </div>
  );
};

LabeledRuleColorSwatch.propTypes = {
  currentColor: PropTypes.string,
  type:         PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default LabeledRuleColorSwatch;