import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { map } from 'lodash';

const ChangePlanWarnings = ({ users, maps, sources, deprecatedApi }) => {

  return (
    <div className='alert alert-warning'>
      {!deprecatedApi && <>
        <p className='bold' style={{ marginBottom: '1em' }}>
          If you make this change, some of the users and/or data in this account will be over the new plan limit.
          Please make sure you understand and approve the below before pressing the Confirm button.
        </p>
      </>}  
        

      {users && <>
        <h4>The following members will lose access to the team:</h4>
        <ul>
          {map(users, ({name, role, user_id}) => {
            return <li key={user_id}>{name} ({role})</li>
          })}
        </ul>
        <br />
        <p>If these are not the members you want deleted, please delete the ones you would like removed on the <a href="/users">Members Dashboard</a> before continuing here.</p>
        <br/>
      </>}

      {maps && <>
        <h4>These projects will be removed from the account:</h4>
        <ul>
          {map(maps, ({id, name}) => {
            return <li key={id}>{name}</li>
          })}
        </ul>
        <br/>
        <p>If these are not the projects you want deleted, please delete the ones you would like removed on the <a href="/profile">Projects Dashboard</a> before continuing here.</p>
      </>}

      {sources && <>
        <h4>The following datasets will be removed:</h4>
        <ul>
          {map(sources, ({id, name, type}) => {
            return <li key={id}>{name} ({type})</li>
          })}
        </ul>
      </>}

      {deprecatedApi && <>
        <p className='bold' style={{ marginBottom: '1em' }}>
          Your current plan of {deprecatedApi} is no longer offered. You can choose to upgrade your 
          plan to bundle access. Once this change has been made, you will not be able to revert back to 
          the previous {deprecatedApi} plan. Click Confirm to continue
        </p>
      </>}
   </div>
  )
}


ChangePlanWarnings.propTypes = {
  users:    PropTypes.array,
  maps:     PropTypes.array,
  sources:  PropTypes.array,
}

export default ChangePlanWarnings;
