import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import mapSettings from '../mapSettings'
import {
  setUserPreference,
  getUsersPreference,
} from 'common/utils/UserPreferences'
// Redux
import { getCurrentFloatLayer, getThirdPartyLayer } from 'ducks/mapProperties'

const ZoomInLocationReminderGL = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [isDismissed, setIsDismissed] = useState(false);
  const currentFloatLayer = useSelector(getCurrentFloatLayer)
  const currentThirdPartyLayer = useSelector(getThirdPartyLayer)
  const currentZoom = useSelector((state) => state.mapProperties.zoomLevel)
  const mapAvailable = useSelector((state) => state.mapProperties.mapAvailable)

  useEffect(() => {
    const getReminderDismissalStatus = async () => {
      const isDismissed = await getUsersPreference('zoom_in_reminder_dismissed');
      const dismissed = isDismissed ?? false
      setIsDismissed(dismissed)
      return dismissed
    };

    getReminderDismissalStatus();
    
    if (mapAvailable && !isDismissed) {
      setIsVisible(true)
    }
  }, [mapAvailable, currentFloatLayer, currentThirdPartyLayer, currentZoom])

  if (!mapAvailable) {
    return null
  }

  const notifierClassName = `zoom-location-reminder-gl ${isVisible ? '' : 'fade-out'
    }`

  const handleClose = () => {
    setIsVisible(false)
    setUserPreference({ zoom_in_reminder_dismissed: true })
  };

  const displayNotifier = currentZoom && !isDismissed && currentZoom < mapSettings.zoomReminderInMinZoom 

  if (displayNotifier) {
    return (
      <div className={notifierClassName}>
        <span className="zoom-location-reminder-gl-text rounded centered">
          <div className="close no-btn small">
            <i
              className="fas fa-times"
              onClick={handleClose}
            ></i>
          </div>
          Use the <i className="fa fa-crosshairs margin-sm"></i> button <br />to zoom to your location
        </span>
      </div>
    )
  } else {
    return null
  }
}

export default ZoomInLocationReminderGL
