import React from "react"

const ApiSandboxCard = () => {
  const description =
    'Unleash powerful land parcel insights and transform your geospatial data interaction in this OpenAPI-enabled test environment.'

  return (
    <div id="sandbox-tile" className="margin-btm-lg">
      <div className="tile-left col-xs-12 col-md-6">
        <h3 className="margin-top-none">
          Regrid Parcel API Interactive Sandbox
        </h3>
        <p
          className="desc mb-auto"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
        <a
          className="btn btn-round btn-primary"
          href="/users/sign_up?flow=api_sandbox"
        >
          Get Started
          <i className="fas fa-angle-right margin-left-md"></i>
        </a>
      </div>
      <div className="tile-right col-xs-12 col-md-6">
        <h3 className="centered margin-top-none">
          Try the API Sandbox Free for 30 Days
        </h3>
        <p className="centered">
          Get complete access to seven full counties of land parcel data
        </p>
      </div>
    </div>
  )
}

export default ApiSandboxCard;
