import React from 'react'

/**
 * ImageDisplay component renders an image within a container. 
 * The image can be either a metered placeholder or the actual image blob. 
 * The container visibility is controlled based on the `currentlyShowing` prop.
 * 
 * @param {Object} props - The component props
 * @param {string} props.type - type of the image (e.g., 'streetview', 'birdseye')
 * @param {string} props.href - URL to link to when the image is clicked
 * @param {string} props.srcBlob - source URL of the image blob
 * @param {boolean} props.isMetered - indicates if the user is metered (Starter plan)
 * @param {boolean} props.isTypeShown - indicates if the user opted to show the image
 * @param {string} props.currentlyShowing - type of image currently being shown ('streetview' or 'birdseye')
 * @param {string} props.fallbackImg - fallback image if the blob is not available
 * @param {string} props.anchorTarget - target attribute for the anchor tag
 * @returns {JSX.Element} The rendered component
 */
const ImageDisplay = ({
  type,
  href,
  srcBlob,
  isMetered,
  isTypeShown,
  currentlyShowing,
  fallbackImg,
  anchorTarget,
  birdseyeAngle,
  setBirdseyeAngle,
}) => {
  const isHidden = currentlyShowing !== type
  const isRotateAllowed = !isMetered && type === 'birdseye'

  /**
   * Rotate the birdseye imagery, for non-metered users
   * Updates the angle state in the parent to re-render the image
   * base url + '/us/mi/wayne/detroit/176773/birdseye.jpg?angle=270'
   */
  const handleRotate = (incr) => {
    const newAngle = (birdseyeAngle + incr) % 360
    setBirdseyeAngle(newAngle)
  }

  // Oblique imagery rotate buttons
  const obliqueButtons = (
    <div
      className="birdseye-buttons"
      data-angle={`${birdseyeAngle}`}
      data-base-url={href}
    >
      <a
        className="btn btn-sm btn-default"
        href="#"
        title="Rotate left"
        onClick={() => handleRotate(270)}
      >
        <i className="fas fa-undo"></i>
      </a>
      <a
        className="btn btn-sm btn-default"
        href="#"
        title="Rotate right"
        onClick={() => handleRotate(90)}
      >
        <i className="fas fa-redo"></i>
      </a>
    </div>
  )

  return (
    <div className={`${type}-container ${isHidden ? 'hide' : ''}`}>
      <a href={href} target={anchorTarget}>
        {isMetered && !isTypeShown ? (
          <img
            src={fallbackImg}
            className={`${type} imagery-placeholder disabled`}
            data-placeholder="true"
            alt="Property photo"
          />
        ) : (
          <img
            src={srcBlob || fallbackImg}
            className={`${type} ${
              srcBlob ? '' : 'imagery-placeholder disabled'
            }`}
            data-placeholder={!srcBlob}
            alt="Property photo"
          />
        )}
      </a>
      {isRotateAllowed && obliqueButtons}
    </div>
  )
}

export default ImageDisplay