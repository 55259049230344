import React, { useState, useEffect, useRef } from 'react'
  const ParcelInfoCard = ({ text, link, onClose }) => {
    return (
      <div className="panel panel-default margin-btm-md">
      <div
        id={"collapse"}
        className={"panel-collapse collapse in"}
        role="tabpanel"
        aria-labelledby={"heading"}
      >
        <div className="panel-body" style={{ position: "relative" }}>
          <a
            className="close-button close"
            onClick={onClose}
          >
            <i className="fa fa-times" />
          </a>
          <div className= "margin-top-md">
          <span className='subtle small'>{text}</span>
          <br></br>
          <a className= "margin-top-md small" href={link} target="_blank" rel="noopener noreferrer">
            Check out our Canada Data FAQ's
          </a>
          </div>
        </div>
      </div>
    </div>
  );
  };
  

export default ParcelInfoCard
