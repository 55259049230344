import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { isEmpty, isUndefined, map } from 'lodash';

const SEClone = props => {
  let body = null;
  if(isUndefined(props.copyOptions)) {
    body = <div className='centered' style={{width: '100%'}}>
      <i className='fas fa-spinner fa-spin big' style={{ marginTop: '10px', marginBottom: '10px' }}/>
    </div>;
    
  } else if(isEmpty(props.copyOptions)) {
    body = <p className='lead'>You do not have any other surveys to copy.</p>;
    
  } else {
    body = <div>
      <h4>Copy a Survey Question Set</h4>
      <p>Choose a survey to copy questions from. You will have a chance to review and edit the questions before saving.</p>
      <p><b>Please don't forget to press Save to confirm your choice.</b></p>
      <table className='table table-condensed'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Questions</th>
            <th>Posts</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {map(props.copyOptions, (source, i) => {
            return <tr key={source.id}>
              <td><a onClick={e => { props.handlers.copySource(e, source) }} href='#'>{source.name}</a></td>
              <td>{source.questions.length} {pluralize(source.questions.length, 'question', 'questions')}</td>
              <td>{source.count} {pluralize(source.count, 'post', 'posts')}</td>
              <td>{source.created_at}</td>
            </tr>
          })}
        </tbody>
      </table>
    </div>;
  }
  
  return <div className='clone-survey well'>
    <button onClick={props.handlers.closeCopy} type="button" className="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    {body}
  </div>
}

SEClone.propTypes = {
  copyOptions: PropTypes.arrayOf(PropTypes.object),
  handlers:    PropTypes.objectOf(PropTypes.func).isRequired,
}

export default SEClone;
