import React, { useEffect } from 'react'
import { apiSandboxData, apiEnterpriseData } from './plansUtil.js'

const ApiCompare = (props) => {
  const marketingRoot = marketingURL()
  const linkTarget = 'llext' // opens a new tab that gets reused
  const checkCircle = <i className="fas fa-check-circle"></i>

  const apiSandbox = apiSandboxData || null
  const apiEnterprise = apiEnterpriseData || null
  const apiStandard = props?.plans.find(plan => plan.plan === 'api_standard') || null;
  const apiPremium = props?.plans.find(plan => plan.plan === 'api_premium') || null;

  const isSandbox = props?.currentApiPlan === apiSandbox?.name
  const isStandard = props?.currentApiPlan === apiStandard?.name
  const isPremium = props?.currentApiPlan === apiPremium?.name

  useEffect(() => {
    // scroll both tables at the same time
    const table1 = document.getElementById('inclusive-table')
    const table2 = document.getElementById('exclusive-table')

    table1.addEventListener('scroll', () => {
      table2.scrollLeft = table1.scrollLeft
    })

    table2.addEventListener('scroll', () => {
      table1.scrollLeft = table2.scrollLeft
    })

    return () => {
      table1.removeEventListener('scroll', () => {})
      table2.removeEventListener('scroll', () => {})
    }
  }, [])

  if (!apiStandard || !apiPremium || !apiEnterprise || !apiSandbox) {
    return null
  }

  const handlePlan = (e) => {
    e.preventDefault()
    const clickedPlan = e.target.dataset.plan
    const clickedPlanName = e.target.dataset.planName
    if (props.currentApiPlan && props.currentApiPlan !== clickedPlanName) {
      // existing api user trying to change plan
      const urlRoot = window.data.group.path
      const newUrl =
        urlRoot + '/settings/billing?plan=' + encodeURIComponent(clickedPlan)
      // navigate to billing page with plan param
      window.location.href = newUrl
    } else {
      props.onClickPlan(e, clickedPlan)
    }
  }

  return (
    <div className="plan-compare-row">
      <div className="table-wrapper">
        <table
          className="table table-striped table-hover api-compare-table margin-btm-none"
          id="inclusive-table"
        >
          <thead>
            <tr>
              <th className=" lead sans th-first" scope="col"></th>
              <th className="lead sans plan-col-trial" scope="col">
                <p>
                  {apiSandbox?.alternate_name
                    ? apiSandbox?.alternate_name
                    : apiSandbox?.display_name}
                </p>
                { (props.expiredTrial || isStandard || isPremium) && (
                  <a
                    href="#"
                    className="btn btn-round btn-xs btn-default btn-block disabled"
                  >{`Not Available`}</a>
                )}
                { (isSandbox && !props.expiredTrial) && (
                  <a
                    href="#"
                    className="btn btn-round btn-xs btn-success btn-block disabled"
                  >{`Current Plan`}</a>
                )}
                { !isSandbox && !isStandard && !isPremium && (
                  <a
                    href="/users/sign_up?flow=api_sandbox"
                    className="btn btn-round btn-xs btn-block plan-self-serve-accent"
                    onClick={(e) => handlePlan(e)}
                    data-plan={apiSandbox.plan}
                    data-plan-name={apiSandbox.name}
                  >{`Start with Free`}</a>
                )}
              </th>
              <th className="lead sans plan-col-api-std" scope="col">
                <p>{apiStandard.name}</p>
                { isStandard ? (
                  <a
                    href="#"
                    className="btn btn-round btn-xs btn-success btn-block disabled"
                  >{`Current Plan`}</a>
                ) : (
                  <a
                    href="#"
                    className="btn btn-round btn-xs btn-default btn-block"
                    onClick={(e) => handlePlan(e)}
                    data-plan={apiStandard.plan}
                    data-plan-name={apiStandard.name}
                  >{`Try ${apiStandard.name}`}</a>
                )
                }
              </th>
              <th className="lead sans plan-col-api-premium" scope="col">
                <p>{apiPremium.name}</p>
                { isPremium ? (
                  <a
                    href="#"
                    className="btn btn-round btn-xs btn-success btn-block disabled"
                  >{`Current Plan`}</a>
                ) : (
                  <a
                    href="#"
                    className="btn btn-round btn-xs btn-default btn-block"
                    onClick={(e) => handlePlan(e)}
                    data-plan={apiPremium.plan}
                    data-plan-name={apiPremium.name}
                  >{`Try ${apiPremium.name}`}</a>
                )
                }
              </th>
              <th className="lead sans plan-col-api-enterprise" scope="col">
                <p>{apiEnterprise.name}</p>
                <a
                  href={apiEnterprise?.properties?.contact_link}
                  className="btn btn-round btn-xs btn-default btn-block"
                >{`Get a Demo`}</a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className=" td-first" scope="row">
                Subscription Type Available
              </td>
              <td className="plan-col-trial">
                <p>{apiSandbox?.limits?.subscription}</p>
              </td>
              <td className="plan-col-api-std">
                <p>{apiStandard?.prices?.monthly ? 'Monthly' : 'Annual'}</p>
              </td>
              <td className="plan-col-api-premium">
                <p>{apiPremium?.prices?.monthly ? 'Monthly' : 'Annual'}</p>
              </td>
              <td className="plan-col-api-enterprise">
                <p>{apiEnterprise.subscription}</p>
              </td>
            </tr>
            <tr>
              <td className=" td-first" scope="row">
                Parcel Records
              </td>
              <td className="plan-col-trial">
                <p>{`${numberWithCommas(apiSandbox?.limits.results)}`}</p>
              </td>
              <td className="plan-col-api-std">
                <p>{`${numberWithCommas(
                  apiStandard.limits.results
                )} + overage`}</p>
              </td>
              <td className="plan-col-api-premium">
                <p>{`${numberWithCommas(
                  apiPremium.limits.results
                )} + overage`}</p>
              </td>
              <td className="plan-col-api-enterprise">
                <p>Custom</p>
              </td>
            </tr>
            <tr>
              <td className=" td-first" scope="row">
                Parcel Tiles
              </td>
              <td className="plan-col-trial">
                <p>{`${numberWithCommas(apiSandbox?.limits.tiles)}`}</p>
              </td>
              <td className="plan-col-api-std">
                <p>{`${numberWithCommas(
                  apiStandard.limits.tiles
                )} + overage`}</p>
              </td>
              <td className="plan-col-api-premium">
                <p>{`${numberWithCommas(
                  apiPremium.limits.tiles
                )} + overage`}</p>
              </td>
              <td className="plan-col-api-enterprise">
                <p>Custom</p>
              </td>
            </tr>
            <tr>
              <td className=" td-first" scope="row">
                <p>Parcel Schema</p>
                <p className="small subtle">
                  (Standard Schema, Premium Schema)
                </p>
              </td>
              <td className="plan-col-trial">
                <p>{apiSandbox?.limits?.schema.join(' & ')}</p>
              </td>
              <td className="plan-col-api-std">
                <p>
                  {apiStandard?.limits.schema.length === 1
                    ? `${apiStandard?.limits.schema[0]} Only`
                    : apiStandard?.limits.schema.join(' & ')}
                </p>
              </td>
              <td className="plan-col-api-premium">
                <p>
                  {apiPremium?.limits.schema.length === 1
                    ? `${apiPremium?.limits.schema[0]} Only`
                    : apiPremium?.limits.schema.join(' & ')}
                </p>
              </td>
              <td className="plan-col-api-enterprise">
                <p>{apiEnterprise?.properties.schema_types.join(' & ')}</p>
              </td>
            </tr>
            <tr>
              <td className=" td-first" scope="row">
                Technical Customer Support
              </td>
              <td className="plan-col-trial">
                {apiSandbox?.properties?.technical_support ? checkCircle : ''}
              </td>
              <td className="plan-col-api-std">
                {apiStandard?.properties?.technical_support ? checkCircle : ''}
              </td>
              <td className="plan-col-api-premium">
                {apiPremium?.properties?.technical_support ? checkCircle : ''}
              </td>
              <td className="plan-col-api-enterprise">
                {apiEnterprise?.properties?.technical_support ? checkCircle : ''}
              </td>
            </tr>
            <tr>
              <td className=" td-first" scope="row">
                Scalable Volume Pricing
              </td>
              <td className="plan-col-trial">
                {apiSandbox?.properties?.volume_pricing ? checkCircle : ''}
              </td>
              <td className="plan-col-api-std">
                {apiStandard?.properties?.volume_pricing ? checkCircle : ''}
              </td>
              <td className="plan-col-api-premium">
                {apiPremium?.properties?.volume_pricing ? checkCircle : ''}
              </td>
              <td className="plan-col-api-enterprise">
                {apiEnterprise?.properties?.volume_pricing ? checkCircle : ''}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {/* Framed Exclusive Datasets */}
      <div className="table-wrapper display-wrapper rounded-lg">
        <table
          id="exclusive-table"
          className="table table-striped table-hover api-compare-table margin-top-none"
        >
          <thead>
            <tr>
              <th
                className="th-first col-md-12 lead sans"
                scope="col"
                colSpan="5"
              >
                Exclusive Datasets
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className=" td-first" scope="row">
                Matched Building Footprints
              </td>
              <td className="plan-col-trial">
                {apiSandbox?.properties?.matched_buildings ? checkCircle : ''}
              </td>
              <td className="plan-col-api-std">
                {apiStandard?.properties?.matched_buildings ? checkCircle : ''}
              </td>
              <td className="plan-col-api-premium">
                {apiPremium?.properties?.matched_buildings ? checkCircle : ''}
              </td>
              <td className="plan-col-api-enterprise">
                {apiEnterprise?.properties?.matched_buildings ? checkCircle : ''}
              </td>
            </tr>
            <tr>
              <td className=" td-first" scope="row">
                Matched Secondary Addresses
              </td>
              <td className="plan-col-trial">
                {apiSandbox?.properties?.matched_addresses ? checkCircle : ''}
              </td>
              <td className="plan-col-api-std">
                {apiStandard?.properties?.matched_addresses ? checkCircle : ''}
              </td>
              <td className="plan-col-api-premium">
                {apiPremium?.properties?.matched_addresses ? checkCircle : ''}
              </td>
              <td className="plan-col-api-enterprise">
                {apiEnterprise?.properties?.matched_addresses ? checkCircle : ''}
              </td>
            </tr>
            <tr>
              <td className=" td-first" scope="row">
                Typeahead Address Search
              </td>
              <td className="plan-col-trial">
                {apiSandbox?.properties?.typeahead_search ? checkCircle : ''}
              </td>
              <td className="plan-col-api-std">
                {apiStandard?.properties?.typeahead_search ? checkCircle : ''}
              </td>
              <td className="plan-col-api-premium">
                {apiPremium?.properties?.typeahead_search ? checkCircle : ''}
              </td>
              <td className="plan-col-api-enterprise">
                {apiEnterprise?.properties?.typeahead_search ? checkCircle : ''}
              </td>
            </tr>
            <tr>
              <td className=" td-first" scope="row">
                Enhanced Ownership
              </td>
              <td className="plan-col-trial">
                {apiSandbox?.properties?.enhanced_ownership ? checkCircle : ''}
              </td>
              <td className="plan-col-api-std">
                {apiStandard?.properties?.enhanced_ownership ? checkCircle : ''}
              </td>
              <td className="plan-col-api-premium">
                {apiPremium?.properties?.enhanced_ownership ? checkCircle : ''}
              </td>
              <td className="plan-col-api-enterprise">
                {apiEnterprise?.properties?.enhanced_ownership ? checkCircle : ''}
              </td>
            </tr>
            <tr>
              <td className=" td-first" scope="row">
                Standardized Zoning
              </td>
              <td className="plan-col-trial">
                {apiSandbox?.properties?.standardized_zoning ? checkCircle : ''}
              </td>
              <td className="plan-col-api-std">
                {apiStandard?.properties?.standardized_zoning ? checkCircle : ''}
              </td>
              <td className="plan-col-api-premium">
                {apiPremium?.properties?.standardized_zoning ? checkCircle : ''}
              </td>
              <td className="plan-col-api-enterprise">
                {apiEnterprise?.properties?.standardized_zoning ? checkCircle : ''}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ApiCompare