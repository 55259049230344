import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CYCLES } from 'common/settings';
import { find, map, isEmpty, extend } from 'lodash';
import ChangePlanWarnings from 'components/Payment/ChangePlanWarnings';

class ChangePlan extends Component {
  constructor(props) {
    super(props)

    this.state = {
      processing:     false,
      selectedPlan:   props.plan,
      selectedCycle:  props.cycle,
      numberOfUsers:  props.users,
      perSeatPrice:   props.per_seat_price,
      error:          null,
      showForm:       false,
      possible:       false,
    }

    this.allCycles = {
      monthly: 'Monthly',
      annual: 'Annual',
    }
    this.onlyMonthlyCycle = {
      monthly: 'Monthly',
    }

    // Minimum number of users for per seat pricing
    this.minimumNumberOfUsers = 2

    this.onClickConfirm = this.onClickConfirm.bind(this)
    this.onChangePlan   = this.onChangePlan.bind(this)
    this.onChangeCycle  = this.onChangeCycle.bind(this)
    this.onClickToggle  = this.onClickToggle.bind(this)
    this.onChangeNumberOfUsers = this.onChangeNumberOfUsers.bind(this)
    this.checkCurrentPlanSelected = this.checkCurrentPlanSelected.bind(this)
    this.onClickCancel =  this.onClickCancel.bind(this)
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const plan = urlParams.get('plan_change')
    
    if (plan) {
      this.setState({
        showForm: true,
        selectedPlan: plan, // 'team_flex'
      })
    }
  }

  render() {
    let selectedDetails = find(this.props.plans, {
      plan: this.state.selectedPlan,
    })
    const currentPlanSelected = this.checkCurrentPlanSelected()

    // Only monthly billing cycle available if Team Flex plan selected
    const perSeatPricingSelected = selectedDetails.per_seat_pricing
    const perSeatPrice =
      this.state.perSeatPrice || selectedDetails.prices.monthly
    const availableCycleOptions = perSeatPricingSelected
      ? this.onlyMonthlyCycle
      : this.allCycles
    // Render multiplied cost and number of users if Team Flex plan selected
    const monthlyCost = perSeatPricingSelected
      ? `$${numberWithCommas(perSeatPrice * this.state.numberOfUsers)}/month`
      : `$${numberWithCommas(selectedDetails.prices.monthly)}/month`
    const updateText = perSeatPricingSelected
      ? ` for ${this.state.numberOfUsers} users at `
      : ' plan at '

    return (
      <div className="change-plan" style={{ marginBottom: '4em' }}>
        <div>
          <h2 style={{ display: 'inline-block' }}>
            Your {this.props.plans[this.props.plan].name} Plan
          </h2>
          <a
            href="#"
            onClick={this.onClickToggle}
            className={`btn btn-round plan-${selectedDetails.name.toLowerCase()}-accent`}
            style={{ marginLeft: '1em', marginTop: '-10px' }}
          >
            Change plan or billing schedule{' '}
            <i
              className={
                'fas ' +
                (this.state.showForm ? 'fa-caret-down' : 'fa-caret-right')
              }
            />
          </a>
        </div>

        {this.state.showForm && (
          <div className="land-well">
            <div className="row">
              <div className="col-md-3">
                <label>New Plan</label>
                <select
                  className="form-control"
                  value={this.state.selectedPlan}
                  onChange={this.onChangePlan}
                >
                  {map(this.props.plans, (info, key) => {
                    return (
                      <option key={key} value={key}>
                        {info.name}
                      </option>
                    )
                  })}
                </select>
              </div>

              <div className="col-md-3">
                <label>Billing Cycle</label>
                <select
                  className="form-control"
                  value={this.state.selectedCycle}
                  onChange={this.onChangeCycle}
                >
                  {map(availableCycleOptions, (name, key) => {
                    return (
                      <option key={key} value={key}>
                        {name}
                      </option>
                    )
                  })}
                </select>
              </div>

              {perSeatPricingSelected && (
                <div className="row">
                  <div className="col-md-5">
                    <label htmlFor="slider">
                      Number of Users: {this.state.numberOfUsers}
                    </label>
                    <input
                      className="range-slider"
                      type="range"
                      name="slider"
                      min="2"
                      max="25"
                      value={this.state.numberOfUsers}
                      onChange={this.onChangeNumberOfUsers}
                    />
                  </div>
                </div>
              )}

              <div className="col-md-6" style={{ paddingTop: '2em' }}>
                <a href="/plans" target="_blank">
                  View plans, features and pricing{' '}
                  <i className="fas fa-arrow-right" />
                </a>
              </div>
            </div>

            {this.state.error && (
              <p className="alert alert-warning">{this.state.error}</p>
            )}

            {currentPlanSelected && (
              <div className="row">
                <div className="col-md-12" style={{ paddingTop: '1em' }}>
                  <span className="lead">
                    This is your <span className="museo900">current plan.</span>
                  </span>
                  <br />
                  <p>
                    Please use the dropdown options above to change the plan
                    type or billing cycle.
                  </p>
                </div>
              </div>
            )}

            {this.state.possible && (
              <div className="row">
                <div className="col-md-12" style={{ paddingTop: '1em' }}>
                  <span className="lead">
                    You have selected a change to the{' '}
                    <strong>{selectedDetails.name}</strong>
                    {updateText}
                    <strong>
                      {this.state.selectedCycle === CYCLES.ANNUAL
                        ? `$${numberWithCommas(
                            selectedDetails.prices.annual
                          )}/year`
                        : monthlyCost}
                    </strong>
                    .
                  </span>
                  <br />
                  <p>
                    Price changes will be prorated as of today. If upgrading,
                    the current card will be charged immediately for any
                    outstanding balance; if downgrading, you may have a credit
                    on your account towards future charges. You must confirm
                    with the button below for this to take effect.
                  </p>
                </div>
              </div>
            )}

            {this.state.possible && !isEmpty(this.state.effects) && (
              <ChangePlanWarnings {...this.state.effects} />
            )}

            {this.hasChanges() && (
              <div className="row">
                <div className="col-md-12" style={{ paddingTop: '1em' }}>
                  <a
                    href="#"
                    className="btn btn-lg btn-info btn-round"
                    disabled={!this.state.possible || this.state.processing}
                    onClick={this.onClickConfirm}
                  >
                    {this.state.processing
                      ? 'Please wait...'
                      : 'Confirm Change'}
                  </a>
                  <a
                    href="#"
                    className="btn btn-lg btn-default btn-round"
                    onClick={this.onClickCancel}
                  >
                    Cancel Change
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  onClickToggle() {
    this.setState({ showForm: !this.state.showForm })
  }

  onChangePlan(e) {
    const newPlan = e.target.value
    this.setState({ selectedPlan: newPlan }, this.checkPlanChange)
  }

  checkPlanChange() {
    const perSeatPricingSelected = this.planHasPerSeatPricing()
    // Automatically set plan to monthly if selecting a per seat pricing plan
    if (perSeatPricingSelected) {
      this.setState({ selectedCycle: CYCLES.MONTHLY }, this.checkEffects)
      this.checkNumberOfUsers()
    } else {
      // If user changes to another plan after selecting a per seat pricing plan, have the slider reset to the number of users in props
      this.setState({ numberOfUsers: this.props.users }, this.checkEffects)
    }
  }

  onChangeCycle(e) {
    this.setState({ selectedCycle: e.target.value }, this.checkEffects)
  }

  hasChanges() {
    return !this.checkCurrentPlanSelected()
  }

  onChangeNumberOfUsers(e) {
    this.setState({ numberOfUsers: e.target.value }, this.checkEffects)
  }

  planHasPerSeatPricing() {
    return this.props.plans[this.state.selectedPlan].per_seat_pricing
  }

  checkEffects() {
    if (!this.hasChanges()) {
      // No change, hide the "change" stuff
      this.setState({
        processing: false,
        possible: false,
        effects: null,
        error: null,
      })
      return
    }

    this.setState({ processing: true })
    let self = this
    let opts = {
      plan:   this.state.selectedPlan,
      cycle:  this.state.selectedCycle,
    }
    if (this.planHasPerSeatPricing()) {
      opts.users = this.state.numberOfUsers
    }
    LL.Ajax.getSpin('/settings/check_change.json', opts)
      .then((response) => {
        self.setState({
          processing: false,
          possible:   response.possible,
          effects:    response.effects,
          error:      response.reason,
        })
      })
      .catch((msg) => {
        self.setState({ processing: false, error: msg })
      })
  }

  // If numbers of users less than minimum for team account or null, set state to minimum number
  checkNumberOfUsers() {
    const lessThanMinimum =
      parseInt(this.state.numberOfUsers) < this.minimumNumberOfUsers
    const usersNull = this.state.numberOfUsers === null
    if (lessThanMinimum || usersNull) {
      this.setState(
        { numberOfUsers: this.minimumNumberOfUsers },
        this.checkEffects
      )
    }
  }

  usingOffer() {
    return (
      this.props.offer &&
      this.props.offer.plan === this.state.selectedPlan &&
      this.props.offer.cycle === this.state.selectedCycle
    )
  }

  checkCurrentPlanSelected() {
    const samePlan =
      this.state.selectedPlan === this.props.plan &&
      this.state.selectedCycle === this.props.cycle
    const hasPerSeatPricing = this.planHasPerSeatPricing()
    // Make sure number of users match to show if current plan is selected (Team Flex only)
    if (hasPerSeatPricing) {
      return samePlan && parseInt(this.state.numberOfUsers) === this.props.users
    } else {
      return samePlan
    }
  }

  resetToCurrentPlan() {
    this.setState({
      selectedCycle:  this.props.cycle,
      selectedPlan:   this.props.plan,
      numberOfUsers:  this.props.users,
    })
  }

  onClickCancel() {
    this.onClickToggle()
    this.resetToCurrentPlan()
  }

  // Submit upgrade/downgrade for real
  onClickConfirm(e) {
    e.preventDefault()
    if (this.state.processing || !this.state.possible) {
      return
    }

    let opts = {
      plan:   this.state.selectedPlan,
      cycle:  this.state.selectedCycle,
    }
    if (this.usingOffer()) {
      extend(opts, {
        code:   this.props.offer.code,
        price:  this.props.offer.price,
      })
    }
    if (this.planHasPerSeatPricing()) {
      opts.users =  this.state.numberOfUsers
      opts.per_seat_price = this.state.perSeatPrice
    }
    var self = this
    this.setState({ processing: true })
    LL.Ajax.putSpin('/subscriptions/update.json', opts)
      .then((response) => {
        window.location.reload()
      })
      .catch((msg) => {
        self.setState({ error: msg, processing: false })
      })
  }
}

ChangePlan.propTypes = {
  plan:           PropTypes.string.isRequired,
  cycle:          PropTypes.string.isRequired,
  plans:          PropTypes.object.isRequired,
  offer:          PropTypes.object,
  users:          PropTypes.number,
  per_seat_price: PropTypes.number
};

export default ChangePlan;
