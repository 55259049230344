import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { thirdPartyLayers } from '../Layers/thirdPartyLayers'

// Redux
import { getThirdPartyLayer } from 'ducks/mapProperties'

import landCoverLegendImg from '@/assets/layer-legends/land-cover'
import lbcsActivityLegendImg from '@/assets/layer-legends/lbcs-activity'
import populationDensityLegendImg from '@/assets/layer-legends/pop-density'
import populationGrowthLegendImg from '@/assets/layer-legends/pop-growth'
import housingAffordabilityLegendImg from '@/assets/layer-legends/housing-affordability'

const MapLayerLegend = ({ handleClose }) => {
  const currentThirdPartyLayer = useSelector(getThirdPartyLayer)
  const [isLegendVisible, setIsLegendVisible] = useState(false)
  const isLayersModalOpen = useSelector(state => state.mapControls.layersModalOpen)

  useEffect(() => {
    setIsLegendVisible(currentThirdPartyLayer && images[currentThirdPartyLayer])
  }, [currentThirdPartyLayer])

  const thirdPartyLayer =
    thirdPartyLayers.find((layer) => layer.id === currentThirdPartyLayer) || {}

  const legendHeadline =
    currentThirdPartyLayer === 'lbcs-activity'
      ? 'LBCS Activity'
      : thirdPartyLayer?.name ?? ''

  const images = {
    'landuse-wms': landCoverLegendImg,
    'lbcs-activity': lbcsActivityLegendImg,
    'lbcs-activity': lbcsActivityLegendImg,
    'population_density': populationDensityLegendImg,
    'population_growth': populationGrowthLegendImg,
    'housing_affordability': housingAffordabilityLegendImg,
  }

  const getLegendStyles = () => {
    const baseRight = isLayersModalOpen ? 565 : 60
    const screenRight = window.innerWidth < 768 ? 10 : baseRight
    return {
      display: isLegendVisible ? 'flex' : 'none',
      right: `${screenRight}px`,
    }
  }

  const selectedImg = currentThirdPartyLayer && images[currentThirdPartyLayer]

  return (
    <div
      style={getLegendStyles()}
      className="layers-legend rounded-lg shadow-gl padded-lg medium"
    >
      <div className="map-options-title-row padding-top-lg">
        <h4 className="map-options-title">{legendHeadline} Legend</h4>
        <div className="close" onClick={handleClose}>
          <i className="fa fa-times"></i>
        </div>
      </div>
      <img src={selectedImg} alt="Legend" />
    </div>
  )
}

export default MapLayerLegend
