import React from 'react'
import PropTypes from 'prop-types'
import ParcelDetailsField from './ParcelDetailsField'
import {
  hiddenMailingAddessFields
} from '../propertySettings.js'

/**
 * Renders a subsection of parcel details.
 *
 * @component
 * @param {Object} props - component props
 * @param {string} props.subhead - subsection header
 * @param {Array} props.secFields - fields to render in the subsection
 * @param {Object} props.parcel - full parcel object
 * @returns {JSX.Element} Rendered component
 */
const ParcelDetailsSubsection = ({ subhead, secFields, parcel }) => {
  /**
   * Check if a subsection should be rendered based on the fields and parcel data
   * @param {Array} fields - fields to check
   * @param {Object} parc - parcel data
   * @returns {Boolean} - true if subsection should be rendered
   */
  const checkRenderSubsection = (fields, parc) => {
    const parcelFields = Object.keys(parc.fields)
    for (const field of fields) {
      if (Array.isArray(field)) {
        // e.g. ['lbcs_activity', 'lbcs_activity_desc']
        // If array, check if every string in the array is included and !null
        if (
          field.every(
            (f) =>
              (parcelFields.includes(f) && parc.fields[f] !== null) ||
              !!parc.premium_field_metadata[f]
          )
        ) {
          return true
        }
      } else if (
        // If string, check if it is included in parcelFields and !null
        (parcelFields.includes(field) && parc.fields[field] !== null) ||
        !!parc.premium_field_metadata[field]
      ) {
        return true
      }
    }
    return false
  }

  if (checkRenderSubsection(secFields, parcel)) {
    return (
      <div className="subsection margin-top-lg">
        <p className="subsection-header padding-top-sm padding-btm-sm bold small">
          {subhead}
        </p>
        <div className="subsection-fields">
          {secFields.map((sectionField) => {
            let field = sectionField
            let fieldSecondary = null
            
            // skipping duplicated mailing address fields
            if (hiddenMailingAddessFields.includes(field)) {
              return null; 
            }

            // combined fields rendered as one, ['lbcs_activity', 'lbcs_activity_desc']
            if (Array.isArray(field)) {
              fieldSecondary = field[1]
              field = field[0]
            }
            return (
              <ParcelDetailsField
                key={field}
                field={field}
                fieldSecondary={fieldSecondary}
                parcel={parcel}
              />
            )
          })}
        </div>
      </div>
    )
  } else {
    // debug(`Not rendering ParcelDetailsSubsection for ${subhead}(subhead)`)
    return null
  }
}

ParcelDetailsSubsection.propTypes = {
  subhead: PropTypes.string,
  secFields: PropTypes.array,
  parcel: PropTypes.object,
}

export default ParcelDetailsSubsection
