import React from 'react'

/**
 * ImageMeteredNotice component renders different notices based on the user's 
 * permissions and the availability of imagery.
 * It can display a notice to show the image, an unavailable imagery notice, 
 * or an upgrade notice.
 * 
 * @param {Object} props - The component props
 * @param {boolean} props.isAllowed - indicates if the user is allowed to view the imagery
 * @param {boolean} props.isMetered - indicates if the user is metered (Starter plan)
 * @param {boolean} props.isTypeShown - indicates if the user opted to show the image
 * @param {boolean} props.isLastMetered - indicates if this is the last metered image the user can view
 * @param {boolean} props.isTypeUnrequested - indicates if the current imagery type has not been requested
 * @param {boolean} props.isCurrentUnavailable - indicates if the current imagery is unavailable
 * @param {boolean} props.needUpgrade - indicates if the user needs to upgrade to view more imagery
 * @param {Function} props.handleUpgrade - function to handle the upgrade action
 * @param {Function} props.handleShowImg - function to handle user requested image (Starter plan)
 * @returns {JSX.Element|null} The rendered component or null if no notice is needed 
 */
const ImageMeteredNotice = ({ 
  isAllowed,
  isMetered,
  isTypeShown,
  isLastMetered,
  isTypeUnrequested,
  isCurrentUnavailable,
  needUpgrade,
  handleUpgrade,
  handleShowImg
 }) => {
  // User opt-in show image notice
  if (isMetered && isAllowed && isTypeUnrequested) {
    return (
      <div
        className={`imagery-disabled lead flex-row-center margin-btm-none${
          isTypeShown ? ' hide' : ''
        }`}
      >
        <a role="button" id="imagery-show" className="imagery-show" onClick={handleShowImg}>
          Show image
        </a>
      </div>
    )  
  }

  // Unavailable imagery notice
  if (isAllowed && isCurrentUnavailable && !isTypeUnrequested) {
    return (
      <div className="imagery-disabled lead flex-row-center margin-btm-none">
        Imagery is unavailable here
      </div>
    )
  }

  // Upgrade notice
  if (!isAllowed && needUpgrade && !isLastMetered) {
    return (
      <div className="imagery-disabled lead flex-row-center margin-btm-none">
        <span className="small">
          Please{' '}
          <a role="button" id="imagery-signup-link" onClick={handleUpgrade}>
            upgrade to Pro
          </a>{' '}
          to continue seeing imagery.
        </span>
      </div>
    )
  }

  return null
}

export default ImageMeteredNotice
