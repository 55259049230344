import React from 'react'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import withStore from 'components/withStore'
import classNames from 'common/utils/classNames'

// Components
import InfoTooltip from 'components/Utilities/InfoTooltip'

// Redux
import {
  setBuildingsLayerRendered,
  getBuildingsLayerRendered,
} from 'ducks/mapProperties'

/**
 * This component is responsible for rendering a single structure layer item in the list.
 * It handles the click event of the structure item and manages active and hover states.
 *
 * @component
 * @param {object} layer - The structure layer object passed as a prop, sourced from the structureLayers array in thirdPartyLayers.js
 * @param {string} layerImg - The image url for the structure layer passed as a prop
 * @param {boolean} isPaidUser - Determines if the user is a paid account 
 * @returns {React.Element} Returns a div element that represents a single structure layer item
 */
const StructuresListItem = ({ layer, layerImg, isPaidUser, softDisabled }) => {
  const dispatch = useDispatch()
  const buildingsLayerActive = useSelector(getBuildingsLayerRendered)
  const [hovered, setHovered] = useState(false)  

  const handleClick = () => {
    if (buildingsLayerActive) {
      // If layer is active, turn it off
      dispatch(setBuildingsLayerRendered(false))
    } else {
      // If layer is not active, turn it on
      dispatch(setBuildingsLayerRendered(true))
    }
  }

  const layerClasses = classNames(
    'modal-tile',
    'structure-tile',
    'shadow-gl-light',
    {
      active: buildingsLayerActive,
      disabled: !isPaidUser,
      'disabled-product': softDisabled && isPaidUser && buildingsLayerActive,
      'hide': softDisabled && !buildingsLayerActive,
    }
  )

  const iconClasses = classNames('icon-overlay', {
    active: buildingsLayerActive,
    hover: hovered, // add hover class when hovered
  })

  const tooltipDescription = `<p>${layer.description}</p><p>Source: <a href="${layer.docs}" target="_blank">${layer.source}</a></p>`

  return (
    <div
      id={`layer-${layer.id}`}
      className={
        layerClasses + (buildingsLayerActive || hovered ? ' plan-color-glow' : '')
      }
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
    >
      <div className="modal-tile-img">
        <img src={layerImg} alt={layer.name} />
        <div className={iconClasses}>
          {!buildingsLayerActive && hovered && <i className="fa fa-plus"></i>}
          {buildingsLayerActive && hovered && <i className="fa fa-times"></i>}
        </div>
      </div>
      <div
        className={`modal-tile-detail ${
          buildingsLayerActive ? 'plan-color-accent white' : ''
        }`}
      >
        <div className="bold md-lg responsive-font">
          {layer.name}
          <div className="tooltip-parent">
            <InfoTooltip
              id={`layer-info-${layer.id}`}
              tip={tooltipDescription}
              html={true}
              delayHide={1000}
              clickable={true}
              circleSize="fa-sm"
              place="top"
              effect="solid"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withStore(StructuresListItem)
