import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const HelpButton = ({ isActive, toggleModal }) => {
  const mapAvailable = useSelector((state) => state.mapProperties.mapAvailable)
  const [isHovered, setHovered] = useState(false)
  if (!mapAvailable) return null

  // TODO icon color does not reset immediately. Any interaction resets it.

  return (
    <div className="mapboxgl-control-container">
      <div id="map-help-button" className="mapboxgl-ctrl-bottom-right">
        <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
          <button
            className={`${isActive || isHovered ? 'plan-color' : ''} ${
              isActive ? ' active' : ''
            }`}
            type="button"
            aria-label="Regrid Help"
            onClick={toggleModal}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onTouchStart={() => setHovered(true)}
            onTouchEnd={() => setHovered(false)}
          >
            <span className="" aria-hidden="true" title="Regrid Help">
              <i className="fas fa-question-circle mapbox-ctrl-icon large"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default HelpButton
