import React from 'react';
import PropTypes from 'prop-types';

const PlaceRow = ({
  follow,
  //handleAddressClick,
  handleDeleteClick,
}) => {
  const place = follow.place.properties;
  return (
    <div className="bookmark-row" data-id={follow.id}>
      <a href={place.path}>{place.headline}</a>
      <div className="bookmark-row-btns">
        <i onClick={() => handleDeleteClick(follow.id)} className="fas fa-trash" title="Unfollow"></i>
      </div>
    </div>
  );
};

PlaceRow.propTypes = {
  follow: PropTypes.object.isRequired,
  //handleAddressClick: PropTypes.func.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
};

export default PlaceRow;
