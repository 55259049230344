import React from 'react'
import ReactTooltip from 'react-tooltip'
import { capitalize } from 'lodash'

/**
 * Note on field badges:
 * - 'addon' badge is displayed in the field label
 * - 'team' and 'pro' plan badges are displayed in the field value
 * - Exception fields may have a value of 'No Data' and should still display a badge if applicable
 * 
 * @component
 * @param {Object} props
 * @param {string} props.fieldId - field ID
 * @param {string} props.badgeType - badge type, e.g. 'pro', 'team', 'addon'
 * @param {string} props.fieldType - field type, e.g. 'premium', 'team'
 * @param {boolean} props.showAddonPanel - state of addon panel
 * @param {Function} props.setShowAddonPanel - set addon panel state
 * @param {boolean} props.isException - decalred field value, 'No Data'
 * @param {string} props.plan - user's account plan
 * @param {string} props.populatedLink - populated link for upsell
 * @returns {JSX.Element} rendered ParcelFieldBadge component
 */
const ParcelFieldBadge = ({
  fieldId,
  badgeType,
  fieldType,
  showAddonPanel,
  setShowAddonPanel,
  isException = false,
  plan = null,
  populatedLink,
}) => {
  const handleAddonClick = () => {
    if (badgeType === 'addon') setShowAddonPanel(!showAddonPanel)
  }

  let content = ''
  switch (badgeType) {
    case 'pro':
      content = 'Available with a Pro subscription.'
      break
    case 'team':
      content = 'Available with a Team subscription.'
      break
    case 'addon':
      content =
        'Additional value as an add-on to our parcel data, and available as a licensed product from Regrid.'
      break
    default:
      // If badgeType is not recognized, return
      return null
      break
  }

  // If field is an exception check if user has access to it, e.g. 'eo_owner'
  if (isException && badgeType !== 'addon') {
    if (plan === 'team') {
      // team plan has access to all exceptions
      return null
    } else if (plan === 'pro' && fieldType === 'premium') {
      // pro plan has access to pro exceptions
      return null
    }
  }

  const badgeId = `tt-${fieldId}-${badgeType}`
  const badgeTitle = badgeType === 'addon' ? 'Add-On' : capitalize(badgeType)

  return (
    <span
      className={`badge badge-${badgeType}`}
      style={{ flex: 1 }}
      data-tip={content}
      data-for={badgeId}
      onClick={handleAddonClick}
    >
      {badgeTitle}
      {badgeType !== 'addon' && (
        <i
          className="fas fa-lock margin-left-md margin-right-sm"
          aria-hidden="true"
        ></i>
      )}
      <ReactTooltip
        id={badgeId}
        place="right"
        className="tooltip upsell tt-width-200 shadow-gl-light"
        effect="solid"
        clickable={true}
        delayHide={100}
        delayShow={250}
        delayUpdate={500}
        getContent={(dataTip) => (
          <div className="tooltip-inner margin-btm-md">
            <p>{content}</p>
            {badgeType === 'addon' ? (
              <a role="button" className="small" onClick={handleAddonClick}>
                <i className="fas fa-arrow-right margin-right-md" />
                About this Add-On
              </a>
            ) : (
              <a
                href={populatedLink}
                className="small centered"
                onClick={() => {
                  window.location.href = populatedLink
                }}
              >
                <i className="fas fa-arrow-right margin-right-md" />
                {`Upgrade to ${badgeTitle}`}
              </a>
            )}
          </div>
        )}
      />
    </span>
  )
}

export default ParcelFieldBadge
