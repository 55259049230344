import React, { useEffect, useState } from 'react'
import { post } from 'ducks/api'
import { zoomToBoundary } from '../../../mapUtilities'
import { useDispatch, useSelector } from 'react-redux'
import {
  activeProjectPresent,
  getActiveProjectSlug,
  updateProjectRegions,
  getActiveProjectId,
} from 'ducks/projects'
import { fetchFocusAreas } from 'ducks/focusAreas'
import passGLMapEventToFlight from 'common/utils/passGLMapEventToFlight'

/**
 * React component form for confirming and saving a focus area
 *
 * @component
 * @param {object} feature GeoJSON feature from the drawing
 * @param {function} handleClose function to handle closing the form
 * @param {object} map MapboxGL map
 * @returns {React.Element}
 */
const FocusAreaConfirmation = ({
  feature,
  handleClose,
  map,
}) => {
  const dispatch = useDispatch()
  // const groups = window.data.groups
  // const currentGroup = groups.filter((group) => group.current)
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [savePending, setSavePending] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  // const [useGroup, setUseGroup] = useState(true)
  // const [groupSlug, setGroupSlug] = useState(currentGroup.slug)
  const [addToMap, setAddToMap] = useState(true)
  const [useAsDefault, setUseAsDefault] = useState(true)

  const hasActiveProject = useSelector(activeProjectPresent)
  const activeProjectId = useSelector(getActiveProjectId)
  const mapSlug = useSelector(getActiveProjectSlug)

  // TODO: radius
  // TODO: need to trigger any flight events?

  useEffect(() => {
    if (name.length > 2) {
      setSaveEnabled(true)
    } else {
      setSaveEnabled(false)
    }
  }, [name])

  useEffect(() => {
    if (!hasActiveProject) {
      setAddToMap(false)
      setUseAsDefault(false)
    }
  }, [hasActiveProject])

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value)
  }

  const handleAddToMapChange = () => {
    setAddToMap(!addToMap)
  }

  const handleUseAsDefaultChange = () => {
    setUseAsDefault(!useAsDefault)
  }

  const handleSubmit = (e) => {
    if (savePending) return false
    if (name.length < 2) return false
    e.preventDefault()

    // some other stuff, then build opts to pass to save function
    const opts = {
      id: feature.id,
      name: name,
      description: description,
      shape: JSON.stringify(feature),
      // radius:       dd.radius, // not sure
      group_slug: window.data.group_slug,
      map_slug: addToMap ? mapSlug : '',
      set_as_default: useAsDefault,
    }
    saveFocusArea(opts)
  }

  const saveFocusArea = (opts) => {
    setSavePending(true)

    try {
      const done = (data) => {
        // thought we would use the addFocusArea action, but data isn't the exact shape.
        // works better to refetch with the thunk
        dispatch(fetchFocusAreas())
        if (data.set_as_default) {
          passGLMapEventToFlight('mapp:project:change-root', { path: data.path })
        }
        passGLMapEventToFlight('upsell:refresh-usage')
        if (data.map_slug) {
          //TODO: check that we've fully covered all flight events and equivalents
          // passEventToFlight('project:load') // this needs the updated project object to be attached, how do we get that?
          passGLMapEventToFlight('mapp:regions:reload-focus-areas')
          if (data.map_regions) {
            dispatch(updateProjectRegions(activeProjectId, data.map_regions))
            passGLMapEventToFlight('mapp:regions:menu', data.map_regions)
          }
        }

        zoomToBoundary(data.path, map)
        handleClose()

        return data
      }

      const fail = (msg) => {
        //TODO: show an error msg to user
      }

      post('/neighborhoods.json', opts, { done, fail })
    } catch (err) {
      console.error(err)
    } finally {
      //TODO: enable link in footer
      setSavePending(false)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="name"
            id="focus-area-name"
            placeholder="Name this focus area (required)"
            value={name}
            onChange={handleNameChange}
          />
        </div>
        <div className="form-group">
          <textarea
            name="description"
            rows="3"
            className="form-control"
            placeholder="Enter an optional description"
            value={description}
            onChange={handleDescriptionChange}
          />
        </div>

        <div className="form-group small">
          {hasActiveProject && (
            <>
              <label id="add-to-map-label">
                <input
                  type="checkbox"
                  name="add_to_map"
                  checked={addToMap}
                  onChange={handleAddToMapChange}
                />
                Add this to the current project as a Focus Area
              </label>
              <label id="set-default-place-label">
                <input
                  type="checkbox"
                  name="set_default_place"
                  checked={useAsDefault}
                  onChange={handleUseAsDefaultChange}
                />
                Make this the default place for this project
              </label>
            </>
          )}
        </div>

        <p className="alert alert-warning hide hood-error"></p>
      </div>
      <div className="footer" style={{ textAlign: 'right' }}>
        <button
          type="submit"
          className="save btn btn-sm btn-primary"
          disabled={!saveEnabled}
          data-id={feature.id}
        >
          Save and go to area
        </button>
        <button
          className="remove btn btn-sm btn-default"
          data-id={feature.id}
          onClick={() => handleClose()}
        >
          Cancel
        </button>
      </div>
    </form>
  )
}

export default FocusAreaConfirmation
