import React, { useState, useEffect, useCallback } from 'react'
import withStore from '../../withStore.jsx'
import { useSelector, useDispatch } from 'react-redux'
import { getSelectedParcel } from 'ducks/parcels'
import { isNullOrUndefined } from 'common/util.js'
import { flatten, isEqual } from 'lodash'
import {
  dropdownSections,
  addressFields,
  datasetMatchingFields,
  areaDemographicsFields,
  riskFactorFields,
  ownerFields,
  propertyFields,
  zoningFields,
  structureFields,
  geoFields,
  noShowFields,
} from '../propertySettings.js'
import { PLAN_NAMES } from 'common/settings'
import mapSettings from '../../Map/mapSettings.js'
import ParcelHighlights from '../Components/ParcelHighlights.jsx'
import ParcelDetailsSection from '../Components/ParcelDetailsSection.jsx'
import { toggleSection,setLookupLimits } from 'ducks/userProperties.js'
import ParcelInfoCard from '../Components/ParcelInfoCard.jsx'
import ParcelLimitsInfoCard from '../Components/ParcelLimitsInfoCard.jsx'

/**
 * Renders the details of a parcel.
 *
 * @component
 * @returns {JSX.Element} rendered component
 */
const ParcelDetails = () => {
  const dispatch = useDispatch()
  const currentParcel = useSelector(getSelectedParcel)
  const userLimits = useSelector(
    (state) => state.userProperties.lookupLimits,
    isEqual
  )
  // Do not use a useSelector for dropdown prefs,
  // it will cause a re-render that cancels toggle animation #7216
  const store = window.LL.store
  const userDropdowns = store.getState()?.userProperties?.propertySections

  const [hasViewsRemaining, setHasViewsRemaining] = useState(false)
  const [isLoadingLimits, setIsLoadingLimits] = useState(false)
  const [used, setUsed] = useState(null)

  const otherProperties = currentParcel?.others || [] // Array of other properties at this location
  const isOthersOpen = userDropdowns['dd-other-properties']
  let isCanada = false
  const level = !window?.data?.current_user_id
  ? PLAN_NAMES.ANON
  : (window?.data?.plan_simple || PLAN_NAMES.FREE)
  const [cards, setCards] = useState([
    { id: 1, text: 'Wondering why our data has different fields in Canada than in the United States?', link: 'https://support.regrid.com/parcel-data/canada-faq' }
  ])

  /**
   * Fetches the user's lookup limits from the server and updates the Redux store.
   * Sets the limits loading state while fetching.
   */
  const fetchLookupLimits = useCallback(async () => {
    setIsLoadingLimits(true)
    let isMounted = true

    try {
      const response = await fetch(`/users/lookup_limits.json`)
      const limits = await response.json()

      if(isMounted && !isEqual(limits, userLimits)) {
        dispatch(setLookupLimits(limits))
      }
    } catch (error) {
      console.error('PI fetchLookupLimits error:', error)
    } finally {
      if(isMounted) {
        setIsLoadingLimits(false)
      }
    }

    return () => {
      isMounted = false
    }
  }, [dispatch, userLimits])

  useEffect(() => {
    fetchLookupLimits()
  }, [fetchLookupLimits])

  useEffect(() => {
    if(userLimits) {
      const remaining = typeof(userLimits?.remaining) === 'undefined'  ? Infinity : userLimits.remaining
      setHasViewsRemaining(remaining > 0)
      setUsed(Math.min(userLimits?.used ?? 0, userLimits?.total ?? Infinity))
    }
  }, [userLimits])

  /**
   * Handles toggling the dropdown sections.
   *
   * @param {string} id - id of the dropdown section, e.g. 'dd-additional-items'
   */
  const handleSectionToggle = (id) => {
    dispatch(toggleSection(id))
  }

  /**
   * Checks if the dropdown should be rendered based on the parcel object fields.
   *
   * @param {boolean} isOpen - indicates if the dropdown is open
   * @param {string} category - category of the dropdown
   * @param {Object} parcel - parcel object
   * @param {string[]} dropFields - fields to check for in the parcel object
   * @returns {JSX.Element|null} rendered dropdown component or null
   */
  const checkRenderDropdown = (isOpen, category, parcel, dropFields, id) => {
    if(!Array.isArray(dropFields)) {
      return null
    }

    // Check if any of the fields are present in the parcel object
    for(const field of dropFields) {
      const value = parcel.fields[field]
      if(id === 'dd-owner-information' && isCanada ){
        return null
      }
      if(
        !isNullOrUndefined(value) ||
        field.fields?.some(
          (subSecField) =>
            parcel.fields[subSecField] ||
            parcel.fields[subSecField] === 0 ||
            !!parcel.premium_field_metadata[subSecField]
        )
      ) {
        return (
          <ParcelDetailsSection
            key={id}
            id={id}
            isOpen={userDropdowns[id] !== undefined ? userDropdowns[id] : true}
            category={category}
            parcel={parcel}
            fields={dropFields}
            onToggle={handleSectionToggle}
          />
        )
      }
    }
    return null
  }

  /**
   * Flattens the array of fields.
   *
   * @param {Array} array - The array of fields.
   * @returns {Array} The flattened array of fields.
   */
  const flattenFields = (array) => {
    const strings = array.filter((field) => typeof field === 'string')
    const objs = array
      .filter((field) => typeof field === 'object')
      .map((field) => {
        // Filter out the fields that are included in the combined arrays
        const fields = field.fields.filter(
          (f) => !field?.combined || !field?.combined.flat().includes(f)
        )
        return fields
      })
    return [...strings, ...flatten(objs)]
  }

  /**
   * Finds the miscellaneous fields that haven't been rendered.
   *
   * @param {Array} allFields - all the category fields
   * @param {string[]} noShowFields - fields marked not to render
   * @param {Object} parcel - parcel object
   * @returns {string[]} miscellaneous fields
   */
  const getMiscFields = (allFields, noShowFields, parcel) => {
    const parcFields = Object.keys(parcel.fields)
    const noShowSet = new Set(noShowFields)
    const flatAllSet = new Set(allFields.flat())
    const { countries } = mapSettings
    const country = parcel.fields['admin0']?.toLowerCase()
    isCanada = country === countries.CA

    const miscFields = parcFields.filter(
      (field) => !noShowSet.has(field) && !flatAllSet.has(field)
    )
    return miscFields
  }

  /** If parcel hasn't loaded, show spinner  */
  if(!currentParcel?.fields || !userLimits || isLoadingLimits) {
    return <i className="fas fa-spinner fa-spin" aria-hidden="true"></i>
  }

  // ----- Dropdown Sections -----
  /**
   * Used to calculate misc fields that haven't been rendered,
   * include all category fields but miscFields
   */
  const allFields = [
    ...addressFields,
    ...datasetMatchingFields,
    ...flattenFields(ownerFields),
    ...flattenFields(propertyFields),
    ...flattenFields(zoningFields),
    ...flattenFields(structureFields),
    ...flattenFields(geoFields),
    ...flattenFields(areaDemographicsFields),
    ...flattenFields(riskFactorFields),
  ]

  let miscDropdown = {
    id:     'dd-additional-items',
    title:  'Additional Items',
    fields: [],
    isOpen:
      userDropdowns['dd-additional-items'] !== undefined
        ? userDropdowns['dd-additional-items']
        : true,
  }

  /* Gathers fields we want to render in "Additional Fields" dropdown */
  const miscFields = getMiscFields(allFields, noShowFields, currentParcel)

  if(miscFields?.length > 0) {
    miscDropdown = {
      ...miscDropdown,
      fields: miscFields,
    }
  }

  /**
   * May be more than one address if scity !== city
   * e.g. "Springfield" is a municipality within the "Springfield Township",
   * and both are valid city addresses
   */
  const addressVariants = currentParcel?.formatted_addresses || null

  /**
   * Builds a multi-line address from an array of address components.
   *
   * @param {Array} addressArray - array of address components
   * @returns {JSX.Element|null} multi-line address component or null
   */
  const buildMultiLineAddress = (addressArray) => {
    if(!addressArray || addressArray.length < 4) return null
    const multiLineAddress = (
      <address className="bold margin-none">
        <p className="margin-none">{addressArray[0] + ','}</p>
        <p className="margin-none">{`${addressArray[1]}, ${addressArray[2]} ${addressArray[3]}`}</p>
      </address>
    )
    return multiLineAddress
  }

  const handleClose = (id) => {
    //anon users should always see disclaimer
    if(window.data.signed_in){
      localStorage.setItem('canadaFieldsDisclaimer', true)
    }

    setCards((prevCards) => prevCards.filter((card) => card.id !== id))
  }

  /**
   * Include miscFields in dropdownSections
   */
  const renderedSections = [...dropdownSections, miscDropdown]
  const isDismissed = localStorage.getItem('canadaFieldsDisclaimer')

  return (
    <>
      {isCanada && !isDismissed &&
        cards.map((card) => (
          <ParcelInfoCard
            key={card.id}
            text={card.text}
            link={card.link}
            onClose={() => handleClose(card.id)}
          />
        ))}
      {isLoadingLimits ? (
        <p>Loading lookup limits...</p>
      ) : (([PLAN_NAMES.ANON, PLAN_NAMES.FREE].includes(level) || ([PLAN_NAMES.PRO, PLAN_NAMES.TEAM].includes(level) && !hasViewsRemaining))&& (
      <ParcelLimitsInfoCard
        used={String(used)}
        limit={userLimits?.total}
        level={level}
        hasViewsRemaining={hasViewsRemaining}
      />))}
      {hasViewsRemaining && (
      <ParcelHighlights
        parcel={currentParcel}
        addressVariants={addressVariants}
        buildMultiLineAddress={buildMultiLineAddress}
        isOpen={userDropdowns['dd-parcel-highlights']}
        isOthersOpen={isOthersOpen}
        otherProperties={otherProperties}
        onToggle={handleSectionToggle}
        // renders "Other Records at Location" dropdown if hasOtherProperties
      />)}
      {hasViewsRemaining &&
      renderedSections?.map((dropdown) =>
        checkRenderDropdown(
          userDropdowns[dropdown.id], // isOpen (redux)
          dropdown.title,   // category
          currentParcel,    // parcel
          dropdown.fields,  // dropFields
          dropdown.id       // id
        )
      )}

    </>
  )
}

export default withStore(ParcelDetails)
