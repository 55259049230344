import React from 'react'
import InfoTooltip from 'components/Utilities/InfoTooltip'
import { includes } from 'lodash'

/**
 * UI component for the subsection of the Export Pane that lets users choose which format they want
 *
 * @component
 * @param {object} format
 * @param {string} storeLink
 * @param {Function} handleClickFormat
 * @returns {React.Element}
 */

const ExportFormats = ({ format, storeLink, handleClickFormat }) => {
  return (
    <>
      <p className="subtle small">
        Export property data by selecting your options below. If you would like
        to buy the county or state, please visit our
        <a href={storeLink ? storeLink : '/store'}>{` Data Store`}</a>.
      </p>
      <div className="exp-file-types centered rounded padded margin-btm-lg">
        {format.formatList.map((fmt) => {
          // format object => {'shp':'Shapefile'}
          let formatKey = Object.entries(fmt)[0][0]
          let formatVal = Object.entries(fmt)[0][1]
          let activeKey = format.activeFormat
          // TODO will include these fmts in future update:
          let hiddenFormats = ['geojson', 'geopkg', 'sql']

          return (
            <a
              key={`btn-${formatKey}`}
              id={`exp-format-${formatKey}`}
              className={`btn btn-sm btn-block
          ${formatKey === activeKey ? 'btn-neon' : 'btn-primary'}
          ${includes(hiddenFormats, formatKey) ? 'hide' : ''}
        `}
              onClick={(e) => handleClickFormat(e, formatKey)}
              data-format={formatKey}
              data-skip-pjax="true"
              href="#"
            >
              <span className="bold">{formatVal}</span>
              <span>{` (.${formatKey})`}</span>
            </a>
          )
        })}
        <div className="exp-price flex-col-start width-100">
          <div className="tooltip-parent bold">
            Pricing
            <a
              className="tooltip-link"
              href="https://support.regrid.com/property-app/export-data"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InfoTooltip
                id="file-formats-tip"
                tip="Not sure which file format is right for you? Read our documentation about available file formats by clicking here."
                circleSize="fa-xs"
                place="right"
                type="dark"
                delayShow={250}
              />
            </a>
          </div>
          <p className="small flex-row-between width-100">
            <span>Spreadsheet format (.csv):</span>
            <span className="pull-right">$0.10 /parcel record.</span>
          </p>
          <p className="small flex-row-between width-100">
            <span>Spatial formats (.shp, .kml):</span>
            <span className="pull-right">$0.15 /parcel record.</span>
          </p>
        </div>
      </div>
    </>
  )
}

export default ExportFormats
