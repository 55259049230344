import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
  data: [],
  status: null,
  error: null,
}

/**
 * Thunk action for fetching list of focus areas available to the user/group.
 *
 * @returns {Array} Array of neighborhood (aka focus area) objects
 */
export const fetchFocusAreas = createAsyncThunk(
  'focusArea/fetchFocusAreas',
  async () => {
    try {
      // TODO: use api duck instead?
      const response = await fetch(`/neighborhoods.json`)

      if (!response.ok) {
        throw new Error(
          `Server responded with ${response.status}: ${response.statusText}`
        )
      }

      const data = await response.json()

      if (data?.status !== 'ok' || !data?.neighborhoods) {
        throw new Error('Invalid data structure from server.')
      }

      return data.neighborhoods
    } catch (error) {
      console.error('Problem getting focus areas:', error.message)
      throw error // rethrow error so createAsyncThunk can move to "rejected" state
    }
  }
)

// Reducer and actions
const focusAreaSlice = createSlice({
  name: 'focusArea',
  initialState,
  reducers: {
    addFocusArea(state, action) {
      state.data = [...state.data, action.payload]
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFocusAreas.pending, (state) => {
        state.status = 'loading'
        state.error = null
      })
      .addCase(fetchFocusAreas.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.data = action.payload
        state.error = null
      })
      .addCase(fetchFocusAreas.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  },
})

export const { addFocusArea } = focusAreaSlice.actions
export default focusAreaSlice.reducer

// Selectors
export const getAvailableFocusAreas = (state) => {
  return state.focusAreas.data
}

export const getFocusAreasStatus = (state) => {
  return state.focusAreas.status
}
