import React, { Component } from 'react';
import PropTypes from 'prop-types';
import produce from 'immer';

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

/**
  Bridge the react and flight worlds. Allows receiving events from flight,
  and triggering them again from react handlers.
*/
function withFlight(WrappedComponent) {

  class Wrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
      this.passEventToFlight = this.passEventToFlight.bind(this);
      this.handleEvent       = this.handleEvent.bind(this);
    }

    componentDidMount() {
      this.node = document.getElementById(this.props.nodeId);
      this.node.addEventListener('react:incoming', this.handleEvent);
    }

    componentWillUnmount() {
      // Unlisten!
    }


    render() {
      return <WrappedComponent {...this.props} {...this.state}
      passEventToFlight={this.passEventToFlight}/>;
    }


    passEventToFlight(name, opts) {
      debug('withFlight passEventToFlight', name, opts);
      let event = new CustomEvent('react:outgoing', {
        detail: {
          name:    name,
          options: opts,
        }
      });
      this.node.dispatchEvent(event);
    }


    handleEvent(e) {
      e.stopImmediatePropagation();
      let { name, options } = e.detail;
      //debug('withFlight handleEvent', name, options);
      if(name === 'props') {
        let changes = _.cloneDeep(options);
        //debug('handleEvent setting state', changes);
        this.setState(changes);
      }
    }
  }

  Wrapper.propTypes = {
  };

  Wrapper.displayName = `withFlight(${getDisplayName(WrappedComponent)})`;

  return Wrapper;
}

export default withFlight;
