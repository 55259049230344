import React from 'react'
import PropTypes from 'prop-types'
import ParcelDetailsField from './ParcelDetailsField'
import ParcelDetailsSubsection from './ParcelDetailsSubsection'

/**
 * Renders a collapsible section of parcel details.
 *
 * @component
 * @param {Object} props - component props
 * @param {string} props.category - category of the parcel details
 * @param {Object} props.parcel - full parcel object
 * @param {Array} props.fields - fields to render in the section
 * @param {string} props.id - unique identifier for the section
 * @param {boolean} props.isOpen - whether the section is open
 * @param {Function} props.onToggle - fn to handle user prefs and caret animation
 * @returns {JSX.Element} Rendered component
 */
const ParcelDetailsSection = ({
  category,
  parcel,
  fields,
  id,
  isOpen,
  onToggle,
}) =>
  {
  return (
    <div className="panel panel-default margin-btm-md">
      <div
        className={`panel-heading${!isOpen ? ' collapsed' : ''}`}
        role="tab"
        id={`heading-${id}`}
        data-toggle="collapse"
        href={`#collapse-${id}`}
        aria-expanded={isOpen}
        aria-controls={`collapse-${id}`}
        onClick={() => onToggle(id)}
      >
        <h4 className="panel-title">
          <button className="no-btn width-100">
            <span className="pull-left">{category}</span>
            <i
              id={`caret-${id}`}
              className={`fa fa-angle-up pull-right`}
            />
          </button>
        </h4>
      </div>
      <div
        id={`collapse-${id}`}
        className={`panel-collapse collapse${isOpen ? ' in' : ''}`}
        role="tabpanel"
        aria-labelledby={`heading-${id}`}
      >
        <div className="panel-body">
          {fields.map((sectionField, index) => {
            let fieldSecondary = null
            if(
              typeof sectionField !== 'string' &&
              !(sectionField instanceof Array)
            ) {
              return (
                <ParcelDetailsSubsection
                  subhead={sectionField.subhead}
                  secFields={sectionField.fields}
                  parcel={parcel}
                  key={index}
                />
              )
            } else {
              if(Array.isArray(sectionField)) {
                fieldSecondary = sectionField[1]
                sectionField = sectionField[0]
              }
              return (
                <ParcelDetailsField
                  field={sectionField}
                  fieldSecondary={fieldSecondary}
                  parcel={parcel}
                  key={index}
                />
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

ParcelDetailsSection.propTypes = {
  category: PropTypes.string,
  parcel:   PropTypes.object,
  fields:   PropTypes.array,
  id:       PropTypes.string,
  isOpen:   PropTypes.bool,
  onToggle: PropTypes.func,
}

export default ParcelDetailsSection
