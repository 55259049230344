// This operates as a normal useEffect except it
// won't fire on mount, just dependency triggers.
import { useEffect, useRef } from 'react';

const useDependencyEffect = (callback, dependencyArray) => {

  const isMounted = useRef(false);
  
    useEffect(() => {
      if(isMounted.current) {
        callback();
      } else {
        // Don't want to run this useEffect on
        // component mount, just dependency changes.
        isMounted.current = true;
      }
    }, dependencyArray);
};

export default useDependencyEffect;
