import React, { useState, useEffect, useCallback, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get, has, isArray, isEmpty, isEqual, isNull, omit } from 'lodash'

// Components
import withFlight from 'containers/withFlight'
import withStore from 'components/withStore'
import ReviewModal from './ReviewModal'
import FilterList from './FilterList'
import ExportList from './ExportList'
import ExportNoProject from './ExportNoProject'
import ExportWarning from './ExportWarning'
import ExportStats from './ExportStats'
import ExportFormats from './ExportFormats'
import ExportPaneFooter from './ExportPaneFooter'

// Redux
import {
  activeProjectPresent,
  getActiveProjectId,
  getActiveProjectRootPath,
  getActiveProjectPath,
  getActiveProjectQuery,
  areProjectsLoading,
  changeProjectRoot,
} from 'ducks/projects'
import { getActiveSources, getActiveSourceIds } from 'ducks/sources'
import {
  getCurrentQuery,
  getWithDataset,
  getProjectLastSaved,
  getMultiTable,
  getMultiFilter,
  addToCartThunk,
  fetchCoverage,
  getCoverage,
  fetchCount,
  fetchLimits,
  getExportLimits,
  fetchExports,
  getExportList,
  getCounts,
  updateCurrentCount,
} from 'ducks/exportSlice'
import {
  getAllParcelPaths,
  getActivePlaceParcelPath,
  getWorkingBoundaryPath, // path of the boundary being used for Export
} from 'ducks/boundaries'
import { getNumberOfBookmarks } from 'ducks/bookmarks'
import { getCurrentPane } from 'ducks/mapProperties'
import {
  applyExportLimits,
  calculateExportStats,
  calculatePriceWarning,
  checkMultiFilter,
} from './exportUtilities'

const ExportPane = memo(function _ExportPane ({ passEventToFlight }) {
  const [localState, setLocalState] = useState({
    pendingCart: false, // Export cart -- useEffect
    pendingDownload: false, // Export dl -- useEffect
    pendingSave: false, // Save button --  useEffect
  })

  const [format, setFormat] = useState({
    activeFormat: 'csv',
    formatList: [
      { csv: 'Spreadsheet' },
      { shp: 'Shapefile' },
      { kml: 'KML' },
      { geojson: 'GeoJSON' }, // added
      { geopkg: 'GeoPKG' }, // added
      { sql: 'SQL' }, // added
    ],
  })
  const [unsavedFilters, setUnsavedFilters] = useState({
    keysAdded: [],
    keysRemoved: [],
    valsAdded: [],
    valsRemoved: [],
  })
  const [saveNeeded, setSaveNeeded] = useState(false) // has unsavedFilters?
  const [priceWarning, setPriceWarning] = useState(false) // price > 0 && price > remaining

  // 'useSelector(get...)' fxns are imported in the form of:
  // ((state) => state.sliceOfState.someProperty)
  const availability = useSelector(getExportLimits, isEqual) //object
  const currentQuery = useSelector(getCurrentQuery, isEqual) // null or OBJECT
  const expList = useSelector(getExportList, isEqual) // index of downloads
  const projectLastSaved = useSelector(getProjectLastSaved) // datetime integer
  const visiblePane = useSelector(getCurrentPane) //
  const isMultiTable = useSelector(getMultiTable) // boolean
  const isMultiFilter = useSelector(getMultiFilter) // boolean
  const withDataset = useSelector(getWithDataset) //boolean (true = "Only My Data")
  const coverage = useSelector(getCoverage, isEqual) // null or object; % coverage of filter attrs
  const counts = useSelector(getCounts, isEqual)

  // Selectors - Active Project
  const dispatch = useDispatch()
  const projectsLoading = useSelector(areProjectsLoading)
  const hasActiveProject = useSelector(activeProjectPresent) // projects.active !== null
  const projectId = useSelector(getActiveProjectId) // integer
  const projectQuery = useSelector(getActiveProjectQuery, isEqual) // OBJECT
  const projectPath = useSelector(getActiveProjectPath) // string
  const projectRootPath = useSelector(getActiveProjectRootPath) // string
  const projectSources = useSelector(getActiveSources, isEqual) // [...] or empty []
  const activeSourceIds = useSelector(getActiveSourceIds, isEqual)

  // Selectors - Boundaries && Places
  const boundaryPaths = useSelector(getAllParcelPaths, isEqual) // ARRAY of strings
  const workingBoundaryPath = useSelector(getWorkingBoundaryPath, isEqual) // ARRAY of strings
  const activePlaceParcelPath = useSelector(getActivePlaceParcelPath, isEqual) // ARRAY of strings

  // Selectors - Follows/Bookmarks
  const numBookmarks = useSelector(getNumberOfBookmarks) // integer

  let pathArray = isArray(boundaryPaths) ? boundaryPaths[0]?.split('/') : []

  let storeLink =
    pathArray?.length > 0
      ? `/store/${pathArray[1]}/${pathArray[2]}/${pathArray[3]}`
      : '/store'

  const hasNonParcelSources =
    hasActiveProject && projectSources?.length > 0 ? true : false
  // Prevent excess calls if not on export pane
  let onExportPane = visiblePane === '#export'

  const getRemainingApplied = (numRequested) => {
    // show how many of remaining free applied at subtotal
    return availability?.remaining > numRequested
      ? numRequested
      : availability?.remaining
  }

  let { activeCount, remainingApplied, activePrice, activePriceShp } =
    calculateExportStats(
      hasNonParcelSources,
      withDataset,
      counts,
      getRemainingApplied,
      availability
    )

  useEffect(() => {
    dispatch(fetchLimits())
    getAllCounts()
  }, [])

  useEffect(() => {
    // debug('\nprojectLastSaved changed: ', projectLastSaved)
    // Acts as debounce, waits for project to save then navigates
    // to pendingCart or pendingDownload
    if (localState.pendingCart) {
      addToCart()
    }
    if (localState.pendingDownload) {
      handleDownload()
    }
    if (localState.pendingSave) {
      setTimeout(() => {
        // use !unsavedBoundary?
        setLocalState((prevState) => ({
          ...prevState,
          pendingSave: false,
        }))
      }, 2000)
    }
  }, [projectLastSaved])

  // Listen for updates to unsavedFilters
  useEffect(() => {
    checkSaveNeeded()
  }, [unsavedFilters])

  // Listen for change in visiblePane
  useEffect(() => {
    // debug('visiblePane changed: ', visiblePane);
    onExportPane = visiblePane === '#export'
  }, [visiblePane])

  useEffect(() => {
    debug('priceWarning: ', priceWarning)
    const warning = calculatePriceWarning(
      hasNonParcelSources,
      withDataset,
      format.activeFormat,
      activePrice,
      activePriceShp,
      counts?.currentCount,
      getRemainingApplied
    )
    if (warning !== priceWarning) {
      setPriceWarning(warning)
    }
  }, [
    hasNonParcelSources,
    withDataset,
    format.activeFormat,
    activePrice,
    activePriceShp,
    counts?.currentCount,
    getRemainingApplied,
    priceWarning,
  ])

  useEffect(() => {
    checkSaveNeeded()

    if (
      onExportPane &&
      hasActiveProject &&
      !localState.pendingCart &&
      !localState.pendingDownload
    ) {
      // debug('hasActiveProject && not pending');
      if (projectPath && projectRootPath) {
        dispatch(fetchLimits())
        getAllCounts()
      }
      if (projectPath) {
        // debug('getting exports');
        dispatch(fetchExports())
      }
      checkMultiFilter(
        currentQuery,
        isMultiFilter,
        isMultiTable,
        passEventToFlight
      )
    }

    // A new project initializes with both projectQuery and currentQuery as null.
    if (
      hasActiveProject &&
      projectRootPath &&
      isNull(currentQuery) &&
      isNull(projectQuery)
    ) {
      let initialQuery = { path: projectRootPath }
      // set current query to active place boundary
      passEventToFlight('query:change', initialQuery)
    }
  }, [
    currentQuery,
    projectQuery,
    hasActiveProject,
    projectRootPath,
    workingBoundaryPath,
    numBookmarks,
    onExportPane,
  ])

  useEffect(() => {
    if (
      onExportPane &&
      hasActiveProject &&
      (activePlaceParcelPath || workingBoundaryPath)
    ) {
      let linkArray = activePlaceParcelPath
        ? activePlaceParcelPath?.split('/')
        : workingBoundaryPath?.split('/')

      let link = `/store/${linkArray[1]}/${linkArray[2]}/${linkArray[3]}`

      if (
        hasActiveProject &&
        !localState.pendingCart &&
        !localState.pendingDownload
      ) {
        dispatch(fetchCoverage({ link, linkArray }))
      }
    }
  }, [
    onExportPane,
    hasActiveProject,
    workingBoundaryPath,
    activePlaceParcelPath,
  ])

  const checkSaveNeeded = useCallback(() => {
    // if this is a new project, don't show save warning
    let emptyNewProject = isEmpty(omit(currentQuery, ['path', 'operation']))
    if (!isEqual(currentQuery, projectQuery) && !emptyNewProject) {
      // debug('checkSaveNeeded: true');
      if (!saveNeeded) {
        setSaveNeeded(true)
      }
    } else {
      if (saveNeeded) {
        setSaveNeeded(false)
      }
    }
  }, [currentQuery, projectQuery, saveNeeded])

  const handleDownload = useCallback(() => {
    if (!(withDataset && hasNonParcelSources) && !availability?.can_download) {
      return
    }

    // only my data should not increment downloads
    const dlLink = `${projectPath}/exports/start.json`

    // Fetch link url
    const activeSource = activeSourceIds[0]
    let query = currentQuery
    let allParcels = true
    const datasetQuery =
      withDataset && activeSource !== null ? { [activeSource]: true } : {}
    if (withDataset && hasNonParcelSources) {
      allParcels = false
      query = {
        ...currentQuery,
        ...datasetQuery,
        path: workingBoundaryPath, // update count to reflect active place
      }
    }
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content')

    fetch(dlLink, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      },
      // credentials: 'include',
      body: JSON.stringify({
        fmt: format.activeFormat,
        all_parcels: allParcels.toString(),
        query: query,
      }),
    })
      .then((response) => {
        // debug('handleDownload response: ', response)
        if (get(response, 'url')) {
          // create and click hidden dom element to prevent blocked popup
          let url = response.url

          window.location.href = url
        } else {
          debug('Invalid response: URL property not found')
        }
      })
      .catch((err) => {
        debug('Error - handleDownload: ', err)
      })
      .finally(() => {
        // clear local state if this does not navigate away
        setTimeout(() => {
          setLocalState((prevState) => ({
            ...prevState,
            pendingDownload: false,
          }))
        }, 1000)
      })
  }, [
    withDataset,
    hasNonParcelSources,
    projectPath,
    format.activeFormat,
    availability,
  ])

  const handleCart = useCallback(() => {
    let purchase = false

    if (format.activeFormat !== 'csv') {
      // kml & shp are only free for 'Only My Data'
      if (hasNonParcelSources && !withDataset) {
        purchase = true
      } else if (!hasNonParcelSources) {
        purchase = true
      }
    }

    if (format.activeFormat === 'csv') {
      if (!hasNonParcelSources && activePrice > 0) {
        purchase = true
      } else if (hasNonParcelSources && !withDataset) {
        // requested is more than remaining free, purchase = true
        purchase = availability.remaining - counts?.currentCount < 0
      }
    }

    if (purchase) {
      setLocalState((prevState) => ({
        ...prevState,
        pendingDownload: false,
        pendingCart: true,
      }))
      // acts as a debounce while project saves
      unsavedBoundary ? handleSaveRoot() : passEventToFlight('project:save')
    } else {
      setLocalState((prevState) => ({
        ...prevState,
        pendingCart: false,
        pendingDownload: true,
      }))
      // useEffect debounce until project saves
      unsavedBoundary ? handleSaveRoot() : passEventToFlight('project:save')
    }
  }, [
    availability,
    localState?.pendingCart,
    localState?.pendingDownload,
    withDataset,
    hasNonParcelSources,
    format.activeFormat,
  ])

  const addToCart = useCallback(() => {
    let opts
    if (projectSources?.length < 1) {
      // Project contains only parcels
      opts = {
        path: projectRootPath,
        count: counts?.currentCount,
        price:
          format.activeFormat === 'csv'
            ? activePrice.toFixed(2)
            : (availability.requested * 0.15).toFixed(2),
        fmt: format.activeFormat, // 'csv'
        map_id: projectId,
        all_parcels: true,
      }
    } else {
      // Project contains uploaded dataset
      opts = !withDataset
        ? {
            // All data
            path: projectRootPath,
            count: counts?.currentCount,
            price:
              format.activeFormat === 'csv'
                ? (
                    (counts?.currentCount - availability?.remaining) *
                    0.1
                  ).toFixed(2)
                : (counts?.currentCount * 0.15)?.toFixed(2),
            fmt: format.activeFormat, // 'csv'
            map_id: projectId,
            all_parcels: true,
          }
        : {
            // Only my data
            path: projectRootPath,
            count: counts?.withDatasetCount,
            price: 0, // user datasets are free
            fmt: format.activeFormat, // 'csv'
            map_id: projectId,
            all_parcels: false,
          }
    }
    // debug('addToCart opts: ', opts);
    dispatch(addToCartThunk(opts))
  }, [
    projectSources,
    projectRootPath,
    withDataset,
    counts,
    format.activeFormat,
    projectId,
    availability,
  ])

  const handleSaveRoot = useCallback(() => {
    if (!localState.pendingSave) {
      setLocalState((prevState) => ({
        ...prevState,
        pendingSave: true,
      }))
    }
    dispatch(changeProjectRoot(projectPath, workingBoundaryPath))
  }, [localState.pendingSave, workingBoundaryPath, projectPath])

  const handleClickFormat = useCallback(
    (e, formatType) => {
      e.preventDefault()
      setFormat({ ...format, activeFormat: formatType })
    },
    [format]
  )

  const getAllCounts = useCallback(async () => {
    if (
      !hasActiveProject ||
      !projectPath ||
      !projectRootPath ||
      !workingBoundaryPath
    ) {
      return
    }

    let countQuery =
      !isEqual(currentQuery, projectQuery) && !isNull(currentQuery)
        ? currentQuery
        : projectQuery

    // Add non-parcel sources to countQuery if necessary
    if (hasNonParcelSources) {
      const projectSourceIds = projectSources.map((source) => source.id)
      for (const sourceId of projectSourceIds) {
        if (!has(currentQuery, `${sourceId}`)) {
          countQuery = { ...countQuery, [sourceId]: true }
        }
      }
    }

    // Basic options for count query
    const baseOpts = {
      style: 'counts',
      query: {
        ...countQuery,
        path: workingBoundaryPath,
      },
    }

    // Define options for different count types
    const opts = { ...baseOpts }
    const allCountOpts = hasNonParcelSources
      ? { ...baseOpts, all_parcels: true }
      : null
    const withDataCountOpts = hasNonParcelSources ? { ...baseOpts } : null

    // Fetch and update counts
    await Promise.all([
      dispatch(fetchCount({ type: 'projectCount', opts: opts })),
      dispatch(fetchCount({ type: 'allCount', opts: allCountOpts })),
      dispatch(fetchCount({ type: 'withDataCount', opts: withDataCountOpts })),
    ])
  }, [
    hasActiveProject,
    projectPath,
    projectRootPath,
    hasNonParcelSources,
    currentQuery,
    projectQuery,
    projectSources,
    workingBoundaryPath,
    withDataset,
  ])

  const handleDropdown = useCallback((e) => {
    // e.currentTarget =>   <div class="exp-info-label"></div>
    e.currentTarget.lastChild.classList.toggle('rotate-180')
  }, [])

  const updateFilters = useCallback(
    (filtersObj) => {
      if (!isEqual(unsavedFilters, filtersObj)) {
        setUnsavedFilters((prevState) => ({
          ...prevState,
          keysAdded: filtersObj?.keysAdded,
          keysRemoved: filtersObj?.keysRemoved,
          valsAdded: filtersObj?.valsAdded,
          valsRemoved: filtersObj?.valsRemoved,
        })),
          () => {}
      } else {
        // debug('updateFilters SAME SAME');
      }
    },
    [unsavedFilters]
  )

  const unsavedBoundary =
    hasActiveProject && projectRootPath !== workingBoundaryPath

  let activeSourceNames = {}
  if (projectSources) {
    projectSources.forEach((source) => {
      activeSourceNames = {
        ...activeSourceNames,
        [source.id]: source.name,
      }
    })
  }

  useEffect(() => {
    dispatch(updateCurrentCount(activeCount))
  }, [activeCount])

  let { limitCounty, limitPrice, priceThresholdLimit } = applyExportLimits(
    activeCount,
    activePrice,
    activePriceShp,
    coverage,
    format,
    availability
  )

  // --- RENDER ---
  const isPaidUser = window.data.has_sitecontrol
  const isManager = window.data.is_manager
  if (isPaidUser && !hasActiveProject && !projectsLoading) {
    return (
      <React.Fragment>
        <ExportNoProject />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <div className="review-modal-container">
        <ReviewModal
          price={activePrice}
          priceShp={activePriceShp}
          count={activeCount}
          coverage={coverage}
          format={format.activeFormat}
          handleCart={handleCart}
          storeLink={storeLink}
          withDataset={withDataset}
          hasNonParcelSources={hasNonParcelSources}
          priceWarning={priceWarning}
          limitCounty={limitCounty}
          limitPrice={limitPrice}
          priceThresholdLimit={priceThresholdLimit}
          isManager={isManager}
        />
      </div>
      <div className="exp-column">
        <div className="exp-content">
          <ExportFormats
            format={format}
            storeLink={storeLink}
            handleClickFormat={handleClickFormat}
          />

          <ExportStats
            unsavedBoundary={unsavedBoundary}
            pendingSave={localState.pendingSave}
            handleSaveRoot={handleSaveRoot}
          />

          {hasActiveProject && coverage?.schema && !isMultiFilter && (
            <FilterList
              projectQuery={projectQuery} // possible null
              currentQuery={currentQuery} // possible null
              passEventToFlight={passEventToFlight}
              updateFilters={updateFilters}
              saveNeeded={saveNeeded}
              projectSources={projectSources}
              coverageSchema={coverage?.schema}
              handleDropdown={handleDropdown}
              activeSourceNames={activeSourceNames}
            />
          )}
          {priceWarning &&
            !isPathCanada(coverage?.path) && (
            <ExportWarning
              handleDropdown={handleDropdown}
              storeLink={storeLink}
              contentType={'price'}
            />
          )}
          {isMultiTable && isMultiFilter && (
            <ExportWarning
              handleDropdown={handleDropdown}
              storeLink={storeLink}
              contentType={'multi'}
            />
          )}
          {projectSources.some((source) => source.type === 'Generic') && (
            <ExportWarning
              handleDropdown={handleDropdown}
              storeLink={storeLink}
              contentType={'generic'}
            />
          )}

          <ExportList expList={expList} handleDropdown={handleDropdown} />

          <div className="exp-guidelines centered">
            <div className="exp-guidelines-label small subtle">
              Want to learn more about exporting with Regrid?{' '}
              <a
                href="https://support.regrid.com/property-app/export-data"
                target="_blank"
              >{`Check out our Support Documentation`}</a>
              .
            </div>
          </div>
        </div>
        {/* Footer - No User Uploaded Datasets */}
        {isPaidUser &&
          !projectSources.some((source) => source.type === 'Generic') && (
            <ExportPaneFooter
              activeFormat={format.activeFormat}
              activePrice={activePrice}
              activePriceShp={activePriceShp}
              remainingAvailable={availability.remaining}
              remainingApplied={remainingApplied}
              hasNonParcelSources={hasNonParcelSources}
            />
          )}
      </div>
    </React.Fragment>
  )
}
)

export default withStore(withFlight(ExportPane))
