// ---------- mapSettings Object ----------
export default {
  baseLayerTypes: {
    street:    ['street'],
    satellite: ['satellite', 'satellite2'],
  },
  // These are just defaults for street and satellite, as the
  // user can edit their own colors.
  styles: {
    street: {
      parcelLineColor:     '#008888',
      hoverParcelColor:    '#2980B9',
      selectedParcelColor: '#e92063',// hsl(340, 82%, 52%)
      boundaryColor:       '#000000',
      labelTextColor:      '#000000',
      labelHaloColor:      '#ffffff',
      contourColor:        '#9a689c',
      subboundaries:       {
        lineColor:      '#1d525f',
        lineHoverColor: '#daff0a',
        fillColor:      '#1d525f',
        noDataColor:    '#e8927c',
      },
    },
    satellite: {
      parcelLineColor:     '#daff0a',
      hoverParcelColor:    '#2980B9',
      selectedParcelColor: '#e92063',// hsl(340, 82%, 52%)
      boundaryColor:       '#ffffff',
      labelTextColor:      '#ffffff',
      labelHaloColor:      '#000000',
      contourColor:        '#ffffff',
      subboundaries:       {
        lineColor:      '#dae1e9',
        lineHoverColor: '#daff0a',
        fillColor:      '#dae1e9',
        noDataColor:    '#e8927c', //todo set this up better bc the value is the same satellite & street
      },
    },
    buildings: {
      buildingsLineColor: '#dfdbd7',
      buildingsFillColor: '#dfdbd7',
    },
    focusAreas: {
      focusAreaColor:      '#E8927C',
      focusAreaHoverColor: '#daff0a',
    },
  },
  // Map styles that can be edited by the user
  editableStyleTypes: {
    streetParcelLines:       'streetParcelLines',
    streetHoverParcel:       'streetHoverParcel',
    streetSelectedParcel:    'streetSelectedParcel',
    satelliteParcelLines:    'satelliteParcelLines',
    satelliteHoverParcel:    'satelliteHoverParcel',
    satelliteSelectedParcel: 'satelliteSelectedParcel',
  },
  rasterMaxZoom:         13, // Zoom level at which we swap from raster to vector on the parcel layer
  parcelMinZoom:         11, // Minimum zoom level display for any parcels
  zoomReminderInMinZoom: 6,
  commonBounds:          {
    usBounds: [
      [-127.3, 23.5],
      [-64.6, 48.5],
    ],
    canadaBounds: [
      [-141.5, 40],
      [-49.5, 75],
    ],
    worldBounds: [
      [-200, -90],
      [200, 90],
    ],
    usAkBounds: [
      [-172.617821, 50.353551],
      [-127.731216, 72.088672],
    ],
    usHiHonoluluBounds: [
      [-158.710851, 20.896337],
      [-157.287825, 21.907658],
    ],
  },
  layerIds: {
    rasterBuildings:           'raster-buildings',
    subboundariesFill:         'subboundaries-fill',
    subboundariesOutline:      'subboundaries-outline',
    subboundariesOutlineHover: 'subboundaries-outline-hover',
    workingBoundaryOutline:    'working-boundary-outline',
    vectorParcelsInteraction:  'vector-parcel-interaction-layer',
    vectorParcelsOutline:      'vector-parcels-outline',
    vectorParcelsHover:        'vector-parcels-hover',
    parcelSelected:            'selected-parcel',
    parcelHover:               'parcel-hover',
    rasterParcelsStreet:       'raster-parcels-street',
    rasterParcelsSatellite:    'raster-parcels-satellite',
    focusAreasFill:            'focus-areas-fill',
    focusAreasOutline:         'focus-areas-outline',
    zoningFill:                'vector-zoning-fill',
    zoningOutline:             'vector-zoning-outline',
    zoningLabel:               'vector-zoning-label',
    parcelOwnerLabels:         'owner-name-layer',
    parcelNumberLabels:        'parcel-number-layer',
    parcelDimensions:          'parcel-dimensions-layer',
    wetlandsFill:              'wetlands-fill',
    wetlandsOutline:           'wetlands-outline',
    wetlandsLabel:             'wetlands-label',
    noFloat:                   'no-float',
  },
  sourceIds: {
    rasterBuildings:        'raster-buildings-source',
    subboundaries:          'subboundaries-source',
    workingBoundary:        'working-boundary-source',
    vectorParcels:          'vector-parcels-source',
    rasterParcelsStreet:    'raster-parcels-street-source',
    rasterParcelsSatellite: 'raster-parcels-satellite-source',
    parcelSelected:         'selected-parcel-source',
    focusAreas:             'focus-areas-source',
    zoningSource:           'vector-zoning-source',
    wetlandsSource:         'wetlands-source',
  },
  thirdPartyLayers: {
    customVectorLayer: {
      lineColor:      '#9a689c',
      lineWidth:      2,
      labelTextSize:  16,
      labelTextColor: '#9a689c',
      labelHaloColor: '#ffffff',
      labelHaloWidth: 2,
      labelHaloBlur:  0.5,
    },
    elevation: {
      lineColorStreet:         '#9a689c', // not a regrid brand color, should we change?
      lineColorSatellite:      '#ffffff',
      lineWidth:               2,
      labelTextSize:           16,
      labelTextColorStreet:    '#9a689c',
      labelTextColorSatellite: '#ffffff',
      labelHaloColorStreet:    '#ffffff',
      labelHaloColorSatellite: '#000000',
      labelHaloWidth:          2,
      labelHaloBlur:           0.5,
    },
  },
  emptyGeoJSON: {
    type:     'FeatureCollection',
    features: [],
  },
  // n.b. `countries` duplicated from utils.js `COUNTRIES` constant due to load order issues and availability in test env between sprockets and webpack.
  countries: {
    US: 'us',
    CA: 'ca',
  },
  countryLabels: {
    us: 'United States',
    ca: 'Canada',
  },
}
