import React from 'react'

const SearchBar = () => {
  return (
    <div id='nav-search-v2'>
      <form className='form-inline'>
        <div className='input-group'>
          <input 
            name='search'
            type='text' 
            className='form-control' 
            placeholder="Search"
            data-tip='search'
            onSubmit={(e) => e.preventDefault()}
          />
          <i className='fa fa-search'></i>
        </div>
      </form>
    </div>
  )
}

export default SearchBar