import React from 'react'
import { useSelector } from 'react-redux'
import { Layer, Source } from 'react-map-gl'
import mapSettings from '../mapSettings'

// Redux
import { getSatelliteLayerRendered } from 'ducks/mapProperties'
import { getBoundaryOutline } from 'ducks/boundaries'


/**
 * This component is responsible for rendering the "working" boundary layer on the map. 
 * The working boundary may also be referenced as 'region' or 'boundary' in legacy code.  
 *
 * Depending on whether the map is in satellite or street mode, 
 * it changes the color of the boundary.
 *
 * @component
 * @returns {React.Element} Returns a set of React components responsible for displaying the working boundary layer on the map.
 */
const WorkingBoundaryLayer = () => {
  const workingBoundaryPath = useSelector((state) => state.boundaries.workingBoundary)
  const outline = useSelector((state) => getBoundaryOutline(state, workingBoundaryPath))
  const isSatellite = useSelector(getSatelliteLayerRendered)
  const currentColor = isSatellite
    ? mapSettings.styles.satellite.boundaryColor
    : mapSettings.styles.street.boundaryColor

  const layerLineStyle = {
    id: mapSettings.layerIds.workingBoundaryOutline,
    type: 'line',
    source: mapSettings.sourceIds.workingBoundary,
    paint: {
      'line-color': currentColor,
      'line-width': 5,
    },
  }

  return (
    <>
      <Source
        id={mapSettings.sourceIds.workingBoundary}
        type="geojson"
        data={outline}
      >
        <Layer {...layerLineStyle} />
      </Source>
    </>
  )
}

export default WorkingBoundaryLayer
