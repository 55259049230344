import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import withStore from 'components/withStore'
import classNames from 'common/utils/classNames'

// Redux
import { getCurrentFloatLayer, setCurrentFloatLayer } from 'ducks/mapProperties'
import mapSettings from '../../../mapSettings'

/**
 * This component is responsible for rendering a single label item in the list.
 * It handles the click event of the label item and manages active and hover states.
 *
 * @component
 * @param {object} label - The label object passed as a prop, sourced from the floatLayers array in thirdPartyLayers.js
 * @param {string} labelImg - The image url for the label layer passed as a prop
 * @returns {React.Element} Returns a div element that represents a single label item
 */
const LabelsListItem = ({ label, labelImg, isPaidUser, softDisabled }) => {
  const { name, id, description, field, mapId } = label
  const [hovered, setHovered] = useState(false)
  const activeFloatLayer = useSelector(getCurrentFloatLayer)
  let isActive = activeFloatLayer === id
  const dispatch = useDispatch()

  const handleLabelClick = (e) => {
    e.stopPropagation()
    if (isActive) {
      // If label is active, turn it off
      dispatch(setCurrentFloatLayer(mapSettings.layerIds.noFloat))
    } else {
      // If label is not active, turn it on
      dispatch(setCurrentFloatLayer(id))
    }
  }

  const labelClasses = classNames(
    'label-tile',
    'modal-tile',
    'shadow-gl-light',
    {
      active: isActive,
      disabled: !isPaidUser,
      "disabled-product": softDisabled && isPaidUser && isActive,
      "hide": softDisabled && !isActive,
    }
  )

  const iconClasses = classNames('icon-overlay', {
    active: isActive,
    hover: hovered, // add hover class when hovered
  })

  // placeholder for tooltip description
  const tooltipDescription = description
    ? `<p>${description}</p><p>Source: <a href="" target="_blank"></a></p>`
    : null

  return (
    <div
      id={`label-${id}`}
      className={labelClasses + (isActive || hovered ? ' plan-color-glow' : '')}
      onClick={handleLabelClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
    >
      <div className="modal-tile-img">
        <img src={labelImg} alt={name} />
        <div className={iconClasses}>
          {!isActive && hovered && <i className="fa fa-plus"></i>}
          {isActive && hovered && <i className="fa fa-times"></i>}
        </div>
      </div>
      <div
        className={`modal-tile-detail ${isActive ? 'plan-color-accent white' : ''}`}
      >
        <p className="bold md-lg">
          {name}
          {tooltipDescription && (
            <div className="tooltip-parent">
              <InfoTooltip
                id={`layer-info-${layer.id}`}
                tip={description}
                html={true}
                delayHide={1000}
                clickable={true}
                circleSize="fa-sm"
                place="top"
                effect="solid"
              />
            </div>
          )}
        </p>
      </div>
    </div>
  )
}

LabelsListItem.propTypes = {
  label: PropTypes.object.isRequired,
}

export default withStore(LabelsListItem)
