import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { map, slice } from 'lodash';
import SEQuestion from 'components/SurveyEditor/SEQuestion';

const SEQuestionSet = props => (
  <>
    <div className='questions'>
      {map(props.questions, (question, i) => {
        return <SEQuestion {...question}
          key={i}
          index={i}
          handlers={props.handlers}
          nextQuestions={slice(props.questions, i+1)}
          />
      })}
    </div>
    <a className='btn btn-primary' href='#' onClick={props.handlers.addQuestion}>
      <i className='fas fa-plus' aria-hidden='true'/> Add Another Question
    </a>
  </>
)

SEQuestionSet.propTypes = {
  questions:   PropTypes.arrayOf(PropTypes.object).isRequired,
  handlers:    PropTypes.objectOf(PropTypes.func).isRequired,
}

export default SEQuestionSet;
