import { uniqueArray } from '../util';

// Used to create a className string when using conditionals.
// Can only take string and objects as args, the objects being the
// class name as they key and the expression as the value.
const classNames = (...args) => {

  if(args.length === 0){
    debug('classNames: Sorry, we need args to create classes');
    return;
  }

  const classes = [];
  for(const arg of args) {

    // If a string, lets push it straight through
    if(typeof arg === 'string'){
      classes.push(arg);
    }
    
    // If an object, lets loop through and check its values before pushing its respective key through
    if(arg && typeof arg === 'object'){
      const keys = Object.keys(arg);
      for(const key of keys){
        if(arg[key]){
          classes.push(key);
        }
      }
    }
  }

  // Make the array unique so we don't have any repeat classes
  const uniqueClasses = uniqueArray(classes);

  return uniqueClasses.join(' ');
};

export default classNames;