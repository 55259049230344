import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

// Our reducers
import bookmarks from 'ducks/bookmarks';
import boundaries from 'ducks/boundaries';
import cart from 'ducks/cart';
import filters from 'ducks/filters';
import focusAreas from 'ducks/focusAreas';
import mapControls from 'ducks/mapControls';
import mapProperties from 'ducks/mapProperties';
import exports from 'ducks/exportSlice';
import parcels from 'ducks/parcels';
import parcelverse from 'ducks/parcelverse';
import projects from 'ducks/projects';
import sources from 'ducks/sources';
import styles from 'ducks/styles';
import userProperties from 'ducks/userProperties';
import { mapMiddleware } from './mapProperties';

const rootReducer = combineReducers({
  bookmarks,
  boundaries,
  cart,
  exports,
  filters,
  focusAreas,
  mapControls,
  mapProperties,
  parcels,
  parcelverse,
  projects,
  sources,
  styles,
  userProperties,
});

export const configureStore = (preloadedState) => {
  const middlewares = [thunk, mapMiddleware];

  // Apply dev tools
  // const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? 
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true, 
      traceLimit: 25
    }) : compose;

  return createStore(
    rootReducer,
    preloadedState, // useful for writing tests with redux-connected components
    composeEnhancer(applyMiddleware(...middlewares))
  );
};

export default configureStore;
