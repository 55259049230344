import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { isEqual, pick } from 'lodash'

const initialState = {
  coverage: null,
  counts: {
    projectCount: null,
    currentCount: null,
    allCount: null,
    withDatasetCount: null,
  },
  currentQuery: null,
  downloadsAvailable: 0,
  exportList: [],
  limits: {
    price: 0,
    remaining: 0,
    requested: 0,
  },
  withDataset: true,
  projectLastSaved: 0,
  spinCount: 0,
  multiTable: false,
  multiFilter: false,
}

const exportSlice = createSlice({
  name: 'exports',
  initialState,
  reducers: {
    updateCounts(state, action) {
      state.counts = action.payload
    },
    updateCurrentCount(state, action) {
      state.counts.currentCount = action.payload
    },
    updateProjectCount(state, action) {
      state.counts.projectCount = action.payload
    },
    updateAllCount(state, action) {
      state.counts.allCount = action.payload
    },
    updateWithDatasetCount(state, action) {
      state.counts.withDatasetCount = action.payload
    },
    updateCoverage(state, action) {
      state.coverage = action.payload
    },
    updateExpQuery(state, action) {
      state.currentQuery = { ...action.payload }
    },
    updateExportLimits(state, action) {
      state.limits = { ...action.payload }
    },
    updateExportList(state, action) {
      state.exportList = action.payload
    },
    updateWithDataset(state, action) {
      state.withDataset = action.payload
    },
    updateProjectLastSaved(state, action) {
      state.projectLastSaved = action.payload
    },
    spinIncrement(state) {
      state.spinCount += 1
    },
    spinDecrement(state) {
      state.spinCount -= 1
    },
    updateMultiTable(state, action) {
      state.multiTable = action.payload
    },
    updateMultiFilter(state, action) {
      state.multiFilter = action.payload
    },
  },
})

// Actions
export const {
  updateAvailability,
  updateCounts,
  updateCurrentCount,
  updateProjectCount,
  updateAllCount,
  updateWithDatasetCount,
  updateCoverage,
  updateExpQuery,
  updateExportLimits,
  updateExportList,
  updateWithDataset,
  updateProjectLastSaved,
  spinIncrement,
  spinDecrement,
  updateMultiTable,
  updateMultiFilter,
} = exportSlice.actions

// Selectors
export const getExportLimits = (state) => state.exports.limits
export const getExportList = (state) => state.exports.exportList
export const getCurrentCount = (state) => state.exports.counts.currentCount
export const getCurrentQuery = (state) => state.exports.currentQuery
export const getWithDataset = (state) => state.exports.withDataset
export const getAvailableDownloads = (state) => state.exports.downloadsAvailable
export const getProjectLastSaved = (state) => state.exports.projectLastSaved
export const getSpinCount = (state) => state.exports.spinCount > 0
export const getMultiTable = (state) => state.exports.multiTable
export const getMultiFilter = (state) => state.exports.multiFilter
export const getCoverage = (state) => state.exports.coverage
export const getCounts = (state) => state.exports.counts

// Thunks
export const addToCartThunk = createAsyncThunk(
  'exports/addToCart',
  async (opts, { dispatch, rejectWithValue }) => {
    try {
      dispatch(spinIncrement())
      const response = await LL.Ajax.postSpin(
        '/orders/cart/add_export_items.json',
        opts
      )
      debug('Success adding product to cart', response)
      window.location = '/orders/cart'
    } catch (err) {
      debug(' Error adding product to cart', err)
      return rejectWithValue(err)
    } finally {
      dispatch(spinDecrement())
    }
  }
)

export const fetchCoverage = createAsyncThunk(
  'exports/fetchCoverage',
  async ({ link, linkArray }, { dispatch, getState, rejectWithValue }) => {
    if (!linkArray || linkArray?.length < 1) {
      return rejectWithValue(`Problem with linkArray value: ${linkArray}`)
    }
    let activeState = linkArray[2]
    let activeCounty = linkArray[3]

    dispatch(spinIncrement())

    try {
      const response = await LL.Ajax.getSpin(`${link}.json`, {
        state: activeState,
        county: activeCounty,
      })

      const previousCoverage = getState().exports.coverage
      if (!isEqual(response, previousCoverage)) {
        dispatch(updateCoverage(response)) //probably don't need full response
      }
      // return response
    } catch (error) {
      console.error('Error - store county response', error)
      return rejectWithValue(error)
    } finally {
      dispatch(spinDecrement())
    }
  }
)

const getProjectPath = (active, byId) => {
  if (active !== null) {
    const project = byId[active]
    if (project) {
      return project.path
    }
  }
  return null
}

export const fetchCount = createAsyncThunk(
  'exports/fetchCount',
  async ({ type, opts }, { dispatch, getState, rejectWithValue }) => {
    const { active, byId } = getState().projects
    const hasActiveProject = active !== null
    const projectPath = getProjectPath(active, byId)
    const workingBoundaryPath = getState().boundaries.workingBoundary
    if (!hasActiveProject || !projectPath || !workingBoundaryPath) {
      return rejectWithValue('No active project or paths are not set')
    }
    dispatch(spinIncrement())

    try {
      const result = await LL.Ajax.postSpin(projectPath + '/blexts.json', opts)
      const counts = getState().exports.counts
      switch (type) {
        case 'projectCount':
          if (result.count && result.count !== counts?.projectCount) {
            dispatch(updateProjectCount(result.count))
          }
          break
        case 'allCount':
          if (result.count && result.count !== counts?.allCount) {
            dispatch(updateAllCount(result.count))
          }
          break
        case 'withDataCount':
          if (result.count && result.count !== counts?.withDatasetCount) {
            dispatch(updateWithDatasetCount(result.count))
          }
          break
        default:
          return
      }
      return result
    } catch (error) {
      return rejectWithValue(error.toString())
    } finally {
      dispatch(spinDecrement())
    }
  }
)

export const fetchLimits = createAsyncThunk(
  'exports/fetchLimits',
  async (args, { dispatch, getState, rejectWithValue }) => {
    const { active, byId } = getState().projects
    const projectPath = getProjectPath(active, byId)
    const workingBoundaryPath = getState().boundaries.workingBoundary
    const currentLimits = getState().exports.limits
    const currentQuery = getState().exports.currentQuery
    const withDataset = getState().exports.withDataset
    const activeSource = getState().sources.active[0] // TODO: read all active sources

    if (!projectPath || !currentQuery) {
      return rejectWithValue('No projectPath or no currentQuery')
    }

    let limitsQuery = pick(currentQuery, ['operation', 'parcel', 'path'])
    const datasetQuery =
      withDataset && activeSource !== null ? { [activeSource]: true } : {}
    let opts = {
      query: {
        ...limitsQuery,
        ...datasetQuery,
        path: workingBoundaryPath, // update count to reflect active place
      },
    }

    dispatch(spinIncrement()) // Spinner > 0 => show spinner

    // project path: `/m/project-name`
    LL.Ajax.postSpin(projectPath + '/exports/check', opts)
      .then((response) => {
        if (!isEqual(currentLimits, response)) {
          // TODO: redux probably doesn't need to keep everything from the response
          dispatch(updateExportLimits(response))
        }
      })
      .catch((err) => {
        return rejectWithValue(err.toString())
      })
      .finally(() => {
        dispatch(spinDecrement())
      })
  }
)

export const fetchExports = createAsyncThunk(
  'exports/fetchExports',
  async (args, { dispatch, getState }) => {
    const { active, byId } = getState().projects
    const exportList = getState().exports.exportList
    const projectPath = getProjectPath(active, byId)
    if (!projectPath) {
      return rejectWithValue('No projectPath')
    }
    // spinner not necessary
    LL.Ajax.getSpin(projectPath + '/exports.json').then(function (results) {
      // const SCHEDULED = 0
      // const RUNNING   = 1
      // const DONE      = 2
      // const FAILED    = 3
      // const REMOVED   = 4

      if (!isEqual(results.exports, exportList)) {
        dispatch(updateExportList(results.exports))
      }
    })
  }
)

export default exportSlice.reducer
