import React from 'react';
import PropTypes from 'prop-types'
import { isEmpty, map } from 'lodash';

const AccountDetailDownloads = props => {
  const g = props.group
  const showAttempted = props.showAttempted
  return <>
    {!isEmpty(g.downloaded_parcel_history) && <>
      <h3>Free Downloads</h3>
      {props.showDownloadNums && (
        <>
          <p>You get {window.data.max_list_count.toLocaleString()} free spreadsheet(CSV) downloads per month with your Regrid</p>
          <p> Property App subscription, <a href="https://support.regrid.com/property-app/export-data" target="_blank">read more about these exports here</a>.</p>
          <br/>
          <p className="medium">Monthly downloads used so far: {g.downloaded_parcels_sum}</p>
          <p className="medium padding-btm-md">Monthly downloads remaining: {g.downloaded_parcels_remaining}</p>
        </>
      )}
      <table className='downloads table table-condensed' style={{ marginBottom: "0" }}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Downloaded</th>
            {showAttempted && <th>Attempted</th>}
          </tr>
        </thead>
        <tbody>
          {map(g.downloaded_parcel_history, row => {
            return <tr key={row.date}>
              <td>{row.date}</td>
              <td>{numberWithCommas(row.successful)}</td>
              {showAttempted && <td>{numberWithCommas(row.attempted)}</td>}
            </tr>
          })}
        </tbody>
      </table>
      <br/>
      <br/>
    </>}
  </>
}

AccountDetailDownloads.propTypes = {
  group: PropTypes.object.isRequired
}

export default AccountDetailDownloads;
