import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMap } from 'react-map-gl'
import {
  setDrawActive,
  setDrawMode,
  setCurrentDrawTool,
  setUnitArea,
  setUnitDistance,
} from 'ducks/mapProperties'
import MapboxDraw from '@mapbox/mapbox-gl-draw'
import {
  streetDrawConfig,
  satelliteDrawConfig,
  segmentLayerConfig,
  labelLayerConfig,
} from './drawStyle'
import {
  availableUnits,
  defaultUnits,
  formatUnitForDisplay,
  calculateMidpointCoords,
  createLabelFeatures,
  updatePointLabels,
  processAlphaLabel,
  getDisplayArea,
  getDisplayPerimeter,
  getDisplaySegments,
  processPointsContent,
} from './drawUtils'
import FocusAreaConfirmation from '../FocusAreas/FocusAreaConfirmation'

const FeatureButtonSmall = ({ title, type, handleClick }) => {
  const iconType = {
    create: 'fas fa-pencil-alt',
    delete: 'fas fa-trash-alt',
    Line: 'fas fa-ruler-horizontal',
    Shape: 'fas fa-draw-polygon',
    Point: 'fas fa-crosshairs',
  }

  let mouseoverTitle
  if (type === 'create') {
    mouseoverTitle = `New ${title}`
  } else if (type === 'delete') {
    mouseoverTitle = `Delete ${title}`
  } else {
    mouseoverTitle = title
  }

  return (
    <>
      <button
        className={`btn btn-xs btn-round feature-btn-small`}
        onClick={handleClick}
        title={mouseoverTitle}
      >
        {type === 'create' && (
          <>
            <i className={`${iconType[title]} feature-icon`}></i>
            <i className="fas fa-plus-circle feature-plus-icon"></i>
          </>
        )}
        {type === 'delete' && <i className={iconType[type]}></i>}
      </button>
    </>
  )
}

const DrawnPolygonContent = ({
  feature,
  onUnitChange,
  setMessageContent,
  handleCloseControl,
  map,
}) => {
  const areaUnit = useSelector((state) => state.mapProperties.unitArea)
  const distUnit = useSelector((state) => state.mapProperties.unitDistance)
  debug('!! DrawnPolygonContent area, distance :', areaUnit, distUnit)

  // allow null values to flag user has not selected a unit
  const displayArea = getDisplayArea(feature, areaUnit)
  const displayPerimeter = getDisplayPerimeter(feature, distUnit)

  const units = {
    area: areaUnit || defaultUnits.area,
    distance: distUnit || defaultUnits.distance,
  }

  const handleSaveAsFocusArea = (e) => {
    setMessageContent(
      <FocusAreaConfirmation
        feature={feature}
        handleClose={handleCloseControl}
        map={map}
      />
    )
  }

  return (
    <div className="table-responsive">
      <table
        className="table table-bordered table-condensed small"
        style={{ maxWidth: '100%' }}
      >
        <colgroup span="3"></colgroup>
        <thead>
          <tr>
            <th colSpan="3" scope="colgroup">
              <div className="flex-row-between">
                <span>Current Shape</span>
                <button
                  className="btn btn-round btn-xs btn-primary"
                  title="Save as Focus Area"
                  onClick={handleSaveAsFocusArea}
                >
                  <i className="fas fa-save margin-right-md"></i>
                  Focus Area
                </button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {displayArea && (
            <tr>
              <td style={{ width: '25%' }}>
                <strong>Area</strong>
              </td>
              <td className="right" style={{ width: '35%' }}>
                <strong>{displayArea}</strong>
              </td>
              <td>
                <select
                  value={units.area}
                  style={{ width: '100%' }}
                  onChange={(e) => onUnitChange('area', e.target.value)}
                >
                  {availableUnits.area.map((unit) => (
                    <option key={unit} value={unit}>
                      {formatUnitForDisplay(unit)}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          )}
          {displayPerimeter && (
            <tr>
              <td style={{ width: '25%' }}>
                <strong>Perimeter</strong>
              </td>
              <td className="right" style={{ width: '35%' }}>
                <strong>{displayPerimeter}</strong>
              </td>
              <td>
                <select
                  value={units.distance}
                  style={{ width: '100%' }}
                  onChange={(e) => onUnitChange('distance', e.target.value)}
                >
                  {availableUnits.distance.map((unit) => (
                    <option key={unit} value={unit}>
                      {formatUnitForDisplay(unit)}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

const DrawnPointsContent = ({ pointsList, selectedFeatureId }) => {
  return (
    <div className="table-responsive">
      <table
        className="table table-bordered table-condensed small"
        style={{ maxWidth: '100%' }}
      >
        <colgroup span="2"></colgroup>
        <thead>
          <tr>
            <th>Point</th>
            <th>Lat/Long</th>
          </tr>
        </thead>
        <tbody>
          {pointsList.map((item, idx) => {
            const isSelected = item?.id === selectedFeatureId
            return (
              <tr key={`coord-${idx + 1}`} className={isSelected ? 'bold' : ''}>
                <td>{`Point ${processAlphaLabel(idx)}${
                  isSelected ? ' (selected)' : ''
                }`}</td>
                <td>{item.text}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

const DrawnLineContent = ({ feature, onUnitChange }) => {
  const distUnit = useSelector((state) => state.mapProperties.unitDistance)
  debug('DrawnLineContent - distUnit: ', distUnit)
  const units = distUnit || 'miles'
  const { segmentLengths, totalLength } = getDisplaySegments(feature, distUnit)

  return (
    <div className="table-responsive">
      {segmentLengths.length > 0 && (
        <table
          className="table table-bordered table-condensed small"
          style={{ maxWidth: '100%' }}
        >
          <colgroup span="2"></colgroup>
          <thead>
            <tr>
              <th>Total length of line</th>
              <th className="right">{numberWithCommas(totalLength)}</th>
              <th>
                <select
                  value={units}
                  onChange={(e) => onUnitChange('distance', e.target.value)}
                  style={{ width: '100%' }}
                >
                  {availableUnits.distance.map((unit) => (
                    <option key={unit} value={unit}>
                      {formatUnitForDisplay(unit)}
                    </option>
                  ))}
                </select>
              </th>
            </tr>
          </thead>
          <tbody>
            {segmentLengths.map((length, idx) => (
              <tr key={`segment-${idx + 1}`}>
                <td>{`Segment ${processAlphaLabel(idx)}`}</td>
                <td className="right">{`${numberWithCommas(length)}`}</td>
                <td>{formatUnitForDisplay(units)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
}

// State bools and values for draw modes:
// drawActive: (show draw control UI)
// currentTool: (modal draw tile is active) 'measureDistance' | 'measureArea' | 'measureCoords' | null
// drawMode: (user is actively drawing) 'draw_line_string' | 'draw_polygon' | 'draw_point' | null

const DrawControl = () => {
  const { current: map } = useMap()
  const dispatch = useDispatch()

  const unitDistance = useSelector((state) => state.mapProperties.unitDistance)
  const unitArea = useSelector((state) => state.mapProperties.unitArea)
  const baseLayer = useSelector((state) => state.mapProperties.baseLayer)
  const drawActive = useSelector((state) => state.mapProperties.drawActive)
  const currentTool = useSelector(
    (state) => state.mapProperties.currentDrawTool
  )
  const mode = useSelector((state) => state.mapProperties.drawMode) // TODO not necessary?
  const isDrawingFocus = useSelector(
    (state) => state.mapProperties.isDrawingFocus
  )

  const drawControl = useRef(null)
  const [drawnFeatures, setDrawnFeatures] = useState({}) // TODO not necessary?
  const [messageContent, setMessageContent] = useState(null)
  const [isFeatureSelected, setIsFeatureSelected] = useState(false)
  const [isSelectMode, setIsSelectMode] = useState(false)
  // const [selectedFeatureId, setSelectedFeatureId] = useState(null)

  const addMapEventListeners = () => {
    map.on('draw.create', handleDrawCreate)
    map.on('draw.update', handleDrawUpdate)
    map.on('draw.delete', handleDrawDelete)
    map.on('draw.modechange', (e) => {
      debug('Draw Mode Changed:', e)
    })
    map.on('draw.selectionchange', (e) => {
      handleSelection(e)
      debug('Draw Selection Changed:', e)
      let currentMode = drawControl.current.getMode()
      let featureSelected = e.features.length > 0
      if (isFeatureSelected !== featureSelected) {
        setIsFeatureSelected(featureSelected)
      }
      let selectMode =
        currentMode === 'simple_select' || currentMode === 'direct_select'
      if (isSelectMode !== selectMode) {
        setIsSelectMode(selectMode)
      }
    })
  }

  const removeMapEventListeners = () => {
    map.off('draw.create', handleDrawCreate)
    map.off('draw.update', handleDrawUpdate)
    map.off('draw.delete', handleDrawDelete)
    map.off('draw.modechange')
    map.off('draw.selectionchange', handleSelection)
    map.off('mousemove')
  }

  const setupDrawControl = (
    drawStyles,
    initialMode = 'simple_select',
    featuresToAdd = []
  ) => {
    // Remove current drawControl and its event listeners
    if (drawControl.current) {
      removeMapEventListeners()
      map.removeControl(drawControl.current)
      drawControl.current = null
    }

    // Initialize a new drawControl with updated styles and add it to the map
    const draw = new MapboxDraw({
      displayControlsDefault: false,
      userProperties: true,
      defaultMode: initialMode,
      styles: drawStyles,
    })

    map.addControl(draw, 'top-left')
    drawControl.current = draw

    // If there are features to add, add them back to the drawControl
    if (featuresToAdd.length) {
      featuresToAdd.forEach((feature) => {
        drawControl.current.add(feature)
      })
    }
  }

  const clearMessageAndFeatures = () => {
    setMessageContent(null)
    setDrawnFeatures({})
  }

  const clearSegmentLabels = () => {
    removeLayer('segment-order-layer', 'segment-order-source')
  }

  const clearPointLabels = () => {
    removeLayer('point-label-layer', 'point-label-source')
  }

  useEffect(() => {
    if (!map) return

    // Determine draw styles based on base layer and current tool
    const drawStyles =
      baseLayer === 'satellite' ? satelliteDrawConfig : streetDrawConfig
    const drawMode = DrawToolData[currentTool]?.drawMode || 'simple_select'
    dispatch(setDrawMode(drawMode))
    debug('useEffect drawMode: ', drawMode)
    debug('currentTool: ', currentTool)

    if (drawActive) {
      // Add draw control to map
      setupDrawControl(drawStyles, drawMode)
      addMapEventListeners()
    } else if (!drawActive && drawControl.current) {
      // Remove draw control from map
      map.removeControl(drawControl.current)
      drawControl.current = null
    }

    // Clear messageContent whenever currentTool or drawActive changes
    clearMessageAndFeatures()

    if (!drawActive) {
      clearSegmentLabels()
      clearPointLabels()
      return
    }

    if (currentTool !== 'measureDistance') {
      clearSegmentLabels()
    }

    if (currentTool !== 'measureCoords') {
      clearPointLabels()
    }

    return () => {
      if (drawControl.current) {
        removeMapEventListeners()
        map.removeControl(drawControl.current)
        drawControl.current = null
      }
      clearSegmentLabels()
      clearPointLabels()
    }
  }, [map, drawActive, currentTool])

  useEffect(() => {
    if (!map || !drawControl.current) return

    const allFeatures = drawControl.current.getAll()
    // Remove draw and add it back with updated styles
    handleRefreshDrawControl(allFeatures)
  }, [baseLayer])

  // watch for changes to selected units and redraw message content
  useEffect(() => {
    if (!drawControl.current) return

    const selectedFeatures = drawControl.current.getSelected().features
    if (!selectedFeatures || selectedFeatures.length === 0) return

    const selectedFeature = selectedFeatures[0]

    if (selectedFeature?.geometry.type === 'LineString') {
      processLine(selectedFeature)
    } else if (selectedFeature?.geometry.type === 'Polygon') {
      processPolygon(selectedFeature)
    }
  }, [unitArea, unitDistance])

  const handleDrawCreate = (e) => {
    const feature = e.features[0]
    if (currentTool === 'measureArea') {
      processPolygon(feature)
    } else if (currentTool === 'measureDistance') {
      processLine(feature)
    } else if (currentTool === 'measureCoords') {
      processPoint()
    }
    map.getCanvas().style.cursor = 'pointer'
  }

  const handleDrawUpdate = (e) => {
    const feature = e.features[0]
    if (currentTool === 'measureArea') {
      processPolygon(feature)
    } else if (currentTool === 'measureDistance') {
      processLine(feature)
    } else if (currentTool === 'measureCoords') {
      processPoint()
    }
  }

  const handleDrawDelete = (event) => {
    debug('Draw Delete:', event)

    setDrawnFeatures((currentFeatures) => {
      const newFeatures = { ...currentFeatures }
      for (const feature of event.features) {
        delete newFeatures[feature.id]
      }
      return newFeatures
    })
  }

  const handleUnitChange = (type, unit) => {
    // content redraw handled by useEffect watching selector changes
    if (type === 'distance') {
      dispatch(setUnitDistance(unit))
    } else if (type === 'area') {
      dispatch(setUnitArea(unit))
    }
  }

  const processPolygon = (polygon) => {
    if (!polygon) {
      return
    }
    debug('processPolygon area, distance :', unitArea, unitDistance)

    if (currentTool === 'measureArea') {
      setMessageContent(
        <DrawnPolygonContent
          feature={polygon}
          onUnitChange={handleUnitChange}
          //these three props are passed in case user wants to save as Focus Area after measuring an area
          setMessageContent={setMessageContent}
          handleCloseControl={handleCloseControl}
          map={map}
        />
      )
    } else if (currentTool === 'drawFocusArea') {
      setMessageContent(
        <FocusAreaConfirmation
          feature={polygon}
          handleClose={handleCloseControl}
          map={map}
        />
      )
    }
  }

  const processPoint = () => {
    if (!drawControl.current) {
      return
    }
    const allFeats = drawControl.current.getAll()
    const allPoints = allFeats.features.filter(
      (feat) => feat.geometry.type === 'Point'
    )
    const allSelected = drawControl.current.getSelected()
    const selectedFeatureId = allSelected.features.length
      ? allSelected.features[0].id
      : null

    const data = updatePointLabels(allPoints)
    updateLayer(
      'point-label-layer',
      'point-label-source',
      data,
      labelLayerConfig
    )

    const pointsContent = processPointsContent(allPoints)
    setMessageContent(
      <DrawnPointsContent
        pointsList={pointsContent}
        selectedFeatureId={selectedFeatureId}
      />
    )
  }

  const processLine = (line) => {
    if (!line || !line.geometry || line.geometry.type !== 'LineString') {
      return
    }

    // Handle segment order labels layer
    // const midpoints = calculateMidpoints(line)
    const midpointCoords = calculateMidpointCoords(line)
    const midpoints = createLabelFeatures(midpointCoords)
    updateLayer(
      'segment-order-layer',
      'segment-order-source',
      midpoints,
      segmentLayerConfig
    )

    // Update messageContent with segment lengths
    setMessageContent(
      <DrawnLineContent feature={line} onUnitChange={handleUnitChange} />
    )
  }

  const DrawToolData = {
    measureDistance: {
      type: 'LineString',
      drawMode: 'draw_line_string',
      headline: 'Measure Distance',
      title: 'Line',
      description: 'Click on the map to measure the distance between points.',
      useInfo: 'Double-click to end line.',
    },
    measureArea: {
      type: 'Polygon',
      drawMode: 'draw_polygon',
      headline: 'Measure Area',
      title: 'Shape',
      description: 'Draw a shape on the map to measure its area.',
      useInfo:
        'Complete the shape by clicking on the first point, or by double-clicking.',
    },
    measureCoords: {
      type: 'Point',
      drawMode: 'draw_point',
      headline: 'Measure Coordinates',
      title: 'Point',
      description:
        'Click on the map to get the latitude/longitude coordinates of a point.',
      useInfo: null,
    },
    drawFocusArea: {
      type: 'Polygon',
      drawMode: 'draw_polygon',
      headline: 'Draw Focus Area',
      title: 'Shape',
      description: 'Draw a shape on the map to create a Focus Area.',
      useInfo:
        'Complete the shape by clicking on the first point, or by double-clicking.',
    },
  }

  const handleRefreshDrawControl = (allFeatures) => {
    // Save drawn features to local state
    setDrawnFeatures(allFeatures)

    // Initialize a new drawControl with updated styles and add it to the map
    const drawStyles =
      baseLayer === 'satellite' ? satelliteDrawConfig : streetDrawConfig
    setupDrawControl(drawStyles, 'simple_select', allFeatures?.features || [])
  }

  const handleRefreshUI = () => {
    if (!drawControl.current) {
      return
    }

    const allFeats = drawControl.current.getAll()
    const processType = DrawToolData[currentTool]?.type

    const handlers = {
      Point: {
        filterFn: (feat) => feat?.geometry.type === 'Point',
        processFn: (feats) => processPoint(),
        clearFns: [() => setMessageContent(null), clearPointLabels],
      },
      LineString: {
        filterFn: (feat) => feat?.geometry.type === 'LineString',
        processFn: (feats) => processLine(feats[feats.length - 1]),
        clearFns: [() => setMessageContent(null), clearSegmentLabels],
      },
      Polygon: {
        filterFn: (feat) => feat?.geometry.type === 'Polygon',
        processFn: (feats) => processPolygon(feats[feats.length - 1]),
        clearFns: [() => setMessageContent(null)],
      },
    }

    const currentHandler = handlers[processType]

    if (currentHandler) {
      const featuresOfType = allFeats.features.filter(currentHandler.filterFn)
      if (featuresOfType.length) {
        currentHandler.processFn(featuresOfType)
      } else {
        currentHandler.clearFns.forEach((fn) => fn())
        setIsFeatureSelected(false)
      }
    }
  }

  const handleSelection = (e) => {
    // Handle deselection and clear message content
    if (!e.features || e.features.length === 0) {
      setMessageContent(null)
      return
    }

    // Handle selection
    const selectedFeature = e.features[0]
    const featureType = selectedFeature?.geometry?.type
    if (featureType === 'Polygon') {
      processPolygon(selectedFeature)
    } else if (featureType === 'LineString') {
      processLine(selectedFeature)
    } else if (featureType === 'Point') {
      processPoint()
    } else {
      debug(
        'handleSelection - not recognized - selectedFeature, featureType: ',
        selectedFeature,
        featureType
      )
      return
    }
  }

  const handleDrawNewFeature = () => {
    const featureMode = DrawToolData[currentTool]?.drawMode || 'simple_select'
    if (
      drawControl.current &&
      drawControl.current.getMode() !== DrawToolData[currentTool]?.drawMode
    ) {
      drawControl.current.changeMode(featureMode)
    }
  }

  const handleDeleteFeature = () => {
    if (!drawControl.current) {
      return
    }

    const selectedFeatures = drawControl.current.getSelected()
    if (selectedFeatures.features.length) {
      const selectedFeatureId = selectedFeatures.features[0].id
      drawControl.current.delete(selectedFeatureId)
    }

    handleRefreshUI() // update map labels and message content
  }

  const handleCloseControl = () => {
    // remove active styling on nav
    const activeNavItem = document.querySelector('#map-nav ul.nav li.active')
    if (activeNavItem) { activeNavItem.classList.remove('active')}
    dispatch(setDrawActive(false))
    dispatch(setDrawMode(null))
    dispatch(setCurrentDrawTool(null))
  }

  const removeLayer = (layerId, sourceId) => {
    if (map.getMap().getLayer(layerId)) {
      map.getMap().removeLayer(layerId)
    }
    if (map.getMap().getSource(sourceId)) {
      map.getMap().removeSource(sourceId)
    }
  }

  const updateLayer = (layerId, sourceId, data, layerConfig) => {
    // Remove existing layer/source if present
    removeLayer(layerId, sourceId)

    // Add new source
    map.getMap().addSource(sourceId, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: data,
      },
    })

    // Add new layer with provided configuration
    map.getMap().addLayer({
      ...layerConfig,
      id: layerId,
      source: sourceId,
    })
  }

  return (
    <div id={`draw-control-wrapper${drawActive ? '' : ' hide'}`}>
      {currentTool && (
        <div className="draw-control-content">
          <div className="message-content-header message-content-row">
            <span className="h4">{DrawToolData[currentTool]?.headline}</span>
            <button className="close" onClick={handleCloseControl}>
              <i className="fas fa-times"></i>
            </button>
          </div>
          <div className="message-content-body message-content-row">
            <div className="message-content">
              {DrawToolData[currentTool]?.description && !messageContent && (
                <p className="small">
                  {DrawToolData[currentTool]?.description}
                </p>
              )}
              {DrawToolData[currentTool]?.useInfo && !messageContent && (
                <p className="small italic">
                  {DrawToolData[currentTool]?.useInfo}
                </p>
              )}
              {messageContent}
            </div>
            <div className="message-content-btns">
              <FeatureButtonSmall
                title={DrawToolData[currentTool]?.title}
                type="create"
                handleClick={handleDrawNewFeature}
              />
              {isSelectMode && isFeatureSelected && (
                <FeatureButtonSmall
                  title={DrawToolData[currentTool]?.title}
                  type="delete"
                  handleClick={handleDeleteFeature}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default DrawControl
