import React from 'react'

/**
 * ImageToggle component renders toggle buttons to switch between streetview 
 * and birdseye imagery.
 * It displays the appropriate toggle button based on the currently showing imagery type.
 * 
 * @param {Object} props - The component props
 * @param {string} props.streetview - URL implies streetview imagery may be available
 * @param {string} props.birdseye - URL implies birdseye imagery may be available
 * @param {boolean} props.noImages - indicates if no images are available, post request
 * @param {string} props.currentlyShowing - type of imagery currently being shown ('streetview' or 'birdseye')
 * @param {Function} props.handleToggle - function to handle toggling between imagery types
 * @returns {JSX.Element} The rendered component 
 */
const ImageToggle = ({
  streetview,
  birdseye,
  noImages,
  currentlyShowing,
  handleToggle
}) => {
  return (
    <div className="property-image-toggle small rounded">
      {currentlyShowing === 'streetview' && streetview && (
        <a
          role="button"
          id="toggle-birdseye"
          onClick={() => handleToggle('birdseye')}
        >
          Show bird's eye
        </a>
      )}
      {currentlyShowing === 'birdseye' && birdseye && (
        <a
          role="button"
          id="toggle-streetview"
          onClick={() => handleToggle('streetview')}
        >
          Show streetview
        </a>
      )}
    </div>
  )
}

export default ImageToggle