import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getActiveProject, getActiveProjectQuery } from 'ducks/projects'
import { getCurrentQuery, getProjectLastSaved } from 'ducks/exportSlice'
import { omit, isEmpty, isEqual } from 'lodash'
import withStore from '../withStore'
import WorkingBoundaryBreadcrumbs from '../Map/MapControls/WorkingBoundaryBreadcrumbs'
import passGLMapEventToFlight from '../../common/utils/passGLMapEventToFlight'

const ProjectSettings = ({
  groupPath,
  groupId,
  rootPath,
  mapsList,
  isAdmin,
  inGroup,
  inFree,
}) => {
  const [selectedProject, setSelectedProject] = useState('')
  const [unsavedFilters, setUnsavedFilters] = useState(false)
  const activeProject = useSelector(getActiveProject, isEqual)
  const activeProjectQuery = useSelector(getActiveProjectQuery, isEqual)
  const currentQuery = useSelector(getCurrentQuery, isEqual)
  const lastSaved = useSelector(getProjectLastSaved)
  const mapBoundaryPath = useSelector(
    (state) => state.boundaries.currentMapBoundary.path
  )

  useEffect(() => {
    if (activeProject) {
      setSelectedProject(activeProject.name)
    }
  }, [activeProject])

  useEffect(() => {
    if (currentQuery === undefined) return
    // Remove all but the `parcel` key
    const baseCurrentQuery = omit(currentQuery, ['path', 'operation'])
    const baseActiveProjectQuery = omit(activeProjectQuery, [ 'path', 'operation' ])
    // An empty baseCurrentQuery `{}` implies a new project or that the current query was cleared
    const isCurrentEmpty = isEmpty(baseCurrentQuery)

    // If the current query is empty, we need to compare only the base queries
    const isQueryEqual = !isCurrentEmpty
      ? isEqual(currentQuery, activeProjectQuery)
      : isEqual(baseCurrentQuery, baseActiveProjectQuery)

    const filterIndicators = document.querySelectorAll('.save-indicator')

    if (!isQueryEqual) {
      setUnsavedFilters(true)
      filterIndicators.forEach((el) => {
        el.classList.remove('hide')
      })
    } else {
      setUnsavedFilters(false)
      filterIndicators.forEach((el) => {
        el.classList.add('hide')
      })
    }
  }, [lastSaved, currentQuery, activeProjectQuery])

  const handleProjectChange = (e) => {
    const selectedOption = e.target.options[event.target.selectedIndex]
    let selectedUrl = selectedOption?.getAttribute('data-select-url')
    let selectedValue = selectedOption?.getAttribute('value')
    if (mapBoundaryPath && !selectedValue) {
      selectedUrl += mapBoundaryPath
    }
    if (selectedUrl) {
      window.location.href = selectedUrl
    }
  }

  const handleClickShare = () => {
    document.querySelector("#map-nav a[href='#sharing']").click()
  }

  const handleClickNewProject = (e) => {
    const element = e.target

    const need = LL.Upsell.shouldBlock('save-map')

    if (need) {
      need.placement = 'right'
      $(element).trigger('upsell:show', need)
      return false
    }
    //show the new project modal
    passGLMapEventToFlight('project:new')
  }

  if (!(inGroup || inFree)) return null

  return (
    <>
      <div className="flex-row-between">
        {activeProject && activeProject.name ? (
          <>
            <span className="h4">Project</span>
            <div className="flex-row">
              <a
                href="#"
                id="share-gl"
                className="btn btn-round btn-primary btn-xs"
                title="Project Sharing and Access"
                onClick={handleClickShare}
              >
                <i className="fas fa-share-square margin-right-md"></i>
                Share
              </a>
              <a
                href="#"
                id="new-project-gl"
                className="btn btn-round btn-primary btn-xs"
                title="New Project"
                onClick={handleClickNewProject}
              >
                <i className="fas fa-folder-plus margin-right-md"></i>
                New
              </a>
            </div>
          </>
        ) : (
          <span className="h4 bold">Projects</span>
        )}
      </div>
      {activeProject && activeProject.name && (
        <div className="flex-row-between">
          <h3 className="margin-top-none margin-btm-sm wrap-word">
            {activeProject.name}
          </h3>
        </div>
      )}
      {activeProject ? (
        <>
          <div className="flex-row-between project-action-btns">
            <a
              href="#"
              id="save-map-gl"
              className={`btn btn-block btn-round btn-primary btn-xs save-map ${
                activeProject?.permissions?.update ? '' : 'disabled'
              } ${unsavedFilters ? ' alert-glow' : ''}`}
              title="Save Project"
            >
              <i className="fas fa-save margin-right-md"></i>
              Save
            </a>
            <a
              href="#"
              id="save-copy-gl"
              className="btn btn-block btn-round btn-primary btn-xs save-as"
              title="Save a Copy"
            >
              <i className="fas fa-clone margin-right-md"></i>
              Copy
            </a>
            <a
              href="#"
              id="reset-save-gl"
              className="btn btn-block btn-round btn-primary btn-xs revert-map"
              title="Reset to Saved State"
            >
              <i className="fas fa-history margin-right-md"></i>
              Reset
            </a>
            {activeProject?.permissions?.update && (
              <>
                <a
                  href="#"
                  id="delete-map-gl"
                  className="btn btn-block btn-round btn-danger btn-xs delete-map"
                  data-confirm="Are you sure you want to delete this project? You may lose exports that have not expired."
                  data-method="delete"
                  href={activeProject?.path}
                  rel="nofollow"
                  title="Delete Project"
                >
                  <i className="fas fa-trash"></i>
                </a>
              </>
            )}
          </div>
          {unsavedFilters && (
            <div className="alert alert-warning small">
              <i className="fas fa-exclamation-triangle margin-right-md"></i>
              You have unsaved changes.
            </div>
          )}
        </>
      ) : (
        <div className="centered">
          <a
            href="#"
            id="new-project-gl"
            className="btn btn-round btn-primary btn-block"
            title="New Project"
            onClick={handleClickNewProject}
          >
            <i className="fas fa-folder-plus margin-right-md"></i>
            Create a New Project
          </a>
        </div>
      )}

      <div className="flex-row-between">
        <div className="dropdown projects width-100">
          <div data-tip="projects-menu" className="margin-btm-sm h4 form-group">
            <label htmlFor="projectSelector" className="small subtle">
              {activeProject ? 'Change or Close Project' : 'Available Projects'}
            </label>
            <select
              className="rounded form-control width-100 truncate"
              id="projectSelector"
              data-current-name={activeProject?.name || ''}
              value={selectedProject}
              onChange={handleProjectChange}
            >
              {isEmpty(mapsList) ? (
                <option value="">No projects</option>
              ) : (
                <>
                  <option
                    value=""
                    data-select-url={groupPath || window.data.host}
                  >
                    {activeProject
                      ? 'Close Current Project'
                      : 'Select a Project'}
                  </option>
                  {mapsList
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((m) => (
                      <option
                        key={m.url}
                        value={m.name}
                        data-select-url={m.url}
                      >
                        {m.slug === activeProject?.slug
                          ? m.name + ' (current)'
                          : m.name}
                      </option>
                    ))}
                </>
              )}
            </select>
          </div>
        </div>
      </div>
      <div id="project-home">
        <div className="panel panel-default margin-top-lg">
          {activeProject ? (
            <>
              <div className="panel-heading">
                <h4>Project Home</h4>
              </div>
              <div className="panel-body">
                <p className="small subtle underline margin-btm-sm">
                  Working Boundary
                </p>
                <WorkingBoundaryBreadcrumbs />
                <p className="small subtle underline margin-top-lg margin-btm-sm">
                  Project Home Boundary
                </p>
                {/* project_settings.js mounts at the #project-title below. Also handles 'Do you want to change...' message. */}
                <div id="project-title" className="bold"></div>
              </div>
            </>
          ) : (
            <>
              <div className="panel-heading">
                <h4>Working Boundary</h4>
              </div>
              <div className="panel-body">
                <WorkingBoundaryBreadcrumbs />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default withStore(ProjectSettings)
