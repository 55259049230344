import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MapLayersButton from './MapLayersButton'
import HelpButton from './HelpButton'
import MapHelpContent from './MapHelpContent'
import MapLayersContent from './MapLayersContent'
import MapLayerLegend from './MapLayerLegend'
import { setLayersModalOpen, setHelpModalOpen, closeOtherModals, setLegendModalOpen } from '../../../ducks/mapControls'
import { handleModalVisibility } from '../../../common/util'
import { AttributionControl } from 'react-map-gl'
import { getHashState } from 'common/utils/hashUtils'
import { baseLayers } from '../Layers/thirdPartyLayers'


/**
 * This component serves as a container for the layer picker, legend, help, etc. and renders these controls on the map.
 * 
 * @component
 * @returns {React.Element} 
 */
const MapControlsContainer = () => {
  const dispatch = useDispatch()
  const isHelpModalOpen = useSelector(state => state.mapControls.helpModalOpen)
  const isLayersModalOpen = useSelector(state => state.mapControls.layersModalOpen)
  const isLegendModalOpen = useSelector(state => state.mapControls.legendModalOpen)
  const isWideScreen = window.innerWidth > 1165
  const isWideWaterfall = Boolean(document.querySelector('#mapbox.with-wide-waterfall'));
  const allowMultipleModals = isWideScreen && !isWideWaterfall
  const base = getHashState('base')
  let wordmarkClass = 'mapbox-wordmark hide'
  
  //only display mapbox wordmark with mapbox basemaps
  if (
    base &&
    baseLayers.some(
      (baseLayer) => baseLayer.id === base && baseLayer.vendor === 'mapbox'
    )
  ) {
    wordmarkClass = `mapbox-wordmark`
  }
  
  //zendesk chat behavior on map
  const [zEExists, setZExists] = useState(window.zE ? true : false);
  const chatInit = () => {
    if (window.zE) {
      window.zE('messenger:on', 'close', function() {
        window.zE('messenger', 'hide');
      });

      window.zE('messenger:on', 'open', function() {
        window.zE('messenger', 'show');
      });
      window.zE("messenger:on", "unreadMessages", function (count) {
        console.log(`You have ${count} unread message(s).`)
        //let x = $('iframe[title="Number of unread messages"')[0]
        let x = document.querySelector("div[data-testid='unread-indicator-container'")
        if (x) {
          x.style.display = 'none'
          debug('unread indicator hidden')
        } else {
          debug('no unread indicator found')
        }

        setTimeout(() => {
          let y = $('iframe[title="Number of unread messages"')[0]
          if (y) {
            y.style.display = 'none'
            debug('in timeout: unread indicator hidden')
          } else {
            debug('in timeout: no unread indicator found')
          }
        }, 100)
      })
    } else {
      debug('zE not loaded')
    }
  } 
  
  useEffect(() => {
    const checkZExists = setInterval(() => {
      if (window.zE) {
        setZExists(true);
        chatInit();
        clearInterval(checkZExists);
      }
    }, 1000); // Check every second
    return () => clearInterval(checkZExists); // Clean up on component unmount
  }, []);

  const toggleHelpModal = () => {
    // close modals other than the help and tools nav
    const keepModals = ['helpModalOpen', 'mapNavOpen']
    if (allowMultipleModals) {
      // keep waterfall modal open if it's open
      keepModals.push('waterfallModalOpen')
    } else {
      // toggles based on help modal state
      handleModalVisibility('helpModalOpen', isHelpModalOpen)
    }
    dispatch(closeOtherModals(keepModals))
    dispatch(setHelpModalOpen(!isHelpModalOpen))
  }
  
  const toggleLayersModal = () => {
    // close modals other than the layers and tools nav
    const keepModals = ['layersModalOpen', 'mapNavOpen']
    if (allowMultipleModals) { 
      // keep waterfall modal open if it's open
      keepModals.push('waterfallModalOpen') 
    } else {
      // toggles based on layers modal state
      handleModalVisibility('layersModalOpen', isLayersModalOpen)
    }
    dispatch(closeOtherModals(keepModals))
    dispatch(setLayersModalOpen(!isLayersModalOpen))
    if (isLegendModalOpen) {
      dispatch(setLegendModalOpen(true));
    }
  }

  const toggleLegendModal = () => {
    // close modals other than the layers and tools nav
    const keepModals = ['layersModalOpen', 'mapNavOpen']
    if (allowMultipleModals) { 
      // keep waterfall modal open if it's open
      keepModals.push('waterfallModalOpen') 
    } else {
      // toggles based on layers modal state
      handleModalVisibility('layersModalOpen', isLayersModalOpen)
    }
    dispatch(closeOtherModals(keepModals))
    dispatch(setLegendModalOpen(!isLegendModalOpen))
  }

  return (
    <div className="map-controls">
      <MapLayersButton
        isActive={isLayersModalOpen}
        toggleModal={toggleLayersModal}
      />
      <HelpButton
        isActive={isHelpModalOpen}
        toggleModal={toggleHelpModal}
      />
      {isLegendModalOpen && (
        <MapLayerLegend
          handleClose={toggleLegendModal}
        />
      )}
      {isHelpModalOpen && <MapHelpContent handleClose={toggleHelpModal} />}
      {isLayersModalOpen && (
        <MapLayersContent
          handleClose={toggleLayersModal}
        />
      )}
      <a
        href="http://mapbox.com/about/maps"
        target="_blank"
        className={wordmarkClass}
      ></a>
      <AttributionControl compact={false} />
    </div>
  )
};

export default MapControlsContainer;