import React from 'react';
import { useSelector } from 'react-redux';

// Components
import StreetRasterParcelLayer from './StreetRasterParcelLayer';
import SatelliteRasterParcelLayer from './SatelliteRasterParcelLayer';

// Redux
import { getSatelliteLayerRendered } from 'ducks/mapProperties';

const RasterParcelLayer = ({tokenParam}) => {

  const isSatellite = useSelector(getSatelliteLayerRendered);

  return (
    <>
      { 
        isSatellite ? 
        <SatelliteRasterParcelLayer tokenParam={tokenParam}/> :
        <StreetRasterParcelLayer tokenParam={tokenParam}/>
      }
    </>
  );
};

export default RasterParcelLayer;