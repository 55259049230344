import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'common/utils/classNames';

const ColorPickerSwatch = ({
  color,
  handleColorChange,
  isSelected,
}) => {

  return (
    <div
      className={classNames('swatch', { 'selected': isSelected })}
      style={{ backgroundColor: color }}
      onClick={() => handleColorChange(color)}>
    </div>
  );
};

ColorPickerSwatch.propTypes = {
  color:             PropTypes.string.isRequired,
  isSelected:        PropTypes.bool.isRequired,
  handleColorChange: PropTypes.func.isRequired,
};

export default ColorPickerSwatch;
