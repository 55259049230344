import { combineReducers } from 'redux'

import { get } from './api'
// import { createLayer } from 'ducks/layers'

// Actions
import {
  ACTIVATE_PROJECT,
  ACTIVATE_SOURCE,
  ACTIVATE_SOURCES,
  DEACTIVATE_SOURCE,
  REQUEST_PLACE_SOURCES,
  RECEIVE_PLACE_SOURCES,
  REQUEST_SOURCE,
  RECEIVE_SOURCE,
  RECEIVE_SOURCE_ERROR
} from './actions'

// Action creators
export const activate = id => (dispatch, getState) => {
  dispatch({
    type: ACTIVATE_SOURCE,
    id
  })

  const sources = [...getActiveSourceIds(getState()), id]
  const query = buildQueryFromActiveSources(sources)

    // TODO: map layer not yet handled by reducer
  // createLayer(dispatch, getState(), { query })
  // TODO activate source here
}

export const activateMultiple = ids => (dispatch, getState) => {
  dispatch({
    type: ACTIVATE_SOURCES,
    ids
  })

  const sources = [...getActiveSourceIds(getState()), ...ids]
  const query = buildQueryFromActiveSources(sources)
    
  // TODO: map layer not yet handled by reducer
  // createLayer(dispatch, getState(), { query })

  // Activate any sources if needed
  ids.forEach(id => {
    if (!getSource(getState(), id).name) {
      dispatch(fetchSource(id))
    }
  })
}

export const deactivate = id => (dispatch, getState) => {
  dispatch({
    type: DEACTIVATE_SOURCE,
    id
  })

  const sourcesStillActive = getActiveSourceIds(getState()).filter(activeId => {
    return activeId != id
  })
  const query = buildQueryFromActiveSources(sourcesStillActive)
  
  // TODO: map layer not yet handled by reducer
  // createLayer(dispatch, getState(), { query })
}

// API
export const fetchPlaceSources = placePath => dispatch => {
  const start = () => {
    dispatch({
      type: REQUEST_PLACE_SOURCES,
      placePath
    })
  }

  const done = json => {
    dispatch({
      type:      RECEIVE_PLACE_SOURCES,
      sources:   normalize(json.sources),
      sourceIds: json.sources.map(source => source.id),
      placePath
    })
  }

  return get(`${placePath}/sources.json`, { start, done })
}

export const fetchSource = (id, callback) => (dispatch) => {
  const start = () => {
    dispatch({
      type: REQUEST_SOURCE,
      id:   id
    })
  }

  const done = json => {
    dispatch({
      type: RECEIVE_SOURCE,
      id:   id,
      data: json
    })
    if(callback) {
      callback.success(json)
    }
  }

  const fail = json => {
    dispatch({
      type:    RECEIVE_SOURCE_ERROR,
      id:      id,
      message: json.message
    })
    if(callback) {
      callback.failed()
    }
  }

  return get(`/sources/${id}.json`, { start, done, fail })
}

// Helpers

export const isPlace = id => {
  return Number.isNaN(Number(id))
}

// TODO rename this?
export const buildQueryFromActiveSources = sourceIds => {
  const query = {}
  sourceIds.forEach(id => {
    query[id] = true
  })
  query.operation = 'union'
  return query
}

/**
 * Normalize a list of sources to an array keyed by source ID
 */
const normalize = sources => {
  const sourcesById = {}
  sources.forEach(source => {
    sourcesById[source.id] = source
  })
  return sourcesById
}

// Getters

export const fetchSourceIfNeeded = (id, callback) => (dispatch, getState) => {
  const sources = getState().sources.byId
  if (!sources[id]) {
    // Fetch it async
    return dispatch(fetchSource(id, callback))
  } else if(callback) {
    // Return the data we already have
    callback.success(sources[id])
  }
}

export const fetchPlaceSourcesIfNeeded = placePath => (dispatch, getState) => {
  const places = getState().sources.byPlace
  if (!places[placePath]) {
    return dispatch(fetchPlaceSources(placePath))
  }
}

export const getPlaceSources = (state, placePath) => {
  const placeSources = state.sources.byPlace[placePath]
  if (!placeSources) {
    return {
      sources: [...getActiveSources(state)]
    }
  } else {
    const activeSourceIds = getActiveSourceIds(state)
    const placeSourceIds = [
      ...new Set([...activeSourceIds, ...placeSources.sourceIds])
    ]

    return {
      isLoading: placeSources.isLoading,
      sourceIds: placeSourceIds,
      sources:   placeSourceIds.map(id => state.sources.byId[id])
    }
  }
}

export const getSource = (state, id) => {
  return state.sources.byId[id] || {}
}

export const getActiveSourceIds = state => {
  return state.sources.active
}

export const getActiveSources = state => {
  return getActiveSourceIds(state).map(id => getSource(state, id))
}

// Reducers

/**
 * Store sources by place path
 */
const byPlace = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_PLACE_SOURCES:
      return {
        ...state,
        [action.placePath]: {
          isLoading: false,
          sourceIds: action.sourceIds
        }
      }
    case REQUEST_PLACE_SOURCES:
      return {
        ...state,
        [action.placePath]: {
          isLoading: true,
          sourceIds: []
        }
      }
    default:
      return state
  }
}

const active = (state = [], action) => {
  switch (action.type) {
    case ACTIVATE_SOURCE:
      return [...new Set([...state, action.id])]

    case ACTIVATE_SOURCES:
      return [...new Set([...state, ...action.ids])]

    case ACTIVATE_PROJECT: 
      return Object.keys(action.data.sources)

    case DEACTIVATE_SOURCE:
      return state.filter(id => {
        return id != action.id
      })
    default:
      return state
  }
}

/**
 * Store sources by ID
 */
const byId = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_SOURCE: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isLoading: true
        }
      }
    }
    case RECEIVE_SOURCE: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          ...action.data,
          isLoading: false
        }
      }
    }
    case RECEIVE_SOURCE_ERROR: {
      return {
        ...state,
        [action.id]: {
          message:   action.message,
          isLoading: false,
          error:     true,
          ...state[action.id]
        }
      }
    }
    case ACTIVATE_SOURCE: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          active: true
        }
      }
    }
    case ACTIVATE_SOURCES: {
      const newState = { ...state }
      action.ids.forEach(id => {
        newState[id] = {
          ...newState[id],
          active: true,
          id:     id
        }
      })
      return newState
    }

    case ACTIVATE_PROJECT: {
      // When a project is activated, we need to activate the sources as well
      Object.values(action.data.sources).forEach(source => {
        source.active = true
      })

      return { 
        ...action.data.sources
      }
    }
    case DEACTIVATE_SOURCE: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          active: false
        }
      }
    }
    case RECEIVE_PLACE_SOURCES: {
      const newState = { ...state }
      Object.keys(action.sources).forEach(id => {
        newState[id] = {
          ...action.sources[id],
          ...newState[id]
        }
      })

      return newState
    }
    default:
      return state
  }
}

export const sources = combineReducers({ byPlace, byId, active })
export default sources