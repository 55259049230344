import React, { useState, useEffect } from 'react'
import Message from '../Admin/ReviewItemsDeploy/Message'

const SearchFeedbackModal = () => {
  const [showModal, setShowModal] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [additionalInformation, setAdditionalInformation] = useState('')
  const [alertMessage, setAlertMessage] = useState(false)
  const [alertType, setAlertType] = useState('')

  useEffect(() => {
    const openModalListener = (event) => {
      setSearchQuery(event.detail.searchQuery || '')
      setShowModal(true)
    }

    window.addEventListener('openSearchFeedbackModal', openModalListener)

    return () => {
      window.removeEventListener('openSearchFeedbackModal', openModalListener)
    }
  }, [])

  const handleClose = () => {
    setAlertMessage(false)
    setAdditionalInformation('')
    setShowModal(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    LL.Ajax.postSpin('/search/feedback', {
      feedback: {
        query: searchQuery,
        additional_information: additionalInformation,
      },
    })
      .then(function () {
        setAlertMessage(true)
        setAlertType('success')
        setTimeout(() => {
          setShowModal(false)
          setAlertMessage(false)
          setAdditionalInformation('')
          setSearchQuery('')
        }, 3000)
      })
      .catch(function (err) {
        setAlertMessage(true)
        setAlertType('error')
        console.error('Error submitting feedback', err)
      })
  }

  const renderMessage = () => {
    if (alertType == 'success') {
      return (
        <Message
          alertType={'alert-success'}
          message={'Thank you for your submitted feedback!'}
          setAlertMessage={setAlertMessage}
        />
      )
    } else {
      return (
        <Message
          alertType={'alert-danger'}
          message={'Something went wrong, please try again'}
          setAlertMessage={setAlertMessage}
        />
      )
    }
  }

  return (
    <div
      className={`modal ${showModal ? 'show' : ''}`}
      style={{ display: showModal ? 'block' : 'none' }}
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            {alertMessage && renderMessage()}
            <h5 className="modal-title">Help us Improve</h5>
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="search-query">What you searched</label>
                <input
                  type="text"
                  id="search-feedback-query"
                  value={searchQuery}
                  className="form-control"
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="additional-information">
                  Additional Information{' '}
                </label>
                <input
                  type="text"
                  id="search-feedback-additional-information"
                  className="form-control"
                  placeholder="Add any extra info that might help us investigate here, like full address (optional)"
                  value={additionalInformation}
                  onChange={(e) => setAdditionalInformation(e.target.value)}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchFeedbackModal
