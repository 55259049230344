// Source and layer for displaying the results of applied filters
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Layer, Source } from 'react-map-gl'
import mapSettings from '../mapSettings'
import settings from '../../../common/settings' // TODO: is there opportunity to merge settings/mapSettings?
import { generateMapboxExpression } from '../mapUtilities'
import { useSelector } from 'react-redux'
import { isNull, isEqual } from 'lodash'
import {
  getActiveRules,
  isDefaultRuleActive,
} from 'ducks/styles'
import { activeProjectPresent } from 'ducks/projects';
import { getCurrentQuery } from 'ducks/exportSlice'

/**
 *
 * @param {string} url  url for the vector tile
 * @param {string} tileLayerId
 * @returns
 */
const QueryLayer = ({ url, tileLayerId }) => {
  const activeRules = useSelector(getActiveRules)
  const defaultRuleIsActive = useSelector(isDefaultRuleActive)
  const [fill, setFill] = useState(settings.styles.defaultFillColor)
  const [line, setLine] = useState(settings.styles.defaultLineColor)
  const projectPresent = useSelector(activeProjectPresent);
  const currentQuery = useSelector(getCurrentQuery, isEqual) // null or OBJECT

  useEffect(() => {
    if (activeRules && activeRules.length > 0) {
      // console.log('mapbox expression: ', JSON.stringify(generateMapboxExpression(activeRules, 'fill')))
      setFill(generateMapboxExpression(activeRules, 'fill'))
      setLine(generateMapboxExpression(activeRules, 'line'))
    }
    else if (!defaultRuleIsActive && !projectPresent && isNull(currentQuery)){
      setFill('rgba(0,0,0,0)')
      setLine('rgba(0,0,0,0)')
    }
  }, [activeRules])

  if (!url || !tileLayerId) return null

  const sourceId = `query-source-${tileLayerId}`

  const lineDef = {
    id: `query-layer-line-${tileLayerId}`,
    beforeId: mapSettings.layerIds.parcelSelected,
    type: 'line',
    source: sourceId,
    'source-layer': tileLayerId,
    paint: {
      'line-color': line,
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        13,
        1,
        17,
        4,
      ],
    },
  }

  const fillDef = {
    id: `query-layer-fill-${tileLayerId}`,
    beforeId: `query-layer-line-${tileLayerId}`,
    type: 'fill',
    source: sourceId,
    'source-layer': tileLayerId,
    paint: {
      'fill-color': fill,
      'fill-opacity': 0.7,
    },
  }

  let token
  if (window.data.tile_token) {
    token = `userToken=${window.data.tile_token}`
  } else if (window.data.group_token) {
    token = `token=${window.data.group_token}`
  }

  return (
    <Source id={sourceId} type="vector" url={`${url}&${token}`}>
      <Layer {...lineDef} />
      <Layer {...fillDef} />
    </Source>
  )
}

QueryLayer.propTypes = {
  url: PropTypes.string,
  tileLayerId: PropTypes.string,
}

export default QueryLayer
