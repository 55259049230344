import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'common/utils/classNames'
import focusAreaImg from '@/assets/draw/focus-area.jpg'
import {
  setDrawActive,
  setDrawMode,
  setCurrentDrawTool,
} from 'ducks/mapProperties'

const DrawFocus = ({ handleClose, isDisabled=false }) => {
  const [hovered, setHovered] = useState(false)
  const drawActive = useSelector((state) => state.mapProperties.drawActive)
  const currentTool = useSelector(
    (state) => state.mapProperties.currentDrawTool
  )
  const isCurrentTool = currentTool === 'drawFocusArea'
  const dispatch = useDispatch()

  const drawFocusAreaClasses = classNames(
    'draw-focus-area',
    'modal-tile',
    'shadow-gl-light',
    {
      active: isCurrentTool,
      disabled: isDisabled,
    }
  )

  const handleClick = () => {
    if (drawActive && isCurrentTool) {
      // If currently drawing, stop drawing
      dispatch(setDrawActive(false))
      dispatch(setDrawMode(null))
      dispatch(setCurrentDrawTool(null))
    } else {
      // Start drawing a focus area
      dispatch(setDrawActive(true))
      dispatch(setDrawMode('draw_polygon'))
      dispatch(setCurrentDrawTool('drawFocusArea'))
      handleClose()
    }
  }

  return (
    <div
      id="draw-focus-btn"
      className={
        drawFocusAreaClasses + (isCurrentTool || hovered ? ' plan-color-glow' : '')
      }
      onClick={handleClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
    >
      <div className="modal-tile-img">
        <img src={focusAreaImg} alt="Focus Area" />
      </div>
      <div
        className={`modal-tile-detail draw-tool-detail ${
          isCurrentTool ? 'plan-color-accent white' : ''
        }`}
      >
        <span className="md bold">
          <i className="fa fa-draw-polygon margin-right-md"></i>
          Draw Focus Area
        </span>
        <div className="small">
          Draw a shape on the map to create a saved Focus Area.
        </div>
      </div>
    </div>
  )
}

export default DrawFocus
