import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'common/utils/classNames'
import measureAreaImg from '@/assets/draw/measure-area.jpg'
import {
  setDrawActive,
  setDrawMode,
  setCurrentDrawTool,
} from 'ducks/mapProperties'

const MeasureArea = ({ handleClose }) => {
  const dispatch = useDispatch()
  const drawActive = useSelector((state) => state.mapProperties.drawActive)
  const currentTool = useSelector(
    (state) => state.mapProperties.currentDrawTool
  )
  const isCurrentTool = currentTool === 'measureArea'
  const [hovered, setHovered] = useState(false)

  const measureAreaClasses = classNames(
    'measure-area',
    'modal-tile',
    'shadow-gl-light',
    {
      active: isCurrentTool,
    }
  )

  const handleMeasureAreaClick = () => {
    if (drawActive && isCurrentTool) {
      // If currently drawing, stop drawing
      dispatch(setDrawActive(false))
      dispatch(setDrawMode(null))
      dispatch(setCurrentDrawTool(null))
    } else {
      // Start drawing a line for measurement
      dispatch(setDrawActive(true))
      dispatch(setDrawMode('draw_polygon'))
      dispatch(setCurrentDrawTool('measureArea'))
      handleClose()
    }
  }

  return (
    <div
      className={
        measureAreaClasses + (isCurrentTool || hovered ? ' plan-color-glow' : '')
      }
      onClick={handleMeasureAreaClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
    >
      <div className="modal-tile-img">
        <img src={measureAreaImg} alt="Measure Area" />
      </div>
      <div
        className={`modal-tile-detail draw-tool-detail ${
          isCurrentTool ? 'plan-color-accent white' : ''
        }`}
      >
        <span className="md bold">
          <i className="fa fa-vector-square margin-right-md"></i>
          Measure Area
        </span>
        <div className="small">
          Draw a shape on the map to measure its area.
        </div>
      </div>
    </div>
  )
}

export default MeasureArea
