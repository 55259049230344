import { combineReducers } from 'redux'
import { createSelector } from '@reduxjs/toolkit'
// import { feature as turfFeature } from '@turf/helpers'
// import centroid from '@turf/centroid'
// import { getCoord } from '@turf/invariant'
import querystring from 'querystring'
import {
  handleError,
  SUCCESS,
  FAIL,
  RATE_LIMIT_EXCEEDED,
  get,
  debug,
} from '../common/util'

// Actions
import {
  ACTIVATE_SOURCE,
  LOG_OUT,
  SET_PARCEL_OPTIONS,
  REQUEST_PARCEL,
  RECEIVE_PARCEL,
  SELECT_PARCEL,
  SELECT_ANOTHER_PARCEL,
  DESELECT_PARCEL,
} from './actions'

import { getActiveProject } from './projects'

const getUser = () => {
  return {}
}

// Selected parcel
// In the web app, we store this state in the URL.
// Here we use Redux.
export const selectParcel = (data) => (dispatch) => {
  dispatch({
    type: SELECT_PARCEL,
    data,
  })
}

export const requestParcel = (data) => (dispatch) => {
  // used in mapboxGL version, we don't want the full object, just data.properties
  // with geojson geom added in
  data.properties.geojson = data.geometry
  dispatch({
    type: REQUEST_PARCEL,
    parcelPath: data?.properties?.path,
    data: data.properties,
  })
  dispatch(selectParcel(data.properties))
}

export const getParcel = (state, parcelPath) => {
  return state.parcels.byId[parcelPath]
}

export const getParcels = (state, parcelPaths) => {
  const uniquePaths = [...new Set(parcelPaths)]
  return uniquePaths
    .map((path) => getParcel(state, path))
    .filter((parcel) => parcel !== undefined)
}

/* Get a list of all parcels accessed this session (survey) */
export const getSessionParcels = (state) => {
  return state.parcels.sessionParcels
    .map((path) => getParcel(state, path))
    .filter((parcel) => parcel !== undefined)
}

export const getSessionParcelPaths = (state) => {
  return state.parcels.sessionParcels
}

export const selectAnotherParcelFromFeatureCollection =
  (featureCollection) => (dispatch, getState) => {
    const selectedParcels = getState().parcels.selectedParcel
    featureCollectionToParcels(featureCollection).map((parcel) => {
      if (selectedParcels.indexOf(parcel.path) !== -1) {
        // Deselect the parcel if it's already selected
        dispatch({
          type: DESELECT_PARCEL,
          data: parcel,
        })
      } else {
        // Otherwise select it
        dispatch({
          type: SELECT_ANOTHER_PARCEL,
          data: parcel,
        })
        dispatch(fetchParcelIfNeeded(parcel.path))
      }
    })
  }

export const getParcelDetails =
  (parcelPath, stackArray = []) =>
  async (dispatch) => {
    // dev code for testing what happens when parcel is not found
    if (global.forceFetchParcel404) {
      parcelPath = '/us/mi/wayne/detroit/0'
    }
    stackArray = stackArray.concat([
      'parcels.js',
      'selectParcelFromFeatureCollectionAsync',
    ])

    const [status] = await dispatch(fetchParcelAsync(parcelPath, stackArray))
    if (status == RATE_LIMIT_EXCEEDED) {
      return RATE_LIMIT_EXCEEDED
    } else {
      return SUCCESS
    }
  }

/*** selectParcelFromFeatureCollectionAsync
 * used only on Map/index.js
 *
 **/

export const selectParcelFromFeatureCollection =
  (featureCollection) => (dispatch) => {
    // move around some fields, and filter parcel only
    let parcels = featureCollectionToParcels(featureCollection)

    for (let i = 0, len = parcels.length; i < len; ++i) {
      let parcel = parcels[i]
      dispatch(selectParcel(parcel))
    }
  }

/*** featureToParcel ***/
/* Promotes feature "properties" property to first class properties
 * keeps geometry as a property */
export const featureToParcel = (feature) => {
  return { ...feature.properties, geometry: feature.geometry }
}

/*** isParcel ***/
/* check to make it's not a focus area */

export const isParcel = (feature) => {
  // Prevent clicks on focus areas
  if (feature?.properties?.classes?.includes('place')) {
    return false
  }

  // There is no positive check for a parcel at this time
  return true
}

/*** featureCollectionToParcels ***/
/* Make sure the fature collection has features,
 * filter out features that aren't parcels */
export const featureCollectionToParcels = (featureCollection) => {
  if (!featureCollection.features || !featureCollection.features.length)
    return []
  return featureCollection.features.filter(isParcel).map(featureToParcel)
}

export const parcelToFeature = (parcel) => {
  if (parcel) {
    const fields = parcel.fields || {}
    return {
      type: 'Feature',
      properties: { ...parcel, ...fields },
      geometry: parcel.geometry ?? parcel.geojson,
    }
  }
}
export const parcelsToFeatureCollection = (parcels) => {
  return {
    type: 'FeatureCollection',
    features: parcels.map(parcelToFeature),
  }
}

export const deselectParcel = (parcel) => (dispatch) => {
  dispatch({
    type: DESELECT_PARCEL,
    data: parcel,
  })
}

// used on Survey.js
export const fetchAndSelectParcel = (path) => (dispatch, getState) => {
  const parcel = getParcel(getState(), path)
  if (parcel && Object.keys(parcel).length > 0) {
    dispatch(selectParcel(parcel))
  } else {
    dispatch(fetchParcel(path, (data) => dispatch(selectParcel(data))))
  }
}

// Get the parcel from state if we have it.
// We pretend here that there can only be one selected parcel, which is still
// useful for things like getting a centroid
export const getSelectedParcel = (state) => {
  if (state.parcels.selectedParcel.length === 0) return null
  return getParcel(state, state.parcels.selectedParcel[0])
}

export const getSelectedParcelPath = (state) => {
  if (state.parcels.selectedParcel.length === 0) return null
  return state.parcels.selectedParcel[0]
}

export const getSelectedParcels = (state) => {
  return state.parcels.selectedParcel.map((parcel) => getParcel(state, parcel))
}

export const getSelectedParcelsFeatureCollection = (state) => {
  const parcels = getSelectedParcels(state)
  return parcelsToFeatureCollection(parcels)
}

export const getSelectedParcelFeature = createSelector(
  getSelectedParcel,
  (parcel) => {
    if (parcel) return parcelToFeature(parcel)
  }
)

// memoized because is an array of objects
export const getSelectedParcelContext = createSelector(
  [getSelectedParcelPath, state => state.parcels.byId],
  (selectedParcelPath, parcelsById) => parcelsById[selectedParcelPath]?.context
)

// export const getSelectedParcelCentroid = (state) => {
//   const parcelFeature = getSelectedParcelFeature(state)
//   if (!parcelFeature) return
//   const coords = getCoord(centroid(turfFeature(parcelFeature.geometry)))
//   // turf uses GeoJSON and GeoJSON does lon, lat
//   // This return format matches geolocation.getCurrentPosition
//   return {
//     coords: {
//       latitude:  coords[1],
//       longitude: coords[0],
//     },
//   }
// }

export const headline = (parcels) => {
  return parcels.map(({ headline, address }) => headline || address).join(', ')
}

export const normalize = (json) => {
  if (!json || !json.fields) {
    json.base = []
    return json
  }
  json.base = Object.keys(json.fields).map((key) => {
    return {
      key: key,
      name: json.key[key] || key,
      value: json.fields[key],
    }
  })
  return json
}

export const fetchParcelIfNeeded = (parcelPath) => (dispatch, getState) => {
  if (parcelPath) {
    const parcel = getParcel(getState(), parcelPath)
    let parcelHeadline = get(parcel, 'headline')
    let parcelIsLoading = get(parcel, 'isLoading')
    if (!parcelHeadline && !parcelIsLoading) {
      return dispatch(fetchParcel(parcelPath))
    }
  }
}

// Helpers
/**
 * makePath - Generate a path to a parcel (eg /us/mi/wayne/detroit/123) from
 * a parcel's metadata.
 *
 * TODO: We should use the template provided by the server, but it doesn't
 * currently come through from the server in a nice way
 */
export const makePath = (opts) => {
  const keys = ['state', 'county', 'city', 'fid']
  let path = '/us'
  keys.forEach((key) => {
    if (opts[key]) {
      path += `/${opts[key]}`
    }
  })
  return path
}

const byId = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_PARCEL:
      return {
        ...state,
        [action.parcelPath]: {
          ...state[action.parcelPath],
          isLoading: true,
        },
      }
    case RECEIVE_PARCEL:
      return {
        ...state,
        [action.parcelPath]: {
          ...action.data,
          isLoading: false,
          loadedAt: Date.now(),
        },
      }
    case SELECT_PARCEL:
    case SELECT_ANOTHER_PARCEL:
      return {
        ...state,
        [action.data.path]: {
          ...action.data, // We want existing data to take precedence
          ...state[action.data.path],
        },
      }
    case SET_PARCEL_OPTIONS: {
      const newState = {
        ...state,
      }
      action.data.forEach((parcel) => {
        newState[parcel.id] = {
          ...state[parcel.id],
          ...parcel,
        }
      })
      return newState
    }
    case LOG_OUT:
      return []
    default:
      return state
  }
}

const selectedParcel = (state = [], action) => {
  switch (action.type) {
    case SELECT_PARCEL:
      return [action.data.path]
    case SELECT_ANOTHER_PARCEL:
      return [...new Set([...state, action.data.path])]
    case DESELECT_PARCEL:
      return state.filter((s) => s !== action.data.path)
    case LOG_OUT:
      return []
    default:
      return state
  }
}

// Keep track of which parcels were accessed this session
// A session for our purposes is a selected survey. Clears when you
// switch surveys. Useful in some edge cases.
const sessionParcels = (state = [], action) => {
  switch (action.type) {
    case SELECT_PARCEL:
    case SELECT_ANOTHER_PARCEL:
      return [...new Set([...state, action.data.path])]
    case ACTIVATE_SOURCE:
    case LOG_OUT:
      return []
    default:
      return state
  }
}

const parcelReducer = combineReducers({
  byId,
  selectedParcel,
  sessionParcels,
})

export default parcelReducer
