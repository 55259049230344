import React from 'react'
import { useSelector } from 'react-redux'
import { convertToUSCurrency } from '../../common/util'
import InfoTooltip from 'components/Utilities/InfoTooltip'
import ReactTooltip from 'react-tooltip'
import {
  getSpinCount,
  getMultiFilter,
  getMultiTable,
  getWithDataset,
  getCurrentCount,
} from 'ducks/exportSlice'

const ExportPaneFooter = ({
  remainingApplied,
  activeFormat,
  remainingAvailable,
  hasNonParcelSources,
  activePrice,
  activePriceShp,
}) => {
  const showSpinner = useSelector(getSpinCount)
  const isMultiTable = useSelector(getMultiTable) // boolean
  const isMultiFilter = useSelector(getMultiFilter)
  const withDataset = useSelector(getWithDataset)
  const currentCount = useSelector(getCurrentCount)

  const tipFreeRemaining = `You have ${numberWithCommas(
    remainingAvailable
  )} remaining of ${window.data.max_list_count.toLocaleString()} free csv rows per month. Remaining rows will be automatically applied to reduce total cost to export.`

  const tipExportMyData = `Exporting data that you import, as well as any data that you create using our surveying or following tools, is free. Click to learn more.`

  const tipExportCost =
    activeFormat === 'csv'
      ? `${numberWithCommas(
          remainingApplied
        )} remaining free csv records this month automatically applied to this export. Cost for the csv format is $0.10 per row after that.`
      : `Cost to export the ${activeFormat} format is $0.15 per record.`

  return (
    <div className="exp-footer">
      <div className="exp-footer-total">
        <div className="exp-footer-row">
          <span className="small">Properties Currently Selected:</span>
          {showSpinner ? (
            <span>
              <i className="fas fa-spinner fa-spin" />
            </span>
          ) : (
            <span className="small">{numberWithCommas(currentCount)}</span>
          )}
        </div>
        {/* Hide remaining if 0 or my data */}
        <div
          className={`exp-footer-row${
            remainingApplied && activeFormat === 'csv' ? '' : ' hide'
          }`}
        >
          <span className="tooltip-parent small">
            Free Records Applied (CSV only):
            <InfoTooltip
              id="free-remaining-tip"
              tip={tipFreeRemaining}
              circleSize="fa-xs"
              place="right"
              type="dark"
              delayShow={250}
            />
          </span>
          {showSpinner ? (
            <span>
              <i className="fas fa-spinner fa-spin" />
            </span>
          ) : (
            <span className="small">
              {
                remainingAvailable > remainingApplied
                  ? `- ${numberWithCommas(remainingApplied)}`
                  : `- ${numberWithCommas(remainingAvailable)}`
                // show # remaining free applied
              }
            </span>
          )}
        </div>
        <div className="exp-footer-row">
          <span className="small">{`Format (.${activeFormat}):`}</span>
          <span
            className={`small${
              hasNonParcelSources && withDataset ? ' hide' : ''
            }`}
          >
            {activeFormat === 'csv'
              ? `($0.10 per record)`
              : `($0.15 per record)`}
          </span>
        </div>
        <div className="exp-footer-row total-row">
          <span className="tooltip-parent exp-footer-cost">
            Total Cost
            <InfoTooltip
              id="export-cost-tip"
              tip={
                hasNonParcelSources && withDataset
                  ? tipExportMyData
                  : tipExportCost
              }
              circleSize="fa-xs"
              place="right"
              type="dark"
              delayShow={250}
            />
          </span>
          {showSpinner ? (
            <span className="exp-footer-cost">
              <i className="fas fa-spinner fa-spin" />
            </span>
          ) : (
            <span className="exp-footer-cost">
              {activeFormat === 'csv'
                ? `${convertToUSCurrency(activePrice)}`
                : `${convertToUSCurrency(activePriceShp)}`}
            </span>
          )}
        </div>
      </div>
      <div className="exp-footer-btns">
        {isMultiTable && isMultiFilter ? (
          <>
            <div
              className={`exp-review-btn btn btn-primary btn-block disabled-soft tooltip-parent`}
              data-for="export-multi-table"
              data-tip="You can only filter in one county at a time. Please narrow down your filters to within just one county to see accurate parcel counts and exports."
            >
              {activeFormat !== 'csv' ||
              (activeFormat === 'csv' && currentCount - remainingAvailable > 0)
                ? `Review Data & Proceed to Cart`
                : `Review Data & Download`}
              <i className="fas fa-exclamation-circle margin-xs fa-md" />
            </div>
            <ReactTooltip
              id="export-multi-table"
              place="top"
              type="dark"
              effect="solid"
              delayShow={150}
            />
          </>
        ) : (
          <a
            className={`exp-review-btn btn btn-primary btn-block${
              showSpinner ? ' disabled' : ''
            }`}
            data-toggle="modal"
            data-target="#review-modal"
          >
            {activeFormat !== 'csv' ||
            (activeFormat === 'csv' && currentCount - remainingAvailable > 0)
              ? `Review Data & Proceed to Cart`
              : `Review Data & Download`}
          </a>
        )}
      </div>
    </div>
  )
}

export default ExportPaneFooter
