import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Layer, useMap } from 'react-map-gl'
import { getFeatures } from '../mapUtilities'
import mapSettings from '../mapSettings'
import {
  getMapColor,
  getSatelliteLayerRendered,
} from 'ducks/mapProperties'

/**
 * HoverParcelLayer is responsible for rendering hover styles on parcels.
 * conditionally attaches or removes event listeners based on zoom level (map state)
 *
 * @returns {JSX.Element} Rendered hover parcel layer
 */
const HoverParcelLayer = () => {
  const { current: map } = useMap()

  const isSatellite = useSelector(getSatelliteLayerRendered)
  const hoverColorType = isSatellite
    ? mapSettings.editableStyleTypes.satelliteHoverParcel
    : mapSettings.editableStyleTypes.streetHoverParcel
  const hoverColor = useSelector(getMapColor(hoverColorType))

  const { layerIds, sourceIds, rasterMaxZoom } = mapSettings
  const parcelsLayer = layerIds.vectorParcelsInteraction
  const parcelsSource = sourceIds.vectorParcels

  let hoverFeatureId = null

  const layerLineStyle = {
    id: layerIds.vectorParcelsHover,
    type: 'line',
    source: sourceIds.vectorParcels,
    'source-layer': 'parcels',
    minzoom: rasterMaxZoom,
    paint: {
      'line-color': hoverColor,
      'line-width': ['interpolate', ['linear'], ['zoom'], 13, 3, 17, 5],
      // Apply hover style only when hover is true
      'line-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1,
        0,
      ],
    },
  }

  // Handles hover state
  const handleHover = (e) => {
    // Get features and display properties based on zoom level
    const zoomLevel = map.getZoom()
    const showVectorParcels = zoomLevel >= rasterMaxZoom
    if(!showVectorParcels) return

    const layers = [parcelsLayer]

    const featureState =  {
          source: parcelsSource,
          sourceLayer: 'parcels',
          id: hoverFeatureId,
        }
      

    const features = getFeatures(map, e, layers)

    // No features found, reset states and return
    if (features.length === 0) {
      if (hoverFeatureId !== null) {
        map.setFeatureState(featureState, { hover: false })
      }
      hoverFeatureId = null
      return
    }

    // Reset hover state
    if (hoverFeatureId !== null) {
      map.setFeatureState(featureState, { hover: false })
    }

    // Assign ID and set new hover state
    hoverFeatureId = features[0].id
    featureState.id = hoverFeatureId
    map.setFeatureState(featureState, { hover: true })
  }

  // Resets hover state when mouse leaves the map
  const handleLeave = (e) => {
    if (hoverFeatureId === null) return
    const featureState = {
          source: parcelsSource,
          sourceLayer: 'parcels',
          id: hoverFeatureId,
        }

    // Reset hover state
    map.setFeatureState(featureState, { hover: false })
    hoverFeatureId = null
  }

  useEffect(() => {
    const handleZoomEnd = () => {
      map.on('mousemove', [parcelsLayer], handleHover)
      map.on('mouseleave', [parcelsLayer], handleLeave)
    }

    map.on('load', handleZoomEnd)
    map.on('zoomend', handleZoomEnd)

    return () => {
      map.off('load', handleZoomEnd)
      map.off('zoomend', handleZoomEnd)
    }
  }, [map])

  return (
    <Layer {...layerLineStyle} />
  )
}

export default HoverParcelLayer
