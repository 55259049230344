export default {
    supportDomain: 'https://support.regrid.com',
    map: {
        baseLayer:
            'https://api.mapbox.com/styles/v1/loveland/cifq4lvda000j8zluw5ek6hr6/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibG92ZWxhbmQiLCJhIjoibHFTSURFNCJ9.titsQDMlSIud_r60hOlmeA',
        defaultStyles:
            'Map { background-color: rgba(0,0,0,0); } #loveland { polygon-fill: #40a0dd; }',
        defaultBlextColor: '#40a0dd'
    },
    styles: {
        defaultFillColor: '#69387a',
        defaultPurpleLineColor: '#4b2857',
        defaultLineColor: 'rgba(0,0,0,0)',
        transparentValue:  'rgba(0,0,0,0)',
        hexTransparentValue: '#FFFFFF00'
    }
};

// Needs to always match plan_helper.rb
export const PLANS = {
  FREE:       'level0',
  PRO:        'level1',
  ENTERPRISE: 'level2',
  TEAM:       'level3',
  TEAM_FLEX:  'team_flex',
  UNLIMITED:  'level4',
};

// Needs to always match plan_helper.rb
export const PLAN_NAMES = {
  ANON:       'Anon',
  FREE:       'Starter',
  PRO:        'Pro',
  TEAM:       'Team'
};

export const CYCLES = {
  MONTHLY: 'monthly',
  ANNUAL:  'annual'
};

// Styles preset colors
// Sequential colors
export const sequentialColors = [
  [
    "#ffc6c4",
    "#f4a3a8",
    "#e38191",
    "#cc607d",
    "#ad466c",
    "#8b3058",
    "#672044",
  ],
  [
    "#edf8fb",
    "#bfd3e6",
    "#9ebcda",
    "#8c96c6",
    "#8c6bb1",
    "#88419d",
    "#6e016b",
  ],
  [
    "#edf8fb",
    "#ccece6",
    "#99d8c9",
    "#66c2a4",
    "#41ae76",
    "#238b45",
    "#005824",
  ],
  [
    "#ffffd4",
    "#fee391",
    "#fec44f",
    "#fe9929",
    "#ec7014",
    "#cc4c02",
    "#8c2d04",
  ],
  [
    "#f1eef6",
    "#d0d1e6",
    "#a6bddb",
    "#74a9cf",
    "#3690c0",
    "#0570b0",
    "#034e7b",
  ],
];

// Diverging colors
export const divergingColors = [
  [
    "#009392",
    "#39b185",
    "#9ccb86",
    "#e9e29c",
    "#eeb479",
    "#e88471",
    "#cf597e",
  ],
  [
    "#c51b7d",
    "#e9a3c9",
    "#fde0ef",
    "#f7f7f7",
    "#e6f5d0",
    "#a1d76a",
    "#4d9221",
  ],
  [
    "#d73027",
    "#fc8d59",
    "#fee090",
    "#ffffbf",
    "#e0f3f8",
    "#91bfdb",
    "#4575b4",
  ],
  [
    "#8c510a",
    "#d8b365",
    "#f6e8c3",
    "#f5f5f5",
    "#c7eae5",
    "#5ab4ac",
    "#01665e",
  ],
  [
    "#A16928",
    "#bd925a",
    "#d6bd8d",
    "#edeac2",
    "#b5c8b8",
    "#79a7ac",
    "#2887a1",
  ],
];

// Qualitative colors
export const qualitativeColors = [
  [
    "#5F4690",
    "#1D6996",
    "#38A6A5",
    "#0F8554",
    "#73AF48",
    "#EDAD08",
    "#E17C05",
  ],
  [
    "#666666",
    "#1b9e77",
    "#d95f02",
    "#7570b3",
    "#e7298a",
    "#66a61e",
    "#e6ab02",
  ],
  [
    "#8dd3c7",
    "#ffffb3",
    "#bebada",
    "#fb8072",
    "#80b1d3",
    "#fdb462",
    "#b3de69",
  ],
  [
    "#855C75",
    "#D9AF6B",
    "#AF6458",
    "#736F4C",
    "#526A83",
    "#625377",
    "#68855C",
  ],
  [
    "#7F3C8D",
    "#11A579",
    "#3969AC",
    "#F2B701",
    "#E73F74",
    "#80BA5A",
    "#A5AA99",
  ],
];
// End styles preset colors