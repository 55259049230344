import { filter, has, isBoolean, isObject, keys, values } from 'lodash'

/**
 *
 * @param {boolean} hasNonParcelSources
 * @param {boolean} withDataset
 * @param {object} counts
 * @param {Function} getRemainingApplied
 * @param {object} availability
 * @returns {object}
 */
export function calculateExportStats(
  hasNonParcelSources,
  withDataset,
  counts,
  getRemainingApplied,
  availability
) {
  let activeCount
  let remainingApplied
  let activePrice
  let activePriceShp
  if (!hasNonParcelSources) {
    // Project w/ no sources
    activeCount = counts?.projectCount
    remainingApplied = getRemainingApplied(activeCount)
    activePrice =
      activeCount - availability.remaining > 0
        ? (activeCount - availability.remaining) * 0.1
        : 0
    activePriceShp = activeCount * 0.15
  } else if (hasNonParcelSources && !withDataset) {
    // All data
    activeCount = counts?.allCount
    remainingApplied = getRemainingApplied(activeCount)
    activePrice =
      activeCount - availability.remaining > 0
        ? (activeCount - availability.remaining) * 0.1
        : 0
    activePriceShp = activeCount ? activeCount * 0.15 : 0
  } else if (hasNonParcelSources && withDataset) {
    // Only my data
    activeCount = counts?.withDatasetCount
    remainingApplied = 0 // does not increment
    activePrice = 0 // always free
    activePriceShp = 0 // always free
  }

  return { activeCount, remainingApplied, activePrice, activePriceShp }
}

/**
 *
 * @param {number} activeCount
 * @param {number} activePrice
 * @param {number} activePriceShp
 * @param {object} coverage
 * @param {string} format
 * @param {object} availability
 * @returns {object}
 */
export function applyExportLimits(
  activeCount,
  activePrice,
  activePriceShp,
  coverage,
  format,
  availability
) {
  let limitCounty = false
  let limitPrice = false
  // limit purchase of >= cost of a county, in the active format, minus any remaining free records
  if (activeCount >= coverage?.verse_record?.total_objects) {
    limitCounty = true
  } else if (
    format.activeFormat === 'csv' &&
    activePrice >=
      (coverage?.verse_record?.total_objects - availability.remaining) * 0.1
  ) {
    limitCounty = true
  } else if (
    format.activeFormat !== 'csv' &&
    activePriceShp >= coverage?.verse_record?.total_objects * 0.15
  ) {
    limitCounty = true
  }
  let priceThresholdLimit = isPathCanada(coverage?.path) ? 6000 : 8000 // utils.js

  // limit purchase of >= priceThresholdLimit in the active format
  if (format.activeFormat === 'csv' && activePrice >= priceThresholdLimit) {
    limitPrice = true
  } else if (format.activeFormat !== 'csv' && activePriceShp >= priceThresholdLimit) {
    limitPrice = true
  }

  return { limitCounty, limitPrice, priceThresholdLimit }
}

/**
 *
 * @param {boolean} hasNonParcelSources
 * @param {boolean} withDataset
 * @param {string} format
 * @param {number} activePrice
 * @param {number} activePriceShp
 * @param {number} currentCount
 * @param {Function} getRemainingApplied
 * @returns {boolean}
 */
export function calculatePriceWarning(
  hasNonParcelSources,
  withDataset,
  format,
  activePrice,
  activePriceShp,
  currentCount,
  getRemainingApplied
) {
  let warning = false
  if (!hasNonParcelSources && format === 'csv') {
    warning = activePrice > 149
  } else if (!hasNonParcelSources && format !== 'csv') {
    warning = activePriceShp > 299
  } else if (hasNonParcelSources && !withDataset && format === 'csv') {
    let remaining = getRemainingApplied(currentCount)
    warning = (currentCount - remaining) * 0.1 > 149
  } else if (hasNonParcelSources && !withDataset && format !== 'csv') {
    warning = currentCount * 0.15 > 299
  }
  return warning
}

/**
 * 
 * @param {object} query 
 * @param {boolean} isMultiFilter 
 * @param {boolean} isMultiTable 
 * @param {Function} passEventToFlight 
 */
export function checkMultiFilter(
  query,
  isMultiFilter,
  isMultiTable,
  passEventToFlight
) {
  let multiTable = has(query, 'parcel') && keys(query?.parcel).length > 1
  let multiFilter = filter(values(query?.parcel), isObject).length > 1
  // check if multiTable
  if (isBoolean(multiTable) && multiTable !== isMultiTable) {
    passEventToFlight('export:multi-table', multiTable)
    // check if multiFilter
    if (isBoolean(multiFilter) && multiFilter !== isMultiFilter) {
      passEventToFlight('export:multi-filter', multiFilter)
    }
  }
}
