import React from 'react'
import { floatLayers } from '../floatLayers'
import mapSettings from '../../../mapSettings'

// Components
import LabelsListItem from './LabelsListItem'
import ReactTooltip from 'react-tooltip'

// Images
import defaultImg from '@/assets/default'
import floatOwnerImg from '@/assets/layers/float-owner'
import floatParcelImg from '@/assets/layers/float-parcel'
import floatDimensionsFtImg from '@/assets/layers/dimensions-ft'
import floatDimensionsMImg from '@/assets/layers/dimensions-m'

/**
 * This component is responsible for rendering a list of label items.
 * The label items are obtained from the floatLayers array.
 * Labels are also referred to as "float layers".
 *
 * @component
 * @returns {React.Element} Returns a div that contains a list of LabelsListItem components.
 */
const LabelsList = ({ isWorkingInUSBoundary, workingCountryCode }) => {
  const isPaidUser = window.data.has_sitecontrol
  const { countries } = mapSettings
  const workingCountry = countries[workingCountryCode?.toUpperCase()] // utils.js
  const tooltipText = 'Labels'
  const images = {
    'float-owner': floatOwnerImg,
    'float-parcel': floatParcelImg,
    'float-dimensions-ft': floatDimensionsFtImg,
    'float-dimensions-m': floatDimensionsMImg,
  }

  const availableLabels = floatLayers.filter((layer) => {
    return layer.country.includes(workingCountry)
  })

  // Remove redundant layers if not working in US boundary
  const unavailableLabelsUSA = isWorkingInUSBoundary
    ? []
    : floatLayers.filter((layer) => {
        return (
          layer.country.includes(countries.US) &&
          !layer.country.includes(workingCountry)
        )
      })

  return (
    <>
      <div
        className="modal-tile-wrapper margin-btm-lg"
        id={isWorkingInUSBoundary ? 'float-labels' : 'float-labels-non-us'}
        data-tip={tooltipText}
        data-for="float-labels-tt"
      >
        {availableLabels.map((label) => {
          // Get the image for this label, or use the default image
          const labelImg = images[label.id] || defaultImg
          return (
            <LabelsListItem
              key={`label-item-${label.id}`}
              label={label}
              labelImg={labelImg}
              isPaidUser={isPaidUser}
              softDisabled={false}
            />
          )
        })}
        {!isPaidUser && (
          <ReactTooltip
            id="float-labels-tt"
            place="top"
            className="tooltip upsell"
            effect="solid"
            clickable={true}
            delayHide={500}
            delayShow={250}
            delayUpdate={500}
            getContent={(dataTip) => (
              <div className="tooltip-inner margin-btm-md">
                <p>{`${dataTip} are available to Regrid Pro customers.`}</p>
                <a href="/plans">
                  <i className="fas fa-arrow-right" />
                  Upgrade to Pro
                </a>
              </div>
            )}
          />
        )}
      </div>
      {!isWorkingInUSBoundary && (
        <>
          {/* Display a message if the user is not working in the US boundary */}
          <div className="layers-unavailable margin-btm-md">
            <i className="fas fa-exclamation-circle margin-right-md"></i>
            <span className="bold">
              Additional label options are available within the US.
            </span>
          </div>
          {/* Render any unavailable layers that are currently active */}
          <div
            className="modal-tile-wrapper"
            id="float-labels" // TODO - new id?
            data-tip={tooltipText}
            data-for="float-labels-tt"
          >
            {unavailableLabelsUSA.map((label) => {
              // Get the image for this label, or use the default image
              const labelImg = images[label.id] || defaultImg
              return (
                <LabelsListItem
                  key={`label-item-${label.id}`}
                  label={label}
                  labelImg={labelImg}
                  isPaidUser={isPaidUser}
                  softDisabled={!isWorkingInUSBoundary}
                />
              )
            })}
          </div>
        </>
      )}
    </>
  )
}

export default LabelsList
