import { useState, useEffect } from 'react';

/**
 * Hook that checks if a DOM element is visible in the viewport.
 * @param {string} elementId - The ID of the DOM element to observe.
 * @param {number} threshold - A value between 0 and 1 indicating the percentage of the element that must be visible for the callback.
 * @returns {boolean} - Whether the element is visible or not.
 */
function useElementVisibility(elementId, threshold = 0.1) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const element = document.getElementById(elementId);

        if (!element) {
            console.warn(`Element with ID ${elementId} not found.`);
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
            },
            {
                threshold: threshold
            }
        );

        observer.observe(element);

        return () => {
            observer.unobserve(element);
        };
    }, [elementId, threshold]);

    return isVisible;
}

export default useElementVisibility;
