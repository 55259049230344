import React from 'react';
import { useSelector } from 'react-redux';
import withStore from 'components/withStore';
// Redux
import { getCurrentZoomLevel, getCurrentFloatLayer } from 'ducks/mapProperties';
import mapSettings from '../mapSettings';

const ZoomInNotifier = () => {
  const currentZoom = useSelector(getCurrentZoomLevel);
  const displayNotifier = currentZoom && currentZoom < window.data.min_zoom;

  const currentFloatLayer = useSelector(getCurrentFloatLayer);
  // debug('Current float layer: ', currentFloatLayer)
  // layers.js floatLayers[{opts{minZoom: 17}}]
 
  if (displayNotifier) {
    return (
      <div className="zoom-notifier">
        <span className="zoom-notifier-text">
          <i className="fas fa-search-plus"></i> 
          Zoom in to see parcels
        </span>
      </div>
    )
  } else if (currentFloatLayer && currentFloatLayer !== mapSettings.layerIds.noFloat && currentZoom < 17) {
    return (
      <div className="zoom-notifier">
        <span className="zoom-notifier-text">
          <i className="fas fa-search-plus"></i>
          { // Check float layer type
            (currentFloatLayer === 'float-owner')
            ? "Zoom in to see owner names"
            : "Zoom in to see parcel numbers"
          } 
        </span>
      </div>
    )
  } else {
    return null
  }
};

export default withStore(ZoomInNotifier);