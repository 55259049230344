import React from 'react'
import MeasureArea from './MeasureArea';
import MeasureCoords from './MeasureCoords';
import MeasureDistance from './MeasureDistance';
import DrawFocus from '../FocusAreas/DrawFocus';
import withStore from '../../../../withStore';
import passGLMapEventToFlight from '../../../../../common/utils/passGLMapEventToFlight';
import { useDispatch } from 'react-redux'
import { closeOtherModals } from 'ducks/mapControls'

const MeasureTools = () => {
  const dispatch = useDispatch()
  
  const handleClose = () => {
    dispatch(closeOtherModals(['mapNavOpen', 'drawControlOpen'])) // close everything but the map nav
    passGLMapEventToFlight('pane:request:hide')
  }

  return (
    <div className="draw-measure-list">
      <MeasureDistance handleClose={handleClose} />
      <MeasureArea handleClose={handleClose} />
      <MeasureCoords handleClose={handleClose} />
      <DrawFocus handleClose={handleClose}/>
    </div>
  );
}

export default withStore(MeasureTools)