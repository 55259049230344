import React, { useEffect,useState } from 'react'
import { Layer, Source, useMap } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { getBoundaryStyle, getChildren} from 'ducks/boundaries'
import mapSettings from '../mapSettings'
import { getSatelliteLayerRendered, getFillColorFromPath } from '../../../ducks/mapProperties'

/**
 * This component is responsible for rendering sub-boundaries
 * within the currently active boundary on the map.
 * This layer provides visual details for sub-boundaries within the current boundary,
 * highlighting them differently based on interaction and zoom level.
 * Subboundary may also be referenced as 'chunk' in legacy code.
 *
 * @component
 * @returns {React.Element} Returns a set of React components responsible for displaying the subboundary layer on the map.
 */
const SubboundariesLayer = () => {
  const { current: map } = useMap()
  const workingBoundaryPath = useSelector((state) => state.boundaries.workingBoundary)
  const boundaryStyle = useSelector((state) =>
    getBoundaryStyle(state, workingBoundaryPath)
  )
  const children = useSelector((state) =>
    getChildren(state, workingBoundaryPath, boundaryStyle)
  )
  const { layerIds, sourceIds, styles } = mapSettings
  const isSatellite = useSelector(getSatelliteLayerRendered)
  const [placesWithData, setPlacesWithData] = useState([])
  const lineColor = isSatellite
    ? styles.satellite.subboundaries.lineColor
    : styles.street.subboundaries.lineColor
  const lineHoverColor = isSatellite
    ? styles.satellite.subboundaries.lineHoverColor
    : styles.street.subboundaries.lineHoverColor
  const fillColor = isSatellite
    ? styles.satellite.subboundaries.fillColor
    : styles.street.subboundaries.fillColor
  const noDataColor = styles.street.subboundaries.noDataColor

const fillLayer = {
  id:      layerIds.subboundariesFill,
  type:    'fill',
  source:  sourceIds.subboundaries,
  maxzoom: mapSettings.rasterMaxZoom,
  paint:   {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      fillColor,
      ['in', ['get', 'path'],['literal', placesWithData]],
      fillColor,
      noDataColor
    ],
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0,
      0.3,
    ]
  }
}
  const lineLayer = {
    id:     layerIds.subboundariesOutline,
    type:   'line',
    source: sourceIds.subboundaries,
    paint:  {
      'line-color': lineColor,
      'line-width': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        4, // when hover is true
        3, // when hover is false
      ],
      'line-opacity': 1,
    },
  }

  const lineLayerHover = {
    id:     layerIds.subboundariesOutlineHover,
    type:   'line',
    source: sourceIds.subboundaries,
    paint:  {
      'line-color':   lineHoverColor,
      'line-width':   4,
      'line-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        1, // when hover is true
        0, // when hover is false
      ],
    },
  }

  let hoverFeatureId = null

  // set hover state
  const handleSubboundaryHover = (e) => {
    const features = map.queryRenderedFeatures(e.point, {
      layers: [layerIds.subboundariesFill],
    })
    if(features.length > 0) {
      const subboundary = features[0]
      const feature = {
        source: subboundary.source,
        id:     hoverFeatureId,
      }
      // reset hover state
      if(hoverFeatureId !== null) {
        map.setFeatureState(feature, { hover: false })
      }
      // assign id and set new hover state
      hoverFeatureId = subboundary.id
      feature.id = hoverFeatureId
      map.setFeatureState(feature, { hover: true })
    }
  }

  // reset hover state
  const handleSubboundaryLeave = () => {
    if(hoverFeatureId !== null) {
      const feature = {
        source: sourceIds.subboundaries,
        id:     hoverFeatureId,
      }
      map.setFeatureState(feature, { hover: false })
      hoverFeatureId = null
    }
  }

  useEffect(() => {
    if(!workingBoundaryPath) return

    const fetchData = async () => {
      try {
        const result = await getFillColorFromPath(workingBoundaryPath)
        if(result.success) {
          setPlacesWithData(result.data)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [workingBoundaryPath])


  useEffect(() => {
    const handleZoomEnd = () => {
      map.on('mousemove', [layerIds.subboundariesFill], handleSubboundaryHover)
      map.on('mouseleave', [layerIds.subboundariesFill], handleSubboundaryLeave)
    }

    map.on('load', handleZoomEnd)
    map.on('zoomend', handleZoomEnd)

    return () => {
      map.off('load', handleZoomEnd)
      map.off('zoomend', handleZoomEnd)
    }
  }, [map])

  return (
    <Source
      id={sourceIds.subboundaries}
      type="geojson"
      data={children?.geojson}
    >
      <Layer {...fillLayer} />
      <Layer {...lineLayer} />
      <Layer {...lineLayerHover} />
    </Source>
  )
}

export default SubboundariesLayer
