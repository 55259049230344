import React, { useEffect } from 'react'
import bbox from '@turf/bbox'
import booleanContains from '@turf/boolean-contains'
import flatten from '@turf/flatten'

/* 
parcelFeature : GeoJSON feature
map: mapboxGL map instance
*/

const useParcelBounds = (parcelFeature, map) => {
  useEffect(() => {
    if (!parcelFeature?.properties?.isLoading && parcelFeature?.geometry) {
      const currentZoom = map.getZoom()
      const zoomTooFarOut = currentZoom < 14.5
      const parcelBounds = bbox(parcelFeature.geometry)
      const mapBounds = map.getBounds()
      const mapBoundsGJ = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [mapBounds.getWest(), mapBounds.getSouth()],
              [mapBounds.getEast(), mapBounds.getSouth()],
              [mapBounds.getEast(), mapBounds.getNorth()],
              [mapBounds.getWest(), mapBounds.getNorth()],
              [mapBounds.getWest(), mapBounds.getSouth()],
            ],
          ],
        },
      }
      const zoomLimit = 17
      const maxZoom = currentZoom > zoomLimit ? currentZoom : zoomLimit
      let parcelOutOfView = false
      let geometryType = parcelFeature?.geometry?.type
      if (geometryType === 'Polygon') {
        parcelOutOfView = !booleanContains(mapBoundsGJ, parcelFeature.geometry)
      } else if (geometryType === 'MultiPolygon') {
        const polygons = flatten(parcelFeature.geometry)
        parcelOutOfView = polygons.features.some(poly => !booleanContains(mapBoundsGJ, poly))
      }

      if (zoomTooFarOut || parcelOutOfView) {
        map.fitBounds(parcelBounds, { maxZoom, padding: 60, animate: false })
      }
    }
  }, [parcelFeature?.properties?.isLoading, parcelFeature?.geometry, map])
}

export default useParcelBounds
