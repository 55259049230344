import PropTypes from 'prop-types'
import React, { useState, Fragment } from 'react'
import ReactTooltip from 'react-tooltip'
import withStore from '../withStore'
import { convertToUSCurrency, titleize } from 'common/util'

/**
 * The `ProductOption` component renders individual product options.
 *
 * @component
 * @prop {Object} product - Product object.
 * @prop {Function} onSelect - Click handler.
 * @prop {Object} selectedProduct - Currently selected product.
 * @prop {boolean} shapefileTooLarge - Shapefile size exceeds allowed limit for direct download.
 * @prop {boolean} buildingsNotAvailable - Matched Buildings are not available for the product.
 * @prop {boolean} standardBuildingsNotAvailable - Used to disable matched buildings when enhanced is selected.
 * @prop {boolean} enhancedBuildingsNotAvailable -  Used to disable enhanced buildings when matched is selected.
 * @prop {string} headline - Headline text for the product.
 * @prop {boolean} inCart - Product is currently in cart.
 * @prop {string} includedProducts - Included product names.
 * @prop {string} path - URL path.
 * @prop {JSX.Element} addButton - JSX button element.
 * @prop {boolean} allowMultiCart - Multiple products (addons) can be added to the cart.
 * @prop {boolean} isCartEmpty - Cart is empty.
 * @prop {boolean} allowAddons - Addons are allowed.
 * @prop {boolean} isCartUpdated - Cart has been updated.
 * @prop {boolean} zoningNotAvailable - Zoning is not available for the product if <=10%.
 * @prop {number} zoningPercentage - Zoning percentage available.
 *
 * @returns {JSX.Element} Rendered `ProductOption` component.
 */
const ProductOption = ({
  product,
  onSelect,
  selectedProduct,
  selectedAddons,
  shapefileTooLarge,
  buildingsNotAvailable,
  standardBuildingsNotAvailable,
  enhancedBuildingsNotAvailable,
  headline,
  inCart,
  includedProducts,
  path,
  addButton,
  allowMultiCart,
  isCartEmpty,
  allowAddons,
  isCartUpdated,
  zoningNotAvailable,
  zoningPercentage,
}) => {
  const isEsri = window.location.pathname.includes('/store/esri')
  const [isOpen, setIsOpen] = useState(false)
  const isAddon = product?.addon || false

  // Handler to toggle the checkbox
  const toggleCheckbox = (e) => {
    const checkbox = e.target.matches('input[type="checkbox"]')
      ? e.target
      : e.target.closest('input[type="checkbox"]')
    if (checkbox && !checkbox.disabled) {
      const isChecked = !(isAddon
        ? selectedAddons &&
          selectedAddons.find(
            (selectedAddon) => selectedAddon.id === product.id
          )
        : selectedProduct && selectedProduct.id === product.id)
      // onSelect(product, isChecked)
    }
  }

  let disableProduct = false
  if (product.id === 'buildings-addon' && (buildingsNotAvailable || standardBuildingsNotAvailable)) {
    disableProduct = true
  }
  if (product.id === 'enhanced-buildings-addon' && (buildingsNotAvailable || enhancedBuildingsNotAvailable)) {
    disableProduct = true
  }
  if (product.id === 'zoning-addon' && zoningNotAvailable) {
    disableProduct = true
  }
  if (isAddon && !allowAddons) {
    disableProduct = true
  }

  let cartButton = inCart && (
    <a
      href={`/orders/${isEsri ? 'esri/' : ''}cart?from=${path}`}
      className="btn btn-default btn-round flex-col-center"
    >
      <span>
        <i className="fas fa-shopping-cart margin-right-md"></i>
        Cart
        <i className="fas fa-arrow-right margin-left-md"></i>
      </span>
    </a>
  )

  let selectedMessage = selectedProduct &&
    selectedProduct.id === product.id && (
      <div className="cart-message">
        <div className="cart-message-text">
          <p className="small italic">
            {allowMultiCart || isCartEmpty
              ? ''
              : `Adding ${product.name} to your cart will replace the current ${headline} product schema`}
          </p>
          {addButton}
        </div>
      </div>
    )

  let cartMessage = inCart ? (
    <div className="cart-message">
      <div className="cart-message-text">
        <p className="small italic"></p>
        {cartButton}
      </div>
    </div>
  ) : (
    selectedMessage
  )

  let cartBadge = inCart && <p className="badge in-cart">In Cart</p>
  let unavailableBadge = disableProduct && isAddon && (
    <p className="badge margin-left-md">Unavailable</p>
  )

  let noShapefileMessage = (
    <div
      dangerouslySetInnerHTML={{ __html: product.description_no_shapefile }}
    />
  )

  const infoContent = (
    <div className="info margin-left-md margin-right-md">
      <div className="info-title">
        <div className="name">
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
        </div>
      </div>
      {!disableProduct && (
        <div className="files-included">
          <span className="small margin-right-md">Formats Included:</span>
          {product.format_types.map((formatType) => (
            <span
              className={`badge xsmall margin-right-sm`}
              key={`format-type-${formatType}`}
            >
              {formatType}
            </span>
          ))}
        </div>
      )}
      <span className="coverage-report-link margin-btm-sm">
        <a href={product.coverage_report} target="_blank" className="small">
          <i className="fas fa-external-link-alt margin-right-md"></i>
          Coverage Report
        </a>
      </span>
      <div className="margin-btm-sm">
        {Object.keys(product.schemas).map((key) => (
          <span key={key} className="coverage-report-link margin-right-lg">
            <a href={product.schemas[key]} target="_blank" className="small">
              <i className="fas fa-external-link-alt margin-right-md"></i>
              {`${titleize(key)} Schema`}
            </a>
          </span>
        ))}
      </div>
      {cartMessage}
    </div>
  )

  const collapsibleContent = (
    <div
      className={`collapse ${isOpen ? 'in' : ''}`}
      id={`collapse-${product.id}`}
    >
      {infoContent}
    </div>
  )

  return isAddon ? (
    <div className="addon-item rounded padded-sm margin-btm-sm">
      <div className="flex-row" onClick={toggleCheckbox}>
        <input
          type="checkbox"
          checked={
            selectedAddons &&
            selectedAddons.find(
              (selectedAddon) => selectedAddon.id === product.id
            )
          }
          onChange={(e) => {
            e.stopPropagation()
            const isChecked = e.target.checked
            onSelect(product, isChecked)
          }}
          defaultChecked={false}
          disabled={disableProduct}
        />
        <div className="h4 margin-left-md">
          <a
            className="accordion-toggle"
            data-toggle="collapse"
            data-parent="#accordion"
            href={`#collapse-${product.id}`}
            aria-expanded={isOpen ? 'true' : 'false'}
            aria-controls={`collapse-${product.id}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            {product.name}
            <i
              className={`margin-left-sm fas fa-angle-up ${
                isOpen ? 'rotate-180' : 'rotate-0'
              }`}
            ></i>
            {cartBadge}
            {allowAddons && unavailableBadge}
          </a>
        </div>
        <div className="price bold nowrap ms-auto">
          <i className="fas fa-plus"></i>
          {` ${convertToUSCurrency(product.price)}`}
        </div>
      </div>
      {collapsibleContent}
    </div>
  ) : (
    <label
      className={`option rounded${disableProduct ? ' disabled-product' : ''}${
        inCart ? ' in-cart' : ''
      }`}
    >
      <input
        type={isAddon ? 'checkbox' : 'radio'}
        name="product"
        value={product.id}
        checked={
          isAddon
            ? (selectedAddons &&
                selectedAddons.find(
                  (selectedAddon) => selectedAddon.id === product.id
                )) ||
              false
            : (selectedProduct && selectedProduct.id === product.id) || false
        }
        onChange={(e) => {
          const isChecked = e.target.checked
          onSelect(product, isChecked)
        }}
        disabled={disableProduct}
      />
      <div className="info">
        <div className="info-title">
          <div className="name">
            {cartBadge}
            <p className="h4 bold">{product.name}</p>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: product.description }}
            />
          </div>
          <div className="price bold nowrap">{`${convertToUSCurrency(
            product.price
          )}`}</div>
        </div>
        {!disableProduct && (
          <div className="files-included">
            <span className="small margin-right-md">Formats Included:</span>
            {product.format_types.map((formatType) => {
              if (formatType === 'Shapefile' && shapefileTooLarge) {
                return (
                  <Fragment key={`format-type-${formatType}`}>
                    <a
                      data-tip=""
                      data-for="shapefile-too-large-tt"
                      className="badge xsmall margin-right-sm inactive strike"
                    >
                      {formatType}
                    </a>
                    <ReactTooltip
                      getContent={(dataTip) => noShapefileMessage}
                      id="shapefile-too-large-tt"
                      effect="solid"
                      place={'top'}
                      delayUpdate={500}
                      delayHide={250}
                    />
                  </Fragment>
                )
              } else {
                return (
                  <span
                    className={`badge xsmall margin-right-sm`}
                    key={`format-type-${formatType}`}
                  >
                    {formatType}
                  </span>
                )
              }
            })}
          </div>
        )}
        <span className="coverage-report-link margin-btm-sm">
          <a href={product.coverage_report} className="small">
            <i className="fas fa-external-link-alt margin-right-md"></i>
            Coverage Report
          </a>
        </span>
        <div className="margin-btm-sm">
          {Object.keys(product.schemas).map((key) => (
            <span key={key} className="coverage-report-link margin-right-lg">
              <a href={product.schemas[key]} target='_blank' className="small">
                <i className="fas fa-external-link-alt margin-right-md"></i>
                {`${titleize(key)} Schema`}
              </a>
            </span>
          ))}
        </div>
        {cartMessage}
      </div>
    </label>
  )
}

ProductOption.propTypes = {
  onSelect: PropTypes.func.isRequired,
  product: PropTypes.shape({
    description: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    // price:           PropTypes.number.isRequired,
    // price:           PropTypes.object.isRequired,
    variant: PropTypes.string.isRequired,
  }).isRequired,
  selectedProduct: PropTypes.object,
  buildingsNotAvailable: PropTypes.bool,
}

export default withStore(ProductOption)


