import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getBoundaryStyle,
  getBoundaryTypes,
  getWorkingBoundaryPath,
  updateBoundaryStyle,
} from 'ducks/boundaries'
import withStore from '../../../../withStore'

const BoundariesList = ({ parent, isWorkingInUSBoundary }) => {
  const dispatch = useDispatch()
  const workingBoundaryPath = useSelector(getWorkingBoundaryPath)
  const boundaryTypes = useSelector((state) =>
    getBoundaryTypes(state, workingBoundaryPath)
  )
  const boundaryStyle = useSelector((state) =>
    getBoundaryStyle(state, workingBoundaryPath)
  )
  const [activeItemKey, setActiveItemKey] = useState(null)
  useEffect(() => {
    if (!boundaryStyle) {
      // TODO: better way to set default selection
      setActiveItemKey(boundaryTypes[0]?.key)
    }
    // update selected value if boundaryStyle changes
    if (boundaryStyle && boundaryStyle !== activeItemKey) {
      setActiveItemKey(boundaryStyle)
    }
  }, [boundaryStyle, boundaryTypes[0]?.key])

  if (!boundaryTypes) {
    return null
  }

  const handleOptionSelect = (selectElement) => {
    debug('handleOptionSelect - e.currentTarget: ', selectElement)
    const selectedOption = selectElement.options[selectElement.selectedIndex]
    const optionType = selectedOption.dataset.set
    if (!optionType || optionType === activeItemKey) {
      return
    }

    setActiveItemKey(optionType)
    dispatch(updateBoundaryStyle(workingBoundaryPath, optionType))
  }

  const menuItems = boundaryTypes?.map((type) => {
    return (
      <option
        key={type.key}
        value={type.name}
        className="boundary"
        data-set={type.key}
        data-skip-pjax={1}
      >
        {type.name}
      </option>
    )
  })

  const activeItemName = boundaryTypes.find(
    (boundaryType) => boundaryType.key === activeItemKey
  )?.name

  const displayBounds = activeItemName === 'None' ? 'Bounds' : activeItemName

  // Layers Modal
  if (!isWorkingInUSBoundary && parent === 'map-layers') {
    return (
      <div id="boundaries-list"> 
        <div className="layers-unavailable">
          <i className="fas fa-exclamation-circle margin-right-md"></i>
          <span className="bold">
            Additional boundary options are available within the US.
          </span>
        </div>
      </div>
    )
  }

  // Overview Pane
  if (!isWorkingInUSBoundary && parent !== 'map-layers') {
    return null
  }

  return (
    <div id="boundaries-list" className="form-group">
      <label>Sub-Boundaries: </label>
      <select
        id="boundariesSelector"
        className="rounded form-control"
        value={displayBounds}
        onChange={(e) => handleOptionSelect(e.currentTarget)}
      >
        {menuItems}
      </select>
    </div>
  )
}

export default withStore(BoundariesList)
