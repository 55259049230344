import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { copyToClipboard } from 'common/util';

// Components
import BookmarkRow from './BookmarkRow';
import PlaceRow from './PlaceRow';
import NoBookmarksMessage from './NoBookmarksMessage';
import BigSpinner from 'components/Utilities/BigSpinner';
import withFlight from 'containers/withFlight';
import withStore from 'components/withStore';

// Redux
import { 
  fetchBookmarks,
  removeBookmark, 
  getBookmarkSources,
  getFollowedPlaces,
  hasFollowedPlaces,
  hasBookmarksAvailable,
  getBookmarksNeedInitialize,
  followBookmark,
  unfollowBookmark,
  removeFollow,
} from 'ducks/bookmarks';

import {
  getActiveSourceIds,
  fetchSourceIfNeeded,
  activate,
} from 'ducks/sources';


const BookmarksPane = ({ passEventToFlight }) => {
  const bookmarkSources = useSelector(getBookmarkSources);
  const bookmarksNeedInit = useSelector(getBookmarksNeedInitialize)
  const hostDomain = window.data.host
  const dispatch = useDispatch();
  const hasBookmarks = useSelector(hasBookmarksAvailable);
  const hasPlaces = useSelector(hasFollowedPlaces);
  const followedPlaces = useSelector(getFollowedPlaces);
  const activeSourceIds = useSelector(getActiveSourceIds);
  
  useEffect(() => {
    dispatch(fetchBookmarks())
  }, [bookmarksNeedInit]);

  const postNotificationMessage = (message) => {
    passEventToFlight('notify:message', { message });
  };

  const handleAddressClick = (e, bookmark) => {
    e.preventDefault();
    passEventToFlight('property:request', { path: bookmark.path, address: bookmark.address });
  };

  const handleCopyClick = (path) => {
    copyToClipboard(`https://${hostDomain}${path}`)
    postNotificationMessage('Parcel link copied to clipboard.');
  };

  const handleDeletePlace = (followId) => {
    const callback = {
      success: () => postNotificationMessage('Unfollowed.'),
      failed: () => postNotificationMessage(`Could not unfollow.`)
    };
    dispatch(removeFollow(followId, callback));
  };

  const handleDeleteClick = (path, sourceId) => {
    const callback = {
      success: () => {
        passEventToFlight('tiles:reload');
        postNotificationMessage('Bookmark removed.');
      },
      failed: () => postNotificationMessage('Bookmark could not be removed.')
    };
    dispatch(removeBookmark(path, sourceId, callback));
  };

  /*const handleDeleteAllClick = (e, sourceId) => {
    e.preventDefault();
    const callback = {
      success: () => postNotificationMessage('Bookmarks have been removed.'),
      failed: () => postNotificationMessage('Bookmarks could not be removed.')
    }
    if(window.confirm('Are you sure you wish to permanently delete all bookmarks from this list?')) {
      dispatch(removeAllBookmarks(sourceId, callback));
    }
  };*/

  const handleFollowClick = (blextId, path, sourceId, toggle) => {
    const callback = {
      success: () => postNotificationMessage(toggle ? 'Followed.' : 'Unfollowed.'),
      failed: () => postNotificationMessage(`Could not ${toggle ? 'follow' : 'unfollow'}.`)
    };
    if(toggle) {
      dispatch(followBookmark(path, sourceId, callback))
    } else {
      dispatch(unfollowBookmark(blextId, callback))
    }
  };
  const handleExportClick = (e) => {
    passEventToFlight('pane:request:show', {tab: '#export'})
};

  const addSourceToMap = (sourceId) => {
    debug(`adding ${sourceId}`);
    const callback = {
      success: (src) => {
        debug(src)
        postNotificationMessage('Added to map.')
        passEventToFlight('sources:add', src);
        //dispatch(activate(sourceId));  // not implemented all the way
      },
      failed: () => postNotificationMessage(`Could not add dataset to the map.`)
    }
    dispatch(fetchSourceIfNeeded(sourceId, callback))
  };


  let intro = <p>
    <span className='subtle'>
      Places and properties you follow will appear here. You'll be notified when information about them changes.&nbsp;
      {window.data.signed_in && _.isEmpty(window.data.notifications) &&
        <a href='/notify/follows'>Set your email and mobile push notifications here!</a>}
    </span>
  </p>;

  let body = null;

  if(hasBookmarks || hasPlaces) {
    body = <>

      {hasPlaces && <div>
        <h4>Places</h4>
        <div className="bookmarks-container">
          {followedPlaces.map((follow) =>
            <PlaceRow
              follow={follow}
              key={follow.id}
              handleDeleteClick={handleDeletePlace}/>
          )}
        </div>
      </div>}

      {hasBookmarks && <div>
        {bookmarkSources.map((list) => (
          <React.Fragment key={list.source_id}>
            {!activeSourceIds.includes(list.source_id) && 
              <a href='#'
                className='btn btn-xs btn-round btn-info pull-right'
                onClick={(e) => { addSourceToMap(list.source_id) }}>Show on map</a>
            }
            <h4>{list.name}</h4>
            <div className="bookmarks-container">
              {list.bookmarks.map((bookmark) =>
                <BookmarkRow
                  key={bookmark.id}
                  bookmark={bookmark}
                  handleAddressClick={handleAddressClick}
                  handleCopyClick={handleCopyClick}
                  handleDeleteClick={handleDeleteClick}
                  handleFollowClick={handleFollowClick}
                />
              )}
            </div>
          </React.Fragment>
        ))}

        <div className="download-btn-container">
          <a href="/profile/bookmarks.csv" target="_blank" className="btn btn-primary btn-block">Download Follows as CSV</a>
        </div>
        <p>
          <span className='subtle'>
            <i className='fas fa-info-circle' />
            &nbsp;Note: This button will give a plain list of your Followed properties.
             If you'd like to download Followed properties with Regrid's property data attached, 
             please use our <a href="#" onClick={handleExportClick}>
            Export Page</a> &nbsp;
          </span>
        </p>
      </div>}

    </>

  } else if(bookmarksNeedInit) {
    body = <BigSpinner/>

  } else {
    // Doesn't have any bookmarks at the moment
    body = <NoBookmarksMessage />
  }

  return <>
    {intro}
    {body}
  </>;
};

BookmarksPane.propTypes = {
  passEventToFlight:   PropTypes.func.isRequired
};

export default withStore(withFlight(BookmarksPane));
