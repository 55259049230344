import React, { Component } from 'react'
import PropTypes from 'prop-types'

/**
 * PlanPriceButton component that displays a plan button and pricing details.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.plan - Name of the plan.
 * @param {Object} props.prices - Pricing details for the plan.
 * @param {Object} [props.action] - Action performed on button click (optional).
 * @param {string} [props.link] - Link to a "learn more" page (optional).
 * @param {boolean} props.isCurrent - Flag indicating the plan matches the user's plan.
 * @param {boolean} props.isSelected - Flag indicating the plan is selected.
 * @param {function} props.onClickPlan - Handler function when plan in clicked.
 * @returns {JSX.Element} React component.
 */
const PlanPriceButton = (props) => {
  let prices = props.prices //_.clone(props.prices)
  const offerPerSeatPrice = props?.offer?.per_seat_price
  const isPerSeatOffer =
    props.per_seat_pricing && !_.isNil(props.offer?.per_seat_price)
  const isPerSeatButNotOffer =
    props.per_seat_pricing && _.isNil(props.offer?.per_seat_price)
  const isApi = props?.isApi || false
  const OfferPrice = { monthly: props.offer?.price}
  const isOfferPrice =
    props.prices && !_.isNil(props.offer?.price)

  var priceContent
  if (prices.monthly === 0) {
    priceContent = isApi ? ['$0'] : ['Free — no credit', 'card required']
  } else if (prices.text) {
    priceContent = _.flatten([prices.text])
  } else {
    priceContent = []
    if (!_.isNil(prices.monthly) && !props.per_seat_pricing) {
      const price = isApi && props.name.toLowerCase() === props.offer?.api_access
        ? OfferPrice.monthly
        : prices.monthly;
      priceContent.push(`$${numberWithCommas(price)} / month`);
    }
    if (!_.isNil(prices.annual)) {
      priceContent.push(`$${numberWithCommas(prices.annual)} / year`)
    }
    if (isPerSeatOffer) {
      priceContent.push(
        `$${numberWithCommas(offerPerSeatPrice)} / month`,
        'per user'
      )
    }
    if (isPerSeatButNotOffer) {
      priceContent.push(
        `$${numberWithCommas(prices.monthly)} / month`,
        'per user'
      )
    }
  }

  let buttonText
  if (isApi) {
    buttonText =
      props.name === 'Sandbox' ? (
        <span>{props.expiredTrial ? 'Not Available' : `Start with Free`}</span>
      ) : (
        <span>{`Try ${props.name.replace('Access', '')}`}</span>
      )
  } else {
    buttonText = <span>{`Choose ${props.name.replace('Access', '')}`}</span>
  }

  if (props.isCurrent) {
    buttonText = (
      <span>
        <i className="fas fa-check-circle" /> Current Plan
      </span>
    )
  } else if (props.isSelected) {
    buttonText = <span>{`Choosing ${props.name.replace('Access', '')}`}</span>
  }

  const buttonSize = isApi ? 'btn-md' : 'btn-lg'
  let buttonClasses = ['btn', 'btn-round', 'btn-block', buttonSize]
  if (props.isCurrent) {
    buttonClasses.push('not-allowed', 'btn-success')
  } else if (isApi) {
    if (props.isSelected) {
      buttonClasses.push('btn-success', 'selected')
    } else if (props.expiredTrial && props.name === 'Sandbox') {
      buttonClasses.push('not-allowed', 'btn-default')
    } else {
      buttonClasses.push('plan-self-serve-accent')
    }
  } else if (props.isSelected || props.isCurrent) {
    buttonClasses.push('btn-success', 'selected')
  } else {
    buttonClasses.push('btn-primary')
  }
  let buttonClass = buttonClasses.join(' ')

  const handlePlan = (e) => {
    e.preventDefault()
    if (props.isCurrent) {
      return
    }
    if (props.expiredTrial && props.name === 'Sandbox') {
      return
    }
    if (props.currentApiPlan && !props.isCurrent) {
      // existing api user trying to change plan
      const urlRoot = window.data.group.path
      const newUrl =
        urlRoot + '/settings/billing?plan=' + encodeURIComponent(props.plan)
      // navigate to billing page with plan param
      window.location.href = newUrl
    } else {
      props.onClickPlan(e, props.plan)
    }
  }

  return (
    <>
      {!isApi && (
        <div className="centered">
          <h4 className="lead">
            {_.map(priceContent, (line, idx) => {
              return (
                <span key={idx}>
                  {idx ? <br /> : ''}
                  {_.isEmpty(line) ? '\u00A0' : line}
                </span>
              )
            })}
          </h4>
        </div>
      )}
      {props.action ? (
        <a
          href={props.action.link}
          className={`btn btn-primary btn-round ${buttonSize}`}
        >
          {props.action.text}
        </a>
      ) : (
        <>
          <a
            href={
              props.name === 'Sandbox' ? '/users/sign_up?flow=api_sandbox' : '#'
            }
            className={buttonClass}
            onClick={(e) => {
              handlePlan(e)
            }}
          >
            {buttonText}
          </a>
        </>
      )}
      {isApi && (
        <h4 className="lead bold api-plan-price">
          {_.map(priceContent, (line, idx) => {
            return (
              <span key={idx}>
                {idx ? <br /> : ''}
                {_.isEmpty(line) ? '\u00A0' : line}
              </span>
            )
          })}
        </h4>
      )}
    </>
  )
}

PlanPriceButton.propTypes = {
  plan:         PropTypes.string.isRequired,
  prices:       PropTypes.object.isRequired,
  action:       PropTypes.object,
  //offer:      PropTypes.object,
  link:         PropTypes.string, // "learn more" page
  isCurrent:    PropTypes.bool.isRequired,
  isSelected:   PropTypes.bool.isRequired,
  onClickPlan:  PropTypes.func.isRequired,
}

export default PlanPriceButton
