import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import mapSettings from 'components/Map/mapSettings'
import withStore from 'components/withStore'

// Components
import MapColorPicker from './MapColorPicker'

// Redux
import { getSatelliteLayerRendered, setMapColor } from 'ducks/mapProperties'

/**
 * Functional component representing the MapColorPickers.
 * @returns {JSX.Element} 
 */
const MapColorPickers = () => {
  const dispatch = useDispatch()
  const isSatellite = useSelector(getSatelliteLayerRendered)

  let parcelLineType = mapSettings.editableStyleTypes.streetParcelLines
  let hoverParcelType = mapSettings.editableStyleTypes.streetHoverParcel
  let selectedParcelType = mapSettings.editableStyleTypes.streetSelectedParcel

  if (isSatellite) {
    parcelLineType = mapSettings.editableStyleTypes.satelliteParcelLines
    hoverParcelType = mapSettings.editableStyleTypes.satelliteHoverParcel
    selectedParcelType = mapSettings.editableStyleTypes.satelliteSelectedParcel
  }

  const defaultColors = {
    satellite: {
      parcelLineDefault: mapSettings.styles.satellite.parcelLineColor,
      hoverParcelDefault: mapSettings.styles.satellite.hoverParcelColor,
      selectedParcelDefault: mapSettings.styles.satellite.selectedParcelColor,
    },
    street: {
      parcelLineDefault: mapSettings.styles.street.parcelLineColor,
      hoverParcelDefault: mapSettings.styles.street.hoverParcelColor,
      selectedParcelDefault: mapSettings.styles.street.selectedParcelColor,
    },
  }

  const handleResetAll = (e) => {
    e.preventDefault()
    dispatch(
      setMapColor({
        color: defaultColors[isSatellite ? 'satellite' : 'street'].parcelLineDefault,
        colorType: parcelLineType,
      })
    )
    dispatch(
      setMapColor({
        color: defaultColors[isSatellite ? 'satellite' : 'street'].hoverParcelDefault,
        colorType: hoverParcelType,
      })
    )
    dispatch(
      setMapColor({
        color: defaultColors[isSatellite ? 'satellite' : 'street'].selectedParcelDefault,
        colorType: selectedParcelType,
      })
    )
  }

  return (
    <div className="map-colorpickers">
      <MapColorPicker 
        colorType={parcelLineType} 
        description="Parcel Lines" 
      />
      <MapColorPicker
        colorType={hoverParcelType}
        description="Hovered Parcel"
      />
      <MapColorPicker
        colorType={selectedParcelType}
        description="Selected Parcel"
      />
      <a 
        href="#" 
        onClick={handleResetAll} 
        className="parcel-rule-reset small bold margin-top-md"
      >
        <i className="fa-solid fa-rotate-left margin-right-md"></i>
        Reset to Default Colors
      </a>
    </div>
  )
}

export default withStore(MapColorPickers)
