import React from 'react'
import RasterParcelLayer from './RasterParcelLayer'
import VectorParcelLayer from './VectorParcelLayer'

const ParcelLayers = () => {
  let tokenParam
  if (window.data.tile_token) {
    tokenParam = `userToken=${window.data.tile_token}`
  } else if (window.data.group_token) {
    tokenParam = `token=${window.data.group_token}`
  }


  return (
    <>
      <VectorParcelLayer tokenParam={tokenParam}/>
      <RasterParcelLayer tokenParam={tokenParam}/>
    </>
  )
}

export default ParcelLayers
