import React,{ useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Footer from '../Components/Footer';
import RulesContainer from './RulesContainer';
import DefaultRule from '../Components/Rules/DefaultRule';
import AddRuleBtn from '../Components/Rules/AddRuleBtn';
import IssueAlert from '../Components/IssueAlert';
import ApplyResetBtns from '../Components/Rules/ApplyResetBtns';
import MapColorPickers from '../../Map/MapControls/MapOptions/MapColors/MapColorPickers';

import withFlight  from 'containers/withFlight';
import withStore from 'components/withStore';

import { addBeingEditedRule,getActiveRules } from 'ducks/styles';

import settings from 'common/settings';

const StylesTool = ({ passEventToFlight }) => {
  const glMapFlag = window?.data?.flags?.react_map_component 
    const [isVisible, setIsVisible] = useState(false);
    const dispatch = useDispatch();
    const activeRules = useSelector(getActiveRules);


  const handleClick = () => {
    var newRule = {
      field:     null,
      source:    null,
      predicate: 'is',
      value:     null,
      fill:      settings.styles.defaultFillColor,
      line:      settings.styles.defaultLineColor,
    };

    dispatch(addBeingEditedRule(newRule));
    setIsVisible(true);
  };

  useEffect(() => {
    if (activeRules.length === 0) {
      setIsVisible(false); 
    } else {
      setIsVisible(true);
    }
  }, [activeRules]);
  
  return (
    <div className='styles-container'>
      {glMapFlag && (
        <div className="parcel-rules-container padded rounded margin-btm-lg">
          <h4>Base Parcel Styles</h4>
          <MapColorPickers />
        </div>
      )}
      <div className="filter-rules-container padded rounded" style={{ marginBottom: "6em" }}>
          <div className="centered">
            <a className={`add-rule btn btn-primary btn-md ${!isVisible ? 'show' : 'hide'}`} onClick={handleClick}>
              <i className="fas fa-plus" />
               Add a Style Rule
            </a>
          </div>
        
        <div className={`${!isVisible ? 'hide' : ''}`}>
        <h4>Style Rules</h4>
        <RulesContainer passEventToFlight={passEventToFlight} />
        <DefaultRule />
        <IssueAlert />
        <Footer/>
        <div className='buttons rounded style-buttons margin-top-xl'>
        <ApplyResetBtns passEventToFlight={passEventToFlight} />
        <AddRuleBtn passEventToFlight={passEventToFlight} />
        </div>
        </div>
        
      </div>
    </div>
  );
};

StylesTool.propTypes = {
  passEventToFlight: PropTypes.func.isRequired
};

export default withStore(withFlight(StylesTool));
