import React from 'react'
import { Layer, Source } from 'react-map-gl'

const EsriEnrichmentsLayers = ({ layer }) => {
  const sourceOpts = layer?.sourceOpts || {}

  const populationDensityLayerOpts = {
    id: `${layer.id}-layer`,
    type: 'fill',
    source: `${layer.id}-source`,
    'source-layer': `${layer.dataLayer}`,
    paint: {
      'fill-color': [
        'step',
        ['get', 'popdens_cy'],
        '#fff4e0',
        100, '#f39c04',
        675, '#f07624',
        1950, '#dc4123',
        3475, '#c51b8a',
        5475, '#b313a7',
        9500, '#730c6c'
      ],
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        16, 0.6,
        18, 0.3,
        18.1, 0.1,
      ],
    },
    ...layer.opts,
  }

  const populationGrowthLayerOpts = {
    id: `${layer.id}-layer`,
    type: 'fill',
    source: `${layer.id}-source`,
    'source-layer': `${layer.dataLayer}`,
    paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'popgrwcyfy'],
        -1.0, '#D01C8B',
        -0.5, '#F1B6DA',
        0.0, '#F7F7F7',
        0.5, '#B8E186',
        1.0, '#4DAC26'
      ],
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        16, 0.6,
        18, 0.3,
        18.1, 0.1,
      ],
    },
    ...layer.opts,
  }

  const orangeScale = chroma.scale(['#fecaa5', '#fc6b03']).domain([0, 100])

  const greenScale = chroma.scale(['#a5fbcb', '#04622e']).domain([100, 300])

  const housingAffordabilityLayerOpts = {
    id: `${layer.id}-layer`,
    type: 'fill',
    source: `${layer.id}-source`,
    'source-layer': `${layer.dataLayer}`,
    paint: {
      'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'hai_cy'],
        0, orangeScale(0).hex(),
        20, orangeScale(20).hex(),
        50, orangeScale(50).hex(),
        75, orangeScale(75).hex(),
        100, '#fff',
        120, greenScale(120).hex(),
        150, greenScale(150).hex(),
        175, greenScale(175).hex(),
        300, greenScale(300).hex(),
      ],
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        16, 0.6,
        18, 0.3,
        18.1, 0.1,
      ],
    },
    ...layer.opts,
  }

  const layerOptionsObject = {
    population_density: populationDensityLayerOpts,
    population_growth: populationGrowthLayerOpts,
    housing_affordability: housingAffordabilityLayerOpts,
  }

  const layerOpts = layerOptionsObject[layer?.id]

  if (!layerOpts) return null

  return (
    <>
      <Source
        id={`${layer.id}-source`}
        type="vector"
        maxzoom={12} //setting this, but nothing on the layer, allows overzoom on the layer above this zoom level. 
        {...sourceOpts}
      >
       <Layer {...layerOpts} />
      </Source>
    </>
  )
}

export default EsriEnrichmentsLayers
