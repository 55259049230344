
/**
  Metadata about counties: a flat list of county and state names, geoids, path
  and availability info that we use to color in the parcelverse (store) map
  and power the search
*/

// Actions
import {
  RECEIVE_PARCELVERSE_DATA,
  RECEIVE_PARCELVERSE_DATA_ERROR,
  REQUEST_PARCELVERSE_DATA,
} from './actions';

// API
export const fetchParcelverseData = (countiesKey) => dispatch => {
  // Trigger a loading flag
  dispatch({
    type: REQUEST_PARCELVERSE_DATA,
  });

  // We store this in localforage for faster lookup
  // With the new smaller data (like 150kb compressed), it's not really necessary
  return localforage.getItem(countiesKey).then(function(data) {
    if(data) {
      dispatch({
        type: RECEIVE_PARCELVERSE_DATA,
        data,
      });
    } else {
      $.get('/store/stats.json', function(data) {
        localforage.setItem(countiesKey, data);
        dispatch({
          type: RECEIVE_PARCELVERSE_DATA,
          data,
        });
      });
    }
  });
};

// Check if we already have the parcelverse data in the state
// Fetch it if we don't
export const fetchParcelverseDataIfNeeded = (countiesKey) => (dispatch, getState) => {
  const parcelverse = getState().parcelverse;

  // Make sure we're not already loading the data (prevents duplicate requests)
  if(!parcelverse.data.length && !parcelverse.isLoading) {
    dispatch(fetchParcelverseData(countiesKey));
  }
};

export const getParcelverseData = state => {
  return state.parcelverse;
};


// Reducers
export const parcelverse = (state = {
  isLoading: false,
  data:      { counties: [], states: []}
}, action) => {
  switch (action.type) {
    case REQUEST_PARCELVERSE_DATA:
      return {
        ...state, // persist any existing state
        isLoading:  true,
      };
    case RECEIVE_PARCELVERSE_DATA:
      return {
        isLoading:  false,
        data:      action.data
      };
    case RECEIVE_PARCELVERSE_DATA_ERROR:
      return {
        ...state, // persist any existing state
        isLoading:  false,
        error:     true,
        message:   action.message
      };
    default:
      return state;
  }
};

export default parcelverse;
