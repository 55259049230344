// component, mostly used for dev, to display current map zoom level
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import withStore from '../../withStore'

const isAdmin = window.data.admin

const ZoomDisplay = ({ map }) => {
  const [zoom, setZoom] = useState(null)
  const isZoomDisplayOpen = useSelector(state => state.mapControls.zoomDisplayOpen)

  if (map) {
    map.on('load', () => {
      setZoom(map.getZoom())
    })

    map.on('zoom', () => {
      setZoom(map.getZoom())
    })
  }

  // TODO admin_only class is not working
  return (
    <div
      id="zoom-display"
      className={`rounded padded-sm shadow-gl bold flex-row-between admin_only ${ (isZoomDisplayOpen && isAdmin) ? '' : 'hide'}`}
    >
      <span>Zoom:</span>
      <span>{zoom?.toFixed(2)} </span>
    </div>
  )
}

export default withStore(ZoomDisplay)
