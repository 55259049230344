import React, { useState, useEffect } from 'react';
import { Layer, Source } from 'react-map-gl';
import mapSettings from '../mapSettings';


const ZoningLayer = () => {
  const [layer, setLayer] = useState(null);
  const layerStyle = mapSettings.thirdPartyLayers.customVectorLayer

  useEffect(() => {
    const layerDefinition = {
      query: { zoning: true },
      style: 'mapbox://styles/loveland/ckfr5kdme0ifw19nz0kkpvcz1',
    };

    // Create the layer
    fetch(
      `${window.data.tileserver}/api/v1/sources?format=mvt&userToken=${window.data.tile_token}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(layerDefinition),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLayer({
          type: "vector",
          tileSize: 256,
          ...data,
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // Render nothing if we're still waiting for the layer
  if (layer === null) {
    return null;
  }

  const hasData = layer && layer.tiles && layer.tiles.length > 0;
  
  return (
    <>
      {hasData ? (
        <Source
        id={mapSettings.sourceIds.zoningSource}
        type="vector"
        tiles={[ layer.tiles[0] ]}
        minzoom={11}
        maxzoom={20}>
          <Layer
            id={mapSettings.layerIds.zoningFill}
            type='fill'
            source={mapSettings.sourceIds.zoningSource}
            source-layer={layer.id}
            layout= {{
              'visibility': 'visible',
            }}
            paint={{
              'fill-color': [
                'match',
                ['get', 'zoning_type'],
                'Residential',
                '#ffff33',
                'Commercial',
                '#e41a1c',
                'Special',
                '#f781bf',
                'Mixed',
                '#984ea3',
                'Planned',
                '#ff7f00',
                'Agriculture',
                '#4daf4a',
                'Industrial',
                '#377eb8',
                'Overlay',
                '#a65628',
                /* other */ '#fff',
              ],
              'fill-opacity': 0.5,
            }}
            minzoom={11}
            maxzoom={20}
          />
          <Layer
            id={mapSettings.layerIds.zoningOutline}
            type='line'
            source={mapSettings.sourceIds.zoningSource}
            source-layer={layer.id}
            paint={{
              'line-color': '#fff',
              'line-width': 0.7,
              'line-opacity': 0.8,
            }}
            minzoom={11}
            maxzoom={20}
          />
          <Layer
            id={mapSettings.layerIds.zoningLabel}
            type='symbol'
            source={mapSettings.sourceIds.zoningSource}
            source-layer={layer.id}
            layout={{
              'text-field': ['get', 'zoning_type'],
              'text-font': ['Arial Unicode MS Bold'],
              'text-size': 18,
              'text-anchor': 'center',
              'symbol-avoid-edges': true,
              'text-padding': 15,
            }}
            paint={{
              'text-color': layerStyle.labelTextColor,
              'text-halo-color': layerStyle.labelHaloColor,
              'text-halo-width': layerStyle.labelHaloWidth,
              'text-halo-blur': layerStyle.labelHaloBlur,
            }}
            minzoom={11}
            maxzoom={20}
          />

        </Source>
      ) : (
        <div className="zoom-notifier-gl">
          <span className="zoom-notifier-text centered">
            <i className="fas fa-search-plus margin-right-md"></i>
              No Zoning Data Available
          </span>
        </div>
      )}
    </>    
  );
};

export default ZoningLayer;
