import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { post, put } from 'ducks/api'
import CardList from 'components/Payment/CardList'
import CardForm from 'components/Payment/CardForm'

class CardSelector extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showForm: false,
      error: null
    }

    this.onConfirmed  = this.onConfirmed.bind(this)
    this.onClickAdd   = this.onClickAdd.bind(this)
    this.onChooseCard = this.onChooseCard.bind(this)
    this.onRemoveCard = this.onRemoveCard.bind(this)
  }


  render() {
    return (
      <div className='cards' style={{ marginBottom: '4em' }}>
        <h2>Credit Cards</h2>

        <CardList
          cards={this.props.cards}
          activePaymentMethod={this.props.activePaymentMethod}
          onChooseCard={this.onChooseCard}
          onRemoveCard={this.onRemoveCard}
          />

        {this.state.error && <div className='alert alert-warning'>{this.state.error}</div>}

        {this.state.showForm ?
          <CardForm
            disabled={false}
            onConfirmed={this.onConfirmed}
            stripeKey={this.props.stripeKey}
            validGroupName={true}
            buttonLabel='Save and Use Card'/>
          :
          <div>
            <a href='#' className='btn btn-info btn-round' onClick={this.onClickAdd}>Add New Card</a>
          </div>
        }
      </div>
    )
  }

  onClickAdd(e) {
    e.preventDefault()
    this.setState({ showForm: true })
  }


  onConfirmed(pmId) {
    debug('confirmed new card', pmId)
    this.setCard(pmId)
  }


  onChooseCard(e, pmId) {
    e.preventDefault()
    debug('choosing', pmId)
    this.setCard(pmId)
  }

  setCard(pmId) {
    let self = this

    if(this.props.subscriptionId) {
      // Updating existing subscription
      LL.Ajax.putSpin(s.sprintf('/subscriptions/%d.json', this.props.subscriptionId), {
        payment_method_id: pmId,
      }).then(response => {
        window.location.reload()
      }).catch(errMsg => {
        self.setState({ error: errMsg })
      })

    } else {
      // New subscription for this group
      LL.Ajax.postSpin('/subscriptions.json', {
        payment_method_id:  pmId,
        plan:               this.props.plan,
        cycle:              this.props.cycle,
        offer:              this.props.offer,
      }).then(response => {
        window.location.reload()
      }).catch(errMsg => {
        self.setState({ error: errMsg })
      })
    }
  }


  onRemoveCard(e, pmId) {
    e.preventDefault()
    debug('removing', pmId)
    let self = this

    if(!confirm('Are you sure you want to remove this card from your account?')) {
      return;
    }

    LL.Ajax.deleteSpin(s.sprintf('/account/cards/%s.json', pmId)).then(response => {
      window.location.reload()
    }).catch(errMsg => {
      self.setState({ error: errMsg })
    })
  }
}


CardSelector.propTypes = {
  subscriptionId:       PropTypes.number,
  stripeKey:            PropTypes.string.isRequired,
  activePaymentMethod:  PropTypes.string,
  plan:                 PropTypes.string,
  cycle:                PropTypes.string,
  offer:                PropTypes.object,
}

export default CardSelector
