import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { map } from 'lodash';

const CardList = props => {
  return <table className='table card-list'>
    <thead>
      <tr>
        <th>Type</th>
        <th>Cardholder</th>
        <th>Last 4</th>
        <th>Exp</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {map(props.cards, (card) => {
        let isActive = card.token === props.activePaymentMethod
        let actions = null
        if(isActive) {
          actions = 'This is the active card'
        } else {
          let allowUse = !isActive && !card.expired && card.provider === 'stripe'
          let allowRm  = !isActive && card.provider === 'stripe'
          actions = <>
            {allowUse && <a href='#'
              className='btn btn-sm btn-default btn-round'
              onClick={(e) => { props.onChooseCard(e, card.token) }}>
              Use this card
              </a>}
            {allowRm && <a href='#'
              className='margin-left-md'
              onClick={(e) => { props.onRemoveCard(e, card.token) }}
              title='Remove card'>
              <i className='fas fa-trash' />
            </a>}
          </>
        }

        return <tr key={card.token}>
          <td>{card.card_type}</td>
          <td>{card.cardholder_name}</td>
          <td>{card.last_4}</td>
          <td>{card.expiration_month}/{card.expiration_year}</td>
          <td>{actions}</td>
        </tr>
      })}
    </tbody>
  </table>
}


CardList.propTypes = {
  cards:                PropTypes.array.isRequired,
  activePaymentMethod:  PropTypes.string,
  onChooseCard:         PropTypes.func.isRequired,
  onRemoveCard:         PropTypes.func.isRequired,
}


export default CardList
