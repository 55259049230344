import React, { Component } from 'react'
import PropTypes from 'prop-types'
import produce from 'immer'
import { post, put } from 'ducks/api'
import SEControls from 'components/SurveyEditor/SEControls'
import SEQuestionSet from 'components/SurveyEditor/SEQuestionSet'
import SEClone from 'components/SurveyEditor/SEClone'

class SEContainer extends Component {
  constructor(props) {
    super(props)

    this.state = this.initialStateFromSource(props.source)

    this.controlHandlers = {
      save:       this.onClickSave.bind(this),
      new:        this.onClickNew.bind(this),
      revert:     this.onClickRevert.bind(this),
      preset:     this.onClickPreset.bind(this),
      copy:       this.onClickCopy.bind(this),
      closeCopy:  this.onCloseCopy.bind(this),
      copySource: this.onClickCopySource.bind(this),
    }
    this.qsHandlers = {
      changeQuestionText:     this.onChangeQuestionText.bind(this),
      changeQuestionColumn:   this.onChangeQuestionColumn.bind(this),
      changeQuestionType:     this.onChangeQuestionType.bind(this),
      changeQuestionRequired: this.onChangeQuestionRequired.bind(this),
      changeAnswerText:       this.onChangeAnswerText.bind(this),
      changeQuestionNext:     this.onChangeQuestionNext.bind(this),
      changeAnswerNext:       this.onChangeAnswerNext.bind(this),
      addQuestion:            this.onClickAddQuestion.bind(this),
      addAnswer:              this.onClickAddAnswer.bind(this),
      removeQuestion:         this.onClickRemoveQuestion.bind(this),
      removeAnswer:           this.onClickRemoveAnswer.bind(this),
      moveQuestion:           this.onClickMoveQuestion.bind(this),
      moveAnswer:             this.onClickMoveAnswer.bind(this),
      changePhotoRequired:    this.onChangePhotoRequired.bind(this),
    }
  }

  render() {
    return (
      <div
        id="question-form"
        data-source-id={this.props.source.id}
        className={this.props.disabled ? ' disabled' : ''}
      >
        <SEControls
          handlers={this.controlHandlers}
          isNew={this.props.source.new}
          isSaveDisabled={this.state.isSaveDisabled}
        />
        <h3>{this.props.source.name || 'New Survey'}</h3>
        {this.state.showCopyOptions && (
          <SEClone
            copyOptions={this.state.copyOptions}
            handlers={this.controlHandlers}
          />
        )}
        <SEQuestionSet
          questions={this.state.workingQuestions}
          handlers={this.qsHandlers}
        />

        <p style={{ marginTop: '1em', }}>
          <label>
            <input
              type="checkbox"
              name="photo_required"
              value="1"
              checked={this.state.photoRequired}
              onChange={this.qsHandlers.changePhotoRequired}
            />
            &nbsp; Require a photo
          </label>
        </p>

        <div className="centered" style={{ marginBottom: '2em', }}>
          <a
            onClick={this.controlHandlers.save}
            className="btn btn-primary"
            href="#"
            disabled={this.state.isSaveDisabled}
          >
            Save Survey Questions
          </a>
        </div>
      </div>
    )
  }

  initialStateFromSource(source) {
    let result = {
      workingQuestions: source.questions,
      photoRequired:    source.photo_required,
      isSaveDisabled: false,
    }
    // Make sure we show at least something to fill in
    if (_.isEmpty(result.workingQuestions)) {
      result.workingQuestions = [this.blankQuestion(),]
    }
    return result
  }

  // Notice when a new source gets loaded in, and set the workingQuestions
  componentDidUpdate(prevProps, prevState) {
    let oldId = prevProps.source && prevProps.source.id
    let newId = this.props.source && this.props.source.id
    if (oldId !== newId) {
      debug('secontainer reinitializing questionset from new source')
      let newState = this.initialStateFromSource(this.props.source)
      this.setState(newState)
    }
  }

  ///////////////////////////////////////////////
  // High level actions to operate on the survey
  ///////////////////////////////////////////////

  onClickSave(e) {
    let questions = this.transformStateToPost(this.state.workingQuestions)
    let source = this.props.source
    let opts = {
      map_id:         this.props.project && this.props.project.id,
      source_id:      source.new ? null : source.id,
      //survey_type:  preset ? preset : 'custom',
      questions:      JSON.stringify(questions),
      photo_required: this.state.photoRequired ? '1' : '0',
    }

    let start = () => {
      this.setState({ isSaveDisabled: true })
    }
    let done = data => {
      this.props.onSaved(data.source)
      this.setState({ isSaveDisabled: false})
    }
    let fail = error => {
      debug(error)
    }

    if (source.new) {
      post('/surveys.json', opts, { start, done, fail, })
    } else {
      put(`/surveys/${source.id}.json`, opts, { start, done, fail, })
    }

    e.preventDefault()
  }

  // Transform our working question set into a server-friendly format
  transformStateToPost(questions) {
    return _.chain(questions)
      .reject(q => {
        // Remove blank questions
        return _.isEmpty(q.question)
      })
      .map(q => {
        let cleanAnswers =
          q.answers === 'TEXTAREA'
            ? q.answers
            : _.chain(q.answers)
                .reject(a => {
                  // Remove blank entries in multiple choice
                  return _.isEmpty(a.label)
                })
                .map(a => {
                  // 'next' values in answers need to transform from hash to guid
                  let cleanNext = null
                  if (!a.next) {
                    // Do nothing
                  } else if (a.next.index === '__END__') {
                    // Skip to end
                    cleanNext = a.next.index
                  } else if (a.next.disambiguation) {
                    // Explicit next-question value
                    cleanNext = a.next.disambiguation
                  }
                  let cleanAnswer = _.omit(a, 'next')
                  if (cleanNext) {
                    cleanAnswer.next = cleanNext
                  }
                  return cleanAnswer
                })
                .value()

        // Final cleaning of the question obj itself
        let cleanQ = _.assign({}, q, {
          answers: cleanAnswers,
        })
        if(_.isObject(cleanQ.next)) {
          // Extract the string UUID or __END__ out of the object (#3301)
          cleanQ.next = cleanQ.next.disambiguation || cleanQ.next.index
        }
        if(cleanQ.next === '__DEFAULT__') {
          delete cleanQ.next
        }
        return cleanQ
      })
      .value()
  }

  onClickNew(e) {
    this.setState({
      workingQuestions: [this.blankQuestion(),],
      showCopyOptions:  false,
    })
    e.preventDefault()
  }

  onClickRevert(e) {
    let newState = this.initialStateFromSource(this.props.source)
    newState.showCopyOptions = false
    this.setState(newState)
    e.preventDefault()
  }

  onClickPreset(e, name) {
    if (_.isUndefined(this.presets)) {
      let self = this
      LL.Ajax.getSpin('/sources/presets.json').then(resp => {
        self.presets = resp.presets
        self.applyPreset(name)
      })
    } else {
      this.applyPreset(name)
    }
    e.preventDefault()
  }

  applyPreset(name) {
    const preset = this.presets[name];
    const modifiedQuestions = this.setPresetsQuestions(preset);
    this.setState({
      workingQuestions: modifiedQuestions,
    })
  }

  onClickCopy(e, id) {
    let show = !this.state.showCopyOptions
    this.setState({
      showCopyOptions: show,
    })
    e.preventDefault()

    if (show && _.isUndefined(this.state.copyOptions)) {
      let self = this
      LL.Ajax.getSpin('/sources.json?type=survey&group=false', function(resp) {
        let options = _.sortBy(resp.sources, function(s) {
          return s.name.toLowerCase()
        })
        self.setState({ copyOptions: options, })
      })
    }
  }

  onCloseCopy(e) {
    this.setState({ showCopyOptions: false, })
    e.preventDefault()
  }

  // User selected a particular survey source to clone into this one
  onClickCopySource(e, fromSource) {
    let newQuestions = fromSource.questions
    this.setState({
      workingQuestions: newQuestions,
      showCopyOptions:  false,
    })
  }

  //////////////////////////////////
  // Actions within the question set
  //////////////////////////////////

  onChangeQuestionText(e) {
    this.modifyQuestion(e.target.dataset.questionIndex, question => {
      question.question = e.target.value
    })
    e.preventDefault()
  }

  onChangeQuestionColumn(e) {
    this.modifyQuestion(e.target.dataset.questionIndex, question => {
      question.column = e.target.value
    })
    e.preventDefault()
  }

  onChangeQuestionType(e) {
    this.modifyQuestion(e.target.dataset.questionIndex, question => {
      if (e.target.value === 'multiple') {
        question.answers = [{ label: '', value: '', },]
      } else if (e.target.value === 'text') {
        question.answers = 'TEXTAREA'
      }
    })
    e.preventDefault()
  }

  onChangeAnswerText(e) {
    this.modifyQuestion(e.target.dataset.questionIndex, question => {
      let answer = question.answers[e.target.dataset.answerIndex]
      answer.label = answer.value = e.target.value
    })
    e.preventDefault()
  }

  onChangeQuestionRequired(e, idx) {
    this.modifyQuestion(idx, question => {
      question.required = !question.required;
    });
    e.preventDefault();
  }

  // For free-text fields you can specify next question
  onChangeQuestionNext(e) {
    let questionIndex = parseInt(e.target.dataset.questionIndex)
    this.modifyQuestion(questionIndex, question => {
      if (e.target.value === '__DEFAULT__') {
        delete question.next
      } else {
        question.next = e.target.value
      }
    })
    e.preventDefault()
  }

  onChangeAnswerNext(e) {
    // A little awkard since we're working with the question_response, not raw data
    let self = this
    let questionIndex = parseInt(e.target.dataset.questionIndex)
    let answerIndex = parseInt(e.target.dataset.answerIndex)
    this.modifyQuestion(questionIndex, question => {
      let answer = question.answers[answerIndex]
      if (e.target.value === '__DEFAULT__') {
        let nextIdx = questionIndex + 1
        let nextQ = self.state.workingQuestions[nextIdx]
        answer.next = {
          key:   nextQ.column,
          index: nextIdx,
        }
      } else if (e.target.value === '__END__') {
        answer.next = {
          index: '__END__',
        }
      } else {
        let disam = e.target.value
        let nextIdx = _.findIndex(self.state.workingQuestions, {
          disambiguation: disam,
        })
        let nextQ = self.state.workingQuestions[nextIdx]
        answer.next = {
          key:            nextQ.column,
          index:          nextIdx,
          disambiguation: disam,
        }
      }
    })
    e.preventDefault()
  }

  onClickAddQuestion(e) {
    let newQ = this.blankQuestion()
    let newQuestions = produce(this.state.workingQuestions, questions => {
      questions.push(newQ)
    })
    this.setState({ workingQuestions: newQuestions, })
    e.preventDefault()
  }

  onClickAddAnswer(e, questionIdx) {
    this.modifyQuestion(questionIdx, question => {
      question.answers.push({ label: '', value: '', })
    })
    e.preventDefault()
  }

  onClickRemoveQuestion(e, questionIdx) {
    let newQuestions = produce(this.state.workingQuestions, questions => {
      _.pullAt(questions, questionIdx)
    })
    this.setState({ workingQuestions: newQuestions, })
    e.preventDefault()
  }

  onClickRemoveAnswer(e, questionIdx, answerIdx) {
    this.modifyQuestion(questionIdx, question => {
      _.pullAt(question.answers, answerIdx)
    })
    e.preventDefault()
  }

  onClickMoveQuestion(e, questionIdx, direction) {
    let newQuestions = produce(this.state.workingQuestions, questions => {
      let swapWith = { up: questionIdx - 1, down: questionIdx + 1, }[direction]
      if (swapWith >= 0 && swapWith < questions.length) {
        let otherQ = questions[swapWith]
        questions[swapWith] = questions[questionIdx]
        questions[questionIdx] = otherQ
      }
    })
    this.setState({ workingQuestions: newQuestions, })
    e.preventDefault()
  }

  onClickMoveAnswer(e) {
    let questionIdx = parseInt(e.currentTarget.dataset.questionIndex)
    let answerIdx = parseInt(e.currentTarget.dataset.answerIndex)
    let direction = e.currentTarget.dataset.direction
    this.modifyQuestion(questionIdx, question => {
      let answers = question.answers
      let swapWith = { up: answerIdx - 1, down: answerIdx + 1, }[direction]
      if (swapWith >= 0 && swapWith < answers.length) {
        let otherA = answers[swapWith]
        answers[swapWith] = answers[answerIdx]
        answers[answerIdx] = otherA
      }
    })
    e.preventDefault()
  }

  onChangePhotoRequired(e) {
    this.setState({ photoRequired: e.target.checked, })
  }

  // Helper methods
  blankQuestion() {
    return {
      column:         '',
      question:       '',
      disambiguation: guid(),
      answers:        [{ label: '', value: '', },],
    }
  }

  blankTextAreaQuestion(){
    return {
      question: "Any other notes?",
      column:   'notes',
      answers:  'TEXTAREA',
      disambiguation: guid(),
      required: false
    }
  }

  setPresetsQuestions(questions){
    const modifiedQuestions = questions.map(ques => {
      if(ques.answers === "TEXTAREA") {
        return this.blankTextAreaQuestion();
      } else {
        return ques;
      }
    });
    return modifiedQuestions;
  }

  // Helper to update the state by changing one particular question.
  // Pass the index (from 0) of the question, and a callback that does the
  // modification work. Callback is a function(question) that does *not* need to
  // return anything, but should mutate the question obj given to it.
  modifyQuestion(idx, callback) {
    let newQuestions = produce(this.state.workingQuestions, questions => {
      let question = questions[idx]
      callback(question)
    })
    this.setState({ workingQuestions: newQuestions, })
  }
}

SEContainer.propTypes = {
  source:         PropTypes.object.isRequired,
  project:        PropTypes.object,
  disabled:       PropTypes.bool,
  onSaved:        PropTypes.func,
}

export default SEContainer
