import React from 'react'
import { useSelector } from 'react-redux'
import withStore from 'components/withStore'

// Components
import LayersListItem from './LayersListItem'
import StructuresListItem from '../Structures/StructuresListItem'
import ReactTooltip from 'react-tooltip'

// Redux + Utils
import { getAvailableThirdPartyLayerList } from 'ducks/mapProperties'
import { structureLayers } from '../../../Layers/thirdPartyLayers'
import mapSettings from '../../../mapSettings'

// Images
import defaultImg from '@/assets/default'
import elevationFeetImg from '@/assets/layers/elevation-ft'
import elevationMeterImg from '@/assets/layers/elevation-m'
import floodImg from '@/assets/layers/flood'
import redliningImg from '@/assets/layers/redlining'
import landCoverImg from '@/assets/layers/landuse'
import ozonesImg from '@/assets/layers/ozones'
import lbcsActivityImg from '@/assets/layers/lbcs-activity'
import buildingFootprintsImg from '@/assets/layers/building-footprints'
import zoningImg from '@/assets/layers/zoning'
import wetlandsImg from '@/assets/layers/wetlands'
import populationDensityImg from '@/assets/layers/population-density'
import populationGrowthImg from '@/assets/layers/population-growth'
import housingAffordabilityImg from '@/assets/layers/housing-affordability'

/**
 * This component is responsible for rendering a list of layer items.
 * The layer items are obtained from the available third-party layer list in redux state.
 *
 * @component
 * @returns {React.Element} Returns a div element that contains a list of LayersListItem components.
 */

const LayersList = ({ isWorkingInUSBoundary, workingCountryCode }) => {
  const layersList = useSelector(getAvailableThirdPartyLayerList)
  const isPaidUser = window.data.has_sitecontrol
  const { countries } = mapSettings
  const workingCountry = countries[workingCountryCode?.toUpperCase()]
  const tooltipText = 'Additional Layers'

  const images = {
    'elevation-ft': elevationFeetImg,
    'elevation-m': elevationMeterImg,
    flood: floodImg,
    redlining: redliningImg,
    'landuse-wms': landCoverImg,
    ozones: ozonesImg,
    'lbcs-activity': lbcsActivityImg,
    'building-footprints': buildingFootprintsImg,
    'zoning': zoningImg,
    wetlands: wetlandsImg,
    population_density: populationDensityImg,
    population_growth: populationGrowthImg,
    housing_affordability: housingAffordabilityImg,
  }

  const availableLayers = layersList.filter((layer) => {
    return layer.country.includes(workingCountry)
  })

  const unavailableLayersUSA = isWorkingInUSBoundary
    ? []
    : layersList.filter((layer) => {
        return (
          layer.country.includes(countries.US) &&
          !layer.country.includes(workingCountry)
        )
      })

  return (
    <>
      <div
        className="modal-tile-wrapper margin-btm-lg"
        id={isWorkingInUSBoundary ? 'custom-layers' : 'custom-layers-non-us'}
        data-tip={tooltipText}
        data-for="custom-layers-tt"
      >
        {structureLayers.map((layer) => {
          const layerImg = images[layer.id] || defaultImg
          return layer.country.includes(workingCountry) ? (
            <StructuresListItem
              key={`layer-item-${layer.id}`}
              layer={layer}
              layerImg={layerImg}
              isPaidUser={isPaidUser}
              softDisabled={!isWorkingInUSBoundary}
            />
          ) : null
        })}
        {availableLayers.map((layer) => {
          const layerImg = images[layer.id] || defaultImg
          return (
            <LayersListItem
              key={`layer-item-${layer.id}`}
              layer={layer}
              layerImg={layerImg}
              isPaidUser={isPaidUser}
              softDisabled={false}
            />
          )
        })}
        {!isPaidUser && (
          <ReactTooltip
            id="custom-layers-tt"
            place="top"
            className="tooltip upsell"
            effect="solid"
            clickable={true}
            delayHide={500}
            delayShow={250}
            delayUpdate={500} // allows mouse enter
            getContent={(dataTip) => (
              <div className="tooltip-inner margin-btm-md">
                <p>{`${dataTip} are available to Regrid Pro customers.`}</p>
                <a href="/plans">
                  <i className="fas fa-arrow-right" />
                  Upgrade to Pro
                </a>
              </div>
            )}
          />
        )}
      </div>
      {!isWorkingInUSBoundary && (
        <>
          {/* Display a message if the user is not working in the US boundary */}
          <div className="layers-unavailable margin-btm-md">
            <i className="fas fa-exclamation-circle margin-right-md"></i>
            <span className="bold">
              Additional layer options are available within the US.
            </span>
          </div>
          {/* Render any unavailable layers that are currently active */}
          <div
            className="modal-tile-wrapper"
            id="custom-layers" // TODO - new id?
            data-tip={tooltipText}
            data-for="custom-layers-tt"
          >
            {structureLayers.map((layer) => {
              const layerImg = images[layer.id] || defaultImg
              return layer.country.includes(countries.US) &&
                !layer.country.includes(workingCountry) ? (
                <StructuresListItem
                  key={`layer-item-${layer.id}`}
                  layer={layer}
                  layerImg={layerImg}
                  isPaidUser={isPaidUser}
                  softDisabled={!isWorkingInUSBoundary}
                />
              ) : null
            })}
            {unavailableLayersUSA.map((layer) => {
              const layerImg = images[layer.id] || defaultImg
              return (
                <LayersListItem
                  key={`layer-item-${layer.id}`}
                  layer={layer}
                  layerImg={layerImg}
                  isPaidUser={isPaidUser}
                  softDisabled={!isWorkingInUSBoundary}
                />
              )
            })}
          </div>
        </>
      )}
    </>
  )
}

export default withStore(LayersList)
