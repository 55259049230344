import {
  RECEIVE_ZOOM_LEVEL,
  RECEIVE_FLOAT_LAYER,
  RECEIVE_MAP_PROPERTIES,
  VECTOR_LAYER_RENDERED,
  SET_BASE_LAYER_TYPE,
  SET_THIRD_PARTY_LAYER,
  SET_BUILDINGS_LAYER_RENDER,
  SET_OWNER_NAME_LAYER_RENDER,
  SET_PARCEL_NUMBER_LAYER_RENDER,
  SET_MAP_COLORS,
  SET_MAP_COLOR,
  RECEIVE_PANE,
  RENDER_MAP,
  SET_TYPEAHEAD_PLACE,
  SET_DRAW_ACTIVE,
  SET_DRAW_MODE,
  SET_MEASURING_DISTANCE,
  SET_MEASURING_AREA,
  SET_MEASURING_COORDS,
  SET_DRAWING_FOCUS,
  SET_CURRENT_DRAW_TOOL,
  SET_UNIT_AREA,
  SET_UNIT_DISTANCE,
  ZOOM_TO_SOURCE_EXTENT,
} from './actions'

import mapSettings from 'components/Map/mapSettings'
import {
  thirdPartyLayers,
  adminThirdPartyLayers,
  baseLayers,
} from 'components/Map/Layers/thirdPartyLayers'
import {
  setUserPreference,
  getUsersPreference,
} from 'common/utils/UserPreferences'
import { pushHashState } from 'common/utils/hashUtils'
import { zoomToSourceExtent } from 'components/Map/mapUtilities'
import { floatLayers } from 'components/Map/MapControls/MapOptions/floatLayers'
import { setLegendModalOpen } from 'ducks/mapControls'
import { isEmpty } from 'lodash'

const normalizedColorPrefs = {
  streetParcelLines:       'street_parcel_lines',
  streetHoverParcel:       'street_hover_parcel',
  streetSelectedParcel:    'street_selected_parcel',
  satelliteParcelLines:    'satellite_parcel_lines',
  satelliteHoverParcel:    'satellite_hover_parcel',
  satelliteSelectedParcel: 'satellite_selected_parcel',
}

export const getCurrentZoomLevel = (state) => {
  return state.mapProperties.zoomLevel
}

export const getVectorLayerRendered = (state) => {
  return state.mapProperties.vectorLayerRendered
}

export const getBaseLayerType = (state) => {
  return state.mapProperties.baseLayer
}

export const getSatelliteLayerRendered = (state) => {
  const satelliteStyles = mapSettings.baseLayerTypes.satellite
  return satelliteStyles.includes(getBaseLayerType(state))
}

export const getThirdPartyLayer = (state) => {
  return state.mapProperties.thirdPartyLayer
}

export const getBuildingsLayerRendered = (state) => {
  return state.mapProperties.buildingsLayerRendered
}

export const getOwnerNameLayerRendered = (state) => {
  return state.mapProperties.ownerNameLayerRendered
}

export const getParcelNumberLayerRendered = (state) => {
  return state.mapProperties.parcelNumberLayerRendered
}

export const getMapColors = (state) => {
  return state.mapProperties.mapColors
}

export const getMapColor = (colorType) => (state) => {
  return state.mapProperties.mapColors[colorType]
}

export const getAvailableThirdPartyLayerList = () => {
  // Show hidden layers if user is an admin
  return window.data.admin
    ? [...thirdPartyLayers, ...adminThirdPartyLayers]
    : thirdPartyLayers
}

export const getActiveThirdPartyLayerObj = (state) => {
  const activeLayer = getThirdPartyLayer(state)
  const layerList = getAvailableThirdPartyLayerList()
  const activeLayerObj = layerList.find((layer) => layer.id === activeLayer)
  return activeLayerObj || null
}

export const getBaseLayerURL = (state) => {
  const activeBaseLayer = state.mapProperties.baseLayer
  const layer = baseLayers.find((layer) => layer.id === activeBaseLayer)
  return layer ? layer.url : null // TODO - should probably pick a default here
}

const getUsersColorPreferences = async () => {
  const mapColorPrefs = await getUsersPreference('map_colors')
  if(!mapColorPrefs) return

  const unnormalizedColorPrefs = {}
  for(const key in normalizedColorPrefs) {
    unnormalizedColorPrefs[key] = mapColorPrefs[normalizedColorPrefs[key]]
  }

  return unnormalizedColorPrefs
}

export const getFillColorFromPath = async (mapBoundaryPath) => {
  try {
    const response = await fetch(mapBoundaryPath + '/colors')
    const json = await response.json()
    // Give me only the paths with parcel data
    const keysArray = !isEmpty(json.data) ? Object.keys(json.data) : []

    return { success: true, data: keysArray }
  } catch (error) {
    console.log(error)
    return { success: false, data: [] }
  }
}

export const setMapProperties = (data) => async (dispatch) => {
  dispatch({
    type: RECEIVE_MAP_PROPERTIES,
    data,
  })

  // Fetch color preferences if they have any
  const mapColorPrefs = await getUsersColorPreferences()

  if(mapColorPrefs) {
    dispatch({
      type: SET_MAP_COLORS,
      data: mapColorPrefs,
    })
  }
}

export const setZoomLevel = (data) => (dispatch) => {
  dispatch({
    type: RECEIVE_ZOOM_LEVEL,
    data,
  })

  // TODO: Need to optimize this at some point
  const vectorLayerRendered = data > mapSettings.rasterMaxZoom
  // Set boolean to determine whether we should render the raster or vector parcels layer.
  dispatch({
    type: VECTOR_LAYER_RENDERED,
    data: vectorLayerRendered,
  })
}

export const setBaseLayerType = (data) => (dispatch) => {
  pushHashState({ base: data })
  localStorage.setItem('baseLayer', data)
  dispatch({
    type: SET_BASE_LAYER_TYPE,
    data,
  })
}

export const setThirdPartyLayer = (data) => (dispatch) => {
  dispatch({
    type: SET_THIRD_PARTY_LAYER,
    data,
  })
  dispatch(setLegendModalOpen(true))
}

export const setBuildingsLayerRendered = (data) => (dispatch) => {
  dispatch({
    type: SET_BUILDINGS_LAYER_RENDER,
    data,
  })
}

export const setOwnerNameLayerRendered = (data) => (dispatch) => {
  dispatch({
    type: SET_OWNER_NAME_LAYER_RENDER,
    data,
  })
}

export const setParcelNumberLayerRendered = (data) => (dispatch) => {
  dispatch({
    type: SET_PARCEL_NUMBER_LAYER_RENDER,
    data,
  })
}

const saveUsersColorPreferences = (mapColors) => {
  debug('Saving user color preferences')
  const mapColorsCopy = { ...mapColors }

  for(const colorType in mapColorsCopy) {
    const newKey = normalizedColorPrefs[colorType]
    mapColorsCopy[newKey] = mapColorsCopy[colorType]
    delete mapColorsCopy[colorType]
  }

  setUserPreference({ map_colors: mapColorsCopy })
}


export const setMapColors = (data) => (dispatch) => {
  dispatch({
    type: SET_MAP_COLORS,
    data,
  })
}

export const setMapColor = (data) => (dispatch, getState) => {
  dispatch({
    type:      SET_MAP_COLOR,
    colorType: data.colorType,
    color:     data.color,
  })

  // Save user's map color selections
  const mapColors = getMapColors(getState())
  saveUsersColorPreferences(mapColors)
}

export const setCurrentFloatLayer = (currentFloatLayer) => (dispatch) => {
  dispatch({
    type: RECEIVE_FLOAT_LAYER,
    currentFloatLayer,
  })
}

// TODO search.js mounts results to SearchBar.jsx. On click of a result, these reducers send the place path to the store and MapGL.jsx updates active place.  If search logic is translated to SearchBar.jsx, typeahead reducers can be removed.
export const setTypeaheadPlace = (data) => ({
  type: SET_TYPEAHEAD_PLACE,
  data,
})

// TODO tools better as its own slice?
export const setDrawActive = (data) => ({
  type: SET_DRAW_ACTIVE,
  data,
})

export const setDrawMode = (data) => ({
  type: SET_DRAW_MODE,
  data, // string => 'draw_line_string', 'draw_polygon', 'draw_point'
})

export const setMeasuringDistance = (data) => ({
  type: SET_MEASURING_DISTANCE,
  data,
})

export const setMeasuringArea = (data) => ({
  type: SET_MEASURING_AREA,
  data,
})

export const setMeasuringCoords = (data) => ({
  type: SET_MEASURING_COORDS,
  data,
})

export const setDrawingFocus = (data) => ({
  type: SET_DRAWING_FOCUS,
  data,
})

export const setCurrentDrawTool = (data) => ({
  type: SET_CURRENT_DRAW_TOOL,
  data,
})

export const setUnitArea = (data) => ({
  type: SET_UNIT_AREA,
  data,
})

export const setUnitDistance = (data) => ({
  type: SET_UNIT_DISTANCE,
  data,
})

// TODO Unnecessary using selectors with this pattern directly?
// export const getDrawActive = (state) => state.mapProperties.drawActive;
// export const getDrawMode = (state) => state.mapProperties.drawMode;
// export const getMeasuringDistance = (state) => state.mapProperties.isMeasuringDistance;
// export const getMeasuringArea = (state) => state.mapProperties.isMeasuringArea;
// export const getMeasuringCoords = (state) => state.mapProperties.isMeasuringCoords;
// export const getDrawingFocus = (state) => state.mapProperties.isDrawingFocus;

const savedBaseLayer = localStorage.getItem('baseLayer')
const baseLayerTypes = mapSettings.baseLayerTypes
let initialBaseLayer = baseLayerTypes.satellite[0] // default if not in localStorage

if(
  Object.values(baseLayerTypes).some((layerArray) =>
    layerArray.includes(savedBaseLayer)
  )
) {
  initialBaseLayer = savedBaseLayer
}

const initialState = {
  baseLayer:           initialBaseLayer,
  thirdPartyLayer:     null,
  vectorLayerRendered: false,
  zoomLevel:           null,
  floatLayer:          mapSettings.layerIds.noFloat,
  // Default colors for map
  mapColors:           {
    streetParcelLines:       mapSettings.styles.street.parcelLineColor,
    streetHoverParcel:       mapSettings.styles.street.hoverParcelColor,
    streetSelectedParcel:    mapSettings.styles.street.selectedParcelColor,
    satelliteParcelLines:    mapSettings.styles.satellite.parcelLineColor,
    satelliteHoverParcel:    mapSettings.styles.satellite.hoverParcelColor,
    satelliteSelectedParcel: mapSettings.styles.satellite.selectedParcelColor,
  },
  // Have buildings layer on by default for paid users
  buildingsLayerRendered:    false,
  ownerNameLayerRendered:    false,
  parcelNumberLayerRendered: false,
  currentPane:               null,
  mapAvailable:              false,
  drawActive:                false,
  drawMode:                  null, // 'simple_select' or null ?
  isMeasuringDistance:       false,
  isMeasuringArea:           false,
  isMeasuringCoords:         false,
  isDrawingFocus:            false,
  currentDrawTool:           null,
  unitArea:                  null, // 'acres'
  unitDistance:              null, // 'miles'
  typeaheadPlacePath:        null,
}

export const getCurrentFloatLayer = (state) => {
  return state.mapProperties.floatLayer
}

export const getCurrentFloatLayerMapId = (state) => {
  const floatLayer = state.mapProperties.floatLayer
  if(floatLayer !== mapSettings.layerIds.noFloat) {
    return floatLayers.find((i) => i.id === floatLayer)?.mapId
  } else {
    return null
  }
}

export const getCurrentPane = (state) => {
  return state.mapProperties.currentPane
}

/* Thunk stuff and middleware */
export const mapMiddleware =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    const map = window.data?.mapbox?.currentMap

    if(action.type === ZOOM_TO_SOURCE_EXTENT) {
      // listen for a ZOOM_TO_EXTENT action, find the source/extent and fit the map
      await zoomToSourceExtent(action.data, map)
    } else if(
      action.type === RECEIVE_PANE &&
      action.data &&
      action.data?.tab !== '#measure'
    ) {
      // ensure the draw/measure tools are closed if another nav pane is opened
      dispatch(setDrawActive(false))
      dispatch(setDrawMode(null))
      dispatch(setCurrentDrawTool(null))
    }
    return next(action)
  }

/* Reducer */
export const mapProperties = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_MAP_PROPERTIES:
      // TODO: we shouldn't need this, it does nothing, but if removed some issues with parcel colors arise. Need to trace.
      return {
        ...state,
      }
    case RECEIVE_ZOOM_LEVEL:
      return {
        ...state,
        zoomLevel: action.data,
      }
    case VECTOR_LAYER_RENDERED:
      return {
        ...state,
        vectorLayerRendered: action.data,
      }
    case SET_BASE_LAYER_TYPE:
      return {
        ...state,
        baseLayer: action.data,
      }
    case SET_THIRD_PARTY_LAYER:
      return {
        ...state,
        thirdPartyLayer: action.data,
      }
    case SET_BUILDINGS_LAYER_RENDER:
      return {
        ...state,
        buildingsLayerRendered: action.data,
      }
    case SET_OWNER_NAME_LAYER_RENDER:
      return {
        ...state,
        ownerNameLayerRendered: action.data,
      }
    case SET_PARCEL_NUMBER_LAYER_RENDER:
      return {
        ...state,
        parcelNumberLayerRendered: action.data,
      }
    case SET_MAP_COLORS:
      return {
        ...state,
        mapColors: action.data,
      }
    case SET_MAP_COLOR:
      return {
        ...state,
        mapColors: {
          ...state.mapColors,
          [action.colorType]: action.color,
        },
      }
    case RECEIVE_FLOAT_LAYER:
      return {
        ...state,
        floatLayer: action.currentFloatLayer,
        // floatLayer: action.data
      }
    case RECEIVE_PANE:
      return {
        ...state,
        currentPane: action.data?.tab,
      }
    case SET_TYPEAHEAD_PLACE:
      return {
        ...state,
        typeaheadPlacePath: action.data,
      }
    case SET_DRAW_ACTIVE:
      return {
        ...state,
        drawActive: action.data,
      }
    case SET_DRAW_MODE:
      return {
        ...state,
        drawMode: action.data,
      }
    case SET_MEASURING_DISTANCE:
      return {
        ...state,
        isMeasuringDistance: action.data,
      }
    case SET_MEASURING_AREA:
      return {
        ...state,
        isMeasuringArea: action.data,
      }
    case SET_MEASURING_COORDS:
      return {
        ...state,
        isMeasuringCoords: action.data,
      }
    case SET_DRAWING_FOCUS:
      return {
        ...state,
        isDrawingFocus: action.data,
      }
    case SET_CURRENT_DRAW_TOOL:
      return {
        ...state,
        currentDrawTool: action.data,
      }
    case SET_UNIT_AREA:
      return {
        ...state,
        unitArea: action.data,
      }
    case SET_UNIT_DISTANCE:
      return {
        ...state,
        unitDistance: action.data,
      }
    default:
      return state
    case RENDER_MAP:
      return {
        ...state,
        mapAvailable: true,
      }
  }
}

export default mapProperties
