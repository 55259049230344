import React from 'react'
import { takeMapScreenshot } from '../Map/mapUtilities'

const ScreenshotPane = () => {
  const handleClick = () => {
    // this component is not in ReactMapGL tree, so can't easily use `useMap` hook
    const map = window.data.mapbox?.currentMap?.getMap()
    takeMapScreenshot(map)
  }

  return (
    <div id="print-options">
      <div>
        <div>
          <div>
            <p>Click below to download a PNG image of the current map view. The resulting PNG file can be opened up in an image editor and from there printed or edited, as you desire.</p>

            <button id="print-map" className="btn btn-primary btn-block" onClick={handleClick}>
              Print Screenshot
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScreenshotPane
