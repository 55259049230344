import React from 'react';

const NoBookmarksMessage = () => {
  
  return (
    <div className="empty-hint centered">
      <i className="fas fa-star"></i>
      <p className="lead">
        You're not following any properties or places yet!
      </p>
      <p>
        Click a property, then Actions &gt; Follow, and it will show up here.
        View our quick <a href="https://regrid.com/tutorials/following-properties-the-regrid-property-mobile-app" target="_blank">tutorial video</a>, or check out this <a href="https://support.regrid.com/articles/follow-a-property/" target="_blank">support article</a> to learn more!
      </p>
    </div>
  );
};

export default NoBookmarksMessage;