import React, { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMap } from 'react-map-gl'
import { zoomToBoundary } from '../mapUtilities'
import mapSettings from '../mapSettings'
import {
  activeProjectPresent,
  getActiveProjectPath,
  getActiveProjectRootPath,
  changeProjectRoot,
} from 'ducks/projects'
import passGLMapEventToFlight from 'common/utils/passGLMapEventToFlight'
import { updateCurrentMapBoundary } from '../../../ducks/boundaries'

/**
 * ViewedPlaceControl component displays the viewed place information on the map.
 * It receives the viewedState as a prop, which contains the latitude and longitude of the viewed place.
 * The component fetches data based on the viewedState and renders the place information, including the breadcrumb navigation.
 * It also provides functionality to zoom to different boundaries and change the project root.
 *
 * @component
 * @param {Object} viewedState - The viewed state containing the latitude and longitude of the viewed place.
 * @returns {JSX.Element|null} The rendered ViewedPlaceControl component.
 */
const ViewedPlaceControl = ({ viewedState }) => {
  const dispatch = useDispatch()
  const { current: map } = useMap()
  const currentZoom = useSelector((state) => state.mapProperties.zoomLevel)
  const hasActiveProject = useSelector(activeProjectPresent)
  const activeProjectPath = useSelector(getActiveProjectPath)
  const activeProjectRootPath = useSelector(getActiveProjectRootPath)
  const [viewedPlace, setViewedPlace] = useState(null) // current view info

  useEffect(() => {
    if (viewedState?.latitude && viewedState?.longitude) {
      fetchData(viewedState)
    }
  }, [viewedState?.latitude, viewedState?.longitude])
  
  /**
   * Fetches place data based on the provided map view state.
   *
   * PlacesController#at_point limits return place types to:
   * - US: [:neighborhood, :city, :county, :state, :country]
   * - CA: [:city, :county, :state, :country]
   *
   * @param {Object} mapViewState - state of the map view containing latitude and longitude.
   */
  function fetchData(mapViewState) {
    const { latitude, longitude } = mapViewState
    const insideUrl = `/at_point.json?lat=${latitude}&lon=${longitude}`

    fetch(insideUrl)
      .then((response) => response.json())
      .then((data) => {
        setViewedPlace(data)
      })
      .catch((error) => {
        debug('Error fetching viewed place data: ', error)
      })
  }

  const { neighborhood, city, county, state, country } = viewedPlace || {}

  const onClickBreadcrumb = (requestedPath) => {
    if (requestedPath) {
      // TODO: if subboundary style is 'none', set to 'neighborhoods'
      // see regions.js onClickedBreadcrumb
      zoomToBoundary(requestedPath, map)

      passGLMapEventToFlight('pane:request:refresh')
    }
  }

  const handleCountryChange = (country) => {
    if (country) {
      onClickBreadcrumb(`/${country}`)
    }
  }

  const buildCrumbsArray = () => {
    const crumbs = []
    if (county && (countyZoom || cityZoom || neighborhoodZoom)) {
      crumbs.push({ path: county.path, name: county.name })
    }
    if (city && (cityZoom || neighborhoodZoom)) {
      crumbs.push({ path: city.path, name: city.name })
    }
    if (neighborhood && neighborhoodZoom) {
      crumbs.push({ path: neighborhood.path, name: neighborhood.name })
    }
    return crumbs
  }

  const ViewedPlaceHeadline = ({ place }) => {
    if (!place) return null
    let viewHeadline = place?.headline_alt || place?.headline

    return (
      <a
        href="#"
        id="viewed-place-headline"
        className="bold"
        onClick={() => onClickBreadcrumb(place?.path)}
      >
        {viewHeadline}
      </a>
    )
  }

  const neighborhoodZoom = currentZoom >= 12
  const cityZoom = currentZoom >= 10 && currentZoom < 12
  const countyZoom = currentZoom >= 6 && currentZoom < 10

  let headline = null
  let placeName = null
  let placePath = null
  if (neighborhoodZoom) {
    if (neighborhood?.headline_alt || neighborhood?.headline) {
      headline = <ViewedPlaceHeadline place={neighborhood} />
      placeName = neighborhood?.headline_alt || neighborhood?.headline
      placePath = neighborhood?.path
    } else if (city?.headline_alt || city?.headline) {
      headline = <ViewedPlaceHeadline place={city} />
      placeName = city?.headline_alt || city?.headline
      placePath = city?.path
    } else {
      console.warn('ViewedPlaceControl: There is no neighborhood or city data')
    }
  } else if (cityZoom && (city?.headline_alt || city?.headline)) {
    headline = <ViewedPlaceHeadline place={city} />
    placeName = city?.headline_alt || city?.headline 
    placePath = city?.path
  } else if (countyZoom && (county?.headline_alt || county?.headline)) {
    headline = <ViewedPlaceHeadline place={county} />
    placeName = county?.headline_alt || county?.headline
    placePath = county?.path
  } else if (countyZoom && (!county?.headline && !county?.headline_alt) && (city?.headline || city?.headline_alt)) {
    // Fall back to city if no county headline
    headline = <ViewedPlaceHeadline place={city} />
    placeName = city?.headline_alt || city?.headline
    placePath = city?.path
  }

  useEffect(() => {
    dispatch(updateCurrentMapBoundary({path: placePath, name: placeName}))
  }, [placePath, placeName])


  const handleProjectPathChange = useCallback(() => {
    dispatch(changeProjectRoot(activeProjectPath, placePath))
  }, [placePath, activeProjectPath])


  if (!headline) {
    return null
  }

  return (
    <>
      {viewedPlace && mapSettings?.countryLabels && (
        <div id="map-viewed-place" className="padded shadow-gl">
          {headline}
          <div id="viewed-place-breadcrumb" className="mapboxgl-ff">
            {/* World button and dropdown */}
            <div className="btn-group">
              <button
                type="button"
                id="viewed-place-dropdown-toggle"
                className="btn btn-default btn-xs dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className={`fa-solid fa-earth-americas`}></i>
              </button>
              <ul id="viewed-place-dropdown" className="dropdown-menu">
                {Object.entries(mapSettings.countryLabels).map(([code, label]) => (
                  <li key={code}>
                    <a
                      href="#"
                      className="bold"
                      onClick={(e) => {
                        e.preventDefault()
                        handleCountryChange(code)
                      }}
                    >
                      {label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {/* Parent boundaries */}
            <i className="fas fa-angle-right"></i>
            <a
              className="crumb"
              href="#"
              onClick={() => onClickBreadcrumb(country?.path)}
            >
              {country?.headline_alt || country?.headline || 'US'}
            </a>
            <i className="fas fa-angle-right"></i>
            <a
              className="crumb"
              href="#"
              onClick={() => onClickBreadcrumb(state?.path)}
            >
              {state?.name || state?.headline_alt}
            </a>
            {/* Build and render crumbs based on zoom levels */}
            {buildCrumbsArray().map((crumb, index) => (
              <React.Fragment key={index}>
                <i className="fas fa-angle-right"></i>
                <a
                  className="crumb"
                  href="#"
                  onClick={() => onClickBreadcrumb(crumb.path)}
                >
                  {crumb.name}
                </a>
              </React.Fragment>
            ))}
          </div>
          <div id="boundary-notice">
            {hasActiveProject && placePath !== activeProjectRootPath && (
              <a
                href="#"
                onClick={handleProjectPathChange}
              >
                Save your project home to {placeName}?
              </a>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default ViewedPlaceControl
