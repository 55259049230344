import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import Async from 'react-select/async';
import { getURLParamsString } from 'common/util';

import LabeledRuleColorSwatch from '../ColorPicker/LabeledRuleColorSwatch';
import withStore from 'components/withStore';

import { deleteBeingEditedRule, updateBeingEditedRule } from 'ducks/styles';
import settings from 'common/settings';

const Rule = ({
  id,
  field,
  source = 'parcel',
  predicate,
  value,
  path,
  fill,
  line,
  filters,
  onDragStart,
  onDragEnd,
  onDragOver
}) => {

  const hostDomain = window.data.host
  const dispatch = useDispatch();

  // Had to implement this due to moz draggable bug, worked out well though cuz it only allows rules to be dragged from their handles
  const [isDraggable, setIsDraggable] = useState(false)

  // Keep default options for whatever filter is selected
  const [defaultValueOptions, setDefaultValueOptions] = useState([])
  const [defaultOptionsLoading, setDefaultOptionsLoading] = useState(false)

  // Get default options for the field anytime the filter changes
  useEffect(() => {
    // Abort if rerender happens before fetch and state update finished
    const abortController = new AbortController();
    if(field) {
      loadDefaultOptions(abortController);
    }
    return () => { 
      abortController.abort();
      setDefaultOptionsLoading(false);
    }
  }, [field])

  //debug('Rule: working with filters', filters)

  let predicates = [
    { 
      value: "is", 
      label: "is" 
    },
    { 
      value: "is not", 
      label: "is not" 
    },
    { 
      value: "exists", 
      label: "has any value" 
    },
  ];

  const numericalPredicates = [
    ...predicates,
    {
      label: ">",
      value: "gt",
    },
    {
      label: "<",
      value: "lt",
    },
    {
      label: '≥',
      value: "gte",
    },
    {
      label: "≤",
      value: "lte",
    }
  ];

  // Updates change in any rule field
  const handleChange = (changes) => {
    dispatch(updateBeingEditedRule(id, changes))
  }

  // Deletes rule from list
  const handleDelete = (id) => {
    dispatch(deleteBeingEditedRule(id));
  };

  // List parcel filters after any source filters
  const sortedFilterList = filters.sort((filter) => filter.key === "parcel" ? 1 : -1 ) 
  
  // Takes the field parameter and finds the right filter for it
  let formattedFilter = field
  if(field) {
    // First we find which source this filter belongs to 
    const filtersForSource = filters.find(filter => filter.id == source || filter.id == path)

    // Then we find the actual options
    formattedFilter = filtersForSource?.options?.find(option => option.key === field)
  }

  // Adds operator predicates on range filters
  if(formattedFilter?.range) {
    predicates = numericalPredicates;
  }

  // Find the right predicate object based on the current value
  let formattedPredicate = predicate
  if(predicate) {
    formattedPredicate = predicates.find(pred => pred.value === predicate)
  }

  const formatValueOptions = (options) => {
    return options.map((option) => ({ label: option, value: option }))
  };

  // Get default options (top hits) by fetching with a blank query param
  const loadDefaultOptions = (ac) => {
    setDefaultValueOptions([])
    setDefaultOptionsLoading(true)
    const params = source === "parcel" ? { key: field, query: "", filter_path: path } : { key: field, query: "" }
    const paramsString = getURLParamsString(params)
    fetch(`//${hostDomain}/sources/${source}/autocomplete.json${paramsString}`, {
      signal: ac.signal
    })
      .then(res => res.json())
      .then(data => {
        // This endpoint currently only sends a status if theres an error
        if(!data.status) {
          setDefaultValueOptions(formatValueOptions(data))
        }
      })
      .catch(err => debug(`Failed to load default options: ${err.message}`))
      .finally(() => setDefaultOptionsLoading(false))
  };

  // Gets autocomplete options based on user input into value field
  const loadAutocompleteOptions = (inputValue) => {
    const params = source === "parcel" ? { key: field, query: inputValue, filter_path: path } : { key: field, query: inputValue }
    const paramsString = getURLParamsString(params)
    return (
      fetch(`//${hostDomain}/sources/${source}/autocomplete.json${paramsString}`)
        .then(res => res.json())
        .catch(err => debug(`Failed to load autocomplete options: ${err.message}`))
        .then(data => formatValueOptions(data))
    );
  };

  // Reduces the height of the react-select components, regular CSS won't do it
  const customStyles = {
    control: base => ({
      ...base,
      height: 35,
      minHeight: 35
    })
  };

  // Formats the checkbox options so that react-select can render them
  const formatCheckboxes = (checkboxes) => {
    const keys = Object.keys(checkboxes)
    const formattedCheckboxes = []
    for(const key of keys) {
      const val = checkboxes[key]
      formattedCheckboxes.push({ label: val, value: key })
    }
    return formattedCheckboxes;
  };

  // Changes the type of dropdown based on whether the filter has checkboxes or not
  let valueSelector = null
  if(formattedFilter?.checkboxes) {

    const checkboxOptions = formatCheckboxes(formattedFilter.checkboxes)

    const formattedCheckboxValue = checkboxOptions.find(cbo => cbo.value === value )

    valueSelector = (
      <Select
        className="rule-values"
        placeholder="Value"
        value={formattedCheckboxValue}
        options={checkboxOptions}
        onChange={({value}) => handleChange({ value })}
        styles={customStyles}
      />
    )
  } else {
    valueSelector = (
      <Async
        className="rule-values"
        placeholder="Value"
        value={ value ? {value, label: value} : null}
        getOptionLabel={(o) => o.label}
        getOptionValue={(o) => o.value}
        loadOptions={loadAutocompleteOptions}
        loadingMessage={() => "Loading..."}
        noOptionsMessage={() => defaultOptionsLoading ? "Loading..." : "No Options Available"}
        onChange={({value}) => handleChange({ value })}
        styles={customStyles}
        defaultOptions={defaultValueOptions}
        cacheOptions
      />
    )
  }

  if(formattedFilter?.range) {
    valueSelector = (
      <input
        className="rule-values-number"
        type="number"
        placeholder="Value"
        value={value.toString()}
        onChange={(e) => handleChange({ value: Number(e.target.value) })}
      />
    )
  }

  if(predicate === 'exists') {
    const options = [
      { 
        value: "true", 
        label: "Yes" 
      },
      { 
        value: "false", 
        label: "No" 
      }
    ];

    const val = options.find(option => option.value === value)
    if(!val) handleChange({ value: options[0].value }) // Set default value to Yes

    valueSelector = (
      <Select
        className="rule-values"
        placeholder="Value"
        value={val}
        options={options}
        onChange={({value}) => handleChange({ value })}
        styles={customStyles}
      />
    )
  }

  return (
    <div className="style-rule" draggable={isDraggable} onDragOver={e => onDragOver(e, id)} onDragStart={e => onDragStart(e, id)} onDragEnd={() => onDragEnd()}>
      <div className="rule-row">
        <i className="fas fa-bars handle" title="Remove this rule" onMouseOver={() => setIsDraggable(true)} onMouseLeave={() => setIsDraggable(false)} />
        <Select
          className="rule-filters"
          options={sortedFilterList}
          getOptionLabel={(e) => e.label}
          getOptionValue={(e) => e.key}
          placeholder="Column Name"
          value={formattedFilter}
          onChange={(val) => {
            let changes = { field: val.key, source: val.source, value: '' };
            if(val.source === 'parcel') {
              changes.path = val.path;  // Only set a path for parcel sources (#4809 #4950)
            }
            handleChange(changes)
          }}
          styles={customStyles}
        />
        <Select
          className="predicates"
          options={predicates}
          placeholder="Predicate"
          value={formattedPredicate}
          onChange={(val) => handleChange({predicate: val.value })}
          styles={customStyles}
        />
      </div>
      <div className="rule-row">
          {valueSelector}
      </div>
      <div className="rule-row padding-top-xs padding-bottom-xs" style={{ paddingLeft: '30px' }}>
        <LabeledRuleColorSwatch
          currentColor={fill}
          type="fill"
          handleChange={handleChange}
        />
        <LabeledRuleColorSwatch
          currentColor={line}
          type="line"
          handleChange={handleChange}
        />
        <i className="fas fa-trash" onClick={() => handleDelete(id)} />
      </div>
    </div>
  );
};

Rule.propTypes = {
  id:          PropTypes.number.isRequired,
  field:       PropTypes.string,
  source:      PropTypes.oneOfType([
                  PropTypes.string,
                  PropTypes.number
                ]),
  predicate:   PropTypes.string,
  value:       PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number
               ]),
  path:        PropTypes.string,
  fill:        PropTypes.string,
  line:        PropTypes.string,
  filters:     PropTypes.array,
  onDragStart: PropTypes.func.isRequired,
  onDragEnd:   PropTypes.func.isRequired,
  onDragOver:  PropTypes.func.isRequired,
};

export default withStore(Rule);
