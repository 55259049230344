import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LabeledRuleColorSwatch from '../ColorPicker/LabeledRuleColorSwatch';

import { getDefaultRule, updateDefaultRuleColors, defaultRuleChecked } from 'ducks/styles';

const DefaultRule = () => {

  const currentDefaultRule = useSelector(getDefaultRule);
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false); // Local state

  const handleCheck = () => {
    setIsChecked(!isChecked);
    dispatch(defaultRuleChecked());
  };

  const handleColorChange = (changes) => {
    dispatch(updateDefaultRuleColors(changes));
  };

  // Checks box by default if current styles have a default rule
  const checked = currentDefaultRule.predicate === 'default';
  
  return (
    <div className="default-rule form-group ">

      <label>
        <input
          type="checkbox"
          name="use-default-color"
          checked={isChecked}
          onChange={handleCheck}
        />
        Default Color:
      </label>

      <LabeledRuleColorSwatch 
        currentColor={currentDefaultRule.fill}
        type="fill"
        handleChange={handleColorChange}
      />
      <LabeledRuleColorSwatch 
        currentColor={currentDefaultRule.line}
        type="line"
        handleChange={handleColorChange}
      />
    </div>
  );
};

export default DefaultRule;
