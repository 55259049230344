//import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, } from 'react-redux';

import withStore from '../withStore';
import { fetchParcelverseDataIfNeeded, getParcelverseData } from 'ducks/parcelverse';

class ParcelverseSearch extends React.Component {
  constructor(props){
    super(props);

    this.state = {
    };

    //this.handleSearch = debounce(this.handleSearch, 200);
  }

  componentDidMount() {
    const { dispatch, countiesKey } = this.props;

    // Start loading the parcelverse data
    dispatch(fetchParcelverseDataIfNeeded(countiesKey));
  }

  componentDidUpdate() {
    if(!this.initialized) {
      this.setupTypeahead();
    }
  }

  setupTypeahead() {
    const { parcelverse, storePath, country } = this.props;

    // Wait until we have the metadata we need
    if(!parcelverse || parcelverse.isLoading) {
      return;
    }

    const data = parcelverse.data;
    const everything = data.states.concat(data.counties);
    const countryFilter = country?.toLowerCase();

    const filteredData = countryFilter
      ? everything.filter(place => place[1].startsWith(`/${countryFilter}/`))
      : everything;
    
    const searchData = filteredData.map(place => ({
      headline: place[0],
      parent:   place[4],
      path:     place[1],
      combined: [place[0], place[4]].join(' ') // combine the two for search
    }));


    // Fuse comes from our main JS package; when we switch 100% to react we'll need
    // to make sure it's in pacjage.json
    /*this.searchEngine = new Fuse(searchData, {
      shouldSort:     true, // Put the best first
      matchAllTokens: true,
      tokenize:       true,
      distance:       6,
      location:       0,
      threshold:      0.2,
      keys:           [{
        name:   'headline',
        weight: 0.9
      }, {
        name:   'parent',
        weight: 0.5
      }, {
        name:   'combined',
        weight: 0.5
      }]
    });*/

    let hound = new Bloodhound({
      identify: function (obj) { return obj.path; },
      datumTokenizer: function(datum) { return Bloodhound.tokenizers.whitespace(datum.combined); },
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      local: searchData,
    });

    //let self = this;
    //let header = "<div class='results'>";
    let sugg = Handlebars.compile("<div class='result'>" + 
      //"<div class='image'><img src='{{path}}/icon.png?size=50'/></div>" +
      "<b>{{headline}}</b>" +
      "{{#if parent}}<br/>{{parent}}{{/if}}</div>");
    //let footer = "</div>";
    
    $('#verse-search').typeahead({ minLength: 2 }, {
      name: 'verse_search',
      /*source: function(query, syncResults) {
        let results = self.searchEngine.search(query);
        syncResults(results);
      },*/
      source:   hound,
      async:    false,
      limit:    33, // Washington County occurs 33 times
      display:  'headline',
      templates: {
        //header:     header,
        suggestion: sugg,
        //footer:     footer,
      }
    }).on('typeahead:selected', function (e, o) {
      debug('selected', o.path);
      window.location = storePath + o.path;
    //}).on('change', function (e) {
    //}).on('keypress', function (e) {
    });

    this.initialized = true;
  }


  /*handleSearch(value) {
    this.setState({
      results: this.searchEngine.search(value)
    });
  }*/

  render() {
    /*const resultList = results.slice(0, 5).map(result => {
      let subhead = null;
      if(result.parent) {
        subhead = <h4 className="lato">{result.parent}</h4>;
      }
      return (<a key={result.path} className="result" href={`/store${result.path}`}>
          <div className="image"><img src={`${result.path}/icon.png?size=100`} alt="" height={50} /></div>
          <div className="title">
            <h3 className="lato">{result.headline}</h3>
            {subhead}
          </div>
        </a>);
    });*/

    return <div className='search'>
      <input
        className='form-control'
        id='verse-search'
        placeholder={this.props.country === 'CA' ? 'Find a province or census division' : 'Find a state or county'}
      />
    </div>;
  }

}

ParcelverseSearch.propTypes = {
  countiesKey: PropTypes.string.isRequired, // cache key for county metadata
  dispatch:    PropTypes.func.isRequired,
  parcelverse:          PropTypes.shape({
    data:      PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  storePath: PropTypes.string.isRequired,
  country:   PropTypes.string // 2-digit country code
};

const mapStateToProps = (state) => {
  return {
    parcelverse: getParcelverseData(state),
  };
};


export default withStore(connect(mapStateToProps)(ParcelverseSearch));