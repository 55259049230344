import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Layer, Source, useMap } from 'react-map-gl'
import mapSettings from '../mapSettings'

// Redux
import { getSatelliteLayerRendered } from 'ducks/mapProperties'
import {
  getActiveProjectRegions,
  activeProjectPresent,
} from 'ducks/projects'
import { get } from 'ducks/api'
import { getWorkingBoundaryPath } from 'ducks/boundaries'

/**
 * This component is responsible for rendering secondary (aka not the primary/main) Focus Areas for the active project
 * For an Active Project, the primary focus area is often the WorkingBoundaryLayer
 *
 * @component
 * @returns {React.Element} Returns a set of React components responsible for displaying the seondary focus areas on the map.
 */
const FocusAreasLayer = () => {
  const { current: map } = useMap()
  const { layerIds, sourceIds, styles, rasterMaxZoom } = mapSettings
  const [layerFeatures, setLayerFeatures] = useState([])
  const hasActiveProject = useSelector(activeProjectPresent)
  const activeProjectRegions = useSelector(getActiveProjectRegions)
  const workingBoundaryPath = useSelector(getWorkingBoundaryPath)
  const isSatellite = useSelector(getSatelliteLayerRendered)
  const selectedColor = isSatellite
    ? styles.satellite.boundaryColor
    : styles.street.boundaryColor

  useEffect(() => {
    if (!hasActiveProject) return
    const focusAreasRegion = activeProjectRegions.regions.find(
      (region) => region.title === 'Focus Areas'
    )

    if (!focusAreasRegion || !focusAreasRegion.list) return

    const projectFocusAreas = focusAreasRegion.list

    const fetchFeatures = (area) => {
      return new Promise((resolve, reject) => {
        const done = (data) => {
          if (data?.outline && data.outline.type === 'Feature') {
            resolve(data.outline)
          } else {
            resolve(null)
          }
        }

        const fail = (error) => {
          console.error(
            'There was a problem getting the focus area geometry:',
            error
          )
          reject(error)
        }

        get(`${area.path}/boundaries.json`, { done, fail })
      })
    }

    Promise.all(projectFocusAreas.map(fetchFeatures))
      .then((features) => {
        const validFeatures = features
          .filter((feature) => feature !== null)
          .map((feature) => ({
            ...feature,
            properties: {
              ...feature.properties,
              isWorkingBoundary: feature.properties.path === workingBoundaryPath,
            },
          }))
        setLayerFeatures(validFeatures)
      })
      .catch((error) => {
        console.error('Error fetching all features:', error)
      })
  }, [hasActiveProject, activeProjectRegions, workingBoundaryPath])

  const { focusAreaColor, focusAreaHoverColor } = styles.focusAreas

  const lineStyle = {
    id: layerIds.focusAreasOutline,
    type: 'line',
    source: sourceIds.focusAreas,
    paint: {
      'line-color': [
        'case',
        ['==', ['get', 'isWorkingBoundary'], true],
        selectedColor,
        [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          focusAreaHoverColor, // If hovered, use `focusAreaHoverColor`
          focusAreaColor       // If not hovered, use `focusAreaColor`
        ]
      ],
      'line-width': 5,
    },
  }

  const fillStyle = {
    id: layerIds.focusAreasFill,
    type: 'fill',
    source: sourceIds.focusAreas,
    maxzoom: rasterMaxZoom,
    paint: {
      'fill-color': [
        'case',
        ['==', ['get', 'isWorkingBoundary'], true],
        'rgba(0,0,0,0)',
        focusAreaColor,
      ],
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        0, // At zoom level 0, opacity is set based on hover state
        ['case', ['boolean', ['feature-state', 'hover'], false], 0, 0.3], // If hovered, 0; otherwise, 0.3
        mapSettings.rasterMaxZoom, // Up to the max zoom level for raster parcels
        0 // Set opacity to 0 for zoom levels above the max
      ],
    },
  }

  let hoverFeatureId = null

    // set hover state
    const handleHover = (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [layerIds.focusAreasFill],
      })
      if (features.length > 0) {
        const focusArea = features[0]
        const feature = {
          source: focusArea.source,
          id: hoverFeatureId,
        }
        // reset hover state
        if (hoverFeatureId !== null) {
          map.setFeatureState(feature, { hover: false })
        }
        // assign id and set new hover state
        hoverFeatureId = focusArea.id
        feature.id = hoverFeatureId
        map.setFeatureState(feature, { hover: true })
      }
    }
  
    // reset hover state
    const handleLeave = (e) => {
      if (hoverFeatureId !== null) {
        const feature = {
          source: sourceIds.focusAreas,
          id: hoverFeatureId,
        }
        map.setFeatureState(feature, { hover: false })
        hoverFeatureId = null
      }
    }

    useEffect(() => {
      const handleZoomEnd = () => {
        map.on('mousemove', [layerIds.focusAreasFill], handleHover)
        map.on('mouseleave', [layerIds.focusAreasFill], handleLeave)
      }
  
      map.on('load', handleZoomEnd)
      map.on('zoomend', handleZoomEnd)
  
      return () => {
        map.off('load', handleZoomEnd)
        map.off('zoomend', handleZoomEnd)
      }
    }, [map])

  const featureCollection = {
    type: 'FeatureCollection',
    features: layerFeatures,
  }

  return (
    <>
      <Source
        id={mapSettings.sourceIds.focusAreas}
        type="geojson"
        data={featureCollection}
      >
        <Layer {...fillStyle} />
        <Layer {...lineStyle} />
      </Source>
    </>
  )
}

export default FocusAreasLayer
