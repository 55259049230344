const passGLMapEventToFlight = (name, opts, nodeTarget = 'mapboxgl-map') => {
  let event = new CustomEvent('glMapEvent', {
    detail: {
      name: name,
      options: opts,
    },
  })

  // quick and dirty way to make sure that the map exists in the dom before dispatching the event
  // possible better alternatives: use MutationObserver or setup a function that returns a Promise whichs resolves when the map is available.
  const maxRetries = 10
  let retryCount = 0

  const dispatchEventToNode = () => {
    const node = document.getElementById(nodeTarget)
    if (node) {
      node.dispatchEvent(event)
    } else if (retryCount < maxRetries) {
      retryCount++ // Increment the retry counter
      setTimeout(dispatchEventToNode, 100)
    } else {
      console.error(`Failed to find #mapboxgl-map after ${maxRetries} retries.`)
    }
  }

  dispatchEventToNode()
}

export default passGLMapEventToFlight
