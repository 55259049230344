import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'

const options = {
  squareFeet:   'Square Feet',
  acres:        'Acres',
  squareMeters: 'Square Meters',
  hectares:     'Hectares',
}

const UnitSelectorDropdown = ({ unitValue, unit, onUnitChange }) => {
  const [selectedUnit, setSelectedUnit] = useState('acres')
  const [isSelectVisible, setIsSelectVisible] = useState(false) // Toggle visibility of the <select>

  useEffect(() => {
    if(unit && options[unit]) {
      setSelectedUnit(unit)
    }
  }, [unit])

  const handleSelect = (event) => {
    const key = event.target.value
    setSelectedUnit(key)
    onUnitChange({ unitValue, unit: key })
    setIsSelectVisible(false) // Hide the <select> after selection
  }

  const handleCogClick = () => {
    setIsSelectVisible((prev) => !prev) // Toggle dropdown visibility
  }

  return (
    <div id="conversion">
      {/* Conversion Value */}
      <span className="conversion-value">{numberWithCommas(unitValue)} {options[selectedUnit]}</span>

      {/* Cog Icon */}
      <button
        data-tip="Change measurement units"
        className="dropdown-toggle btn btn-default btn-round margin-left-md"
        data-for="measurements-tool-tip"
        onClick={handleCogClick}
      >
        <i className="fas fa-cog" />
        <i
          className={`fas fa-caret-down small`}
          style={{
            transition: 'transform 0.3s',
            transform:  isSelectVisible ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
        <ReactTooltip
          id="measurements-tool-tip"
          place="top"
          effect="solid"
          delayHide={1000}
          globalEventOff="click"
        />
      </button>

      {/* Conditionally Rendered Select Dropdown */}
      {isSelectVisible && (
        <select
          className="conversion-select form-control"
          value={selectedUnit}
          onChange={handleSelect}
        >
          {Object.entries(options).map(([key, label]) => (
            <option key={key} value={key}>
              {label}
            </option>
          ))}
        </select>
      )}
    </div>
  )
}

UnitSelectorDropdown.propTypes = {
  unitValue:    PropTypes.number,
  unit:         PropTypes.string,
  onUnitChange: PropTypes.func,
}

export default UnitSelectorDropdown