import React from 'react'
import { useSelector } from 'react-redux'
import { activeProjectPresent, getActiveProjectHeadline, getActiveProjectName, getActiveProjectRootPath } from '../../ducks/projects'
import withStore from '../withStore'
import passGLMapEventToFlight from '../../common/utils/passGLMapEventToFlight'


// not sure the best name for this, but it's the Profile/Project thing on the top of the sidebar.

const ProfileItem = ({inGroup, group, inFree, currentUser}) => {
  const hasActiveProject = useSelector(activeProjectPresent)
  const activeProjectName = useSelector(getActiveProjectName)
  const placeHeadline = useSelector(getActiveProjectHeadline)

  const showProjectSettings = (e) => {
    passGLMapEventToFlight('pane:request:show', {tab: '#settings'})
  }

  return (
    <li id="profile" className="rule margin-btm-md">
      {inGroup ? (
        <a
          id="profile-link"
          className="margin-btm-md"
          href="#settings"
          data-skip-pjax="1"
          data-upsell="settings"
          data-tip="project-settings"
          data-tier="pro"
          onClick={showProjectSettings}
        >
          <div className="flex-col-start width-100">
            {group && !hasActiveProject && (
              <div className="flex-row-start margin-btm-sm truncate">
                <span className="text bold truncate">{group.name}</span>
              </div>
            )}
            {group && !hasActiveProject && (
              <div className="flex-row-start truncate">
                <i className="fa-solid fa-folder margin-right-md"></i>
                <span className="text">Projects</span>
              </div>
            )}
            {(inGroup || inFree) && hasActiveProject && (
              <>
                <div className="flex-row-start margin-btm-sm truncate">
                  <i className="fa-solid fa-folder margin-right-md"></i>
                  <span className="text bold truncate" title={activeProjectName}>
                    {activeProjectName}
                  </span>
                </div>
                <div className="flex-row-start truncate">
                  <span
                    className="xsmall bold text truncate"
                    title={placeHeadline}
                  >
                    {placeHeadline}
                  </span>
                </div>
              </>
            )}
            {(inGroup || inFree) && !group?.name && !hasActiveProject && (
              <div className="flex-row-start truncate">
                <i className="fa-solid fa-folder margin-right-md"></i>
                <span className="truncate">{activeProjectName || 'Projects'}</span>
              </div>
            )}
          </div>
        </a>
      ) : !inGroup && currentUser ? (
        <a
          id="profile-link"
          className="margin-btm-md bold signup"
          href="#upgrade"
          data-skip-pjax="1"
        >
          <p className="my-auto">Upgrade to Pro</p>
        </a>
      ) : (
        <a
          id="profile-link"
          className="margin-btm-md bold signup"
          href="#signup-signin"
          data-skip-pjax="1"
        >
          <p className="my-auto">Sign Up</p>
        </a>
      )}
    </li>
  )
}

export default withStore(ProfileItem)
