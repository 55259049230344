import mapSettings from "../../mapSettings";

const { layerIds, countries } = mapSettings;

export const floatLayers = [
  {
    id: 'float-owner',
    name: 'Owner Names',
    mapId: layerIds.parcelOwnerLabels,
    description: null,
    field: 'owner',
    sourceLayer: 'parcels',
    country: [countries.US],
  },
  {
    id: 'float-parcel',
    name: 'Parcel Numbers',
    mapId: layerIds.parcelNumberLabels,
    description: null,
    field: 'parcelnumb',
    sourceLayer: 'parcels',
    country: [countries.US, countries.CA],
  },
  {
    id: 'float-dimensions-ft',
    name: 'Parcel Dimensions (ft)',
    mapId: layerIds.parcelDimensions,
    description: null,
    field: '',
    sourceLayer: 'midpoints-source',
    country: [countries.US, countries.CA],
  },
  {
    id: 'float-dimensions-m',
    name: 'Parcel Dimensions (m)',
    mapId: layerIds.parcelDimensions,
    description: null,
    field: '',
    sourceLayer: 'midpoints-source',
    country: [countries.US, countries.CA],
  },
]
