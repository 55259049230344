import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { baseLayers } from '../Layers/thirdPartyLayers'
import classNames from 'common/utils/classNames'
import withStore from '../../withStore'
import satelliteImg from '@/assets/satellite-esri'
import satellite2Img from '@/assets/satellite'
import streetImg from '@/assets/street-esri'
import InfoTooltip from 'components/Utilities/InfoTooltip';

// Redux
import { setBaseLayerType, getBaseLayerType } from 'ducks/mapProperties'

const BaseLayersListItem = ({
  layer,
  layerImg,
  handleClose,
}) => {
  const activeBaseLayer = useSelector(getBaseLayerType)
  const layerIsActive = activeBaseLayer === layer.id
  const [hovered, setHovered] = useState(false)
  const dispatch = useDispatch()

  const handleLayerClick = (e) => {
    e.stopPropagation()
    // If layer is not active, turn it on
    if (!layerIsActive) {
      dispatch(setBaseLayerType(layer.id))
    }
    handleClose()
  }

  const layerClasses = classNames(
    'layer-tile',
    'modal-tile',
    'baselayer-tile', // distinguish overlay differences with other tiles
    'shadow-gl-light',
    {
      active: layerIsActive,
    }
  )

  const iconClasses = classNames('icon-overlay', {
    active: layerIsActive,
    hover: hovered, // add hover class when hovered
  })

  const tooltipDescription = `
    <p>${layer.description}</p>
    <p>Sources:</p>
    <ul class="simple">
      ${layer.sources
        .map(
          ({ source, doc }) =>
            `<li><a href="${doc}" target="_blank">${source}</a></li>`
        )
        .join('')}
    </ul>
  `

  return (
    <div
      id={`layer-${layer.id}`}
      className={layerClasses + (layerIsActive || hovered ? ' plan-color-glow' : '')}
      onClick={handleLayerClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
    >
      <div className="modal-tile-img">
        <img src={layerImg} alt={layer.name} />
        <div className={iconClasses}>
          {!layerIsActive && hovered && <i className="fa fa-plus"></i>}
        </div>
      </div>
      <div
        className={`modal-tile-detail ${
          layerIsActive ? 'plan-color-accent white' : ''
        }`}
      >
        <div className="bold md-lg">
          {layer.name}
          <div className="tooltip-parent">
            <InfoTooltip
              id={`layer-info-${layer.id}`}
              tip={tooltipDescription}
              html={true}
              delayHide={1000}
              clickable={true}
              circleSize="fa-sm"
              place="top"
              effect="solid"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

/**
 * This component allows users to toggle between different base layer types on the map.
 * The currently active base layer type is stored in the Redux state.
 *
 * @component
 * @returns {React.Element} Returns a div element that contains the base layer toggle control.
 */
const BaseLayersList = ({ handleClose }) => {
  const images = {
    street: streetImg,
    satellite: satelliteImg,
    satellite2: satellite2Img,
  }

  return (
    <div className="modal-tile-wrapper" id="base-layers">
      {baseLayers.map((layer) => {
        // Get the image for this layer, or use the default image
        const layerImg = images[layer.id] || ''

        return (
          <BaseLayersListItem
            key={`layer-item-${layer.id}`}
            layer={layer}
            layerImg={layerImg}
            handleClose={handleClose}
          />
        )
      })}
    </div>
  )
}

export default withStore(BaseLayersList)
