import { isPlainObject } from 'lodash';

// Used to updated a user's preferences on the react side of things.
// We should probably create a user reducer at some point and move
// this and most of window.data into it.

// Pass an object in with any relevant preferences e.g. { show_premium: true }
export const setUserPreference = (preference) => {
  if(!preference || !isPlainObject(preference)) {
    debug("setUserPreference: Sorry, I need an object to set a user's preference");
    return;
  }

// Can only save for folks logged in
 if(!window.data.current_user_id) {
   return;
 }

  fetch('/preferences.json', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data: preference })
  })
    .then(res => res.json())
    .then(response => {
      if(response.status === 'ok') {
        debug(`setUserPreference: Successfully set user's preference: ${response.data}`);
        return true;
      }
    })
    .catch(err => {
      debug(`setUserPreference: Failed to save user's preference: ${err}`);
    });
};

// Pass a string of the key for the preference
export const getUsersPreference = async (key) => {

  if(!key) {
    debug("getUserPreference: Sorry, I need a key to fetch a user's preference");
    return;
  }
 
 // Can only save for folks logged in
  if(!window.data.current_user_id) {
    return;
  }
 
  const preferences = await fetch(`/preferences.json?key=${key}`)
  const response = await preferences.json()
  if(response.status === 'ok') {
    debug(`getUserPreference: Successfully got user's preference: key=${response.key}, value=${response.value}`);
    return response.value;
  } else {
    debug("getUserPreference: Failed to get user's preference")
    return;
  }
};