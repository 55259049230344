import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import withStore from 'components/withStore'
import classNames from 'common/utils/classNames'

// Components
import InfoTooltip from 'components/Utilities/InfoTooltip'

// Redux
import { setThirdPartyLayer, getThirdPartyLayer } from 'ducks/mapProperties'

/**
 * This component is responsible for rendering a single layer item in the list.
 * It handles the click event of the layer item and active and hover states.
 *
 * @component
 * @param {object} layer - The layer object passed as a prop, sourced as the default array in thirdPartyLayers.js
 * @param {string} layerImg - The image url for the layer passed as a prop
 * @param {boolean} isPaidUser - Determines if the user is a paid account 
 * @param {boolean} softDisabled - Determines if the layer is clickable with low opacity
 * @returns {React.Element} Returns a div element that represents a single layer item
 */

const LayersListItem = ({ layer, layerImg, isPaidUser, softDisabled }) => {
  const [hovered, setHovered] = useState(false)
  const currentActiveLayer = useSelector(getThirdPartyLayer)
  const layerIsActive = currentActiveLayer === layer.id
  const dispatch = useDispatch()

  const handleLayerClick = (e) => {
    e.stopPropagation()

    if (layerIsActive) {
      // If layer is active, turn it off
      dispatch(setThirdPartyLayer(null))
    } else {
      // If layer is not active, turn it on
      dispatch(setThirdPartyLayer(layer.id))
    }
  }

  const layerClasses = classNames('layer-tile', 'modal-tile', 'shadow-gl-light', {
    active: layerIsActive,
    disabled: !isPaidUser,
    'disabled-product': softDisabled && isPaidUser && layerIsActive, 
    'hide': softDisabled && !layerIsActive, 
  })

  const iconClasses = classNames('icon-overlay', {
    active: layerIsActive,
    hover: hovered, // add hover class when hovered
  })

  const tooltipDescription = `<p>${layer.description}</p><p>Source: <a href="${layer.docs}" target="_blank">${layer.source}</a></p>`

  return (
    <div
      id={`layer-${layer.id}`}
      className={
        layerClasses + (layerIsActive || hovered ? ' plan-color-glow' : '')
      }
      onClick={handleLayerClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
    >
      <div className="modal-tile-img">
        <img src={layerImg} alt={layer.name} />
        <div className={iconClasses}>
          {!layerIsActive && hovered && <i className="fa fa-plus"></i>}
          {layerIsActive && hovered && <i className="fa fa-times"></i>}
        </div>
      </div>
      <div
        className={`modal-tile-detail ${
          layerIsActive ? 'plan-color-accent white' : ''
        }`}
      >
        <div className="bold md-lg responsive-font">
          {layer.name}
          <div className="tooltip-parent">
            <InfoTooltip
              id={`layer-info-${layer.id}`}
              tip={tooltipDescription}
              html={true}
              delayHide={1000}
              clickable={true}
              circleSize="fa-sm"
              place="top"
              effect="solid"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

LayersListItem.propTypes = {
  layer: PropTypes.object.isRequired,
}

export default withStore(LayersListItem)
