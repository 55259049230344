import React from 'react';

const IssueAlert = () => {

  return (
    <p style={{ marginTop: '1em' }} className="issues alert alert-warning hide"></p>
  );
};

export default IssueAlert;
