import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { PLANS } from 'common/settings'

class EnsureUser extends Component {
  constructor(props) {
    super(props)

    this.state = {
      action:      'register', // or 'sign_in'
      name:        props.user && props.user.name || '',
      email:       props.user && props.user.email || '',
      password:    '',
      jobTitle:   '',
      industry:    '',
      agree:       false,
      wantsEmail:  true,
      error:       null,
      submitting:  false,
      reqMessage:  'required information missing - please fill out to proceed',
      showPasswordHelper: false,
    }

    this.handlers = {
      signIn:            this.onClickSignIn.bind(this),
      register:          this.onClickRegister.bind(this),
      toggle:            this.onClickToggle.bind(this),
      changeName:        this.onChangeName.bind(this),
      changeEmail:       this.onChangeEmail.bind(this),
      changePassword:    this.onChangePassword.bind(this),
      changeJobTitle:    this.onChangeJobTitle.bind(this),
      changeIndustry:    this.onChangeIndustry.bind(this),
      changeAgree:       this.onChangeAgree.bind(this),
      changeWantsEmail:  this.onChangeWantsEmail.bind(this),
    }
  }
  
  render() {
    const {
      user,
      onSignedIn,
      selectedPlan,
      currentPlan 
    } = this.props;
    const industries = [
      "Academia",
      "Agriculture",
      "Automotive",
      "Construction/Architecture",
      "Education",
      "Environmental Consulting",
      "Engineering",
      "Finance & Capital Markets",
      "Government - Federal",
      "Government - Local",
      "Government - State",
      "Insurance",
      "Legal",
      "NPO - Telecommunications",
      "NPO/NGO - Land Conservancies",
      "NPO/NGO - Community Orgs",
      "NPO/NGO - Disaster Relief",
      "Oil/Gas/Mining",
      "Outdoor Recreation",
      "Real Estate - Residential",
      "Real Estate - Commercial",
      "Renewable Energy",
      "Retail",
      "Technology",
      "Telecom/Broadband",
      "Transportation & Logistics",
      "Utilities",
      "Other",
  ];
    
    let isStarter = (selectedPlan == PLANS.FREE);  

    return <div className='user-account'>
      <h3>Your Regrid Account</h3>

      <p>{ user 
        ? "You are signed in." 
        : <><span className='bold'>
          { isStarter  
            ? "Step 1 of 1: "
            : "Step 1 of 2: "
          }
          </span> Please create an account or
          {this.state.action === 'register' 
            ? <> <a href='#' onClick={e => { this.handlers.toggle(e, 'sign_in') }}>sign in</a> </> 
            : <> sign in </>
          }
          to get started. 
          { isStarter
            ? ""
            : "You'll select the plan details like monthly or annual billing in the next step."
          }
          </>}
      </p>
      {this.state.error && <p className='alert alert-warning'>{this.state.error}</p>}

      <div className='form-vertical'>
        {( user || this.state.action === 'register') && <div className='form-group'>
          <label>Your name*</label>
          <input type='text'
            name='name'
            className='form-control'
            value={this.state.name}
            onChange={this.handlers.changeName}
            disabled={user}
          />
        </div>}

        <div className='form-group'>
          <label>Email address*</label>
          <input type='text'
            name='email'
            value={this.state.email}
            className='form-control'
            onChange={this.handlers.changeEmail}
            disabled={user}
          />
        </div>

        {!user && <>
          <div className='form-group'>
            <label>Password*</label>
            <input type='password'
              name='password'
              value={this.state.password}
              className='form-control'
              onChange={this.handlers.changePassword}
              disabled={user}
            />
            { this.state.showPasswordHelper && (
              <small className="error">
                Must contain at least one number or symbol and at least 5 or more characters
              </small>
            ) }
          </div>
          {this.state.action === 'register' &&
            <>
            <div className='form-group'>
              <label>Company Name & Job Title</label>
              <input type='text'
                name='jobTitle'
                value={this.state.jobTitle}
                className='form-control'
                onChange={this.handlers.changeJobTitle}
              />
            </div>
            <div className='form-group'>
              <label>Industry</label>
              <select type='text'
                name='industry'
                value={this.state.industry}
                className='form-control'
                onChange={this.handlers.changeIndustry}
              >
                    <option value="" disabled>
                        Select
                    </option>
                  {industries.map((role, index) => (
                        <option key={index} value={role}>
                            {role}
                        </option>
                    ))}
                </select>
            </div>
            </>}

          <div className='form-group'>
            <div className="small subtle">*required</div>
          </div>

          {this.state.action === 'register' && <div className='form-group'>
            <label>
              <input type='checkbox' onChange={this.handlers.changeWantsEmail} checked={this.state.wantsEmail} />{' '}
              May we send you account updates, tips and occasional newsletters?
            </label>
            <label>
              <input type='checkbox' onChange={this.handlers.changeAgree} checked={this.state.agree}/>{' '}
              You agree to the Loveland <a href='/hs/terms' target='agreement' data-skip-pjax='1'>Terms of Use</a> and{' '}
              <a href='/hs/terms/privacy' target='agreement' data-skip-pjax='1'>Privacy Policy</a>
            </label>
          </div>}

          <p className='centered' style={{ marginBottom: '2em' }}>
            {this.state.action === 'register' ?
              <a href='#' onClick={e => { this.handlers.toggle(e, 'sign_in') }}>Already have an account?</a> :
              <a href='#' onClick={e => { this.handlers.toggle(e, 'register') }}>Need to create an account?</a>}
          </p>

          <div className='form-group centered'>
            {this.state.action === 'register' ?
              <a href='#'
                className='btn btn-lg btn-primary btn-round'
                onClick={this.handlers.register}
                title={!this.validRegister() ? this.state.reqMessage : ""}
                disabled={this.state.submitting || !this.validRegister()}>
                Create Account</a> :
              <a href='#'
                className='btn btn-lg btn-primary btn-round'
                onClick={this.handlers.signIn}
                title={!this.validSignIn() ? this.state.reqMessage : ""}
                disabled={this.state.submitting || !this.validSignIn()}>
                Sign In</a>}
          </div>
        </>}
      </div>
    </div>
  }


  onClickSignIn(e) {
    e.preventDefault()
    if(!this.validSignIn()) { return }

    let self = this
    let opts = {
      email:    this.state.email,
      password: this.state.password,
      via:      'plans',
    }

    this.setState({ submitting: true })

    LL.Ajax.postSpin('/users/sign_in.json', opts).then(response => {
      self.setState({ error: null })
      self.props.onSignedIn(response)
      if (response.redirect_url) {
        window.location.href = response.redirect_url;
      }
    }).catch(msg => {
      self.setState({ error: msg, submitting: false })
    })
  }

  onClickRegister(e) {
    e.preventDefault()
    if (!this.validRegister()) { return }
    if(this.state.submitting) { return }
    let selectedPlan = this.props.selectedPlan;
    let isStarter = (selectedPlan == PLANS.FREE)

    let self = this
    let opts = {
      user: {
        name:     this.state.name,
        email:    this.state.email,
        password: this.state.password,
        preferences: {
          profile_answers: {
            job_title: this.state.jobTitle,
            industry: this.state.industry,
          },
          source:     window.location.pathname,
          created_at: new Date(),
        },
      },
      flow: isStarter ? 'free' : 'paid',
      via: 'plans',
      plan: selectedPlan,
      wants_email: this.state.wantsEmail ? '1' : '0',
    }

    this.setState({ submitting: true })

    LL.Ajax.postSpin('/users.json', opts).then(response => {
      self.setState({ error: null, submitting: false })
      self.props.onSignedIn(response)
    }).catch(msg => {
      self.setState({ error: msg, submitting: false })
    })
  }

  validSignIn() {
    return (validPassword(this.state.password) || this.state.action == 'sign_in') && validEmail(this.state.email)
  }

  validRegister() {
    return this.validSignIn() && this.state.name.length >= 2 && this.state.agree
  }


  onChangeName(e) {
    this.setState({ name: e.target.value })
  }

  onChangeEmail(e) {
    this.setState({ email: e.target.value })
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value })
    this.setState({ showPasswordHelper: this.state.action === 'register' && !validPassword(e.target.value) })
  }

  onChangeJobTitle(e) {
    this.setState({ jobTitle: e.target.value })
  }

  onChangeIndustry(e) {
    this.setState({ industry: e.target.value })
  }

  onClickToggle(e, action) {
    e.preventDefault()
    this.setState({ action: action })
  }

  onChangeAgree(e) {
    this.setState({ agree: e.target.checked })
  }

  onChangeWantsEmail(e) {
    this.setState({ wantsEmail: e.target.checked })
  }
}


EnsureUser.propTypes = {
  user:         PropTypes.object,
  onSignedIn:   PropTypes.func.isRequired,
  selectedPlan: PropTypes.string.isRequired,
}


export default EnsureUser
