import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { fetchCartIfNeeded, getCart } from 'ducks/cart';
import { useDispatch, useSelector } from 'react-redux';

import withStore from '../withStore';

const CartButton = ({path}) => {
  const cart = useSelector(getCart);
  const dispatch = useDispatch();

  // Check if this county is already in your cart
  useEffect(() => {
    dispatch(fetchCartIfNeeded());
  });

  let cartPath = '/orders/cart';
  if(path) {
    cartPath += `?from=${path}`;
  }

  const itemsInCart = Object.keys(cart.items).length;
  let count = '';
  if(itemsInCart > 0) {
    count = `${itemsInCart}`;
  }


  return  <a className={"btn map-control btn-primary cart-button "+(itemsInCart > 0 ? 'active' : '')} href={cartPath}>
      <span className='fa-stack fa-lg'>
        <i className="fas fa-shopping-cart fa-stack-2x"></i>
        <i className='fas fa-stack-2x fa-inverse count'>{count}</i>
      </span>
    </a>;
};

CartButton.propTypes = {
  path: PropTypes.string,
};

export default withStore(CartButton);