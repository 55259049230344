import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PlanPriceButton from 'components/Payment/PlanPriceButton'
import { apiSandboxData } from './plansUtil.js'

const PlanSelector = (props) => {
  const isApi = props?.showApi || false
  const plans = isApi ? [apiSandboxData, ...props.plans] : props.plans
  const currentApiPlan = props.currentApiPlan || null
  const showTrial = !currentApiPlan || currentApiPlan === 'Sandbox'
  const expiredTrial = props.sandboxUser && currentApiPlan !== 'Sandbox'

  let btnElements = _.map(plans, (plan, i) => {
    return (
      <PlanPriceButton
        key={plan.plan}
        {...plan}
        onClickPlan={props.onClickPlan}
        currentApiPlan={currentApiPlan}
        isCurrent={
          props.currentPlan === plan.plan || currentApiPlan === plan.name
        }
        isSelected={props.selectedPlan === plan.plan}
        isApi={isApi}
        offer={props.offer}
        registeredUser={props.registeredUser}
        hasPaidAccount={props.hasPaidAccount}
        expiredTrial={expiredTrial}
      />
    )
  })

  const handlePlanClick = (name) => {
    let apiTiersEle = document.getElementById('api-tiers')
    let plansEle = document.getElementById('plan-options')
    let scrollTo = apiTiersEle || plansEle
    debug('handlePlanClick name: ', name)
    if (name === 'San') { return }
    if (isApi && currentApiPlan && currentApiPlan.slice(0, 3) !== name) {
      // api user is switching plans, don't scroll
      return
    }
    if (scrollTo) {
      scrollTo.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }

    let planBtn = document.querySelector(`div.${name} a`)
    planBtn.click()
  }

  return (
    <div className={`plans${isApi ? ' api-plans' : ''}`}>
      {isApi && (
        <h2 className="h2 bold centered margin-btm-lg">
          Self-Serve Parcel Data API Plans That Scale
        </h2>
      )}
      <div className={`plans-in${isApi ? ' api-plans-cards' : ''}`}>
        {_.map(plans, (plan, i) => {
          if (!showTrial && plan?.name === 'Sandbox') {
            return null // don't show sandbox if user has a paid api account
          }
          const isCurrent =
            props.currentPlan === plan?.plan || currentApiPlan === plan?.name

          return (
            <div
              className={
                `plan grow plan-${plan.name
                  .replace(/ .*/, '')
                  .toLowerCase()} margin-btm-md` +
                (isCurrent ? ' not-allowed' : '')
              }
              key={i + 1}
              onClick={() =>
                isCurrent ? undefined : handlePlanClick(plan.name.slice(0, 3))
              }
            >
              <div className="info-text">
                <h3
                  className={`margin-top-none margin-btm-md ${
                    isApi ? '' : 'centered'
                  }`}
                >
                  {plan.display_name ? plan.display_name : plan.name}
                </h3>
                {_.map(plan.description, (description, j) => (
                  <p
                    className="desc"
                    key={j + 1}
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                ))}
              </div>

              <div className="info mobile-only">{btnElements[i]}</div>
              <div className="info nomobile">
                <div className={plan.name.slice(0, 3)} key={i}>
                  {btnElements[i]}
                </div>
              </div>
              {isApi && (
                <div className="info-list margin-top-md">
                  <p className="bold">What's Included:</p>
                  {plan.included.map((item, idx) => {
                    return (
                      <div key={idx} className="api-included">
                        <i className="fas fa-check-circle margin-right-lg" />
                        <p>{item}</p>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

PlanSelector.propTypes = {
  plans:        PropTypes.array.isRequired,
  onClickPlan:  PropTypes.func.isRequired,
  onClickCycle: PropTypes.func.isRequired,
  selectedPlan: PropTypes.string,
  currentPlan:  PropTypes.string,
  currentCycle: PropTypes.string,
}

export default PlanSelector
