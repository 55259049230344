import React from 'react';

const FaqAccordion = () => {
  const faqItems = [
    {
      question: 'What if I run out of parcel records in a month?',
      answer: 'Good news is that you don’t run out of parcel records per month. Your plan is preloaded with 2,000 parcel records. If you reach your 2,000 parcel records, your plan then bills each additional parcel record retrieved.',
    },
    {
      question: 'Can I set a maximum amount of parcel records for each month?',
      answer: 'Yes, you can set a usage cap maximum for the amount of parcel records your plan retrieves each month. This is a best practice approach to control your monthly billing.',
    },
    {
      question: 'How much does it cost to cancel a subscription?',
      answer: 'There are no cancellation fees for any Self-Serve subscription. If you choose to cancel your subscription, your cancellation will be effective at the end of the billing cycle. You are able to restart your subscription at any time in the future. Please note that we do not offer any pause feature for subscription plans.',
    },
    {
      question: 'Do I receive technical support for Self-Serve plans?',
      answer: 'Self-Serve plans are not supported for dedicated technical support. If you have a general question regarding the plan or data, you can reach us through help@regrid.com. However if you need assistance with setting up your technical stack to use an API, we do not offer support for Self-Serve plans.',
    },
    {
      question: 'Can I get an extension after the 30 days have expired?',
      answer: 'The API trial token has a maximum of 30 days and does not have the option for extension after the expiration. However you can continue your access with upgrading to a Self Serve API plan where we offer two options between Standard data or Premium data.',
    },
  ];

  return (
    <div className="container" id="plan-faq">
      <div className="row mx-auto margin-btm-xl">
        <div className="col-md-12">
          <h2 className="centered margin-xl">Frequently asked questions</h2>

          <div className="panel-group" id="faq-accordion" role="tablist" aria-multiselectable="false">
            {faqItems.map((item, index) => (
              <div className="panel panel-default" key={index}>
                <div className="panel-heading" role="tab" id={`faq-question-${index}`}>
                  <h3 className="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      className="accordion-toggle collapsed"
                      data-parent="#faq-accordion"
                      href={`#faq-answer-${index}`}
                      aria-expanded="false"
                      aria-controls={`faq-answer-${index}`}
                    >
                      {item.question}
                    </a>
                  </h3>
                </div>
                <div id={`faq-answer-${index}`} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`faq-question-${index}`}>
                  <div className="panel-body">
                    {item.answer}
                  </div>
                </div>

                <hr />

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqAccordion;