import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames  from 'common/utils/classNames';
import ReactTooltip from 'react-tooltip';

import { applyActiveRules, resetRules, setExistingRules, allStylesValid} from 'ducks/styles';
import { activeProjectPresent } from 'ducks/projects';

const ApplyResetBtns = () => {

  const dispatch = useDispatch();
  const projectPresent = useSelector(activeProjectPresent);
  const allStylesAreValid = useSelector(allStylesValid);


  const handleApply = () => {
    dispatch(applyActiveRules());
  };

  const handleReset = () => {
    projectPresent ? dispatch(setExistingRules()) : dispatch(resetRules());
  };

  const applyBtnClasses = classNames(
    'apply-styles',
    'btn',
    'btn-primary',
    'btn-block',
    'btn-round',
    // Disable Apply button if not all styles are valid
    { 'disabled': !allStylesAreValid }
  );

  return (
    <div className="apply-reset-btn-container centered row padded margin-top-md">
      <div
         // Settings for tooltip
         data-tip='All style rules must have completed fields to Apply'
         data-for='apply-btn-tooltip'
         data-type='error'
         data-event='mouseover'
         data-event-off='mouseout'
         data-tip-disable={allStylesAreValid}
      >
        <a className={applyBtnClasses} onClick={handleApply}>
          Apply       
        </a>
      </div>
      <ReactTooltip id='apply-btn-tooltip' />
      <a className="btn btn-default btn-block btn-round clear width-50" onClick={handleReset}>Reset</a>
    </div>
  );
};

export default ApplyResetBtns;
