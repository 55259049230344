// some utility functions that replicate behavior of the JQuery BBQ plugin with native History API
// These functions get and set the keys/values found in the hash portion of the url, e.g. #b=zips&t=property
// See https://benalman.com/code/projects/jquery-bbq/docs/files/jquery-ba-bbq-js.html for original functions
// TODO: make sure functionality of the BBQ plugin is entirely covered.

/**
 * Helper function to coerce types
 *
 * @param {string} value value of a key/value pair found in url hash
 * @returns {String|Number|Boolean|null|undefined}
 * */

function coerceType(value) {
  if (value === 'true') return true
  if (value === 'false') return false
  if (value === 'null') return null
  if (value === 'undefined') return undefined
  if (!isNaN(value)) return Number(value)
  return value
}

/**
 * get value of specific parameter in hash fragment (like $.bbq.getState)
 * Returns entire hash object if no key
 *
 * @param {string} key key of a key/value pair found in url hash
 * @returns {*}
 * */
export const getHashState = (key) => {
  const fragment = window.location.hash.substring(1)
  const urlParams = new URLSearchParams(fragment)
  if (key) {
    return coerceType(urlParams.get(key))
  } else {
    let paramsObj = {}
    for (let pair of urlParams.entries()) {
      paramsObj[pair[0]] = coerceType(pair[1])
    }
    return paramsObj
  }
}

/**
 * Remove one or more key/value pairs from url hash. equivalent of $.bbq.removeState
 * @param {String[]} keys array of keys
 */

export const removeHashState = (...keys) => {
  const oldFragment = window.location.hash
  const fragment = oldFragment.substring(1) // Remove the '#' character
  const urlParams = new URLSearchParams(fragment)

  keys.forEach((key) => urlParams.delete(key))

  const newFragment = `#${urlParams.toString()}`

  // Only set the new hash and trigger a hashchange event if the new state is different from the old state
  if (oldFragment !== newFragment) {
    window.location.hash = newFragment
    let hashChangeEvent = new CustomEvent('hashchange')
    window.dispatchEvent(hashChangeEvent)
  }
}

/**
 * Updates the URL hash and dispatches a hashchange event. This function can accept either a string 
 * or an object of key-value pairs. If an object is provided, it is serialized into a hash string.
 * If a string is provided, it directly sets the hash. The function also merges new parameters 
 * with existing ones in the hash.
 * 
 * @param {String|Object} newHash - A string representing the new hash or an object of key-value 
 *                                  pairs to be serialized and merged into the hash.
 * @example
 * // Update hash with a string
 * pushHashState('section=contact&details=true');
 * 
 * @example
 * // Update hash with an object
 * pushHashState({ section: 'contact', details: true });
 */
export const pushHashState = (newHash) => {
  const serializeHash = (obj) => {
    return Object.keys(obj)
      .filter(key => obj[key] !== undefined) // Filter out undefined values
      .map(key => `${key}=${obj[key]}`)
      .join('&');
  };

  const parseHash = (hash) => {
    if (!hash) return {}; // Return an empty object if hash is empty
    let result = {};
    let u = new URLSearchParams(hash.replace(/^#/, ''));
    for (const [key, value] of u) { result[key] = value; }
    return result;
  };

  const oldHash = window.location.hash.substring(1); // Remove the # character
  let finalHash;

  if (typeof newHash === 'object') {
    const oldParams = parseHash(oldHash);
    const newParams = { ...oldParams, ...newHash };
    finalHash = serializeHash(newParams);
  } else {
    finalHash = newHash;
  }

  if (oldHash !== finalHash) {
    window.location.hash = finalHash;
    let hashChangeEvent = new CustomEvent('hashchange');
    window.dispatchEvent(hashChangeEvent);
  }
};

