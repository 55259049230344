import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Layer, Source } from 'react-map-gl'
import mapSettings from '../mapSettings'

// Redux
import { getBuildingsLayerRendered, getVectorLayerRendered } from 'ducks/mapProperties'

const BuildingsLayer = () => {

  const [layer, setLayer] = useState(null)
  const buildingsLayerActive = useSelector(getBuildingsLayerRendered)
  const vectorLayerRendered = useSelector(getVectorLayerRendered)
  // Should we render layer (toggle on + in Vector range)
  const renderLayer = buildingsLayerActive && vectorLayerRendered

  // Create a custom raster tileserver layer
  useEffect(() => {
    const layerDefinition = {
      query:  { buildings: true },
      styles: `
      Map { background-color: rgba(0,0,0,0); } 
      #loveland { 
        line-color: ${mapSettings.styles.buildings.buildingsLineColor}; line-width: 1; line-opacity: 0.2;
        [zoom >= 13] { line-opacity: 0.4; }
        [zoom >= 14] { line-opacity: 0.6; }
        [zoom >= 15] { line-width: 1.5; line-opacity: 0.8; polygon-fill: ${mapSettings.styles.buildings.buildingsFillColor}; polygon-opacity: 0.05; }
        [zoom >= 16] { line-width: 1.7; line-opacity: 1; polygon-opacity: 0.1; }
        [zoom >= 17] { ine-width: 2; polygon-opacity: 0.15; } [zoom >= 18] { line-width: 2.5; }
      }`
    }
    // Create the layer
    fetch(
      `${window.data.tileserver}/api/v1/sources?userToken=${window.data.tile_token}`,
      {
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(layerDefinition),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setLayer({
          type:     'raster',
          tileSize: 256,
          ...data,
        })
      })
  }, [])

  // Render nothing if we're still waiting for the layer
  if(layer === null) {
    return null
  }

  return (
    <>
     {renderLayer && (
       <Source
        id={mapSettings.sourceIds.rasterBuildings}
        type='raster'
        tiles={[ layer.tiles[0] ]}
        tileSize={256}>
         <Layer
           id={mapSettings.layerIds.rasterBuildings}
           source={mapSettings.sourceIds.rasterBuildings}
           type='raster'
           beforeId={mapSettings.layerIds.vectorParcelsOutline}
         />
       </Source>
      )}
    </>
  )
}

export default BuildingsLayer
