import React, { useEffect } from 'react'
import { Layer, Source, useMap } from 'react-map-gl'
//flood layer pattern images
import regFloodwayPattern from '@/assets/fema-patterns/regulatory_floodway'
import specialFloodwayPattern from '@/assets/fema-patterns/special_floodway'
import leveeReducedRiskPattern from '@/assets/fema-patterns/area_with_reduced_risk_due_to_levee'
import leveeRiskPattern from '@/assets/fema-patterns/area_with_risk_due_to_levee'
import futureConditionsPattern from '@/assets/fema-patterns/future_conditions_1pct_chance_flood_hazard'

const FemaLayer = () => {
  const { current: map } = useMap()

  const patternNames = {
    regFloodway: 'reg-floodway',
    spFloodway: 'sp-floodway',
    leveeReducedRisk: 'levee-reduced-risk',
    leveeRisk: 'levee-risk',
    futureOnePct: 'future-one-pct',
  }

  useEffect(() => {
    // load images for use in FEMA layer fill patterns
    map.loadImage(regFloodwayPattern, (error, image) => {
      if (error) throw error
      map.addImage(patternNames.regFloodway, image)
    })
    map.loadImage(specialFloodwayPattern, (error, image) => {
      if (error) throw error
      map.addImage(patternNames.spFloodway, image)
    })
    map.loadImage(leveeReducedRiskPattern, (error, image) => {
      if (error) throw error
      map.addImage(patternNames.leveeReducedRisk, image)
    })
    map.loadImage(leveeRiskPattern, (error, image) => {
      if (error) throw error
      map.addImage(patternNames.leveeRisk, image)
    })
    map.loadImage(futureConditionsPattern, (error, image) => {
      if (error) throw error
      map.addImage(patternNames.futureOnePct, image)
    })
  }, [])

  const sourceId = 'flood-source'
  const sourceLayer = 'NFHL_Key_Layers_4326'

  // See: https://github.com/loveland/internal-demos/blob/main/fema_vector.html for style defs
  const baseLayerDef = {
    id: 'flood',
    type: 'fill',
    source: sourceId,
    'source-layer': sourceLayer,
    filter: ['!=', ['get', 'zone_subty'], 'AREA OF MINIMAL FLOOD HAZARD'],
    paint: {
      'fill-color': [
        'match',
        ['get', 'fld_zone'],
        'A',
        '#b2f8ff',
        'A99',
        '#b2f8ff',
        'AE',
        '#b2f8ff',
        'AH',
        '#b2f8ff',
        'AO',
        '#b2f8ff',
        'V',
        '#b2f8ff',
        'VE',
        '#b2f8ff',
        'X',
        '#ffd9b2',
        /* other */ '#fff',
      ],
      'fill-opacity': 0.5,
    },
  }

  const regFloodwayLayerDef = {
    id: 'reg-floodway-layer',
    type: 'fill',
    source: sourceId,
    'source-layer': sourceLayer,
    filter: [
      'all',
      [
        'match',
        ['get', 'zone_subty'],
        [
          'ADMINISTRATIVE FLOODWAY',
          'FLOODWAY',
          'FLOODWAY CONTAINED IN CHANNEL',
          'FLOODWAY CONTAINED IN STRUCTURE',
          'RIVERINE FLOODWAY IN COMBINED RIVERINE AND COASTAL ZONE',
          'RIVERINE FLOODWAY SHOWN IN COASTAL ZONE',
        ],
        true,
        false,
      ],
    ],
    paint: {
      'fill-pattern': patternNames.regFloodway,
    },
  }

  const spFloodwayLayerDef = {
    id: 'sp-floodway-layer',
    type: 'fill',
    source: sourceId,
    'source-layer': sourceLayer,
    filter: [
      'all',
      [
        'match',
        ['get', 'zone_subty'],
        ['COLORADO RIVER FLOODWAY'],
        true,
        false,
      ],
    ],
    paint: {
      'fill-pattern': patternNames.spFloodway,
    },
  }

  const leveeReducedRiskLayerDef = {
    id: 'levee-reduced-risk-layer',
    type: 'fill',
    source: sourceId,
    'source-layer': sourceLayer,
    filter: [
      'all',
      [
        'match',
        ['get', 'zone_subty'],
        ['AREA WITH REDUCED FLOOD RISK DUE TO LEVEE'],
        true,
        false,
      ],
    ],
    paint: {
      'fill-pattern': patternNames.leveeReducedRisk,
    },
  }

  const leveeRiskLayerDef = {
    id: 'levee-risk-layer',
    type: 'fill',
    source: sourceId,
    'source-layer': sourceLayer,
    filter: [
      'all',
      [
        'match',
        ['get', 'zone_subty'],
        ['AREA WITH FLOOD RISK DUE TO LEVEE'],
        true,
        false,
      ],
    ],
    paint: {
      'fill-pattern': patternNames.leveeRisk,
    },
  }

  const futureConditionsLayerDef = {
    id: 'future-one-pct-layer',
    type: 'fill',
    source: sourceId,
    'source-layer': sourceLayer,
    filter: [
      'all',
      [
        'match',
        ['get', 'zone_subty'],
        [
          '1 PCT FUTURE CONDITIONS',
          '1 PCT FUTURE CONDITIONS CONTAINED IN STRUCTURE',
          '1 PCT FUTURE CONDITIONS, COMMUNITY ENCROACHMENT',
          '1 PCT FUTURE CONDITIONS, FLOODWAY',
          '1 PCT FUTURE IN STRUCTURE, COMMUNITY ENCROACHMENT',
          '1 PCT FUTURE IN STRUCTURE, FLOODWAY',
        ],
        true,
        false,
      ],
    ],
    paint: {
      'fill-pattern': patternNames.futureOnePct,
    },
  }

  const outlineLayerDef = {
    id: 'flood-zone-outline',
    type: 'line',
    source: sourceId,
    'source-layer': sourceLayer,
    filter: ['!=', ['get', 'zone_subty'], 'AREA OF MINIMAL FLOOD HAZARD'],
    paint: {
      'line-color': '#fff',
      'line-width': 0.7,
      'line-opacity': 0.8,
    },
  }

  const labelLayerDef = {
    id: 'flood-labels',
    type: 'symbol',
    source: sourceId,
    'source-layer': sourceLayer,
    minzoom: 12,
    filter: ['!=', ['get', 'zone_subty'], 'AREA OF MINIMAL FLOOD HAZARD'],
    layout: {
      'text-field': [
        'concat',
        'Zone ',
        ['get', 'fld_zone'],
        ': ',
        ['get', 'zone_subty'],
      ],
      'text-size': 14,
      'text-font': ['Arial Unicode MS Bold'],
    },
    paint: {
      'text-color': '#FFFFFF',
      'text-halo-color': '#000000',
      'text-halo-width': 2,
    },
  }

  return (
    <Source
      id={sourceId}
      type="vector"
      tiles={[
        `${window.data.tileserver}/api/v1/static/fema/{z}/{x}/{y}.mvt&userToken=${window.data.tile_token}`,
      ]}
      maxzoom={14}
    >
      <Layer {...baseLayerDef} />
      <Layer {...regFloodwayLayerDef} />
      <Layer {...spFloodwayLayerDef} />
      <Layer {...leveeReducedRiskLayerDef} />
      <Layer {...leveeRiskLayerDef} />
      <Layer {...futureConditionsLayerDef} />
      <Layer {...outlineLayerDef} />
      <Layer {...labelLayerDef} />
    </Source>
  )
}

export default FemaLayer
