import mapSettings from '../mapSettings'

const tileURL = window.data.tileserver
const tileToken = window.data.tile_token
// n.b. `countries` duplicated from utils.js `COUNTRIES` constant due to load order issues and availability in test env between sprockets and webpack.
const { layerIds, countries } = mapSettings

/**
 * Third-party layers that can be added to the map.
 * @type {Array}
 * @property {Array} country - The countries the layer is available in, available globally from utils.js
 */
export const thirdPartyLayers = [
  {
    name: 'Standardized Zoning',
    id: 'zoning',
    description:
      'Zoning ordinance boundaries covering over 6,200 municipalities, overlaid on parcels.',
    type: 'mvt',
    url: null,
    source: 'Zoneomics',
    docs: 'https://regrid.com/zoning',
    opts: {
      minZoom: 11,
      maxZoom: 21,
    },
    country: [countries.US],
  },
  {
    name: 'Elevation Contours (ft)',
    id: 'elevation-ft',
    description: 'Contour lines showing elevation, 10-foot increments.',
    type: 'mvt',
    dataLayer: 'us_contours', // source layer name in tileserver
    docs: 'http://prd-tnm.s3.amazonaws.com/index.html?prefix=StagedProducts/Contours/',
    source: 'USGS',
    sourceOpts: {
      tiles: [
        `${tileURL}/api/v1/static/us_contours/{z}/{x}/{y}.mvt&userToken=${tileToken}`,
      ],
      minzoom: 13, // underzooming will not occur below this zoom level
      maxzoom: 13, // use the zoom level 13 vector tiles even when zoom is higher
      // https://stackoverflow.com/a/48197272
    },
    labelOpts: {
      textField: ['concat', ['to-string', ['get', 'contourelevation']], 'ft'],
    },
    opts: {
      beforeId: layerIds.subboundariesFill,
    },
    country: [countries.US],
  },
  {
    name: 'Elevation Contours (m)',
    id: 'elevation-m',
    description: 'Contour lines showing elevation, 10-meter increments',
    type: 'mvt',
    dataLayer: 'contour',
    docs: 'https://docs.mapbox.com/help/troubleshooting/access-elevation-data/#mapbox-terrain-vector-tileset',
    source: 'Mapbox',
    sourceOpts: {
      // see https://docs.mapbox.com/data/tilesets/reference/mapbox-terrain-v2/#elevation for info about Mapbox's contours
      minzoom: 13,
      maxzoom: 14,
      url: 'mapbox://mapbox.mapbox-terrain-v2', // for mapbox data it's url: , not tiles:
    },
    labelOpts: {
      textField: ['concat', ['to-string', ['get', 'ele']], 'm'],
    },
    opts: {
      beforeId: layerIds.subboundariesFill,
    },
    country: [countries.US, countries.CA],
  },
  {
    name: 'FEMA Flood Hazard',
    id: 'flood',
    description:
      'Data from the National Flood Hazard Layer, a GIS database of flood risks and regulatory flood determination data',
    type: 'mvt',
    dataLayer: 'NFHL_Key_Layers_4326',
    source: 'FEMA',
    docs: 'https://www.fema.gov/flood-maps/national-flood-hazard-layer',
    opts: {
      layers: [0, 1, 7, 14, 15, 16, 17, 18, 19, 21, 22, 23, 24, 27, 28],
      f: 'image',
      opacity: 0.7,
    },
    sourceOpts: {
      tiles: [
        `${tileURL}/api/v1/static/fema/{z}/{x}/{y}.mvt&userToken=${tileToken}`,
      ],
    },
    country: [countries.US],
  },
  {
    name: 'Wetlands',
    id: 'wetlands',
    description:
      'National Wetlands Inventory, showing wetland type and extent across the United States',
    type: 'mvt',
    url: null,
    source: 'National Fish & Wildlife Service',
    docs: 'https://www.fws.gov/program/national-wetlands-inventory/wetlands-mapper',
    country: [countries.US],
  },
  {
    name: 'Population Density',
    id: 'population_density',
    description:
      'Estimated number of residents per square mile',
    type: 'mvt',
    url: null,
    source: 'ESRI/US Census',
    dataLayer: 'esri_demographics',
    docs: 'https://www.arcgis.com/home/item.html?id=b0b3b31e531e406185f2de4fff596060',
    country: [countries.US],
    sourceOpts: {
      tiles: [
        `${tileURL}/api/v1/static/esri_enrichments/{z}/{x}/{y}.mvt?userToken=${tileToken}`,
      ],
      minzoom: 6,
    },
  },
  {
    name: 'Population Growth Forecast (5Y)',
    id: 'population_growth',
    description:
      'Forecast of Compound Annual Growth Rate (CAGR) of the population over the next 5 years',
    type: 'mvt',
    url: null,
    source: 'ESRI/US Census',
    dataLayer: 'esri_demographics',
    docs: 'https://www.arcgis.com/home/item.html?id=b0b3b31e531e406185f2de4fff596060',
    country: [countries.US],
    sourceOpts: {
      tiles: [
        `${tileURL}/api/v1/static/esri_enrichments/{z}/{x}/{y}.mvt?userToken=${tileToken}`,
      ],
      minzoom: 6,
    },
  },
  {
    name: 'Housing Affordability Index',
    id: 'housing_affordability',
    description:
      'The Housing Affordability Index (HAI) measures the ability of a typical resident to purchase a home in a geographic area',
    type: 'mvt',
    url: null,
    source: 'ESRI/US Census',
    dataLayer: 'esri_demographics',
    docs: 'https://www.arcgis.com/home/item.html?id=b0b3b31e531e406185f2de4fff596060',
    country: [countries.US],
    sourceOpts: {
      tiles: [
        `${tileURL}/api/v1/static/esri_enrichments/{z}/{x}/{y}.mvt?userToken=${tileToken}`,
      ],
      minzoom: 6,
    },
  },
  {
    name: 'Opportunity Zones',
    id: 'ozones',
    description: 'Qualified Opportunity Zones',
    type: 'raster',
    url: 'https://tiles.regrid.com/api/v1/sources/parcel/layers/4c2c8e69c0b928c1422b0a20c1f533134345378e/{z}/{x}/{y}.png',
    source: 'CDI Fund',
    // source: 'https://www.cdfifund.gov/Pages/Opportunity-Zones.aspx',
    docs: 'https://hub.arcgis.com/datasets/e702f5540ebd45949758fcdb7bd89c25_0?geometry=-85.318%2C33.558%2C-82.705%2C33.958',
    opts: {
      type: 'raster',
      maxZoom: 19,
      beforeId: layerIds.subboundariesFill,
    },
    country: [countries.US],
  },
  {
    name: 'Land Cover',
    id: 'landuse-wms',
    description:
      'GAP Land Cover 1 National Vegetation Classification: detailed information on the vegetation of the United States',
    type: 'wms',
    // formerly esri type, 'Dynamic Map Service' is raster, but not tiled. Converted to wms.
    url: 'https://gis1.usgs.gov/arcgis/services/gap/GAP_Land_Cover_NVC_Class_Landuse/MapServer/WMSServer?service=WMS&request=GetMap&layers=0,1,2&styles=&width=256&height=256&format=image/png&transparent=true&version=1.3.0&crs=EPSG:3857&bbox={bbox-epsg-3857}',
    docs: 'https://www.usgs.gov/centers/eros/science/national-land-cover-database?qt-science_center_objects=0#qt-science_center_objects',
    source: 'USGS',
    opts: {
      paint: {
        'raster-opacity': 0.7,
      },
      type: 'raster',
      beforeId: layerIds.subboundariesFill,
      //layers: [0,1,2],
      // f: 'image',
    },
    country: [countries.US],
  },
  {
    name: 'Historic Redlining',
    id: 'redlining',
    free: true,
    description:
      'HOLC Redlining map, 1935-1940. Only available in some cities; see source for details.',
    type: 'raster',
    url: `https://api.mapbox.com/styles/v1/loveland/ck84u8rql09061jqsemjyb2r8/tiles/256/{z}/{x}/{y}@2x?access_token=${window.data.mapbox.access_token}`,
    docs: 'https://dsl.richmond.edu/panorama/redlining/',
    source: 'Mapping Inequality',
    // source: ' Robert K. Nelson, LaDale Winling, Richard Marciano, Nathan Connolly, et al., “Mapping Inequality,” American Panorama, ed. Robert K. Nelson and Edward L. Ayers',
    opts: {
      type: 'raster',
      beforeId: layerIds.subboundariesFill,
    },
    country: [countries.US],
  },
]

export const structureLayers = [
  {
    name: 'Building Footprints',
    id: 'building-footprints',
    description:
      'Over 183 million building footprints across the US, derived from aerial imagery.',
    type: 'leaflet',
    url: null,
    source: 'EarthDefine',
    docs: 'https://regrid.com/buildings',
    opts: {
      minZoom: 13,
      maxZoom: 21,
    },
    country: [countries.US],
  },
]

// Layers only visible to admins
export const adminThirdPartyLayers = [
  {
    name: 'LBCS Activity',
    id: 'lbcs-activity',
    description: 'LBCS Activity',
    type: 'raster',
    url: 'https://tiles.regrid.com/api/v1/sources/parcel/layers/cd6432c525c9079eb71c952742a2a8aedfb6dfd1/{z}/{x}/{y}.png',
    source: 'Regrid',
    docs: 'https://regrid.com/land-use-codes',
    opts: {
      type: 'raster',
      beforeId: layerIds.subboundariesFill,
      maxZoom: 19,
    },
    country: [countries.US],
  },
]

const esriKey = window.data.esri_basemap_key
const esriStreetsStyle = "6914aca5074146f88a6f38ce57d47f2e"
export const baseLayers = [
  {
    name: 'Satellite 1', // esri
    id: 'satellite',
    description:
      "<a href='https://www.arcgis.com/home/item.html?id=10df2279f9684e4a9f6a7f08febac2a9' target='_blank'>Satellite imagery</a> from Esri.",
    url: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/arcgis/imagery?token=${esriKey}`,
    sources: [{ source: '&copy;&nbsp;Esri', doc: 'https://www.esri.com' }],
    vendor: 'esri',
    country: [countries.US, countries.CA],
  },
  {
    name: 'Street',
    id: 'street',
    description: 'Regrid-styled basemap using Esri tileset',
    url: `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/items/${esriStreetsStyle}?token=${esriKey}`,
    sources: [{ source: '&copy;&nbsp;Esri', doc: 'https://www.esri.com' }],
    vendor: 'esri',
    country: [countries.US, countries.CA],
  },
  {
    name: 'Satellite 2', // mapbox
    id: 'satellite2',
    description:
      "<a href='https://docs.mapbox.com/help/getting-started/imagery/' target='_blank'>Satellite imagery</a> from Mapbox.",
    url: 'mapbox://styles/loveland/cirw7suml001dg9m9u3uztap0',
    sources: [
      {
        source: '&copy;&nbsp;Mapbox',
        doc: 'https://www.mapbox.com/about/maps/',
      },
      {
        source: '&copy;&nbsp;OpenStreetMap',
        doc: 'http://www.openstreetmap.org/about/',
      },
      { source: '&copy;&nbsp;Maxar', doc: 'https://www.maxar.com/' },
      {
        source: 'Improve this map',
        doc: 'https://www.mapbox.com/map-feedback/#/-74.5/40/10',
      },
    ],
    vendor: 'mapbox',
    country: [countries.US, countries.CA],
  },
  // {
  //   name: 'Street (Mapbox)',
  //   id: 'street-',
  //   description: 'Regrid-styled basemap using Mapbox tileset.',
  //   url: 'mapbox://styles/loveland/cl2knute3002x14nx495v8909', // no buildings
  //   sources: [
  //     { source: '&copy;&nbsp;Mapbox', doc: 'https://www.mapbox.com/about/maps/' },
  //     { source: '&copy;&nbsp;OpenStreetMap', doc: 'http://www.openstreetmap.org/about/' },
  //     { source: '&copy;&nbsp;Maxar', doc: 'https://www.maxar.com/' },
  //     { source: 'Improve this map', doc: 'https://www.mapbox.com/map-feedback/#/-74.5/40/10' }
  //   ],
    // vendor: 'mapbox',
    // country: [countries.US, countries.CA],
  // }
]