import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { post } from 'ducks/api';
import FocusAreaListItem from './FocusAreaListItem';

const FocusAreaList = ({focusAreasAttachedToProject, passEventToFlight, project}) => {
  if (!focusAreasAttachedToProject || focusAreasAttachedToProject.list.length === 0) {
    return <div><h4>No attached focus areas</h4></div>;
  }

  const regionList = focusAreasAttachedToProject.list.map(area => <FocusAreaListItem
    key={area.path}
    area={area}
    projectPath={project.path}
    passEventToFlight={passEventToFlight}
    folder={'Focus Areas'}
    active={true}
  />);

  return <div><h4>Project focus areas</h4>{regionList}</div>;
};

FocusAreaList.propTypes = {
  passEventToFlight:  PropTypes.func.isRequired,
  project:            PropTypes.object,
};

export default FocusAreaList;
