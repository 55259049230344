import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withFlight from 'containers/withFlight';
import withStore from '../withStore';

// Redux
import { getWorkingBoundaryPath } from 'ducks/boundaries';
import { getBoundaryParents, getWorkingBoundaryName } from 'ducks/boundaries';

const MapBreadcrumbs = ({ passEventToFlight }) => {

  const workingBoundaryPath = useSelector(getWorkingBoundaryPath);
  const parentBounds = useSelector((state) => getBoundaryParents(state, workingBoundaryPath));
  const activeBoundaryName = useSelector((state) => getWorkingBoundaryName(state, workingBoundaryPath));

  // Handle country/world cases that aren't in the 'parents' element
  let m = workingBoundaryPath && workingBoundaryPath.match(/^\/([a-z]{1,2})(.*)/i);
  const isWorldOrCountry = m && (m[2].length == 0);
  const country = (m && m[1].length == 2) ? m[1] : null;

  const onClickRegion = (requestedPath) => {
    if(requestedPath) {
     passEventToFlight('mapp:regions:breadcrumb-clicked', { path: requestedPath });
    }
  };

  return (
    <div className="map-breadcrumbs">
      <div>
        <span>Currently Viewing: </span>
        {!isWorldOrCountry && country &&
          <span onClick={() => onClickRegion(`/${country}`)}>
            <span className="crumb">{country.toUpperCase()}</span>
            {' '}
          </span>}
        {parentBounds.map((parent, idx) => (
          <span key={idx} onClick={() => onClickRegion(parent.path)}>
            {'> '}<span className="crumb">{parent.name}</span>{' '}
          </span>
          )
        )}
        <span>
          {isWorldOrCountry ? '' : '> '}
          <span className="active-place">
            {activeBoundaryName}
          </span>
        </span>
      </div>
    </div>
  );
};

MapBreadcrumbs.propTypes = {
  passEventToFlight:   PropTypes.func.isRequired
};

export default withStore(withFlight(MapBreadcrumbs));
