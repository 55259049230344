import React, { useEffect } from 'react';

const Message = ({ alertType, message, setAlertMessage }) => {

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage(false)
    }, 5000);
  }, []);

  return (
    <div className={`alert ${alertType}`} role="alert">
      {message}
    </div>
  );
};

export default Message;
