import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import withStore from 'components/withStore'
import withFlight from 'containers/withFlight'
import { getActiveProject } from 'ducks/projects'
import { getAvailableFocusAreas } from '../../ducks/focusAreas'

// Components
import FocusAreaAdderGL from 'components/Project/FocusAreaAdderGL'
import FocusAreaListGL from 'components/Project/FocusAreaListGL'
import DrawFocus from '../Map/MapControls/MapOptions/FocusAreas/DrawFocus'

const FOCUS_AREA_FOLDER = 0 // Title is "Focus Areas"

/*
UI to manage a project's attached focus areas
*/
const FocusAreaManagerGL = ({ passEventToFlight }) => {
  const [focusAreasAttachedToProject, setAttachedFocusAreas] = useState({
    list: [],
  })
  const project = useSelector(getActiveProject)
  const availableFocusAreas = useSelector(getAvailableFocusAreas)
  const supportRoot = window.data.support_url
  const isDisabled = _.isEmpty(project)

  useEffect(() => {
    const attachedAreas = project?.regions.regions.find((region) => {
      return region.folder === FOCUS_AREA_FOLDER
    })

    setAttachedFocusAreas(attachedAreas || { list: [] })
  }, [availableFocusAreas.length, project])

  const handleClose = () => {
    passEventToFlight('pane:request:hide')
    const profileElement = document.getElementById('profile')
    if (profileElement) {
      profileElement.classList.remove('active')
    }
  }

  return (
    <div>
      <h4>Focus Areas</h4>
      {isDisabled && (
        <p className="small italic margin-btm-sm">
          <i className="fas fa-exclamation-triangle margin-right-sm"></i>
          Create or select a project to use Focus Areas.
        </p>
      )}
      <div className="settings-tile-wrapper margin-btm-lg">
        <DrawFocus handleClose={handleClose} isDisabled={isDisabled} />
      </div>
      <FocusAreaListGL
        focusAreasAttachedToProject={focusAreasAttachedToProject}
        passEventToFlight={passEventToFlight}
        project={project}
      />
      <FocusAreaAdderGL
        focusAreasAttachedToProject={focusAreasAttachedToProject}
        passEventToFlight={passEventToFlight}
        project={project}
        handleClose={handleClose}
      />
      <a
        href={`${supportRoot}/property-app/draw-focus-areas`}
        target="_blank"
        className="bold small margin-top-md"
      >
        <i className="fa fa-external-link margin-right-md"></i>
        Focus Area Documentation
      </a>
    </div>
  )
}

FocusAreaManagerGL.propTypes = {
  passEventToFlight: PropTypes.func.isRequired,
}

export default withStore(withFlight(FocusAreaManagerGL))
