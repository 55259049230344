import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ChangePlanWarnings from 'components/Payment/ChangePlanWarnings'

/**
 * ChangePlanApi component for managing subscription plan changes.
 * Displays the current plan, available plan options, and handles the logic for confirming and canceling plan changes.
 * @param {Object} props - Component props.
 * @param {string} props.api_access - API access type.
 * @param {string} props.plan - Current plan.
 * @param {Object} props.plans - Available subscription plans.
 * @param {Object} props.plans.plan - Details of each plan.
 * @param {string} props.plans.plan.name - Plan name.
 * @param {Object} props.plans.plan.prices - Plan prices.
 * @param {string} props.plans.plan.prices.cycle - Price cycle.
 * @returns {JSX.Element} React component.
 */
const ChangePlanApi = (props) => {
  const getDefaultSelectedPlan = () => {
    if (props.plan !== 'api_combo') {
      return `api_standard`;
    } else {
      return `api_${props.api_access}`;
    }
  };

  const [state, setState] = useState({
    processing:   false,
    selectedPlan: getDefaultSelectedPlan(),
    error:        null,
    showForm:     false,
    possible:     false,
    cycle:        'monthly',
  })

  useEffect(() => {
    // If the plan is passed in the URL, show the form with that plan selected
    const params = new URLSearchParams(window.location.search)
    const plan = params.get('plan')
    
    if (plan) {
      setState({
        ...state,
        selectedPlan: plan,
        showForm:     true,
      })
    }
  }, [])

  useEffect(() => {
    checkEffects()
  }, [state.selectedPlan])

  const onClickToggle = () => {
    setState({ ...state, showForm: !state.showForm })
  }

  const onChangePlan = (e) => {
    const newPlan = e.target.value
    setState({ ...state, selectedPlan: newPlan })
  }

  const hasChanges = () => {
    // Checks if the selected plan is the same as the current plan
    return !checkCurrentPlanSelected()
  }

  const checkEffects = () => {
    let deprecatedEffect =
      props.plan !== 'api_combo' ? {deprecatedApi: planTitle()} : null;

    if (!hasChanges()) {
      // No processing is needed, the change is not possible, clear any effects or errors.
      setState((prevState) => ({
        ...prevState,
        processing: false,
        possible: false,
        error: null,
      }));
      return;
    }

    setState({
      ...state,
      processing: true,
      effects: deprecatedEffect,
    });
  
    let opts = {
      plan: state.selectedPlan,
      cycle: state.cycle,
    };
  
    LL.Ajax.getSpin('/settings/check_change.json', opts).then(response => {
      setState({
        ...state,
        processing: false,
        possible:   response.possible,
        effects:    deprecatedEffect ?? response.effect,
        error:      response.reason,
      });
    }).catch(msg => {
      setState({ ...state, processing: false, error: msg });
    });
  };
  

  const checkCurrentPlanSelected = () => {
    if (props.plan !== 'api_combo') {
      return false;
    }
  
    return state.selectedPlan === `api_${props.api_access}`;
  };
  

  const resetToCurrentPlan = () => {
    setState({
      selectedPlan: `api_${props.api_access}`,
    })
  }

  const onClickCancel = () => {
    onClickToggle()
    resetToCurrentPlan()
  }

  const onClickConfirm = (e) => {
    e.preventDefault()
    if (state.processing || !state.possible) {
      return
    }

    let opts = {
      plan: 'api_combo', // api_combo
      api_access: state.selectedPlan.replace(/^api_/, ''),  // standard/premium
      cycle: state.cycle, // monthly
    };

    setState({ ...state, processing: true })

    LL.Ajax.putSpin('/subscriptions/update.json', opts)
      .then(() => {
        window.location.reload()
      })
      .catch((msg) => {
        setState({ ...state, processing: false, error: msg })
      })
  }

  const planTitle = () => {
    let plan = {
      'api_tiles': 'Tiles Only',
      'api_access': 'Parcels Only',
      'api_combo': 'Bundled',
    }

    return `${plan[props.plan]} ${_.capitalize(props.api_access)}`
  }

  const selectedDetails = props.plans[state.selectedPlan];
  const currentPlanSelected = checkCurrentPlanSelected()

  return (
    <div className="change-plan" style={{ marginBottom: '4em' }}>
      <div>
        <h2 style={{ display: 'inline-block' }}>
          Current Plan: {planTitle()}
        </h2>
        <a
          href="#"
          onClick={onClickToggle}
          className={`btn btn-round plan-${selectedDetails?.name.toLowerCase()}-accent`}
          style={{ marginLeft: '1em', marginTop: '-10px' }}
        >
          Change plan
          <i
            className={` padding-left-md fas ${
              state.showForm ? 'fa-caret-down' : 'fa-caret-right'
            }`}
          />
        </a>
      </div>

      {state.showForm && (
        <div className="land-well row margin-right-sm">
          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <label>New Bundled Plan</label>
                <select
                  className="form-control"
                  value={state.selectedPlan}
                  onChange={onChangePlan}
                >
                  {_.map(props.plans, (info, key) => (
                    <option key={key} value={key}>
                      {info.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-md-8" style={{ paddingTop: '2em' }}>
                <a href="/api" target="_blank">
                  View plans, features and pricing{' '}
                  <i className="fas fa-arrow-right padding-left-md" />
                </a>
              </div>
            </div>

            {state.error && (
              <p className="alert alert-warning">{state.error}</p>
            )}

            {currentPlanSelected && (
              <div className="row">
                <div className="col-md-12" style={{ paddingTop: '1em' }}>
                  <span className="lead">
                    This is your <span className="museo900">current plan.</span>
                  </span>
                  <br />
                  <p>
                    Please use the dropdown options above to change the plan
                    type.
                  </p>
                </div>
              </div>
            )}

            {state.possible && (
              <div className="row">
                <div className="col-md-12" style={{ paddingTop: '1em' }}>
                  <span className="lead">
                    You have selected a change to the
                    <strong> {selectedDetails.name} </strong>
                    plan at
                    <strong> ${selectedDetails.prices[state.cycle]}</strong> per
                    month.
                  </span>
                  <hr />
                  <p>
                    Price changes will be prorated as of today. If upgrading,
                    the current card will be charged immediately for any
                    outstanding balance; if downgrading, you may have a credit
                    on your account towards future charges. You must confirm
                    with the button below for this to take effect.
                  </p>
                </div>
              </div>
            )}
            
            {state.possible &&
              !_.isEmpty(state.effects) && (
                <ChangePlanWarnings {...state.effects} />
              )}

            {hasChanges() && (
              <div className="row padding-btm-xl">
                <div className="col-md-12" style={{ paddingTop: '1em' }}>
                  <a
                    href="#"
                    className="btn btn-lg btn-info btn-round"
                    disabled={!state.possible || state.processing}
                    onClick={onClickConfirm}
                  >
                    {state.processing ? 'Please wait...' : 'Confirm Change'}
                  </a>
                  <a
                    href="#"
                    className="btn btn-lg btn-default btn-round"
                    onClick={onClickCancel}
                  >
                    Cancel Change
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-4">
            <label>What's Included</label>
            <ul>
              {selectedDetails.included.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

ChangePlanApi.propTypes = {
  plan:       PropTypes.string.isRequired,
  plans:      PropTypes.object.isRequired,
  api_access: PropTypes.string.isRequired,
}

export default ChangePlanApi
