import React, { memo } from 'react'
import withStore from 'components/withStore'

const ExportList = memo(function _ExportList({
  expList,
  handleDropdown,
}) {
  const SCHEDULED = 0
  const RUNNING = 1
  const DONE = 2
  const FAILED = 3
  const REMOVED = 4

  if (
    expList.filter((exp) => exp.status === DONE || exp.status === REMOVED)
      .length < 1
  ) {
    return <></>
  }

  const checkDayAgo = (date) => {
    const day = 1000 * 60 * 60 * 24 // millisec, sec, min, hour
    const dayAgo = Date.now() - day
    return date > dayAgo
  }

  return (
    <>
      <div className='exp-index padded rounded plan-color'>
        <div
          className="exp-index-label"
          type="button"
          data-toggle="collapse"
          data-target="#exp-list-container"
          aria-expanded="true"
          aria-controls="exp-list-container"
          onClick={handleDropdown}
        >
          <h4 className="bold">
            {`Project Export History (${
              expList.filter(
                (exp) => exp?.status === DONE || exp?.status === REMOVED
              ).length
            })`}
          </h4>
          <i className="fa fa-caret-up rotate-0" aria-hidden="true"></i>
        </div>
        <div id="exp-list-container" className="collapse">
          <div className="centered">
            <p className="small padding-top-md padding-btm-md">
              Below is this project’s export history. Downloads expire after 30
              days; visit your account orders page to download and manage these
              files.
            </p>
            <a
              className="btn btn-primary btn-round btn-sm"
              href="/profile/orders"
              target="_blank"
              rel="noopener noreferrer"
            >
              See Data Orders
            </a>
          </div>
          <ul id="exp-list-ul">
            {expList.map((exp) => {
              let mo =
                typeof exp?.created_at == 'number'
                  ? moment.unix(exp?.created_at)
                  : moment(exp?.created_at)
              let longDate = mo?.format('M-D-YY h:mma')
              // removed 0 scheduled, to prevent abandoned exports clogging the index.
              if (exp?.status === RUNNING) {
                let parsedDate = Date.parse(exp?.created_at)
                if (parsedDate && checkDayAgo(parsedDate)) {
                  // if exp is less than 24 hrs old
                  return (
                    <li key={`exp-list-li-${exp.id}`} className="exp-list-li">
                      <div className="exp-li-info">
                        <div className="exp-li-title small">
                          {`${exp?.filename}.${exp?.format}`}
                        </div>
                        <span className="small italic">In Progress</span>
                      </div>
                      <a className="btn btn-disabled btn-sm">
                        <i className="fas fa-spinner fa-spin"></i>
                      </a>
                    </li>
                  )
                }
              } else if (exp?.status === DONE) {
                return (
                  <li key={`exp-list-li-${exp.id}`} className="exp-list-li">
                    <div className="exp-li-info">
                      <div className="exp-li-title small">
                        {`${exp?.filename}.${exp?.format}`}
                      </div>
                      <span className="small">{longDate}</span>
                    </div>
                    <span className="small italic">Download Available</span>
                  </li>
                )
              } else if (exp?.status === REMOVED) {
                return (
                  <li key={`exp-list-li-${exp?.id}`} className="exp-list-li">
                    <div className="exp-li-info">
                      <div className="exp-li-title small">
                        {`${exp?.filename}.${exp?.format}`}
                      </div>
                      <span className="small">{longDate}</span>
                    </div>
                    <span className="small italic">Expired</span>
                  </li>
                )
              } else if (exp?.status === FAILED) {
                return (
                  <li key={`exp-list-li-${exp?.id}`} className="exp-list-li">
                    <div className="exp-li-info">
                      <div className="exp-li-title small">
                        {`${exp?.filename}.${exp?.format}`}
                      </div>
                      <span className="small italic">
                        Could not be completed
                      </span>
                    </div>
                    <a className="btn btn-disabled btn-sm">Failed</a>
                  </li>
                )
              }
            })}
          </ul>
        </div>
      </div>
    </>
  )
})

export default withStore(ExportList)
