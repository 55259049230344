import React from 'react' 

const FocusAreaListItem = ({focusArea}) => {

  return (
    <div className="focus-area-item">
    <div className="focus-area-preview">
      <i className="fa fa-shapes"></i>
    </div>
    <div className="focus-area-content">
      <div className="focus-area-item-header">{focusArea.name}</div>
      <div className="focus-area-body">
        <span>{focusArea.acres}</span>
        <span> - </span>
        <span>Created {focusArea.created_at}</span>
      </div>
    </div>
    <button className="focus-area-button">
      <i className="fa fa-plus-square"></i>
    </button>
  </div>
  )
}

export default FocusAreaListItem