import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InfoTooltip from 'components/Utilities/InfoTooltip';

// Components
import withFlight from 'containers/withFlight';
import withStore from 'components/withStore';
import ReviewModal from './ReviewModal';

// TODO set .place-name-export button text locally, see export.js updateExportBtn() 

//this.onRegionFocus = function(e, o) {
//   currentRegion = o;
//   this.updateExportBtn();
// }

// this.updateExportBtn = function() {
//   if(!currentRegion) { return; }
//   var parents = currentRegion.parents;
//   var county = parents.find((parent) => parent.path === currentRegion.parcel_path);
//   var storeLink = currentRegion.store_link;
//   var beyondCounty = storeLink && parents.length < 2;
//   var buttonText = currentRegion.outline.properties.headline;
//   // Conditionally update button so as not to specify beyond county
//   if(!beyondCounty && county) {
//     buttonText = county.name;
//     storeLink = `/store${currentRegion.parcel_path}`;
//   } else if(currentRegion.path === '/us'){
//     storeLink = '/store';
//   }
//   $('.export-store-link .place-name-export').text(buttonText);
//   $('#waterfall .export-store-link').attr('href', storeLink);
// }

const ExportNoProject = () => {
  return (
    <div className='empty-hint centered'> 
      <i className='fas fa-download'/>
      <p className='lead'>
        Start a project to export your data.
        Learn more from our Support Center <a href="https://support.regrid.com" target="_blank">Export Guidelines documentation</a>.
      </p>
      <a className='btn btn-primary btn-block new-map' data-upsell='save-map' href="#a=new-project">Create Project</a>
    </div>
  );
}

export default withStore(ExportNoProject);
