import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Layer, Source } from 'react-map-gl'
import mapSettings from '../mapSettings'

// Redux
import { getMapColor } from 'ducks/mapProperties'

const SatelliteRasterParcelLayer = ({tokenParam}) => {
  const [layer, setLayer] = useState(null)
  const currentColor = useSelector(
    getMapColor(mapSettings.editableStyleTypes.satelliteParcelLines)
  )

  // Create a custom raster tileserver layer
  useEffect(() => {
    // Have to reset the layer so that the source
    // rebuilds itself with the new tiles URL and
    // reloads the tiles.
    setLayer(null)

    const layerDefinition = {
      query: {
        parcel: true,
      },
      styles: `
      #loveland {
        line-color: ${currentColor};
        line-width: 0.25;
        line-opacity: 0.25;
      }`,
    }
    // Create the layer
    fetch(`${window.data.tileserver}/api/v1/sources`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(layerDefinition),
    })
      .then((res) => res.json())
      .then((data) => {
        setLayer({
          type: 'raster',
          tileSize: 256,
          ...data,
        })
      })
  }, [currentColor])

  // Render nothing if we're still waiting for the layer
  if (layer === null) {
    return null
  }

  return (
    <>
      <Source
        id={mapSettings.sourceIds.rasterParcelsSatellite}
        type="raster"
        tiles={[`${layer.tiles[0]}?${tokenParam}`]}
        tileSize={256}
        maxzoom={mapSettings.rasterMaxZoom}
        minzoom={mapSettings.parcelMinZoom}
      >
        <Layer
          id={mapSettings.layerIds.rasterParcelsSatellite}
          beforeId={mapSettings.layerIds.subboundariesFill}
          source={mapSettings.sourceIds.rasterParcelsSatellite}
          type="raster"
          maxzoom={mapSettings.rasterMaxZoom}
          minzoom={mapSettings.parcelMinZoom}
        />
      </Source>
    </>
  )
}

export default SatelliteRasterParcelLayer
