import { createSlice } from '@reduxjs/toolkit'
import { dropdownSections } from '../components/Parcels/propertySettings'
import { isEqual } from 'lodash'

const initialState = {
  propertySections: {
    ...dropdownSections.reduce((acc, section) => {
      acc[section.id] = section.isOpen
      return acc
    }, {}),
    'dd-parcel-highlights': true, // ParcelHighlights.jsx dropdown
    'dd-other-properties': false, // ParcelOthers.jsx dropdown
    'dd-additional-items': true, // Additional Items is dynamically generated
  },
  userPermissions: {
    groupRole: '',
    isGuest: null,
    planCssClass: '',
    paidUser: null,
    inGroup: null,
    inFree: null,
  },
  // Image limits for free users (Starter)
  // UsersController#image_limits endpoint returns User.bing_details(@user) which includes:
  imageLimits: {
    allowed: null,
    remaining: null,
    used: null,
    total: null,
    need: null,
    metered: null,
  },
  // lookup limits for free users (Starter)
  // UsersController#lookup_limits endpoint returns User.antiscrape_details(@user) which includes:
  lookupLimits: {
    allowed: null,
    remaining: null,
    used: null,
    total: null,
    need: null,
    metered: null,
  },
}

// Reducer and actions
const userPropertiesSlice = createSlice({
  name: 'userProperties',
  initialState,
  reducers: {
    toggleSection: (state, action) => {
      state.propertySections[action.payload] = !state.propertySections[action.payload]
    },
    updateUserPermissions: (state, action) => {
      // Only update if the permissions have changed
      if (!isEqual(state.userPermissions, action.payload)) {
        state.userPermissions = { ...state.userPermissions, ...action.payload };
      }
    },
    setImageLimits: (state, action) => {
      state.imageLimits = action.payload
    },
    setLookupLimits: (state, action) => {
      state.lookupLimits = action.payload
    },
  },
})

export const { toggleSection, updateUserPermissions, setImageLimits, setLookupLimits } = userPropertiesSlice.actions

export default userPropertiesSlice.reducer
