import React from 'react'
import { handleClickTabPane } from 'common/util'
import fallbackStreetview from '../../../../../assets/images/fallback/bing-streetside.jpg'

/**
 * ImageSignupNotice component renders a placeholder image and a signup notice. 
 * If the user clicks the signup link, it either navigates to the signup/signin 
 * panel in the new UI (MapboxGL) or redirects to the plans page in the old UI (Leaflet).
 * 
 * @param {Object} props - component props
 * @param {boolean} props.reactMapComponent - indicates MapboxGL or Leaflet map
 * @returns {JSX.Element} The rendered component
 */
const ImageSignupNotice = ({ reactMapComponent }) => {
  debug('PI Signup rendered')
  const handleSignup = (e) => {
    e.preventDefault()
    if (reactMapComponent) {
      handleClickTabPane('signup-signin') // New UI panels for signup + upgrade
    } else {
      window.location = '/plans' // Old UI, either anon or starter
    }
  }

  return (
    <div id="property-image" className="property-image margin-btm-md">
      {/* Streetview imagery - Placeholder */}
      <div className='streetview-container'>
        <img
          src={fallbackStreetview}
          className="streetview imagery-placeholder disabled"
          data-placeholder="true"
          alt="Property photo"
        /> 
      </div>

      {/* Signup */}
      <div className="imagery-disabled lead flex-row-center margin-btm-none">
        <span className="small">
          <a href="#" id="imagery-signup-link" onClick={handleSignup}>
            Sign up for a free Starter account
          </a>{' '}
          to view 5 free images, or upgrade to Pro for unlimited imagery.
        </span>
      </div>
    </div>
  )
}

export default ImageSignupNotice
