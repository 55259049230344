import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import withFlight from 'containers/withFlight';
import withStore from 'components/withStore';

import { getActiveProject } from 'ducks/projects';

// Components
import FocusAreaAdder from 'components/Project/FocusAreaAdder';
import FocusAreaList from 'components/Project/FocusAreaList';

const FOCUS_AREA_FOLDER = 0; // Title is "Focus Areas"

/*
UI to manage a project's attached focus areas
*/
const FocusAreaManager = ({passEventToFlight}) => {
  const project = useSelector(getActiveProject);

  if (!project || !project?.permissions?.update) {
    return null
  }

  let focusAreasAttachedToProject = project.regions.regions.find(region => {
    return region.folder === FOCUS_AREA_FOLDER;
  });

  focusAreasAttachedToProject = focusAreasAttachedToProject || { list: [] }


  return <div>
    <FocusAreaList
      focusAreasAttachedToProject={focusAreasAttachedToProject}
      passEventToFlight={passEventToFlight}
      project={project} />
    <FocusAreaAdder
      focusAreasAttachedToProject={focusAreasAttachedToProject}
      passEventToFlight={passEventToFlight}
      project={project} />
  </div>;
};

FocusAreaManager.propTypes = {
  passEventToFlight:    PropTypes.func.isRequired,
};

export default withStore(withFlight(FocusAreaManager));
