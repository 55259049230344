import React from 'react';
import { Provider } from 'react-redux';

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

const withStore = (WrappedComponent) => {
  class Wrapper extends React.Component {
    render() {
      // Weird, this is dangerous:
      // if(!window.LL.store) {
      //   // In dev, somehow this goes undefined for me after it's set in application.js?? -LS
      //   debug("curious, no LL.store?");
      //   window.LL.store = configureStore();
      // }
      return <Provider store={window.LL.store}>
        <WrappedComponent{...this.props}  />
      </Provider>;
    }
  }

  Wrapper.displayName = `WithStore(${getDisplayName(
    WrappedComponent
  )})`;

  return Wrapper;
};

export default withStore;