import React, {useState, useEffect, memo} from 'react';
import { handleClickTabPane } from '../../common/util';
import withStore from 'components/withStore';

const ExportWarning = memo(function _ExportWarning({ handleDropdown, storeLink, contentType }) {
  const handleClickTab = (tabPane) => {
    handleClickTabPane(tabPane)
  }
  
  const priceWarning = <div className="centered">
      <p className='small bold'>
        Your current selection will cost more than buying the whole county from our Data Store.
      </p>
      <p className='small'>
        There are benefits to both approaches - you'll get just your properties of interest here, while on the Data Store you'll likely need to cut down the data yourself using other software - but we wanted to make sure you were aware of your options!
      </p>
      <a className="btn btn-primary btn-round"
        href={storeLink} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        Visit Data Store
      </a>
    </div>
  
  const multiWarning = <div className="centered">
      <p className='small bold'>
        Filtering on too many counties
      </p>
      <p className='small'>
        You are attempting to filter on more than one county. Please narrow down your filters to within just one county to see accurate parcel counts and exports.
      </p>
      <div className="btn btn-primary btn-round"
        onClick={() => handleClickTab('query')}
      >
        Go to Filter 
      </div>
    </div>

  const generic = <div className="centered">
      <p className='small bold'>
        Your current selection includes public sources that cannot be exported
      </p>
    </div>

  // set content dynamically based on props
  let content
  if (contentType === 'price') {
    content = priceWarning
  } else if (contentType === 'multi') {
    content = multiWarning
  } else if (contentType === 'emptyQuery') {
    content = emptyWarning
  } else if (contentType === 'generic') {
    content = generic
  }
  return (
    <>
      <div className="exp-warning padded rounded">
        <div
          className="exp-warning-label"
          type="button"
          data-toggle="collapse"
          data-target={`#exp-warning-${contentType}`}
          aria-expanded="true"
          aria-controls={`exp-warning-${contentType}`}
          onClick={handleDropdown}
        >
          <p className="bold margin-none">
            { 
              // empty ensures even flex spacing 
            }
          </p>
          <p className="bold margin-none">
            <i className="fas fa-exclamation-triangle"></i>
            Heads Up!
          </p>
          <i className="fa fa-caret-down rotate-0" aria-hidden="true"></i>
        </div>
        <div id={`exp-warning-${contentType}`} className='collapse in'>
          { content }
        </div> 
      </div>     
    </>
  )
})

export default withStore(ExportWarning)