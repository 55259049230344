import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import withStore from '../withStore'
import { fetchCartIfNeeded, getCart, updateCart } from 'ducks/cart'
import {
  find,
  get,
  has,
  isEqual,
  isEmpty,
  isString,
  extend,
} from 'lodash'
import ProductOption from './ProductOption'

/**
 * Represents a product selector component.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.headline - The headline of the product selector.
 * @param {string} props.path - The path of the product selector.
 * @param {boolean} props.premiumAvailable - Indicates if premium is available.
 * @param {Array} props.products - The list of available products.
 * @param {boolean} props.shapefileTooLarge - Indicates if the shapefile is too large.
 * @param {boolean} props.buildingsNotAvailable - Indicates if buildings are not available.
 * @returns {JSX.Element} The rendered component.
 */
const ProductSelector = ({
  headline,
  path,
  premiumAvailable,
  products,
  shapefileTooLarge,
  buildingsNotAvailable,
  zoningNotAvailable,
  zoningPercentage,
  country,
}) => {
  const isEsri = window.location.pathname.includes('/store/esri')
  const cart = useSelector(getCart)
  const dispatch = useDispatch()
  const [productInCart, setProductInCart] = useState()
  const [productsInCart, setProductsInCart] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [selectedAddons, setSelectedAddons] = useState([])
  const [allowAddons, setAllowAddons] = useState(false)
  const [isCartUpdated, setCartUpdated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const [standardBuildingsNotAvailable, setStandardBuildingsNotAvailable] = useState(buildingsNotAvailable)
  const [enhancedBuildingsNotAvailable, setEnhancedBuildingsNotAvailable] = useState(buildingsNotAvailable)

  // Don't sell premium if we don't have it here
  if (!premiumAvailable) {
    products = products.filter((product) => product.variant != 'premium')
  }

  const premiumProduct = find(products, { id: 'premium' })
  const standardProduct = find(products, {id: 'standard'})
  let selectedProductIsAddon = get(selectedProduct, 'addon', false)
  let selectedProductIsPremium = get(selectedProduct, 'id', '') === 'premium'
  let cartProductIsAddon = get(productInCart, 'addon', false)
  // let cartProductIsPremium = get(productInCart, 'id', '') === 'premium'
  let cartProductIsPremium = allowAddons

  // TODO This should check selectedAddons and the cart
  let isCartEmpty = isEmpty(productsInCart)
  let allowMultiCart = cartProductIsPremium // TODO these are the same, remove

  // Set the selected product based on the URL params, i.e. '?product=standard'
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const selectedProductParam = urlParams.get('product')

    if (selectedProductParam) {
      const selectedProductFromParams = products.find(
        (product) => product.id === selectedProductParam
      )
      setSelectedProduct(selectedProductFromParams)
    } else {
      if (country === 'US') {
        setSelectedProduct(premiumProduct)
      } else {
        setSelectedProduct(standardProduct)
      }
  
    }
  }, [])

  // Fetch the initial cart if needed
  useEffect(() => {
    dispatch(fetchCartIfNeeded())
  }, [path])

  /**
   * This useEffect hook is used to update the state of the cart and selected product based on changes in the cart items and path.
   *
   * @callback useEffect
   *
   * The callback function does the following:
   * 1. Gets the keys of the cart items.
   * 2. Filters the keys to get the ones that match the current path.
   * 3. Maps the keys to their corresponding products.
   * 4. Filters out any undefined items.
   * 5. If the cart items have changed, updates the state.
   * 6. If there's exactly one item in the cart and it's different from the current product, updates the state.
   * 7. If there are multiple items in the cart, finds the first one that matches a product and sets it as the selected product.
   * 8. If premium is in the cart, sets allowAddons to true.
   *
   * @returns {void} - Nothing to clean up.
   */
  useEffect(() => {
    // Get the keys of the cart items
    const cartKeys = Object.keys(cart.items)

    // Filter the cart keys to get the ones that match the current path
    const inCart = cartKeys
      .filter((key) => cart.items[key].path === path)
      .map((key) => {
        // For each key, find the corresponding product
        const item = cart.items[key]
        return products.find(
          (product) =>
            product.format === item.format && product.variant === item.variant
        )
      })
      .filter(Boolean) // Remove undefined items


    const standardBuildingsInCart = inCart.find((product) => product.id === 'buildings-addon')
    const enhacedBuildingsInCart = inCart.find((product) => product.id === 'enhanced-buildings-addon')

    handleBuildingsAddon(standardBuildingsInCart || enhacedBuildingsInCart)

    // If the cart items have changed, update the state
    if (!isEqual(inCart, productsInCart)) {
      setProductsInCart(inCart)
      setCartUpdated(true)
    }

    // If there's exactly one item in the cart, and it's different from the current product, update the state
    if (inCart.length === 1 && !isEqual(inCart[0], productInCart)) {
      setProductInCart(inCart[0])
      setCartUpdated(true)
    }

    // If there are multiple items in the cart, find the first one that matches a product and set it as the selected product
    if (inCart.length > 1) {
      const matchingProduct = products.find((product) =>
        inCart.some((item) => item.id === product.id)
      )
      if (matchingProduct) {
        setProductInCart(matchingProduct)
        setCartUpdated(true)
      }
    }

    // Check if any of the items in the cart is premium
    const isPremiumInCart = inCart.some(
      (item) => get(item, 'id', '') === 'premium'
    )

    // Set allowAddons to true only if a premium product is in the cart
    if (isPremiumInCart && !allowAddons) {
      setAllowAddons(true)
    } else if (!isPremiumInCart && allowAddons) {
      setAllowAddons(false)
    }
  }, [path, cart])

  useEffect(() => {
    // Check if addon IDs in selectedAddons match addon IDs in the cart
    const addonIdsInCart = productsInCart
      .filter((item) => item?.addon)
      .map((addon) => addon?.id)

    const selectedAddonIds = selectedAddons.map((addon) => addon?.id)

    const allAddonsInCart = _.isEqual(
      _.sortBy(selectedAddonIds),
      _.sortBy(addonIdsInCart)
    )

    // Compare and update state
    if (!allAddonsInCart) {
      const matchingAddons = addonIdsInCart.map((addonId) =>
        products.find((product) => product.id === addonId)
      )
      setSelectedAddons(matchingAddons)
      if (isCartUpdated === true) {
        setCartUpdated(false)
      }
    } else {
      if (isCartUpdated === false) {
        setCartUpdated(true)
      }
    }
  }, [selectedAddons, productsInCart, isCartUpdated])

  const updateCartWithProduct = (opts) => {
    setLoading(true)
    setError(null)

    LL.Ajax.postSpin(
      opts.state ? '/orders/cart/add_state.json' : '/orders/cart/add.json',
      opts
    )
      .then((response) => {
        debug('Success updating cart with product', response)
        dispatch(updateCart(response.cart))
      })
      .catch((err) => {
        debug('Error updating cart with product', err)
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const removeProductFromCart = (removeOpts) => {
    setLoading(true)
    setError(null)

    LL.Ajax.postSpin('/orders/cart/remove.json', removeOpts)
      .then((response) => {
        debug('Success removing product from cart', response)
        dispatch(updateCart(response.cart))
      })
      .catch((err) => {
        debug('Error removing product from cart', err)
        setError(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleAddonSelection = (product, isChecked) => {
    const index = selectedAddons.findIndex(
      (selectedAddon) => selectedAddon.id === product.id
    )

    if(product.id === 'buildings-addon' || product.id === 'enhanced-buildings-addon'){
      handleBuildingsAddon(product)
    }

    if (isChecked) {

      if (index === -1) {
        setSelectedAddons([...selectedAddons, product])
      } else {
        setSelectedAddons([
          ...selectedAddons.slice(0, index),
          ...selectedAddons.slice(index + 1),
        ])
      }

      const opts = extend({}, product, {
        fmt: selectedProduct.format,
        path: path,
      })

      updateCartWithProduct(opts)
    } else {
      setSelectedAddons([
        ...selectedAddons.slice(0, index),
        ...selectedAddons.slice(index + 1),
      ])

      const removeOpts = { path: `${path}:${product.variant}` }
      removeProductFromCart(removeOpts)
    }
  }

  const handleBuildingsAddon = (product) => {
    const buildingsAddonSelected = product?.id === 'buildings-addon';
    const enhancedBuildingsAddonSelected = product?.id === 'enhanced-buildings-addon';
    const buildingsAddonInSelectedAddons = selectedAddons.find((addon) => addon.id === 'buildings-addon');
    const enhancedBuildingsAddonInSelectedAddons = selectedAddons.find((addon) => addon.id === 'enhanced-buildings-addon');

    setStandardBuildingsNotAvailable(false)
    setEnhancedBuildingsNotAvailable(false)

    let removeOpts = null

    if(buildingsAddonSelected){
      setEnhancedBuildingsNotAvailable(true)

      if(enhancedBuildingsAddonInSelectedAddons){
        removeOpts = { path: `${path}:${enhancedBuildingsAddonInSelectedAddons.variant}` }
      }
    }

    if(enhancedBuildingsAddonSelected){
      setStandardBuildingsNotAvailable(true)

      if(buildingsAddonInSelectedAddons){
        removeOpts = { path: `${path}:${buildingsAddonInSelectedAddons.variant}` }
      }
    }

    if(removeOpts){
      setTimeout(() => {
        removeProductFromCart(removeOpts) // to avoid race condition when adding buildings addon
      }, 1000);
    }
  };

  const onSelect = (product, isChecked) => {
    if (product?.addon) {
      handleAddonSelection(product, isChecked)
    } else {
      setSelectedProduct(product)
    }

    debug('ec:click ', product)
    xga('ec:addProduct', ecProduct(product))
    xga('ec:setAction', 'click')
    trackEvent('store', 'select', product.id)
    updateCoverage(product.id)
  }

  const updateCoverage = (id) => {
    // TODO - handle enhanced owner and zoning
    const addTds = document.querySelectorAll('td.addresses-premium')
    const buildTds = document.querySelectorAll('td.buildings-premium')
    debug('updateCoverage id', id)
    if (id === 'addresses-addon') {
      addTds.forEach((td) => td.classList.remove('disabled'))
      buildTds.forEach((td) => td.classList.add('disabled'))
    } else if (id === 'buildings-addon') {
      buildTds.forEach((td) => td.classList.remove('disabled'))
      addTds.forEach((td) => td.classList.add('disabled'))
    }
  }

  // TODO: is this GA push still relevant?
  // Format a product object for GA EC
  // (This is also in non-react-land in cart.html.haml, make sure it's in sync)
  const ecProduct = (product) => {
    return {
      id: product.id,
      name: product.name,
      path: product.path,
      format: product.format,
      variant: product.variant,
      price: product.price,
      category: 'parcel-data',
    }
  }

  const onClickAdd = (product) => {
    // If product is passed in use it, otherwise rule out synth event
    const productIsSynth = !(has(product, 'id') && has(product, 'format'))

    if (productIsSynth) {
      product = selectedProduct
    }

    // Measure a product
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#add-remove-cart

    // If a variant is already in the cart, we need to remove it
    if (productInCart) {
      // Unless it's an addon, then allow multiple from same county/state
      if (!allowMultiCart && !cartProductIsAddon) {
        debug('ec:removing ', productInCart)
        xga('ec:addProduct', ecProduct(productInCart))
        xga('ec:setAction', 'remove')
        trackEvent('store', 'remove-from-cart', product.id)
      } else {
        debug('ec letting this be added: ', productInCart)
      }
    }

    // If the selected product is not premium, remove all addons from cart and deselect all addons
    if (!selectedProductIsPremium) {
      setSelectedAddons([]) // Deselect all addons
    }

    // Then mark it as added to the cart
    debug('ec:adding', product)
    xga('ec:addProduct', ecProduct(product))
    xga('ec:setAction', 'add')
    trackEvent('store', 'add-to-cart', product.id)

    var opts = extend({}, product, {
      fmt: product.format,
      path: path,
    })

    setError(false)
    setLoading(true)
    LL.Ajax.postSpin(
      opts.state ? '/orders/cart/add_state.json' : '/orders/cart/add.json',
      opts
    ).then(
      function (response) {
        debug('Success adding product to cart', response)
        dispatch(updateCart(response.cart))
        setLoading(false)
      },
      function (err) {
        debug('Error adding product to cart', err)
        setError(err)
        setLoading(false)
      }
    )
  }

  const handlePremiumCTA = (e) => {
    e.preventDefault()
    if (!selectedProductIsPremium) {
      setSelectedProduct(premiumProduct)
    }
    onClickAdd(premiumProduct)
  }

  let addButton
  const btnClassNames = isEsri ? 'btn btn-default' : 'btn btn-default btn-round'
  if (loading) {
    addButton = (
      <button className={`${btnClassNames} btn-disabled`} disabled>
        <i className="fas fa-spinner fa-spin"></i> Adding to cart
      </button>
    )
  } else if (productInCart && selectedProduct) {
    if (productInCart.id !== selectedProduct.id) {
      addButton = (
        <button className={btnClassNames} onClick={onClickAdd}>
          Update cart
        </button>
      )
    } else {
      addButton = null
    }
  } else {
    addButton = (
      <button className={btnClassNames} onClick={onClickAdd}>
        Add to cart
      </button>
    )
  }

  const errorMessage = error && (
    <p className="alert alert-warning">
      {isString(error)
        ? error
        : "Sorry, we were not able to add this place to your cart. Please reach out to <a href='mailto:parcels@regrid.com'>parcels@regrid.com</a> for assistance."}
    </p>
  )

  let cartButtonSquare = (
    <a
      href={`/orders/${isEsri ? 'esri/' : ''}cart?from=${path}`}
      className="btn btn-primary flex-col-center"
    >
      <span>
        View Cart
        <i className="fas fa-arrow-right margin-left-md"></i>
      </span>
    </a>
  )

  /**
   * Returns an array of ProductOption components based on the given products.
   *
   * @param {Array} products - The array of products, passed from rails view.
   * @returns {Array} - An array of ProductOption components.
   */
  const getOptionFields = (products) => {
    return products.map((product, index) => {
      const cartItemIndex = productsInCart.findIndex(
        (item) =>
          item?.format === product?.format && item?.variant === product?.variant
      )
      const isProductInCart = cartItemIndex !== -1
      let includedProductNames = ''

      if (!loading && isProductInCart) {
        // Filter and map the product names for included products (excluding addons and the selected product)
        includedProductNames = products
          .filter((currentProduct, i) => i < index && !currentProduct.addon)
          .map((product) => product.name)
          .join(', ')
      }
      return (
        <ProductOption
          product={product}
          key={product.id}
          selectedProduct={selectedProduct}
          selectedAddons={selectedAddons}
          onSelect={onSelect}
          shapefileTooLarge={shapefileTooLarge}
          buildingsNotAvailable={buildingsNotAvailable}
          standardBuildingsNotAvailable={standardBuildingsNotAvailable}
          enhancedBuildingsNotAvailable={enhancedBuildingsNotAvailable}
          inCart={isProductInCart}
          includedProducts={includedProductNames}
          path={path}
          addButton={addButton}
          onClickAdd={onClickAdd}
          headline={headline}
          allowMultiCart={allowMultiCart}
          isCartEmpty={isCartEmpty}
          allowAddons={allowAddons}
          isCartUpdated={isCartUpdated}
          zoningNotAvailable={zoningNotAvailable}
          zoningPercentage={zoningPercentage}
        />
      )
    })
  }

  let tabularProducts = []
  let baseProducts = []
  let addonProducts = []

  products.forEach((product) => {
    // debug('ProductSelector - each product: ', product)
    if (product.format === 'spreadsheet') {
      tabularProducts.push(product)
    } else if (product.format !== 'shapefile' && !product?.addon) {
      // debug('product', product)
      // Core products, addons are not included
      baseProducts.push(product)
    } else if (product.addon && !product?.legacy) {
      addonProducts.push(product)
    }
  })

  const tabularOptions = getOptionFields(tabularProducts)
  const baseProductOptions = getOptionFields(baseProducts)
  const addonOptions = getOptionFields(addonProducts)

  const buttonClassNames = isEsri
    ? 'btn btn-default btn-sm'
    : 'btn btn-default btn-round btn-sm'

  return (
    <form className="buyButton padded">
      <div className="tools">
        <h4>Select a Product Schema</h4>
      </div>
      {errorMessage}
      <div className="options">
        {baseProducts.length > 0 && (
          <div className="options-section padded rounded">
            {baseProductOptions}
            {addonProducts.length > 0 && (
              <div className="addon-section option padded rounded margin-top-md">
                <p className="options-section-title bold">Premium Addons:</p>
                {!allowAddons && (
                  <div className="alert alert-warning centered">
                    <p className="small margin-btm-md">
                      <i className="fas fa-info-circle margin-right-md"></i>
                      Addon datasets are only available with our Premium Parcel
                      Data.
                    </p>
                    <button
                      className={buttonClassNames}
                      onClick={handlePremiumCTA}
                    >
                      Add Premium to Cart
                    </button>
                  </div>
                )}
                <div
                  className="form-group"
                  id="addons-accordion"
                  role="tablist"
                  aria-multiselectable="true"
                >
                  {addonOptions}
                </div>
              </div>
            )}
          </div>
        )}
        {tabularProducts.length > 0 && (
          <div className="options-section tabular-section padded rounded margin-top-md">
            <p className="options-section-title bold museo">
              CSV-Only Formats:
            </p>
            {tabularOptions}
          </div>
        )}
      </div>
      {errorMessage}
      <div className="tools secondary">{cartButtonSquare}</div>
    </form>
  )
}

ProductSelector.propTypes = {
  cart: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  premiumAvailable: PropTypes.bool,
  products: PropTypes.array.isRequired,
  shapefileTooLarge: PropTypes.bool.isRequired,
  buildingsNotAvailable: PropTypes.bool,
}

export default withStore(ProductSelector)
