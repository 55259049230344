import React from 'react'
import { Layer } from 'react-map-gl'
import { useSelector } from 'react-redux'
import withStore from 'components/withStore'

// Redux
import {
  getCurrentFloatLayer,
  getSatelliteLayerRendered
} from 'ducks/mapProperties'
import { floatLayers } from '../MapControls/MapOptions/floatLayers'
import mapSettings from '../mapSettings'

/**
 * This component is responsible for displaying floating labels on the map.
 * It fetches the current active float layer from the redux state
 * and creates a map layer configuration based on its properties.
 * This either uses data sourced from vector parcels (for general labels) or use
 * dimensions data that represents a single parcel calculated in and sourced
 * from the SelectedParcelLayer component.
 *
 * If the active float layer is 'no-float' or if no valid active float layer is found,
 * the component will not render anything.
 *
 * @component
 * @returns {React.Element} | null - Returns a Layer component if there's a valid active float layer.
 */
const FloatLabelsLayer = () => {
  const satelliteLayerRendered = useSelector(getSatelliteLayerRendered)
  const activeFloatLayerId = useSelector(getCurrentFloatLayer)
  const { street, satellite } = mapSettings.styles

  if (activeFloatLayerId === mapSettings.layerIds.noFloat) return null

  // Find the current active layer from the floatLayers array
  const activeFloatLayer = floatLayers.find(
    layer => layer.id === activeFloatLayerId
  )

  // If no layer was found, return null
  if (!activeFloatLayer) {
    return null
  }

  const isDimensionLayer = activeFloatLayerId === 'float-dimensions-ft' || activeFloatLayerId === 'float-dimensions-m'

  const { mapId, field, sourceLayer } = activeFloatLayer

  // Set up layer config
  const labelsConfig = {
    id: mapId,
    type: 'symbol',
    source: mapSettings.sourceIds.vectorParcels,
    'source-layer': sourceLayer,
    layout: {
      visibility: 'visible',
      'text-field': ['get', field],
      'text-size': 15,
      'text-font': ['Arial Unicode MS Bold'],
    },
    paint: {
      'text-color': satelliteLayerRendered
        ? satellite.labelTextColor
        : street.labelTextColor,
      'text-halo-color': satelliteLayerRendered
        ? satellite.labelHaloColor
        : street.labelHaloColor,
      'text-halo-width': 2
    }
  }

  const dimensionsConfig = {
    // dimensions data is a single parcel calculated in and Sourced from the SelectedParcelLayer component.
    id: mapId,
    type: 'symbol',
    source: 'midpoints-source',
    layout: {
      'text-field': '{label}',
      'text-size': 16,
      'text-offset': [1, 0], // shifts text 1em to the right
      'text-font': ['Arial Unicode MS Bold'],
    },
    paint: {
      'text-color': '#000',
      'text-halo-color': '#ffffff',
      'text-halo-width': 2
    }
  }

  const layerConfig = isDimensionLayer ? dimensionsConfig : labelsConfig
  debug('FloatLabelsLayer layerConfig.layout', layerConfig.layout)

  return <Layer key={mapId} {...layerConfig} />
}

export default withStore(FloatLabelsLayer)
