import React from 'react'
import mapSettings from '../mapSettings'
import { Layer, Source } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { getSatelliteLayerRendered } from 'ducks/mapProperties'

const ElevationLayer = ({ layer }) => {
  const isSatellite = useSelector(getSatelliteLayerRendered)
  const layerStyle = mapSettings.thirdPartyLayers.elevation
  const sourceOpts = layer?.sourceOpts || {}
  const hasLabelLayer = layer?.labelOpts || false

  const lineOpts = {
    id: `${layer.id}-layer`,
    type: 'line',
    source: `${layer.id}-source`,
    'source-layer': `${layer.dataLayer}`,
    paint: {
      'line-color': isSatellite
        ? layerStyle.lineColorSatellite
        : layerStyle.lineColorStreet,
      'line-width': 2,
    },
    ...layer.opts,
  }

  // const casingOpts = {
  //   id: `${layer.id}-casing-layer`,
  //   type: 'line',
  //   source: `${layer.id}-source`,
  //   'source-layer': `${layer.dataLayer}`,
  //   paint: {
  //     'line-color': '#fffff0',
  //     'line-width': 3.5,
  //   },
  //   ...layer.opts,
  // }

  // add label layer for elevation
  const labelLayer = hasLabelLayer
    ? {
        id: `${layer.id}-label-layer`,
        type: 'symbol',
        source: `${layer.id}-source`,
        'source-layer': `${layer.dataLayer}`,
        layout: {
          'text-field': layer?.labelOpts.textField || '',
          'text-font': ['Arial Unicode MS Bold'],
          'text-size': layerStyle.labelTextSize,
          'symbol-placement': 'line', // Align symbols to the line
          'symbol-spacing': 350, // Distance between two symbol anchors in pixels
          'text-offset': [0, -0.5], // [x,y] Offset upwards from the line in em units
        },
        paint: {
          'text-color': !isSatellite
            ? layerStyle.labelTextColorStreet
            : layerStyle.labelTextColorSatellite,
          'text-halo-color': !isSatellite
            ? layerStyle.labelHaloColorStreet
            : layerStyle.labelHaloColorSatellite,
          'text-halo-width': layerStyle.labelHaloWidth,
          'text-halo-blur': layerStyle.labelHaloBlur,
        },
      }
    : null

  return (
    <>
      <Source
        id={`${layer.id}-source`}
        type="vector"
        {...sourceOpts}
      >
        {/* <Layer {...casingOpts} /> */}
        <Layer {...lineOpts} />
        {hasLabelLayer && <Layer {...labelLayer} />}
      </Source>
    </>
  )
}

export default ElevationLayer
