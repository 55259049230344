import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { countryCodeFromPath } from '../mapUtilities'
import BoundariesList from './MapOptions/Bounds/BoundariesList'
import LabelsList from './MapOptions/Labels/LabelsList'
import LayersList from './MapOptions/Layers/LayersList'
import BaseLayersList from './BaseLayersList'

/**
 * Renders the MapLayersContent component.
 *
 * @component
 * @param {Function} props.handleClose - The function to handle closing the component and relevant modals.
 * @returns {JSX.Element} The rendered MapLayersContent component.
 */
const MapLayersContent = ({ handleClose }) => {
  const workingBoundary = useSelector((state) => state.boundaries.workingBoundary)
  // Country code determines available layers content
  const workingCountryCode = workingBoundary ? countryCodeFromPath(workingBoundary) : null
  const isWorkingInUSBoundary = (workingCountryCode === 'us') // boolean
  const [isOpen, setIsOpen] = useState({
    sectionLabels: true,
    sectionBoundaries: false, // default to closed
    sectionLayers: true,
    sectionBasemaps: true,
  })

  const toggleSection = (e, section) => {
    setIsOpen((prevState) => ({ ...prevState, [section]: !prevState[section] }))
    // toggle the angle icon animation
    e.currentTarget.lastChild.classList.toggle('rotate-180')
  }

  return (
    <div className="map-layers-modal rounded-lg shadow-gl padded">
      <div className="map-options-title-row padding-top-lg">
        <h3 className="map-options-title">Layers</h3>
        <div className="close" onClick={handleClose}>
          <i className="fa fa-times"></i>
        </div>
      </div>
      <div className="map-options-content">
        <div
          className={`map-options-content-header${
            !isOpen.sectionBoundaries ? ' collapsed-header' : ''
          }`}
          onClick={(e) => toggleSection(e, 'sectionBoundaries')}
        >
          <h4 className="">Boundaries</h4>
          <i className="fa fa-angle-down h4 rotate-0"></i>
        </div>
        <div
          className={`map-options-content-body collapse ${
            isOpen.sectionBoundaries ? 'in' : ''
          }`}
        >
          <BoundariesList
            parent="map-layers"
            isWorkingInUSBoundary={isWorkingInUSBoundary}
          />
        </div>
        <div
          className={`map-options-content-header${
            !isOpen.sectionBasemaps ? ' collapsed-header' : ''
          }`}
          onClick={(e) => toggleSection(e, 'sectionBasemaps')}
        >
          <h4 className="">Basemaps</h4>
          <i className="fa fa-angle-up h4 rotate-0"></i>
        </div>
        <div
          className={`map-options-content-body collapse ${
            isOpen.sectionBasemaps ? 'in' : ''
          }`}
        >
          <BaseLayersList handleClose={handleClose} />
        </div>
        <div
          className={`map-options-content-header${
            !isOpen.sectionLabels ? ' collapsed-header' : ''
          }`}
          onClick={(e) => toggleSection(e, 'sectionLabels')}
        >
          <h4 className="">Labels</h4>
          <i className="fa fa-angle-up h4 rotate-0"></i>
        </div>
        <div
          className={`map-options-content-body collapse ${
            isOpen.sectionLabels ? 'in' : ''
          }`}
        >
          <LabelsList
            isWorkingInUSBoundary={isWorkingInUSBoundary}
            workingCountryCode={workingCountryCode}
          />
        </div>
        <div
          className={`map-options-content-header${
            !isOpen.sectionLayers ? ' collapsed-header' : ''
          }`}
          onClick={(e) => toggleSection(e, 'sectionLayers')}
        >
          <h4 className="">Additional Layers</h4>
          <i className="fa fa-angle-up h4 rotate-0"></i>
        </div>
        <div
          className={`map-options-content-body collapse ${
            isOpen.sectionLayers ? 'in' : ''
          }`}
        >
          <LayersList
            isWorkingInUSBoundary={isWorkingInUSBoundary}
            workingCountryCode={workingCountryCode}
          />
        </div>
      </div>
    </div>
  )
}

export default MapLayersContent
