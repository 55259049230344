import React from 'react'
import { PLANS, CYCLES } from 'common/settings'
import PropTypes from 'prop-types'
import EnsureUser from 'components/Payment/EnsureUser'
import PlanCycle from 'components/Payment/PlanCycle'
import CardForm from 'components/Payment/CardForm'
import BigSpinner from 'components/Utilities/BigSpinner'

const PlanOptions = (props) => {
  const { PRO } = PLANS
  const { ANNUAL, MONTHLY } = CYCLES

  let selectedPlan = props.selectedPlan === "api_combo" 
  ? `api_${props.selectedApiTier}` : props.selectedPlan;

  let selectedDetails = _.cloneDeep(
    _.find(props.plans, { plan: selectedPlan })
  )
  const proPlanSelected = selectedPlan === PRO
  let features = []
  const isApi = props?.isApi || false

  if (isApi) {
    //reset selectedDetails for api bc the selectedPlan is called api_access but the plans are "api_standard" or "api_premium"
    selectedDetails.prices = (!_.isNil(props.offer) && selectedPlan.includes(props.offer?.api_access)) 
      ? { monthly: props.offer.price } 
      : _.pick(selectedDetails.prices, 'monthly');
    
    features = selectedDetails.included
  } else {
    features = selectedDetails.features
  }
  let validGroupName =
    !!props.group ||
    (props.groupName.length >= 3 &&
      props.groupName.toLowerCase() !== 'united states' &&
      /[a-z]/i.test(props.groupName) &&
      !/\b(?:https?:\/\/)?(?:www\.)?[a-z0-9-]+\.(?:[a-z]{2,})(?:\.[a-z]{2,})?\b/i.test(props.groupName)
    )
  // .match returns an array of matches, not a boolean. Use test() instead.
  // ((props.groupName.length >= 3) && props.groupName.toLowerCase() !== 'united states' && props.groupName.match(/[a-z]/i))
  let groupWarn =
    'It appears you have already selected this account name, please try another.'
  let readyToSubmit =
    props.user &&
    props.agree &&
    !props.isGroupDupe &&
    selectedPlan &&
    props.selectedCycle &&
    !props.submitting

  let stepContent = null
  let annualDisabled =
    !selectedDetails.prices.annual ||
    selectedDetails.plan === PLANS.TEAM_FLEX ||
    !!isApi

  const perSeatPricingSelected = selectedDetails.per_seat_pricing
  const perSeatPrice = props?.offer?.per_seat_price
    ? props.offer.per_seat_price
    : selectedDetails.prices.monthly
  let annualPrice = `$${numberWithCommas(selectedDetails.prices.annual)}/year`
  let monthlyPrice = perSeatPricingSelected
    ? `$${numberWithCommas(perSeatPrice)}/month per user`
    : `$${numberWithCommas(selectedDetails.prices.monthly)}/month`
  const monthlyDynamicPricing = perSeatPricingSelected
    ? `$${numberWithCommas(perSeatPrice * props.numberOfUsers)}/month`
    : `$${numberWithCommas(selectedDetails.prices.monthly)}/month`
  const updateText = perSeatPricingSelected
    ? `plan for ${props.numberOfUsers} users at `
    : ' plan at '
  const planName = selectedDetails.name.toLowerCase()
  let priceWithCycle =
    props.selectedCycle === ANNUAL ? annualPrice : monthlyPrice
  const dynamicPricingWithCycle =
    props.selectedCycle === ANNUAL ? annualPrice : monthlyDynamicPricing
  const displaySlider =
    (perSeatPricingSelected || selectedPlan === PRO) && !props.offer

  let isStarter = selectedPlan == PLANS.FREE

  if (!props.user) {
    // First you need to sign in
    stepContent = (
      <EnsureUser
        user={props.user}
        onSignedIn={props.onSignedIn}
        selectedPlan={selectedPlan}
        currentPlan={props.currentPlan}
      />
    )
    // Since login step is before cycle selector, show both in sidebar
    if (_.isNil(selectedDetails.prices.annual) || isStarter) {
      priceWithCycle = <>{monthlyPrice}</>
    } else {
      priceWithCycle = (
        <>
          {annualPrice} or
          <br />
          {monthlyPrice}
        </>
      )
    }
  } else {
    // Select options as third & final step on new accounts
    let payAndConfirm = null
    let paymentRequired = !!selectedDetails.prices[props.selectedCycle]
    let agreement = (
      <label className="checkbox">
        <input
          type="checkbox"
          onChange={props.onChangeAgree}
          checked={props.agree}
        />{' '}
        I understand and agree to these details and&nbsp;
        {/* {selectedDetails.api ? <> */}
        {isApi ? (
          <>
            the{' '}
            <a href="/hs/terms/api" target="agreement" data-skip-pjax="1">
              API/Tileserver Terms of Service
            </a>
          </>
        ) : (
          <>
            the{' '}
            <a
              href="/hs/terms/subscription"
              target="agreement"
              data-skip-pjax="1"
            >
              Regrid Subscription Agreement
            </a>
          </>
        )}
        , including cancellation & refund policy
        {false && isApi ? <>, as well as the API Terms of Use.</> : '.'}
      </label>
    )

    if (paymentRequired) {
      payAndConfirm = (
        <>
          <h3>Payment</h3>
          <p className="lead">
            You are signing up for the{' '}
            <em className={`plan-${planName}`}>
              {selectedDetails.name} ({props.selectedCycle})
            </em>{' '}
            {updateText}{' '}
            <em className={`plan-${planName}`}>{dynamicPricingWithCycle}</em>.{' '}
            {props.trialSelected ? (
              <>
                Your card will first be charged on{' '}
                <em className={`plan-${planName}`}>
                  {moment().add(7, 'd').format('MMMM D')}
                </em>{' '}
                when the free trial ends, and then{' '}
              </>
            ) : (
              'Your card will be charged today and then '
            )}
            once a {{ annual: 'year', monthly: 'month' }[props.selectedCycle]}.{' '}
            {isApi &&
              'Usage beyond the limits will be billed at the end of the month at the rate shown. '}
          </p>
          <p>{`Is valid groupname? ${validGroupName}`}</p>
          {/* {selectedDetails.api && "Usage beyond the limits will be billed at the end of the month at the rate shown. "} */}
          <CardForm
            stripeKey={props.stripeKey}
            disabled={!readyToSubmit}
            buttonLabel={props.submitting ? 'Please wait...' : 'Confirm Order'}
            onConfirmed={props.onConfirmed}
            onValidCard={props.onValidCard}
            onChangeGoals={props.onChangeGoals}
            goals={props.goals}
            validGroupName={validGroupName}
            error={props.error}
            agreement={agreement}
          />
        </>
      )
    } else {
      payAndConfirm = (
        <>
          You are signing up for a complimentary <em>{selectedDetails.name}</em>{' '}
          plan. There will be no charges unless payment information is added in
          the future. {agreement}
          <div className="centered">
            <br />
            <a
              href="#"
              className="btn btn-lg btn-primary btn-round"
              disabled={!readyToSubmit}
              onClick={(e) => {
                props.onConfirmed(null)
              }}
            >
              Create Free Account
            </a>
          </div>
        </>
      )
    }
    // If Starter (level0) don't show 'Step 2 of 2' form.
    {
      isStarter ? (
        <React.Fragment>
          <div className="centered vertical-mid margin-xl">
            <i className="fas fa-spinner fa-spin big"></i>
          </div>
          {(window.location.href = window.location.origin)}
        </React.Fragment>
      ) : (
        (stepContent = (
          <>
            <PlanCycle
              annualDisabled={annualDisabled}
              selectedCycle={props.selectedCycle}
              prices={selectedDetails.prices}
              onChangeCycle={props.onChangeCycle}
            />

            <h3>Name Your Account</h3>
            <p>
              What would you like to call your account? This could be your
              organization or company's name or your intended use for Regrid.
              Please keep it short and sweet (from 3-100 characters). This will
              also be the URL for your account (<b>Acme Widgets</b> becomes{' '}
              <code>acme-widgets.regrid.com</code>).*
            </p>
            <input
              type="text"
              className="form-control input-lg"
              autoComplete="off"
              value={(props.group && props.group.name) || props.groupName}
              disabled={!!props.group}
              onChange={props.onChangeGroupName}
              style={{ marginBottom: '2em' }}
              onBlur={props.onBlurIsGroupDupe}
            />
            {props.isGroupDupe && (
              <p className="alert alert-warning card-errors" role="alert">
                {groupWarn}
              </p>
            )}

            {displaySlider && (
              <div className="row">
                <div className="col-md-12">
                  <h3>Number of Users: {props.numberOfUsers}</h3>
                  {proPlanSelected && (
                    <p>
                      Drag the slider to add users to your group, or continue as
                      is for a single-user Pro account. You can always add more
                      users later from your dashboard. The ability to add users
                      is only available on the web version of the platform.
                    </p>
                  )}

                  {perSeatPricingSelected && (
                    <p>
                      Team accounts are available only as a monthly
                      subscription. The ability to add users is only available
                      on the web version of the platform.
                    </p>
                  )}
                  <input
                    className="range-slider"
                    type="range"
                    name="slider"
                    min="1"
                    max="25"
                    value={props.numberOfUsers}
                    onChange={(e) =>
                      props.onChangeNumberOfUsers(e.target.value)
                    }
                  />
                </div>
              </div>
            )}
            {payAndConfirm}
          </>
        ))
      )
    }
  }

  return (
    <div className="row plan-option-row mx-auto">
      <div
        className={`col-md-4 selected-plan plan-${planName}${
          isApi ? ' plan-self-serve-accent' : ''
        }`}
      >
        <label>Your selected plan:</label>
        <h2 className={isApi ? 'white' : ''}>{selectedDetails?.name}</h2>

        <div className="lead" style={{ marginTop: '0' }}>
          {priceWithCycle}
        </div>
        <ul className="simple">
          {_.map(features, (feat, name) => {
            if (typeof feat === 'string') {
              return isApi ? (
                <li key={feat}>
                  <i className="fas fa-check margin-right-md"></i>
                  <span dangerouslySetInnerHTML={{ __html: ` ${feat}` }}></span>
                </li>
              ) : (
                <li key={name}>
                  <i className="fas fa-check margin-right-md"></i>
                  {name}
                </li>
              )
            } else {
              return (
                <React.Fragment key={name}>
                  <li>
                    <i className="fas fa-check"></i> {name}
                  </li>
                  <ul className="padding-left-lg">
                    {_.map(feat, (desc, name) => (
                      <li className="sub-item" key={name}>
                        <i className="fas fa-check margin-right-md"></i>
                        {name}
                      </li>
                    ))}
                  </ul>
                </React.Fragment>
              )
            }
          })}
        </ul>
      </div>

      <div className="col-md-7 col-md-offset-1 plan-options blue-frame">
        {stepContent}
      </div>
    </div>
  )
}

PlanOptions.propTypes = {
  user: PropTypes.object,
  stripeKey: PropTypes.string.isRequired,
  selectedPlan: PropTypes.string.isRequired,
  selectedCycle: PropTypes.string.isRequired,
  selectedApiTier: PropTypes.string,
  trialSelected: PropTypes.bool,
  groupName: PropTypes.string,
  group: PropTypes.object,
  goals: PropTypes.string,
  onChangeCycle: PropTypes.func.isRequired,
  onChangeTrial: PropTypes.func,
  onChangeAgree: PropTypes.func.isRequired,
  onConfirmed: PropTypes.func.isRequired,
  onValidCard: PropTypes.func.isRequired,
  onChangeGoals: PropTypes.func.isRequired,
  onChangeGroupName: PropTypes.func.isRequired,
  plans: PropTypes.array.isRequired,
  submitting: PropTypes.bool.isRequired,
  error: PropTypes.string,
  agree: PropTypes.bool.isRequired,
  isGroupDupe: PropTypes.bool.isRequired,
  onBlurIsGroupDupe: PropTypes.func.isRequired,
}

export default PlanOptions
