import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import SEContainer from 'components/SurveyEditor/SEContainer';
import withFlight from 'containers/withFlight';
import { filter, find, isEmpty, isEqual, map } from 'lodash';

class SEChooser extends Component {

  constructor(props) {
    super(props);

    let initialState = this.sourceState(props.sources);
    this.state = initialState;

    this.changeSurvey = this.changeSurvey.bind(this);
    this.onSaved = this.onSaved.bind(this);
  }


  render() {
    // The "situation" is a set of the editor's capabilities for the current user &
    // project. Eg do they have permission to make a new one, are they showing/editing
    // an existing one, is it editable in that case...
    let situation = this.situation();

    // Decide if the primary action should be to create a new survey (vs edit existing)
    let primaryNew = isEmpty(this.state.surveySources) ||
      (this.state.activeSource && this.state.activeSource.new);

    let surveyChooser = null;
    if(!isEmpty(this.state.surveySources)) {
      surveyChooser = <div className='form-inline'>
        <b>Choose a survey to edit:</b>
        &nbsp;
        <select className="form-control"
          value={this.state.activeSource ? this.state.activeSource.id : 'new'}
          onChange={this.changeSurvey}>
          {situation.create && <option value="new">Create a new survey</option>}
          {map(this.state.surveySources, (source, i) => {
            return <option key={i} value={source.id}>{source.name}</option>;
          })}
        </select>
      </div>;
    }
    return (
      <Fragment>
      {surveyChooser}

      {situation.asEmpty ?
        <div className='empty-hint centered'>
          <i className='fas fa-check-square' />
          <p className='lead width-75'>{situation.message}</p>
        </div> :
        <p>{situation.message}</p>}


      {this.state.activeSource && <SEContainer
        source={this.state.activeSource}
        project={this.props.project}
        disabled={!situation.edit}
        onSaved={this.onSaved} />}
        </Fragment>
    );
  }


  sourceState(sources) {
    let surveySources = filter(sources, { type: 'Survey' });
    let activeSource = this.state && this.state.activeSource;

    if(activeSource) {
      // Either keep/update it or null out if no longer in list
      activeSource = find(surveySources, { id: activeSource.id });
    } else if(surveySources.length > 0) {
      // Pick the first one to show something
      activeSource = surveySources[0];
    } else {
      // Show a fresh survey form if empty project
      activeSource = this.newSurveySource();
    }
    return {
      surveySources: surveySources,
      activeSource:  activeSource,
    };
  }


  componentDidUpdate(prevProps, prevState) {
    let newState = this.sourceState(this.props.sources);
    let prevSources = prevState.surveySources;
    let newSources = newState.surveySources;
    if(!isEqual(prevSources, newSources)) {
      debug('sechooser: source list changed');
      this.setState(newState);
    }
  }


  newSurveySource() {
    return {
      id:             guid(),
      new:            true,
      type:           'Survey',
      permissions:    { update: true },
      questions:      [],
      photo_required: true,
    };
  }


  changeSurvey(e) {
    if(e.target.value === 'new') {
      this.onClickNew();
    } else {
      let id = parseInt(e.target.value);
      this.onClickSource(id);
    }
  }

  onClickNew() {
    let newSource = this.newSurveySource();
    this.setState({
      activeSource: newSource
    });
  }

  onClickSource(id) {
    var source = find(this.state.surveySources, { id: id });
    this.setState({ activeSource: source });
  }


  // The "situation" is a set of the editor's capabilities for the current user &
  // project. Eg do they have permission to make a new one, are they showing/editing
  // an existing one, is it editable in that case...
  situation() {
    if(!window.data.current_user_id) {
      // Anonymous peeps
      return {
        message: ['A survey allows you to create your own custom questions (or use a template of ours) to gather info on properties you care about.', <br></br>, <br></br>, 'Once the questions are set up, you can start collecting your data via the platform, or using our ', <a target='_blank' href='https://regrid.com/apps'>handy mobile app</a>, '. ', <a target='_blank' href='/plans#p=level1'>Sign up for a Regrid Pro account</a>, ' to get started. ', 'Learn more in our ', <a target='_blank' href='https://regrid.com/tutorials/creating-a-survey'>Survey tutorial video</a>,'.'],
        create:  false,
        edit:    false,
        asEmpty: true,
      };

    } else if(!isEmpty(this.props.project) && (window.data.has_sitecontrol || this.state.activeSource)) {
      // Either you can make surveys or are grandfathered in as free by having one
      // Pro+ users, now we take group limits into consideration
      var result = {};

      if(this.state.activeSource && !this.state.activeSource.new) {
        // Editing existing
        result.edit = this.state.activeSource.permissions.update;
        if(!result.edit) {
          result.message = 'You do not have permission to edit this survey.';
        } else if(this.state.activeSource && !this.state.activeSource.new) {
          result.message = 'You are editing an existing survey.';
        }

      } else {
        // Would be creating a new one
        var createNeed = LL.Upsell.shouldBlock('create-survey');
        if(!createNeed) {
          result.message = 'You are creating a new survey.';
        } else if(createNeed.reason === 'member') {
          result.message = createNeed.message;
        } else if(createNeed.reason === 'limit') {
          result.message = createNeed.message + ' Please upgrade to the ' + titleize(createNeed.level) + ' plan for more.';
        }
        result.create = result.edit = !createNeed;
      }
      
      return result;

    } else if(!window.data.has_sitecontrol) {
      // Free account
      return {
        create:  false,
        edit:    false,
        message: (<Fragment>
          Property surveying is just one of many benefits of a Pro account. Please&nbsp;
          <a href='/plans'>upgrade to a paid plan</a> to use surveying tools.</Fragment>),
        asEmpty: true,
      };

    } else if(isEmpty(this.props.project)) {
      return {
        create:  false,
        edit:    false,
        message: 'To create a survey, please save a Project first using the Save button in the left-hand toolbar.',
        asEmpty: true,
      };
    }
  }

  // Called from SEContainer on successful create/update
  onSaved(source) {
    debug('sechooser: saved', source);
    if(source.created) {
      // Make sure we keep it marked active despite now having a real (different) id
      this.setState({
        activeSource: source
      });
    }
    // Let the rest of the mapp know via flight
    this.props.passEventToFlight('notify:message', { message: 'Survey saved.' });
    this.props.passEventToFlight('sources:add', source);
  }

}

SEChooser.propTypes = {
  sources:    PropTypes.arrayOf(PropTypes.object).isRequired,
  project:    PropTypes.object,
};



export default withFlight(SEChooser);
