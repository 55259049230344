import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { isNullOrUndefined } from 'common/util'
import { parcelHighlightsFields } from '../propertySettings'
import ParcelOthers from './ParcelOthers'
import {
  convertValue
} from 'components/Map/MapControls/MapOptions/DrawTools/drawUtils'
import UnitSelectorDropdown from './UnitSelectorDropdown'
import ParcelDetailsField from './ParcelDetailsField'
/**
 * Renders a panel with highlights about a specific parcel.
 *
 * @component
 * @param {Object} props - component props
 * @param {Object} props.parcel - full parcel object
 * @param {Array} props.addressVariants - array of address variants
 * @param {Function} props.buildMultiLineAddress - fn to build multi-line address
 * @param {Array} props.otherProperties - array of other properties at the location
 * @returns {JSX.Element} Rendered component
 */
const ParcelHighlights = ({
  parcel,
  addressVariants,
  buildMultiLineAddress,
  isOpen,
  isOthersOpen,
  otherProperties,
  onToggle,
}) => {
  const [copied, setCopied] = useState(false)
  const othersPanelRef = useRef(null)

  /** Check if there are other properties at the location. */
  const hasOtherProperties = otherProperties && otherProperties.length > 0
  let calculatedAreainAcres = null
  const [unitValue, setUnitValue] = useState(calculatedAreainAcres)
  const [unit, setUnit] = useState('acres')
  const isPaidUser = window.data.has_sitecontrol
  const isUsa = isPathUSA(parcel?.path)
  const isLocked = !isPaidUser && !isUsa

  const mainAddress = addressVariants?.[0]
    ? buildMultiLineAddress(addressVariants[0])
    : null

  /** Summary fields to display in the panel. */
  const fieldValues = {
    address: {
      value: mainAddress,
    },
    measurements: {
      value:  `${numberWithCommas(parcel?.fields?.ll_gisacre)} acres`,
      value2: `${numberWithCommas(parcel?.fields?.ll_gissqft)} sqft`,
    },
    owner: {
      value: parcel?.fields?.owner,
    },
    zoning: {
      value: parcel?.fields?.zoning_description,
    },
    landuse: {
      value: parcel?.fields?.usedesc,
    },
    structureCount: {
      display: parcel?.fields?.struct, // if struct is false, don't display
      value:   parcel?.fields?.structno,
    },
  }

  // Callback to handle unit change
  const handleUnitChange = ({ unitValue, unit }) => {
    debug('Unit changed: ', unitValue, unit)
    const convertedValue = convertValue(
      'area',
      calculatedAreainAcres,
      unit,
      'acres'
    )
    setUnitValue(convertedValue)
    setUnit(unit)

    if(window.data.signed_in) {
      localStorage.setItem('unitPreference', unit)
    }
  }

  useEffect(() => {
    const unitPref = localStorage.getItem('unitPreference')
    if(unitPref && unitPref !== unit) {
      setUnit(unitPref)
    }
  }, [unit])

  /** Map summary fields to parcel highlights fields. */
  const mapValuesToFields = (fieldValues, fieldMappings) => {
    if(!fieldValues || !fieldMappings) return []
    return Object.keys(fieldMappings).reduce((mappedFields, key) => {
      const { value, value2 } = fieldValues[key] || {}

      if(value !== undefined) {
        mappedFields.push({
          key,
          ...fieldMappings[key],
          value,
          ...(value2 !== undefined && { value2 }), // Add value2 if it exists
        })
      }
      return mappedFields
    }, [])
  }

  /* Show Other Properties panel and scroll into view */
  const handleToggleOthers = () => {
    // Open panel if closed, do not close if already open
    onToggle('dd-other-properties', true)

    // Timeout to allow panel to open before scrolling
    setTimeout(() => {
      othersPanelRef.current.scrollIntoView({ behavior: 'smooth' })
    }, 200)
  }

  const addressToClipboard = () => {
    const address = addressVariants?.[0]
    if(!address || address.length < 4) return

    const addressString =
      address[0] + ', ' + address[1] + ', ' + address[2] + ' ' + address[3]
    navigator.clipboard.writeText(addressString)
    debug('Copied to clipboard: ', addressString)
  }

  const handleCopyAddress = () => {
    addressToClipboard()
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 2000) // reset copied state after 2 seconds
  }

  /* Render mapped values to parcel highlights fields */
  const mappedFields = mapValuesToFields(fieldValues, parcelHighlightsFields)

  return (
    <>
      <div
        id="property-highlights"
        className={`panel panel-default margin-btm-md plan-color`}
      >
        {mainAddress && <h2 className="hide">{mainAddress}</h2>}

        <div
          className={`panel-heading plan-color${!isOpen ? ' collapsed' : ''}`}
          role="tab"
          id="heading-parcel-highlights"
          data-toggle="collapse"
          href="#collapse-parcel-highlights"
          aria-expanded={isOpen}
          aria-controls="collapse-parcel-highlights"
          onClick={() => onToggle('dd-parcel-highlights')}
        >
          <h4 className="panel-title">
            <button className="no-btn width-100">
              <span className="pull-left">Parcel Highlights</span>
              <i
                id="caret-dd-parcel-highlights"
                className={`fa fa-angle-up pull-right`}
              />
            </button>
          </h4>
        </div>
        <div
          id="collapse-parcel-highlights"
          className={`panel-collapse collapse${isOpen ? ' in' : ''}`}
          role="tabpanel"
          aria-labelledby="heading-parcel-highlights"
        >
          <div className={`panel-heading plan-color${!isOpen ? ' collapsed' : ''}`} >
            {mappedFields.map((field) => {
              calculatedAreainAcres = (parcel.fields.ll_gisacre)
              const unitPref = localStorage.getItem('unitPreference') ?? 'acres'
              const convertedValue = convertValue(
                'area',
                calculatedAreainAcres,
                unitPref,
                'acres'
              )
              const labelTag = field.label

              if(isLocked) {
                return (
                  <ParcelDetailsField
                    field={field.label}
                    parcel={parcel}
                    key={field.label}
                  />
                )
              } else {

                const valueTag = field.value2 ? (
                  <>
                    <UnitSelectorDropdown
                      unitValue={unitValue ?? convertedValue}
                      unit={unit}
                      onUnitChange={handleUnitChange}
                    />
                  </>
              ) : (
                field.value
              )
              // if display is false, don't render
              if(isNullOrUndefined(valueTag) || !field.display) return null

              return (
                <div className="flex-row-between" key={field.key}>
                  <div className="field flex-col-start margin-btm-sm width-100">
                    <div className="field-label subtle small">{labelTag}</div>
                    <div className="field-value value">{valueTag}</div>
                  </div>
                  {field.copyButton && (
                    <>
                      <button
                        title={copied ? 'Copied!' : 'Copy to Clipboard'}
                        className="btn btn-default btn-round margin-left-md"
                        onClick={handleCopyAddress}
                        data-tip
                        data-for="copyTooltip"
                      >
                        <i className="fas fa-copy"></i>
                      </button>
                      <ReactTooltip
                        id="copyTooltip"
                        place="top"
                        effect="solid"
                        delayHide={1000}
                        globalEventOff="click"
                        className="tt-width-150"
                      >
                        {copied ? 'Copied!' : 'Copy to Clipboard'}
                      </ReactTooltip>
                    </>
                  )}
                </div>
              )}
            })}
            {hasOtherProperties && (
              <div className="other-properties-toggle">
                <div className="field">
                  <div className="field-label">
                    <div className="key subtle small">
                      Other Records at Location
                    </div>
                  </div>
                  <div className="field-value">
                    <div className="value">
                      <p className="margin-btm-sm">{`(${otherProperties.length}) additional records at this location`}</p>
                      <button
                        className="btn btn-sm btn-round btn-primary btn-block"
                        onClick={handleToggleOthers}
                      >
                        Show Other Records
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
      {hasOtherProperties && (
        <ParcelOthers
          ref={{ othersPanelRef }}
          otherProperties={otherProperties}
          onToggle={onToggle}
          isOpen={isOthersOpen}
        />
      )}
    </>
  )
}

ParcelHighlights.propTypes = {
  parcel:                PropTypes.object.isRequired,
  addressVariants:       PropTypes.array,
  buildMultiLineAddress: PropTypes.func.isRequired,
  isOpen:                PropTypes.bool.isRequired,
  isOthersOpen:          PropTypes.bool.isRequired,
  otherProperties:       PropTypes.array,
  onToggle:              PropTypes.func.isRequired,
}

export default ParcelHighlights
