import React, { useState } from 'react';
import PropTypes from 'prop-types';
import settings from 'common/settings';
import { ChromePicker } from 'react-color';
import { colorPickerValueToRgba } from '../../../Map/mapUtilities'


const RuleColorSwatch = ({
  currentColor,
  type,
  handleChange,
}) => {

  const transparent = currentColor === settings.styles.transparentValue || currentColor === settings.styles.hexTransparentValue;
  const [displayPicker, setDisplayPicker] = useState(false);

  const handleDisplayPicker = () => {
    setDisplayPicker((prevDisplayPicker) => !prevDisplayPicker);
  };
  const handleClose = () => {
    setDisplayPicker(false);
  };

  const handleColorChange = (color) => {
    handleChange({ [type]: colorPickerValueToRgba(color.rgb) });
  };


  // Set background color for fill
  let style = { backgroundColor: currentColor };

  // Change style for lines if not transparent
  if(type === 'line' && !transparent) {
    style = { borderColor: currentColor, borderWidth: '3px' };
  }

  return (
    <div className="rule-color">
      <div className="rule-color-swatch rounded" style={style} onClick={handleDisplayPicker}>
        {/* Horizontal red line for transparent */}
        {transparent && <span className="transparent-line"></span>}
      </div>
      {displayPicker && (
        <>
          <div
            className="chrome-picker-overlay"
            onClick={handleClose}
          />
          <div className="chrome-picker-wrapper">
            <ChromePicker
              className="chrome-picker"
              color={currentColor}
              onChangeComplete={handleColorChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

RuleColorSwatch.propTypes = {
  currentColor: PropTypes.string,
  type:         PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default RuleColorSwatch;