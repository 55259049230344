import length from '@turf/length'
import area from '@turf/area'
import distance from '@turf/distance'
import { convertArea, convertLength } from '@turf/helpers'

export const availableUnits = {
  distance: ['miles', 'kilometers', 'feet', 'meters'],
  area: ['squareFeet', 'acres', 'squareMeters', 'hectares'],
}

export const defaultUnits = {
  distance: 'miles',
  area: 'acres',
}

/**
 * Mapping from display unit to the corresponding conversion unit.
 * @turf/helpers convertArea and convertLength
 */
export const conversionUnits = {
  squareFeet: 'feet',
  acres: 'acres',
  squareMeters: 'meters',
  hectares: 'hectares',
  miles: 'miles',
  kilometers: 'kilometers',
  feet: 'feet',
  meters: 'meters',
}

/**
 * Human-readable display names for units.
 */
export const unitDisplayNames = {
  squareFeet: 'Square Feet',
  acres: 'Acres',
  squareMeters: 'Square Meters',
  hectares: 'Hectares',
  miles: 'Miles',
  kilometers: 'Kilometers',
  feet: 'Feet',
  meters: 'Meters',
}

export const offsetRatio = 0.385 // offsets midpoint label 38.5% along the line length
export const curveBearingThreshold = 5 // threshold of degrees to determine if a line is curved

export const formatUnitForDisplay = (unit) => unitDisplayNames[unit] || unit

// TODO: Move to common/utils.js and create a handlebars helper
export const limitPrecision = (value, decimalPlaces) => {
  const factor = Math.pow(10, decimalPlaces)
  return Math.round(value * factor) / factor
}

// // TODO This is not available in tests from common/utils.js and handlebars, why?
// export const numberWithCommas = (x) => {
//   if (!x) {
//     return '0'
//   }
//   if (typeof x == 'string') {
//     return x
//   } // numberWithCommas(parseInt(x)); }
//   var s = x.toString()
//   if (parseInt(x) != x) {
//     if (x >= 100) {
//       return numberWithCommas(parseInt(x))
//     }
//     var f = parseFloat(Math.round(x * 100) / 100).toFixed(2)
//     var m = f.match(/^([0-9]+)\.([0-9]+)$/)
//     return m ? numberWithCommas(m[1]) + '.' + m[2] : f
//   }
//   return s.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
// }

/**
 * Converts a value from one unit to another using @turf/helpers.
 * If the units are the same, the value is returned unchanged.
 * Units @turf/helpers can convert:
 *  - Area: kilometers, kilometres, meters, metres, centimetres, millimeters, acres, miles, yards, feet, inches, hectares
 *  - Distance: miles, nauticalmiles, inches, yards, meters, metres, kilometers, centimeters, feet
 *
 * @param {string} type - The type of measurement ('distance' or 'area').
 * @param {number} value - The value to convert.
 * @param {string} unit - The unit to convert to.
 * @param {string} [fromUnit] - The unit to convert from. Optional.
 * @returns {number} The converted value.
 */
export const convertValue = (type, value, toUnit, fromUnit) => {
  if (toUnit === fromUnit) return value
  if (!fromUnit) fromUnit = defaultUnits[type] // Optional param => 'miles' or 'acres'
  const originalUnit = conversionUnits[fromUnit] // 'miles' => 'kilometers'
  const finalUnit = conversionUnits[toUnit] // 'squareFeet' => 'feet'
  switch (type) {
    case 'area':
      // @turf/helpers convertArea(area, originalUnit, finalUnit)
      return convertArea(value, originalUnit, finalUnit)
    case 'distance':
      // @turf/helpers convertLength(length, originalUnit, finalUnit)
      return convertLength(value, originalUnit, finalUnit)
    default:
      return value
  }
}

/**
 * Calculates the length of a line segment.
 *
 * @param {Array} start - Start point of the segment.
 * @param {Array} end - End point of the segment.
 * @param {string} [units] - Units to use for the measurement. Optional.
 * @returns {number} Length of the segment.
 */
export const calculateSegmentLength = (start, end, units) => {
  if (!units) {
    units = defaultUnits.distance // e.g. 'miles'
  }
  if (!start || !end) {
    debug('Expected start and end points, but received:', start, end)
    return
  }
  if (start.length !== 2 || end.length !== 2) {
    debug(
      'Expected start and end points to be arrays of length 2, but received:',
      start,
      end
    )
    return
  }
  // @turf/distance units: degrees, radians, miles, or kilometers
  return distance(start, end, { units }) // turf default units: kilometers
}

/**
 * Calculates the lengths of each segment in a line/polygon.
 *
 * @param {Object} line - Line feature to measure.
 * @returns {number[]} Array of segment lengths.
 */
export const calculateSegmentLengths = (feature) => {
  if (!feature || !feature.geometry || !feature.geometry.coordinates) {
    return []
  }
  let coordinates
  if (feature.geometry.type === 'LineString') {
    coordinates = feature.geometry.coordinates
  } else if (feature.geometry.type === 'Polygon') {
    coordinates = feature.geometry.coordinates[0]
  } else {
    debug(
      'Expected a LineString or Polygon, but received:',
      feature.geometry.type
    )
    return []
  }

  const segmentLengths = coordinates.reduce((acc, coord, idx, arr) => {
    if (idx === arr.length - 1) return acc
    acc.push(distance(coord, arr[idx + 1])) // turf distance default: kilometers
    return acc
  }, [])
  return segmentLengths
}

/**
 * Calculates the midpoints for line/polygon segments offset by a given ratio.
 * A value of 0 would mean the start of the segment, 1 would mean the end,
 * and 0.5 would mean the midpoint.
 *
 * @param {Object} feature - Line or Polygon feature.
 * @param {number} positionRatio - Ratio to offset midpoint calculation, optional.
 * @returns {Array<Array<number>>} Array of midpoint coordinates.
 */
export const calculateMidpointCoords = (
  feature,
  positionRatio = offsetRatio
) => {
  if (!feature || !feature.geometry || !feature.geometry.coordinates) {
    return []
  }

  let coordinates
  if (feature.geometry.type === 'LineString') {
    coordinates = feature.geometry.coordinates
  } else if (feature.geometry.type === 'Polygon') {
    coordinates = feature.geometry.coordinates[0]
  } else {
    debug(
      'Expected a LineString or Polygon, but received:',
      feature.geometry.type
    )
    return []
  }

  return coordinates
    .map((coord, idx, arr) => {
      if (idx === arr.length - 1) return null
      const start = coord
      const end = arr[idx + 1]
      const x = start[0] + positionRatio * (end[0] - start[0])
      const y = start[1] + positionRatio * (end[1] - start[1])
      return [x, y]
    })
    .filter((coord) => coord !== null) // filter out the null values
}

/**
 * Creates an array of label features from an array of midpoint coordinates.
 *
 * @param {Array<Array<number>>} midpoints - Array of midpoint coordinates.
 * @param {Array<string|number>} [labels] - Optional array of labels to use.
 * @returns {Array<Object>} Array of point features for labels.
 */
export const createLabelFeatures = (midpoints, labels) => {
  return midpoints.map((midpoint, idx) => {
    const orderValue =
      labels && labels[idx] !== undefined ? labels[idx] : processAlphaLabel(idx)
    return {
      type: 'Feature',
      properties: { label: orderValue },
      geometry: {
        type: 'Point',
        coordinates: midpoint,
      },
    }
  })
}

/**
 * Retrieves parcel dimensions data for a specific path.
 * Returned data accounts for curved parcel lines and combines them into a
 * single 'side' measurement.
 * API endpoint repo: https://github.com/loveland/api
 *
 * @param {string} path - The path for the parcel location, typically in the format '/us/mi/wayne/detroit/60135'.
 * @returns {Promise<object>} A promise that resolves to an object containing parcel dimensions data.
 * @throws {Error} If there's an error during the fetch or JSON parsing process.
 *
 * @example
 * // Usage:
 * const path = '/us/mi/wayne/detroit/60135';
 * try {
 *   const dimensionsData = await getParcelDimensions(path);
 *   console.log(dimensionsData); // The parcel dimensions data
 * } catch (error) {
 *   console.error('Error:', error);
 * }
 *
 * @typedef {Object} ParcelDimensionsData
 * @property {string} headline - The headline or title of the parcel.
 * @property {Object} perimeter - Perimeter in feet and meters.
 * @property {Object} area - Area in square feet, square meters, and acres.
 * @property {Array<Object>} lengths - An array of objects representing segment lengths with feet, meters, and midpoint placement coordinates.
 * @property {Array<Array<number>>} corners - An array of coordinate pairs representing corners of the parcel.
 */
export const getParcelDimensions = async (path) => {
  try {
    const response = await fetch(
      `${window.data.dimensions_host}/api/v1/dimensions.json?path=${path}&token=${window.data.jwt}`
    )

    if (!response.ok) {
      debug(`Error fetching dimensions: ${response.statusText}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    debug('getParcelDimensions error:', error)
  }
}

/**
 * Generates alphabetic labels based on index.
 * Will generate single letter labels for indices 0-25, 'A'-'Z',
 * then double letter labels for indices above 25, 'AA'-'ZZ', etc.
 *
 * @param {number} idx - Index.
 * @returns {string} Alphabetic label.
 */
export const processAlphaLabel = (idx) => {
  if (idx < 0) {
    debug('Expected a positive index, but received:', idx)
    return
  }
  const baseCharCode = 65 // 'A'
  const lettersInAlphabet = 26

  if (idx < lettersInAlphabet) {
    return String.fromCharCode(baseCharCode + idx)
  }
  const firstChar = String.fromCharCode(
    baseCharCode + Math.floor(idx / lettersInAlphabet) - 1
  )
  const secondChar = String.fromCharCode(
    baseCharCode + (idx % lettersInAlphabet)
  )
  return firstChar + secondChar
}

/**
 * Updates point labels with alphabetic labels.
 *
 * @param {Object[]} [points=[]] - Array of points to label.
 * @returns {Object[]} Array of points with updated labels.
 */
export const updatePointLabels = (points = []) => {
  return points.map((point, index) => ({
    type: 'Feature',
    properties: { label: processAlphaLabel(index) },
    geometry: point.geometry,
  }))
}

/**
 * Returns the display area of a polygon.
 *
 * @param {Object} polygon - The polygon to measure.
 * @param {string} [units] - Units to use for the measurement. Optional.
 * @returns {string} Formatted display area.
 */
export const getDisplayArea = (polygon, units) => {
  if (!polygon) return
  if (polygon?.geometry?.type !== 'Polygon') {
    debug('Expected a Polygon, but received:', polygon?.geometry?.type)
    return
  }

  const areaSqMeters = area(polygon) // turf area default: square meters
  const unit = units ? units : defaultUnits.area
  const value = convertValue('area', areaSqMeters, unit, 'meters')
  return `${numberWithCommas(value)} `
}

/**
 * Returns the display perimeter of a polygon.
 *
 * @param {Object} polygon - The polygon to measure.
 * @param {string} [units] - Units to use for the measurement. Optional.
 * @returns {string} Formatted display perimeter.
 */
export const getDisplayPerimeter = (polygon, units) => {
  if (!polygon) return
  if (polygon?.geometry?.type !== 'Polygon') {
    debug('Expected a Polygon, but received:', polygon?.geometry?.type)
    return
  }

  const perimeterKilometers = length(polygon) // turf length default: kilometers

  const unit = units ? units : defaultUnits.distance
  const value = convertValue(
    'distance',
    perimeterKilometers,
    unit,
    'kilometers'
  )
  return `${numberWithCommas(value)} `
}

/**
 * Returns the display segment lengths for a line.
 *
 * @param {Object} line - The line to measure.
 * @param {string} [units] - Units to use for the measurement. Optional.
 * @returns {Object} Object containing segment lengths and total length.
 */
export const getDisplaySegments = (line, units) => {
  if (!line) return
  if (line?.geometry?.type !== 'LineString') {
    debug('Expected a LineString, but received:', line?.geometry?.type)
    return
  }

  const segmentLengthsKilometers = calculateSegmentLengths(line)
  const unit = units ? units : defaultUnits.distance

  const segmentLengths = segmentLengthsKilometers.map((segment) => {
    if (unit === 'kilometers') return segment
    return convertLength(segment, 'kilometers', unit)
  })
  const totalLength = segmentLengths.reduce((acc, curr) => acc + curr, 0)

  return {
    segmentLengths,
    totalLength,
  }
}

/**
 * Processes an array of points for display.
 *
 * @param {Object[]} allPoints - Array of points to process.
 * @returns {Object[]} Processed list of points for display.
 */
export const processPointsContent = (allPoints) => {
  if (!Array.isArray(allPoints)) {
    debug('Expected an array of points, but received:', allPoints)
    return []
  }
  const pointsList = allPoints.reduce((acc, point) => {
    if (!point || point.geometry.type !== 'Point') {
      debug('Invalid point type:', point)
      return acc
    }

    const [longitude, latitude] = point?.geometry.coordinates || [null, null]
    if (!point.id || _.isNil(latitude) || _.isNil(longitude)) {
      debug('Invalid point data:', point)
      return acc
    }
    acc.push({
      id: point.id,
      text: `( ${latitude.toFixed(5)}, ${longitude.toFixed(5)} )`,
    })
    return acc
  }, [])

  return pointsList
}
