import React, { useState } from 'react'

const MapLayersButton = ({ isActive, toggleModal }) => {
  const [isHovered, setHovered] = useState(false)

  return (
    <div id="map-layers-button">
      <div
        className={`map-options-btn ${isActive || isHovered ? 'plan-color-accent' : ''} ${
          isActive ? ' active' : ''
        }`}
        onClick={toggleModal}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onTouchStart={() => setHovered(true)}
        onTouchEnd={() => setHovered(false)}
      >
        <span>
          <i className="fa fa-layer-group"></i>
        </span>
        <span>Layers</span>
      </div>
    </div>
  )
}

export default MapLayersButton
