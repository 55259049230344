import React, { useEffect, memo } from 'react'
import withStore from 'components/withStore'
import InfoTooltip from 'components/Utilities/InfoTooltip'
import { convertToUSCurrency, handleClickTabPane } from '../../common/util'
import ReactTooltip from 'react-tooltip'

const ReviewModal = memo(function _ReviewModal({
  coverage,
  price,
  priceShp,
  count,
  format,
  handleCart,
  storeLink,
  withDataset,
  hasNonParcelSources,
  priceWarning,
  limitCounty,
  limitPrice,
  priceThresholdLimit,
  isManager,
}) {
  let isCanadaExport = isPathCanada(coverage?.path)

  let isFree = false
  if (price === 0 && format === 'csv') {
    isFree = true
  }
  if (priceShp === 0 && format !== 'csv') {
    isFree = true
  }
  if (withDataset && hasNonParcelSources) {
    isFree = true
  }

  let belowPriceMinimum;
  if (format === 'csv') {
    belowPriceMinimum = (price < 1);
  } else {
    belowPriceMinimum = (priceShp < 1);
  }

  let storeArray = storeLink?.split('/')

  let stateStoreLink =
    storeArray?.length > 0 ? `/store/us/${storeArray[3]}` : '/store'

  let canadaProvinceStoreLink = `/store/ca/${coverage?.county?.admin1}`
  let canadaStoreLink = '/store/ca'

  const handleClickTab = (tabPane) => {
    handleClickTabPane(tabPane)
  }

  const purchaseInfo = (
    <p>
      Complete{' '}
      <strong>
        <a
          href={isCanadaExport ? canadaProvinceStoreLink : storeLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {isCanadaExport ? 'province data' : 'county data'}
        </a>
      </strong>{' '}
      {!isCanadaExport && (
        <>
          and{' '}
          <strong>
            <a
              href={stateStoreLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              state data
            </a>
          </strong>{' '}
        </>
      )}
      {isCanadaExport ? 'is' : 'are'} available for purchase from our{' '}
      <strong>
        <a
          href={isCanadaExport ? canadaStoreLink : '/store'}
          target="_blank"
          rel="noopener noreferrer"
        >
          Data Store
        </a>
      </strong>
      .
    </p>
  )

  if (_.isEmpty(coverage)) {
    return (
      <div id="review-modal" className="review-modal modal fade" data-backdrop="false">
        <div className="modal-dialog modal-lg">
          <div className="modal-content rounded-lg">
            <div className="modal-header">
              <div className="modal-header-title">
                <h2>Review Your Data</h2>
                <button
                  aria-hidden="true"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  ×
                </button>
              </div>
              {purchaseInfo}
            </div>
            <div className="modal-body">
              <div className="export-coverage-container">
                <div className="export-coverage-subheader">
                  <h4 className="tooltip-parent">
                    {`Coverage Report Loading...`}
                    <InfoTooltip
                      id="coverage-report-tip"
                      tip={`The % of each field in Regrid's data that we have info for.\n For example, Owner: 80% would indicate that we have the owner name info for 80% of the properties in this county.`}
                      circleSize="fa-xs"
                      place="right"
                      type="dark"
                      delayHide={500} // mouse in tooltip
                      delayShow={250}
                      delayUpdate={500} // mouse in tooltip
                    />
                  </h4>
                </div>
                <div className="coverage-report coverage-loading-parent">
                  <i className="fas fa-spinner fa-spin big" />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="modal-footer-total">
                <h4 className="lead">
                  <span>{`Total Cost: `}</span>
                  <span className="bold">
                    {format === 'csv'
                      ? `${convertToUSCurrency(price ? price : 0)}`
                      : `${convertToUSCurrency(priceShp ? priceShp : 0)}`}
                  </span>
                </h4>
              </div>
              <div className="modal-footer-count">
                <h4 className="lead">
                  <span>{`Records Selected: `}</span>
                  <span className="bold">{`${numberWithCommas(count)}`}</span>
                </h4>
              </div>
              <div className="modal-footer-fmt">
                <h4 className="lead">
                  <span>{`Format: `}</span>
                  <span className="bold">{`(.${format})`}</span>
                </h4>
              </div>
              <div className="modal-footer-btns">
                <button className="btn btn-default" data-dismiss="modal">
                  Back to Export Tab
                </button>
                {!isFree ? (
                  <div
                    data-tip={!isManager ? 'Only available to managers. Please contact your account admin to change your user permissions.' : undefined}
                    data-for="managerTooltip"
                  >
                    <button
                      className={`btn btn-primary btn-add-to-cart${
                        belowPriceMinimum || limitCounty || limitPrice || !isManager ? ` disabled` : ``
                      }`}
                      data-dismiss="modal"
                      onClick={isManager ? handleCart : undefined}
                    >
                      Add to Cart
                    </button>
                  </div>
                ) : (
                  <div
                    data-tip={!isManager ? 'Only available to managers. Please contact your account admin to change your user permissions.' : undefined}
                    data-for="managerTooltip"
                  >
                    <button
                      className={`btn btn-primary btn-add-to-cart${
                        (count === null || count === 0 || !isManager) ? ` disabled` : ``
                      }`}
                      data-dismiss="modal"
                      onClick={isManager ? handleCart : undefined}
                    >
                      {`Download
                    ${
                      (count === null || count === 0) ? (
                        <InfoTooltip
                          id="zero-data-tip"
                          tip={`Your filters currently have 0 properties selected, there is nothing to export. Please adjust your filters and try again.`}
                          circleSize="fa-xs"
                          place="right"
                          type="dark"
                          delayHide={500} // mouse in tooltip
                          delayShow={250}
                          delayUpdate={500} // mouse in tooltip
                        />
                      ) : (
                        ''
                      )
                    }`}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isManager && (
          <ReactTooltip id="managerTooltip" place="top" effect="solid" />
        )}
      </div>
    )
  } else {
    return (
      <div id="review-modal" className="review-modal modal fade" data-backdrop="false">
        <div className="modal-dialog modal-lg">
          <div className="modal-content rounded-lg">
            <div className="modal-header">
              <div className="modal-header-title">
                <h2>Review Your Data</h2>
                <button
                  aria-hidden="true"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                >
                  ×
                </button>
              </div>
              <p>
                Your selected parcels are located in{' '}
                <strong>{`${coverage.verse_record.headline}`}</strong>.
              </p>
              {purchaseInfo}
            </div>
            <div className="modal-body">
              <div className="export-coverage-container">
                <div className="export-coverage-subheader">
                  <h4 className="tooltip-parent">
                    {`${coverage.verse_record.headline} Coverage Report`}
                    <InfoTooltip
                      id="coverage-report-tip"
                      tip={`The % of each field in Regrid's data that we have info for.\n For example, Owner: 80% would indicate that we have the owner name info for 80% of the properties in this county.`}
                      circleSize="fa-xs"
                      place="right"
                      type="dark"
                      delayHide={500} // mouse in tooltip
                      delayShow={250}
                      delayUpdate={500} // mouse in tooltip
                    />
                  </h4>
                  <span>{`County Attribute Fields:  ${
                    Object.keys(coverage.coverage_fields).length +
                    Object.keys(coverage.top_fields).length
                  }`}</span>
                </div>
                <div className="coverage-report">
                  <table className="table table-striped small">
                    <tbody>
                      {coverage.top_fields.map((field, idx) => {
                        let percent = field.percent
                        return (
                          <tr
                            className={percent === 0 ? `disabled` : ''}
                            key={`top-fields-tr${idx}`}
                          >
                            <td>{field.name}</td>
                            <td className="right">{`${percent}%`}</td>
                          </tr>
                        )
                      })}
                      {coverage.coverage_fields.map((field, idx) => {
                        let percent = field.percent
                        return (
                          <tr
                            className={percent === 0 ? `disabled` : ''}
                            key={`coverage-fields-tr${idx}`}
                          >
                            <td>{field.name}</td>
                            <td className="right">{`${percent}%`}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="export-data-sample-container">
                <p className="small">
                  In the table above are the overall county coverage stats: to
                  review data for your specific selected parcels, review in our
                  List tab.
                </p>
                <button
                  className="btn btn-primary btn-round"
                  data-dismiss="modal"
                  onClick={() => handleClickTab('list')}
                >
                  List Tab
                </button>
              </div>
              {belowPriceMinimum &&
                !isFree && ( // prevent purchase under minimum order
                  <div className="export-modal-warning alert alert-warning">
                    <p>
                      <i className="fas fa-exclamation-triangle margin-right-md"></i>
                      Minimum order: 10 parcels ($1 spreadsheet, $1.50 spatial).
                      Please add more parcels to your Export to continue.
                    </p>
                  </div>
                )}
              {priceWarning &&
                !limitCounty &&
                !limitPrice &&
                !isCanadaExport && ( // warn against purchase greater than data store amount
                  <div className="export-modal-warning alert alert-warning">
                    <p>
                      <i className="fas fa-exclamation-triangle margin-right-md"></i>
                      Your current selection will cost more than buying the
                      whole county through our{' '}
                      <a
                        href={storeLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >{` Data Store`}</a>
                      .
                    </p>
                    <p className="small">
                      There are benefits to both approaches - you'll get just
                      your properties of interest here, while on the Data Store
                      you'll likely need to cut down the data yourself using
                      other software - but we wanted to make sure you were aware
                      of your options!
                    </p>
                  </div>
                )}
              {limitCounty &&
                !limitPrice && 
                !isCanadaExport && ( // prevent entire county purchase amount
                  <div className="export-modal-warning alert alert-warning">
                    <p>
                      <i className="fas fa-exclamation-triangle margin-right-md"></i>
                      Want to buy the whole county? Please purchase through our{' '}
                      <a
                        href={storeLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >{` Data Store`}</a>
                      , which will give you the best pricing.
                    </p>
                  </div>
                )}
              {limitPrice && ( // prevent entire county purchase amount
                <div className="export-modal-warning alert alert-warning">
                  <p>
                    <i className="fas fa-exclamation-triangle margin-right-md"></i>
                    This purchase is over the Export tool's {convertToUSCurrency(priceThresholdLimit)} limit. Please
                    purchase through our{' '}
                    <a
                      href={isCanadaExport ? canadaStoreLink : storeLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{` Data Store`}</a>
                    , or contact{' '}
                    <a href="mailto:help@regrid.com">help@regrid.com</a> for
                    assistance.
                  </p>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <div className="modal-footer-total">
                <h4 className="lead">
                  <span>{`Total Cost: `}</span>
                  <span className="bold">
                    {format === 'csv'
                      ? `${convertToUSCurrency(price ? price : 0)}`
                      : `${convertToUSCurrency(priceShp ? priceShp : 0)}`}
                  </span>
                </h4>
              </div>
              <div className="modal-footer-count">
                <h4 className="lead">
                  <span>{`Records Selected: `}</span>
                  <span className="bold">{`${numberWithCommas(count)}`}</span>
                </h4>
              </div>
              <div className="modal-footer-fmt">
                <h4 className="lead">
                  <span>{`Format: `}</span>
                  <span className="bold">{`(.${format})`}</span>
                </h4>
              </div>
              <div className="modal-footer-btns">
                <button className="btn btn-default" data-dismiss="modal">
                  Back to Export Tab
                </button>
                {!isFree ? (
                  <div
                    data-tip={!isManager ? 'Only available to managers. Please contact your account admin to change your user permissions.' : undefined}
                    data-for="managerTooltip"
                  >
                    <button
                      className={`btn btn-primary btn-add-to-cart${
                        belowPriceMinimum || limitCounty || limitPrice || !isManager
                          ? ` disabled tooltip-parent`
                          : ``
                      }`}
                      data-dismiss="modal"
                      onClick={isManager ? handleCart : undefined}
                    >
                      Add to Cart
                    </button>
                  </div>
                ) : (
                  <div
                    data-tip={!isManager ? 'Only available to managers. Please contact your account admin to change your user permissions.' : undefined}
                    data-for="managerTooltip"
                  >
                    <button
                      className={`btn btn-primary btn-add-to-cart${
                        (count === null || count === 0 || !isManager) ? ` disabled tooltip-parent` : ``
                      }`}
                      data-dismiss="modal"
                      onClick={isManager ? handleCart : undefined}
                    >
                      {(count === null || count === 0) ? (
                        <span>
                          Download Empty
                          <i className="fas fa-exclamation-triangle"></i>
                        </span>
                      ) : (
                        <span>Download</span>
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {!isManager && (
          <ReactTooltip id="managerTooltip" place="top" effect="solid" />
        )}
      </div>
    )
  }
})

export default withStore(ReviewModal)