import { getActiveProject } from './projects';
import settings from '../common/settings';
import { isNullOrUndefined } from '../common/util';
import { getActiveSourceIds } from 'ducks/sources';

// Actions
import { SET_STYLES, ADD_BEING_EDITED_RULE, SET_BEING_EDITED_RULES, SET_ACTIVE_RULES, SET_DEFAULT_RULE, RESET_RULES } from './actions';
import { createSelector } from '@reduxjs/toolkit';

const initialDefaultRule = {
  fill: settings.styles.defaultFillColor,
  line: settings.styles.defaultPurpleLineColor,
  //predicate: 'default'  // this makes it checked by... default
};

export const setStyles = data => (dispatch, getState) => {
  dispatch({
    type: SET_STYLES,
    data,
  })

  createLayer(dispatch, getState(), {
    styles: data,
  })
}

export const setBeingEditedRules = data => (dispatch) => {
  dispatch({
    type: SET_BEING_EDITED_RULES,
    data
  })
}

// Load up the rules on saved project into our active state, ie revert
export const setExistingRules = () => (dispatch, getState) => {
    const state = getState()
    let styles = getActiveProject(state)?.styles || []

    // Weed out any rules for sources that are no longer on the project
    const activeSourceIds = getActiveSourceIds(state)
    const filter = sourceFilter(activeSourceIds)
    styles = styles.filter(filter)

    if(styles) {
      let defaultRule = initialDefaultRule;
      const nonDefaultRules = []

      for(const rule of styles) {
        // If rule was created with old styles and doesn't have a line
        // value, let just make it transparent.
        if(!rule.line || rule.line === 'rgba(0,0,0,0)') {
          rule.line = settings.styles.transparentValue
        }
        // Find default rule
        if(rule.predicate === 'default') {
          defaultRule = rule
        } else {
          nonDefaultRules.push(rule)
        }
      }

      dispatch({
        type: SET_DEFAULT_RULE,
        data: defaultRule
      })
    
      dispatch({
        type: SET_BEING_EDITED_RULES,
        data: nonDefaultRules
      })
    
      dispatch({
        type: SET_ACTIVE_RULES,
        data: styles
      })
    } else {
      resetRules()
    }
}

export const addBeingEditedRule = data => (dispatch) => {
    dispatch({
      type: ADD_BEING_EDITED_RULE,
      data,
    })  
}

export const deleteBeingEditedRule = data => (dispatch, getState) => {
    const rules = getState().styles.beingEditedRules
    const newRules = rules.filter((rule, idx) => idx !== data)
    dispatch({
        type: SET_BEING_EDITED_RULES,
        data: newRules
    })
}

export const updateBeingEditedRule = (id, changes) => (dispatch, getState) => {
    const rules = getState().styles.beingEditedRules.slice()
    const currentRule = rules[id]

    const newRule = {
      ...currentRule,
      ...changes
    }

    rules.splice(id, 1, newRule)

    dispatch({
      type: SET_BEING_EDITED_RULES,
      data: rules
  })
}

export const resetRules = () => (dispatch) => {
    dispatch({
      type: RESET_RULES
    })
}

export const applyActiveRules = () => (dispatch, getState) => {
    let activeRules = getState().styles.beingEditedRules
    const defaultRule = getState().styles.defaultRule
    if (defaultRule.hasOwnProperty('predicate')) {
      activeRules = [...activeRules, defaultRule]
    }
    dispatch({
      type: SET_ACTIVE_RULES,
      data: activeRules
    })
}

export const updateDefaultRuleColors = (changes) => (dispatch, getState) => {
    const defaultRule = getState().styles.defaultRule

    const newDefaultRule = {
      ...defaultRule,
      ...changes
    }

    dispatch({
      type: SET_DEFAULT_RULE,
      data: newDefaultRule
  })
}

export const defaultRuleChecked = () => (dispatch, getState) => {
    const defaultRule = getState().styles.defaultRule

    const newDefaultRule = {
      ...defaultRule,
      predicate: 'default' 
    }

    //remove default rule if was already in there
    if(defaultRule.predicate) delete newDefaultRule.predicate

    dispatch({
      type: SET_DEFAULT_RULE,
      data: newDefaultRule
  })
}

const sourceFilter = (sourceIds) => (rule) => {
  sourceIds = sourceIds.map((id) => parseInt(id)) // they can be strings here too, make sure all are ints
  return isNaN(rule.source) ||                    // parcel-source rule or default rule; isNaN("123") === false
    sourceIds.includes(parseInt(rule.source))
}

// Weed out any styles for sources that have been removed from the project state
export const ensureSourceConsistency = (sourceIds) => (dispatch, getState) => {
  const styleState = getState().styles
  const filter = sourceFilter(sourceIds)
  const editedRules = styleState.beingEditedRules.filter(filter)
  const activeRules = styleState.activeRules.filter(filter)

  // Update state immediately to reflect in editor and the map
  dispatch({
    type: SET_BEING_EDITED_RULES,
    data: editedRules
  })
  dispatch({
    type: SET_ACTIVE_RULES,
    data: activeRules
  })
}

// Makes sure no property in the style object equals null, undefined, or ''
export const isStyleValid = (style) => {
  const values = Object.values(style)
  return values.every(value => !isNullOrUndefined(value) && value !== '')
}

export const allStylesValid = (state) => {
  const rules = getBeingEditedRules(state);
  return rules.every(rule => isStyleValid(rule))
}

export const clearQuery = () => () => {}

export const getStyles = state => {
  return state.styles
}

// export const getActiveRules = (state) => {
//   return getStyles(state)?.activeRules
// }

export const getActiveRules = createSelector(
  [getStyles],
  (styles) => styles?.activeRules
)

export const getDefaultRule = state => {
  return state.styles.defaultRule
}

export const getBeingEditedRules = state => {
  return state.styles.beingEditedRules
}

export const isDefaultRuleActive = (state) => {
  const defaultRule = state.styles.defaultRule;

  return state.styles.activeRules.some(rule => 
    rule.fill === defaultRule.fill &&
    rule.line === defaultRule.line &&
    rule.predicate === defaultRule.predicate
  );
};

export const buildLovelandStyles = styles => {
  if (!styles) {
    return settings.map.defaultStyles
  }

  let carto = `
  Map {
    background-color: rgba(0,0,0,0);
  }
  #loveland {
    [GEOMETRY = LineString],
    [GEOMETRY = MultiLineString] {
      line-width: 2;
      [zoom >= 15] {
        line-width: 5;
      }
      [zoom >= 16] {
        line-width: 7;
      }
      [zoom >= 18] {
        line-width: 9;
      }
      line-color: ${styles.default.hexString};
      line-opacity: 1;
    }
    [GEOMETRY = Polygon],
    [GEOMETRY = MultiPolygon] {
      [zoom >= 14] {
        line-color: ${styles.default.hexString};
        line-width:0.5;
      }
      line-width: 0.5;
      line-opacity:0.5;
      polygon-fill: ${styles.default.hexString};
      polygon-opacity:0.9;
    }
    [GEOMETRY = Point] {
      marker-line-width: 1;
      marker-width: 3;
      [zoom >= 16] {
        marker-line-width: 2;
        marker-width: 4;
      }
      marker-type: ellipse;
      marker-line-color: ${styles.default.hexString};
      marker-fill: ${styles.default.hexString};
      marker-fill-opacity: 0.9;
      marker-line-opacity: 1;
    }
  }
  `

  styles.rules.forEach(rule => {
    if (!rule.value) {
      return
    }

    carto += `
    #loveland['${rule.field.source}::${rule.field.value}'${rule.predicate
      .value}'${rule.value}'] {
      [GEOMETRY = LineString],
      [GEOMETRY = MultiLineString] {
        line-color: ${rule.color.hexString};
      }
      [GEOMETRY = Polygon],
      [GEOMETRY = MultiPolygon] {
        [zoom >= 14] {
          line-color: ${rule.color.hexString};
        }
        polygon-fill: ${rule.color.hexString};
        polygon-opacity:0.9;
      }
      [GEOMETRY = Point] {
        marker-line-color: ${rule.color.hexString};
        marker-fill: ${rule.color.hexString};
      }
    }
    `
  })

  return carto
}

export const buildLovelandFields = styles => {
  if (!styles) {
    return {}
  }

  const fields = {
    parcel: {},
  }

  styles.rules.forEach(rule => {
    if (isPlace(rule.field.source)) {
      fields.parcel[rule.field.source] = []
    } else {
      fields[rule.field.source] = []
    }
  })

  styles.rules.forEach(rule => {
    if (isPlace(rule.field.source)) {
      fields.parcel[rule.field.source].push(rule.field.value)
    } else {
      fields[rule.field.source].push(rule.field.value)
    }
  })

  return fields
}

export const buildQueryFromActiveStyles = styles => {
  const query = {
    parcel:    {},
    operation: 'union',
  }
  styles.rules.forEach(rule => {
    if (isPlace(rule.field.source)) {
      query.parcel[rule.field.source] = true
    } else {
      query[rule.field.source] = true
    }
  })

  return query
}

// Reducers

const initialState = {
  beingEditedRules: [],
  activeRules: [],
  defaultRule: initialDefaultRule
};

export const styles = (state = initialState, action) => {
  switch (action.type) {
    case SET_STYLES:
      return {
        ...state,
        ...action.data,
      }
    case ADD_BEING_EDITED_RULE:
      return {
        ...state,
        beingEditedRules: [...state.beingEditedRules, action.data]
      }
    case SET_BEING_EDITED_RULES:
      return {
        ...state,
        beingEditedRules: action.data
      }
    case SET_ACTIVE_RULES:
      return {
        ...state,
        activeRules: action.data
      }
    case SET_DEFAULT_RULE:
      return {
        ...state,
        defaultRule: action.data
      }
    case RESET_RULES:
      return {
        beingEditedRules: [],
        activeRules: [],
        defaultRule: {}
      }
    default:
      return state
  }
}

export default styles
