/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { handleModalVisibility } from '../../common/util'
import { useDispatch, useSelector } from 'react-redux'
import {
  setLogoMenuOpen,
  setZoomDisplayOpen,
  closeOtherModals,
} from 'ducks/mapControls'
import withStore from '../withStore'
import passGLMapEventToFlight from '../../common/utils/passGLMapEventToFlight'

// Logos
import LogoSvgWhite from '../../../assets/images/logos/regrid_tm_white.svg'
import LogoSvgFree from '../../../assets/images/logos/regrid_tm_free.svg'
import LogoSvgPro from '../../../assets/images/logos/regrid_tm_pro.svg'
import LogoSvgEnterprise from '../../../assets/images/logos/regrid_tm_enterprise.svg'
import LogoSvgTeam from '../../../assets/images/logos/regrid_tm_team.svg'

// TODO - move this to a util function
const planTypeLogos = {
  'plan-free':           LogoSvgFree,
  'plan-starter':        LogoSvgFree,
  'plan-tile':           LogoSvgFree,
  'plan-tileserver':     LogoSvgFree,
  'plan-pro':            LogoSvgPro,
  'plan-team':           LogoSvgTeam,
  'plan-bundle':         LogoSvgTeam,
  'plan-enterprise':     LogoSvgEnterprise,
  'plan-unlimited':      LogoSvgEnterprise,
  'plan-api':            LogoSvgEnterprise,
  'plan-combo':          LogoSvgEnterprise,
  'plan-api-combo':      LogoSvgEnterprise,
  'plan-featureservice': LogoSvgEnterprise,
  'plan-typeahead':      LogoSvgEnterprise,
}

const AdminDropdown = () => {
  const adminLinks = [
    { href: '/admin', text: 'Accounts' },
    { href: '/admin/map', text: 'Activity Map' },
    { href: '/admin/api_trials', text: 'API Trials' },
    { href: '/admin/orders', text: 'Data Orders' },
    { href: '/admin/notify', text: 'Follows / Notify' },
    { href: '/admin/metrics', text: 'Metrics' },
    { href: '/admin/codes', text: 'Offers (Codes)' },
    { href: '/admin/requests', text: 'Requests' },
    { href: '/admin/sidekiq', text: 'Sidekiq' },
    { href: '/admin/pages', text: 'Tooltips' },
    { href: '/admin/neighborhoods/new', text: 'Upload Bounds' },
    { href: '/admin/users', text: 'Users' },
    { href: '/admin/user_profiles', text: 'User Profiles' },
    { href: '/admin/imports_exports/running', text: 'Running Imp/Exp' },
    { href: '/admin/search_feedback', text: 'Search Feedback' },
  ]
  return (
    <li className="admin">
      <div className="dropdown">
        <span
          className="dropdown-toggle"
          aria-label="Admin"
          data-toggle="dropdown"
        >
          <i className="fas fa-edit"></i>
          <span className="mobile-only">Admin</span>
          <i className="fas fa-caret-down"></i>
        </span>
        <ul className="dropdown-menu pull-right">
          {adminLinks.map((link, index) => (
            <li key={index}>
              <a href={link.href}>{link.text}</a>
            </li>
          ))}
        </ul>
      </div>
    </li>
  )
}

const MenuItem = ({ title, subtitle, buttons }) => (
  <div className={`logo-menu-body-item`}>
    {subtitle && <p className="xsmall subtle margin-btm-sm">{subtitle}</p>}
    <p className="truncate margin-btm-sm bold">{title}</p>
    <div className="section-title padding-btm-sm bold">
      {buttons.map((button, index) => {
        return (
          <a
            key={index}
            className={`btn btn-round btn-xs ${button?.classes ?? 'plan-color-accent'}`}
            href={button.href}
            title={button.title}
            onClick={button.onClick}
            {...(button?.external && {
              target: '_blank',
              rel:    'noopener noreferrer',
            })}
          >
            {button.icon && <i className={button.icon}></i>}
            {button.text}
          </a>
        )
      })}
    </div>
  </div>
)

const LogoMenu = ({
  marketingRoot,
  platformRoot,
  group,
  groupOptions = [],
  currentUser,
  wasAdmin,
  editUserInfo,
  signOutUser,
  isAdmin,
  isGuestUser,
  isPaidUser,
  supportUrl,
  mapName,
  primaryGroup,
  planClass,
}) => {
  const dispatch = useDispatch()
  const [isCaretHovered, setCaretHovered] = useState(false)
  const isZoomDisplayOpen = useSelector(
    (state) => state.mapControls.zoomDisplayOpen
  )
  const isLogoMenuOpen = useSelector((state) => state.mapControls.logoMenuOpen)

  const handleToggle = () => {
    // close modals other than the logo menu and map nav
    dispatch(closeOtherModals(['logoMenuOpen', 'mapNavOpen']))
    // toggles based on layers modal state
    dispatch(setLogoMenuOpen(!isLogoMenuOpen))
    handleModalVisibility('logoMenuOpen', isLogoMenuOpen)
    // eslint-disable-next-line
    trackGoogleTagManagerEvent({ event: 'clicked_regrid_dropdown' })
  }

  const toggleZoomDisplay = () => {
    dispatch(setZoomDisplayOpen(!isZoomDisplayOpen))
  }

  const handleSignIn = () => {
    handleToggle()
    passGLMapEventToFlight('pane:request:show', {
      tab:       '#signup-signin',
      flipState: 'signin',
    })
  }

  const freeDescription = (
    <>
      <p className="bold margin-top-md">We put land on the map.</p>
      <p>
        Welcome to the first-ever 100% land parcel coverage map of the United
        States, with property boundaries and data for every county.
      </p>
      <p className="small">
        If you want to use this data outside the Regrid Property App, we offer
        other options for using our parcel data as well.
      </p>
      <p className="small bold margin-btm-sm">Self-Serve</p>
      <ul className="padding-left-lg">
        <li className="margin-top-sm small">
          <a
            href={`${platformRoot}/api`}
            target="_blank"
            rel="noopener noreferrer"
            className="bold"
          >
            API
          </a>
        </li>
        <li className="margin-top-sm small">
          <a
            href={`${platformRoot}/store`}
            target="_blank"
            rel="noopener noreferrer"
            className="bold"
          >
            Data Store
          </a>
        </li>
      </ul>
      <p className="small bold margin-btm-sm">Bulk Data Enterprise Licenses</p>
      <p className="small">
        <a
          href={`${marketingRoot}/parcels#contact`}
          target="_blank"
          rel="noopener noreferrer"
          className="bold"
        >
          Contact us
        </a>
        , or read more about licensing options including{' '}
        <a
          href={`${marketingRoot}/parcels`}
          target="_blank"
          rel="noopener noreferrer"
          className="bold"
        >
          bulk file delivery
        </a>{' '}
        or our{' '}
        <a
          href={`${marketingRoot}/feature-service`}
          target="_blank"
          rel="noopener noreferrer"
          className="bold"
        >
          ESRI-compatible Feature Service
        </a>
        .
      </p>
    </>
  )

  const planTypeLogo = planTypeLogos[planClass] ?? LogoSvgFree
  const groupName = isGuestUser ? primaryGroup?.name : group?.name
  const groupPath = isGuestUser ? primaryGroup?.path : group?.path

  return (
    <div
      id="map-logo-menu"
      className={`padded shadow-gl ${
        isLogoMenuOpen
          ? 'rounded-lg'
          : 'rounded-full collapsed plan-color-accent'
      }`}
    >
      <div className="logo-menu-header flex">
        <div
          className="flex-row-between width-100 nomobile"
          onClick={handleToggle}
        >
          <a
            href={marketingRoot}
            title="Regrid Home"
            target="_blank"
            rel="noopener noreferrer"
          >
            {isLogoMenuOpen ? (
              <img src={planTypeLogo} alt="Regrid Logo" />
            ) : (
              <img src={LogoSvgWhite} alt="Regrid Logo" />
            )}
          </a>
          <div
            className="logo-menu-toggle"
            onClick={handleToggle}
            onMouseEnter={() => setCaretHovered(true)}
            onMouseLeave={() => setCaretHovered(false)}
            onTouchStart={() => setCaretHovered(true)}
            onTouchEnd={() => setCaretHovered(false)}
          >
            <i
              className={`fa fa-angle-down h4 margin-left-md ${
                isCaretHovered ? 'plan-color-accent' : ''
              } ${
                isLogoMenuOpen
                  ? 'plan-color-accent rotate-180'
                  : 'rotate-0 white'
              }`}
            ></i>
          </div>
        </div>
        <div className="flex-row-between mobile-only">
          <div className="logo-menu-toggle" onClick={handleToggle}>
            {isLogoMenuOpen ? (
              <i className={`fa-solid fa-times h4 large`}></i>
            ) : (
              <i className={`fa-solid fa-bars h4 large`}></i>
            )}
          </div>
        </div>
      </div>
      <div className={`logo-menu-body margin-top-md`}>
        {isGuestUser && (
          // Guest indicates user is viewing a group (shared project) they are not a member of
          <div className="logo-menu-body-group rounded padded-sm margin-btm-lg plan-color">
            <div className={`logo-menu-body-item`}>
              <div className="badge badge-neon margin-btm-xs">Guest</div>
              <p className="xsmall subtle margin-btm-sm">Shared Project</p>
              <p className="truncate margin-btm-md bold">{mapName}</p>
              <p className="xsmall subtle margin-btm-sm">Shared By</p>
              <p className="truncate margin-btm-md bold">{group.name}</p>
            </div>
          </div>
        )}
        {currentUser && (
          <div className="logo-menu-body-group rounded padded-sm margin-btm-lg plan-color">
            {group && groupOptions.length > 0 && (
              // Group selector
              <MenuItem
                title={groupName}
                subtitle="Account"
                buttons={[
                  {
                    href:  `${groupPath}/settings`,
                    title: 'Settings',
                    icon:  'fas fa-edit small',
                  },
                  {
                    href: `${groupPath}`,
                    text: 'Dashboard',
                  },
                  {
                    href: `${groupPath}/settings/billing`,
                    text: 'Billing',
                  },
                ]}
              />
            )}
            {group && groupOptions.length === 0 && (
              // Group single
              <MenuItem
                title={groupName}
                subtitle="Account"
                buttons={[
                  {
                    href:  `${groupPath}/settings`,
                    title: 'Settings',
                    icon:  'fas fa-edit small',
                  },
                  { href: `${groupPath}`, text: 'Dashboard' },
                  { href: `${groupPath}/settings/billing`, text: 'Billing' },
                ]}
              />
            )}
            {currentUser && isPaidUser && (
              // User - Paid
              <MenuItem
                title={currentUser.name}
                subtitle="User"
                buttons={[
                  {
                    href:  editUserInfo,
                    title: 'Edit User Info',
                    text:  'Edit User Info',
                    icon:  'fas fa-edit margin-right-md',
                  },
                ]}
              />
            )}
            {currentUser && !isPaidUser && (
              // User - Free
              <MenuItem
                title={currentUser.name}
                subtitle="User"
                buttons={[
                  {
                    href:  editUserInfo,
                    title: 'Edit User Info',
                    icon:  'fas fa-edit',
                  },
                  {
                    href:  '/plans',
                    title: 'Upgrade to Pro',
                    text:  'Upgrade to Pro',
                  },
                ]}
              />
            )}
          </div>
        )}
        {(!currentUser || !isPaidUser) && (
          // User Free or Not Signed In
          <div className="margin-btm-md margin-top-lg">
            <div id="logo-cta" className="rounded padded-sm">
              {freeDescription}
            </div>
          </div>
        )}

        {/* Opt out box */}
        {(currentUser || isPaidUser) && (
          <div
            className={`logo-menu-body-group padded-sm rounded is-admin margin-btm-lg`}
          >
            <MenuItem
              title="Enjoying our new design?"
              subtitle=""
              planLevel=""
              buttons={[
                {
                  href:    '#',
                  title:   'Revert to old design',
                  icon:    'fas fa-history margin-right-sm',
                  text:    'Revert',
                  classes: 'btn-default',
                  onClick: async () => {
                    // eslint-disable-next-line no-undef
                    trackGoogleTagManagerEvent({ event: 'clicked_opt_out' })
                    const response = await fetch(`/preferences.json`, {
                      credentials: 'same-origin',
                      headers:     {
                        'Content-Type': 'application/json',
                      },
                      method: 'POST',
                      body:   JSON.stringify({
                        data: {
                          feature_flags: {
                            react_map_component: false,
                          },
                        },
                      }),
                    })
                    if(response?.ok) {
                      const data = await response.json()
                      if(data?.status === 'ok') {
                        window.location.reload()
                      } else {
                        console.error(
                          'There was a problem changing the feature flag (data):',
                          data
                        )
                      }
                    } else {
                      console.error(
                        'There was a problem changing the feature flag (response):',
                        response
                      )
                    }
                  },
                },
              ]}
            />
          </div>
        )}

        {isAdmin && (
          <div className={`logo-menu-body-group padded-sm rounded is-admin`}>
            <div
              data-tip="groups-menu"
              className="logo-menu-body-item margin-btm-md"
            >
              <div className="section-title flex-row-between padding-btm-sm bold">
                <span className="truncate">Admin</span>
                <ul className="nav" id="logo-admin-dropdown">
                  <AdminDropdown />
                </ul>
              </div>
              <div className="margin-top-sm">
                <div className="form-group">
                  <label htmlFor="toggleZoomDisplay" className="small">
                    <input
                      type="checkbox"
                      id="toggleZoomDisplay"
                      checked={isZoomDisplayOpen}
                      onChange={toggleZoomDisplay}
                    />
                    {` `}
                    Display Zoom Level
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}
        {wasAdmin && (
          <div className={`logo-menu-body-item padded-sm rounded is-admin`}>
            <a href="/admin/sign_in_as/last" className="bold">
              <span className="small">Switch to Admin</span>
            </a>
          </div>
        )}
      </div>
      <div className="menu-bottom-links flex-row-between margin-top-md padded">
        <a
          href={`${supportUrl}/property-app/section/property-web-app`}
          className="bold"
          title="Support Documentation"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fas fa-external-link-alt margin-right-md small"></i>
          <span className="small">Support Docs</span>
        </a>
        {currentUser ? (
          <a   href={`${signOutUser}?returnUrl=${encodeURIComponent(window.location.href)}`}
            className="bold">
            <i className="fas fa-sign-out-alt margin-right-md small"></i>
            <span className="small">Sign Out</span>
          </a>
        ) : (
          <a href="#" onClick={handleSignIn} className="show-signup bold">
            <i className="fas fa-sign-in-alt margin-right-md small"></i>
            <span className="small">Sign In</span>
          </a>
        )}
      </div>
    </div>
  )
}

LogoMenu.propTypes = {
  title:         PropTypes.string,
  subtitle:      PropTypes.string,
  marketingRoot: PropTypes.string,
  platformRoot:  PropTypes.string,
  buttons:       PropTypes.arrayOf(
    PropTypes.shape({
      href:    PropTypes.string,
      icon:    PropTypes.string,
      text:    PropTypes.string,
      onClick: PropTypes.func,
    })
  ),

}

export default withStore(LogoMenu)
