import PropTypes from 'prop-types';
import React from 'react';
import { connect, } from 'react-redux';
import bbox from '@turf/bbox';
import centroid from '@turf/centroid';

import withStore from '../withStore';
import { fetchParcelverseDataIfNeeded, getParcelverseData } from 'ducks/parcelverse';
import mapSettings from '../Map/mapSettings';

const PURPLE = 'hsl(304.3, 20%, 40%)';
const AVAILABLE_COLOR = '#68AF7E';
const UNAVAILABLE_COLOR = '#d8c147';

// Index of the geoid in the array of data sent by store/stats.json
const GEOID_INDEX = 2;
const TILESET_ID = '4gb360xr';
// const LAYER_ID = 'counties2023';
const LAYER_ID = 'admin2';
const tileURL = window.data.tileserver
const tileToken = window.data.tile_token
const admin2URL = `${tileURL}/api/v1/static/${LAYER_ID}/{z}/{x}/{y}.mvt&userToken=${tileToken}`

class ParcelverseMap extends React.Component {
  constructor(props){
    super(props);
    this.isEsri = window.location.pathname.includes('/store/esri');
  }

  componentDidMount() {
    const { dispatch, countiesKey, disableInteractivity } = this.props;
    const { center, bounds } = this.getStartingCoordinates();
    // Start loading the parcelverse data
    dispatch(fetchParcelverseDataIfNeeded(countiesKey));

    // Set up the basic map
    mapboxgl.accessToken = window.data.mapbox.access_token;
    const apiKey = window.data.esri_basemap_key;
    const basemapEnum = "arcgis/light-gray"; //https://developers.arcgis.com/maplibre-gl-js/maps/change-the-basemap-style-v2/#add-a-selector

    const opts = {
      container: 'map', // container id
      style:     `https://basemapstyles-api.arcgis.com/arcgis/rest/services/styles/v2/styles/${basemapEnum}?token=${apiKey}`,
      center:    center, // starting position [lng, lat]
      zoom:      9
    }

    if (disableInteractivity) {
      opts.interactive = false
    }

    const map = new mapboxgl.Map(opts);
    this.setState({ map });
    map.fitBounds(bounds, { animate: false, padding: 20 });

    map.on('load', this.setupMap.bind(this));
  }

  componentDidUpdate() {
    // Need to make sure the map has loaded before we try to style counties
    if(this.state.map && this.state.map.isStyleLoaded()) {
      this.styleActiveCounties();
    }
  }

  // Figure out where we start the map
  // If we have a county or state, we want to start centered there
  getStartingCoordinates() {
    const { state, county, country } = this.props;

    // Start with the US bounds
    let extent = [[ -122, 28.92], [-73, 47]];
    let bounds = extent;
    let center = [-74.5, 40];

    if(country === 'CA') {
      extent = mapSettings.commonBounds.canadaBounds
      bounds = extent
      center = [-98, 58]
    }

    if(state) {
      bounds = bbox(state);
      center = centroid(state).coordinates;
    }

    if(county) {
      bounds = bbox(county);
      center = centroid(county).coordinates;
    }

    return { bounds, center };
  }

  // We need for the Mapbox map to initialize before we can do some setup
  setupMap() {
    const map = this.state.map;
    const { county, state, country } = this.props;

    // Show the state outline on the map
    if(state) {
      map.addSource('state', {
        type: 'geojson',
        data: state
      });
      map.addLayer({
        id:     'state',
        type:   'line',
        source: 'state',
        layout: {},
        paint:  {
          'line-color': PURPLE,
          'line-width': 4
        }
      });
    }

    // Show the county outline on the map
    if(county) {
      map.addSource('county', {
        type: 'geojson',
        data: county
      });
      map.addLayer({
        id:     'county',
        type:   'line',
        source: 'county',
        layout: {},
        paint:  {
          'line-color': PURPLE,
          'line-width': 4
        }
      });
      map.addLayer({
        id:     'county-fill',
        type:   'line',
        source: 'county',
        layout: {},
        paint:  {
          'line-color': PURPLE,
          'line-width': 4
        }
      });

      this.displayParcelLayer(this.props.paths);
    }

    if(!county) {
      this.showCountyLayer();
      this.styleActiveCounties();
    }
  }

  // Show the county layer on the map
  // Made from the US Counties shapefile, processed with tippecanoe, and
  // added to mapbox as a dataset
  showCountyLayer() {
    const { map } = this.state;
    const { county, state, country } = this.props;

    // Figure out where to position the counties layer
    // We want to put it below the state or county outline
    let addBefore = null;
    if(state) {
      addBefore = 'state';
    }
    if(county) {
      addBefore = 'county';
    }

    // Actually add the layer
    map.addSource('counties', {
      type: 'vector',
      tiles:  [admin2URL]
    });
    map.addLayer({
      'id':           'counties',
      'type':         'line',
      'source':       'counties',
      'source-layer': LAYER_ID,
      'layout':       {
        'line-join': 'round',
        'line-cap':  'round'
      },
      'paint': {
        // when we load the county data, we'll set this dynamically
        // but that can load slowly, so start out with everything green
        'line-color': AVAILABLE_COLOR,
        'line-width':  { // Make the county outline bigger as you zoom in
          'type':  'exponential',
          'base':  1,
          'stops': [
              [0, 1],
              [9, 1],
              [12, 3],
              [24, 12]
          ]
        }
      },
      filter: ['==', ['get', 'admin0'], country]
    }, addBefore);
    map.addLayer({
      'id':           'counties-fill',
      'type':         'fill',
      'source':       'counties',
      'source-layer': LAYER_ID,
      'layout':       {},
      'paint':        {
        'fill-color':   AVAILABLE_COLOR,
        'fill-opacity': 0.4
      },
      filter: ['==', ['get', 'admin0'], country]
    }, addBefore);
  }

  // Show a raster parcel layer on the map
  displayParcelLayer(paths) {
    const map = this.state.map;
    var url = window.data.tileserver + '/api/v1/sources';

    var DEFAULT_STYLES = 'Map { background-color: rgba(0,0,0,0); } #loveland { line-color: hsl(304.3, 20%, 70%); line-width: 1; line-opacity: 0.6; [zoom >= 13] { line-opacity: 1; } [zoom >= 14] { line-opacity: 1; } [zoom >= 15] { line-width: 1.5; line-opacity: 1; } [zoom >= 16] { line-width: 2; }   [zoom >= 17] { line-width: 2.5; } [zoom >= 18] { line-width: 3.5; }}';

    let parcelQuery = {}
    paths.forEach(path => {
      parcelQuery[path] = true
    })
    $.ajax({
      method:      'POST',
      url:         url,
      contentType: 'application/json; charset=utf-8',
      dataType:    'json',
      data:        JSON.stringify({
        query:  {
          parcel: parcelQuery
        },
        styles: DEFAULT_STYLES,
      })
    })
    .done(tilejson => {
      map.addSource('parcels', {
        type:     'raster',
        tiles:    tilejson.tiles,
        tileSize: 256
      });
      map.addLayer({
        id:     'parcel-tiles',
        type:   'raster',
        source: 'parcels',
      }, 'county'); // add before the county layer
    })
    .fail(console.error);
    // TODO use sentry
  }

  styleActiveCounties() {
    const { map } = this.state;
    const { parcelverse } = this.props;
    const { counties } = parcelverse.data;

    // Too early -- we don't have the metadata yet
    if(parcelverse.isLoading || counties.length == 0) {
      return;
    }

    // Counties aren't loaded yet
    if (!map.getLayer('counties')) {
      return
    }

    // At this point, we have loaded the counties from localforage
    const colors = {
      false: UNAVAILABLE_COLOR,
      true:  AVAILABLE_COLOR,
    };

    // Build up Mapbox GL data-driven styles
    // inidicates which counties are active
    const included = [];
    const expression = ['match', ['get', 'geoid']];
    counties.forEach(county => {
      const geoid = county[GEOID_INDEX];
      const status = county[3];
      const color = colors[status] || colors[false];
      // Match expressions need to be unique
      if(!included.includes(geoid)){
        included.push(geoid);
        expression.push(geoid, color);
      }
    });
    expression.push(UNAVAILABLE_COLOR);

    // Now update the styles with these dynamic filters
    map.setPaintProperty('counties', 'line-color', expression);
    map.setPaintProperty('counties-fill', 'fill-color', expression);

    // Handle interaction on this layer
    // Create a popup, but don't add it to the map yet.
    var popup = new mapboxgl.Popup({
      anchor:       'center',
      closeButton:  false,
      closeOnClick: false,
    });

    // Handle hover on the map
    let self = this;
    map.on('mousemove', 'counties-fill', e => {
      if(self.props.disableInteractivity) {
        return;
      }

      map.getCanvas().style.cursor = 'pointer';

      // If we don't have the base county data loaded yet, skip this step
      if(!counties) {
        return;
      }

      if(e.features.length > 0) {
        const centroidFeature = centroid(e.features[0]);
        const coordinates = centroidFeature.geometry.coordinates;
        const properties = e.features[0].properties;

        // We need to look up the path in the metadata we get from /store/stats.json
        // It's not baked into the tiles, maybe it should be
        const metadata = counties.find(county => county[GEOID_INDEX] == properties.geoid);

        if(!metadata) {
          return;
        }

        let stateAbbr = metadata[1].match(/^\/[a-z]{2}\/([a-z]{2})/)[1].toUpperCase();
        const description = `<a href="/store${this.isEsri ? '/esri' : ''}${metadata[1]}">${properties.namelsad}, ${stateAbbr}</a>`;

        // Populate the popup and set its coordinates
        // based on the feature found.
        popup
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(map);
      }
    });


    // Navigate to a county on click
    if(!self.props.disableInteractivity) {
      map.on('click', 'counties-fill', e => {
        if(e.features.length > 0) {
          const properties = e.features[0].properties;
          const metadata = counties.find(county => county[GEOID_INDEX] == properties.geoid);
          if(metadata) {
            // can't touch 'this' from here, so again
            let isEsri = window.location.pathname.includes('/store/esri');
            let extra = isEsri ? '/esri' : '';
            window.location = `/store${extra}${metadata[1]}`;
          }
        }
      });
    }
    return;
  }

  render() {
    return null; // Managed by the haml template, otherwise we get an unwanted wrapper
  }
}

ParcelverseMap.propTypes = {
  country:              PropTypes.string,
  county:               PropTypes.object, // geojson county object
  countiesKey:          PropTypes.string.isRequired, // cache key for county metadata
  disableInteractivity: PropTypes.bool,
  dispatch:             PropTypes.func.isRequired,
  parcelverse:          PropTypes.shape({
    data:      PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
  }).isRequired,
  state:                PropTypes.object,// geojson state object
  paths:                PropTypes.array,
};


const mapStateToProps = (state) => {
  return {
    parcelverse: getParcelverseData(state),
  };
};


export default withStore(connect(mapStateToProps)(ParcelverseMap));