import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { get, post } from 'ducks/api';
import FocusAreaListItem from './FocusAreaListItem';

/*
Tool to add existing focus areas to a project
*/
const FocusAreaAdder = ({focusAreasAttachedToProject, passEventToFlight, project}) => {
  let [focusAreas, setFocusAreas] = useState([]);
  let [isLoading, setIsLoading] = useState(false);

  // Get the focus areas available to this group
  useEffect(() => {
    const fail = (message) => {
      setIsLoading(false);
    }

    const done = data => {
      setFocusAreas(data.neighborhoods);
      setIsLoading(false);
    };

    // Only set loading the first time we are getting focus areas
    // That's because future loads might happen after draw adds an area, OR after
    // a user adds a focus area to their map (we can't easily tell those events apart yet)
    // and it adds an unnecessary flash of <Loading>
    // This logic should probably be improved
    if(focusAreas.length == 0) {
      setIsLoading(true);
    }
    
    get(`/neighborhoods.json`, { done, fail });
  }, [project]);


  if(isLoading) {
    return <p><i className="fas fa-spin fa-spinner"></i> Loading focus areas</p>;
  }

  // Only show the focus areas not already on the map
  let focusAreasNotYetOnProject = focusAreas.filter(neighborhood => {
    const exists = focusAreasAttachedToProject.list.find(existing => {
      return existing.path == neighborhood.path;
    });
    return !exists;
  });

  const sortRegionsAlphabetically = (a, b) => {
    if(a.headline.toLowerCase() < b.headline.toLowerCase()) { return -1; }
    if(a.headline.toLowerCase() > b.headline.toLowerCase()) { return 1; }
    return 0;
  }

  focusAreasNotYetOnProject = focusAreasNotYetOnProject.sort(sortRegionsAlphabetically)

  const folder = 'Focus Areas';
  const areas = focusAreasNotYetOnProject.map(area =>
    <FocusAreaListItem
      key={area.path}
      area={area}
      passEventToFlight={passEventToFlight}
      folder={folder}
      projectPath={project.path}
      />);

  return <div>
      {!_.isEmpty(areas) && <h4>Add focus area to project</h4>}
      {areas}
    </div>;
};

FocusAreaAdder.propTypes = {
  focusAreasAttachedToProject:  PropTypes.object.isRequired,
  passEventToFlight:            PropTypes.func.isRequired,
  project:                      PropTypes.object,
};

export default FocusAreaAdder;