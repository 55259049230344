import { PLANS } from './settings'
import passGLMapEventToFlight from './utils/passGLMapEventToFlight'

export const NETWORK_REQUEST_FAILED = 'Network request failed'
export const RATE_LIMIT_EXCEEDED = 'Rate limit exceeded'

export const SUCCESS = 'SUCCESS'
export const FAIL = 'FAIL'

// Get Object's Property, returned undefined With Warning if anything is undefined
// get(state, 'prop1', 'prop2')  == state.prop1.prop2
// returns the value of the property
//
export const get = (...args) => {
  let options = { warn: true, has: false, }
  return getProperty(args, options)
}

//
// when you want the functionality without the warning
//
export const getWithoutWarning = (...args) => {
  let options = { warn: false, has: false, }
  return getProperty(args, options)
}

//
// Just check if property exists
// returns: true / false
//
export const has = (...args) => {
  let options = { warn: false, has: true, }
  return getProperty(args, options)
}

//
// Edgecase - if you want to check if it exists, and you want a warning
// if it doesn't
//
export const hasWithWarning = (...args) => {
  let options = { warn: true, has: true, }
  return getProperty(args, options)
}

//
// You probably want to use the helper functions above
// But we're all adults here, you want to use this function directly? Go ahead!
// Example:
// getProperty(state, 'user', 'firstName', {warn: true, has: false, })
//
// Remember: This function can still return undefined, don't do something like this:
// var parcels = getProperty(state, 'user', 'parcels')
// if (parcels.length > 1)  // This can fail if parcles is not array, or undefined!
// console.log(parcels.items) // again this can fail if parcels undefined
//
// @Robustness: We should probably have something like getArray, which either
// returns the array, or an empty array. getObject could work too.
//
// @Robustness: In the future we may want to allow arrays of properties,
// to get multiple properties in one go. Extra complexity though, so only
// worth it if this is a common use case
//
// @Performance: Right now, we're passing in an "options" object, this increases clarity
// but results in a new object each time. Could be an issue if this done in large loops
// in which case we can optimize it into an array, or just two items pushed to the end of
// arguments. Makes adding more options more confusing though.
//
//
export const getProperty = (args, options) => {
  // @simplification. It's probably better to prepare this first.
  // first argument is always object but in can be undfined

  var argsLen = args.length

  var propertyExists = true
  var currentProperty = '(first item)'
  var finalProperty = false
  let obj = null

  for (let index = 0; index < argsLen; ++index) {
    if (index == 0) {
      // first item should be an object
      obj = args[index]
    } else {
      // next items will be properties
      currentProperty = args[index]
      obj = obj[currentProperty]
    }
    if (index == argsLen - 1) {
      finalProperty = true
    }

    // it's okay for the final property to be null
    if ((!finalProperty && obj === null) || obj === undefined) {
      if (options.warn) {
        debug('obj undefined index argsLen ', obj, index, argsLen)
        // "args" can be huge so don't print that here
      }
      propertyExists = false
      break
    }
  }

  // If just checking if object "has" property, return true or false
  if (options.warn && !propertyExists) {
    debug(
      'getProperty() : "' + currentProperty + '"\nProperties: ',
      Array.prototype.slice.call(args, 1, argsLen),
    )
  }
  if (options.has) {
    return propertyExists
  }

  if (finalProperty) {
    return obj
  } else if (options['default']) {
    return options['default']
  } else {
    return undefined
  }
}

export const handleError = (err, filename, message, otherData = null) => {
  debug('util.handleError: ', message, err, filename, otherData)
}

// Console log spam only in development
export const debug = () => {
  if(!['production', 'test'].includes(window.data?.env)) {
    console.log.apply(console, arguments);
  }
};

export const isNullOrUndefined = (val) => {
  return val === null || val === undefined;
} ;

//takes a place path and return an array of the of the country, state, and county
export const getSplitPath = (path) => {
  const splitPath = path.split('/')
  return splitPath.filter(geo => geo !== "")
}

// Send object of params to get back a string to append to URL
export const getURLParamsString = (paramsObj) => {
  const params = new URLSearchParams(paramsObj)
  return '?' + params.toString();
};

export const convertToUSCurrency = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  })
  return formatter.format(amount).replace('.00', '')
};

export const formattedNumber = (number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 5
  })
  return formatter.format(number);
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
};

export const isPaidUser = () => {
  return window.data.has_sitecontrol ?? false;
};

/**
 * Handles the click event on a tab pane.
 * 
 * @param {string|Object} data - The data associated with the tab pane click event. If it is a string, it is treated as the tab ID. If it is an object, it should have the format { tab: '#tab-id' }.
 */
export const handleClickTabPane = (data) => {
  if (data && typeof data === 'string') { data = { tab: data } }
  if (data && data?.tab) {
    // ensure data is an object with the format { tab: '#tab-id' }
    if (!data.tab.startsWith('#')) { data.tab = '#' + data.tab }
    passGLMapEventToFlight('pane:request:show', data);
  } else {
    passGLMapEventToFlight('pane:request:show');
  }
}

// Handles DOM changes for opening/closing modals outside of React 
export const handleModalVisibility = (modalName, setVisible) => {
  const mapNav = document.querySelector('#map-nav');
  const waterfall = document.querySelector('#waterfall');
  const activePane = document.querySelector('#waterfall > .tab-content > .tab-pane.active');
  const activeNavLi = document.querySelector('#map-nav ul.nav li.active');
  const isMobile = window.matchMedia("(max-width: 768px)").matches
  switch (modalName) {
    case 'mapNavOpen':
      // User toggled, dedicated hide classes
      if (setVisible) {
        mapNav.classList.remove('collapsed')
        mapNav.classList.remove('hide')
        waterfall.classList.remove('map-nav-collapsed')
        if (activeNavLi && activePane) { 
          waterfall.classList.add('show') 
        }
      } else {
        mapNav.classList.add('collapsed')
        waterfall.classList.add('map-nav-collapsed')
        if(isMobile) {
          waterfall.classList.remove('show') 
        }
      }
      break;
    case 'logoMenuOpen':
      if (setVisible) {
        mapNav.classList.remove('hide')
        if (activeNavLi && activePane) {
          waterfall.classList.add('show')
        }
      } else {
        mapNav.classList.add('hide')
        waterfall.classList.remove('show')
      }
    case 'layersModalOpen':
    case 'helpModalOpen':
      if (!isMobile && setVisible) {
        if (activeNavLi && activePane) {
          waterfall.classList.add('show')
        }
      } else {
        waterfall.classList.remove('show')
      }
      break;
    default:
      break;
  }
}

export const uniqueArray = (array) => {
  if(!Array.isArray(array)) {
    debug('uniqueArray: Sorry, I only work with arrays')
    return
  }
  return [...new Set(array)]
};

export const titleize = (str) => {
  // lodash capitalize function doesn't handle underscores
  if(!str) {
    debug("titleize: Sorry, I only work with strings");
    return
  }
  return str
    .replace(/_/g, ' ') // replace underscores with spaces
    .replace(/(?:^|\s|-)\S/g, function (c) {
      return c.toUpperCase();
  });
}