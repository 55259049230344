import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { find, map, mapValues, size } from 'lodash';
//import PlanPriceButton from 'components/Payment/PlanPriceButton'

const ApiTierSelector = props => {

  // Select standard/premium
  let plan = props.selectedPlan && find(props.plans, { plan: props.selectedPlan })
  
  let tierButtons = plan && mapValues(plan.levels, (info, key) => {
    return <a href='#'
      key={key}
      className={'btn btn-round btn-lg ' + (props.selectedApiTier == key ? ' btn-success selected' : 'btn-primary')}
      onClick={e => { props.onClickApiTier(e, key) }}
      >
      Choose {info.name}
    </a>
  })

  return <>
    {plan && <div className='plans api-plans'>
      {size(tierButtons) > 1 
        ? <div className='lead centered api-tier'>
            <h3>Your selected plan: {plan.display_name}</h3> 
                Please choose between Standard and Premium tiers.
          </div>
        : <div className='lead centered api-tier'>
            <h3>Your selected plan: {plan.display_name}</h3> 
                One tier available, please choose {plan.display_name.replace(/ .*/,'')} below to confirm.
          </div>
      }
      <div className='plans-in'>
        {map(plan.levels, (info, key) => {
          return <div className='plan grow featured blue-frame' key={key}>
            <div className='header'>
              <h3>{info.name}</h3>
            </div>
            <h4 className='price'>${numberWithCommas(info.price)} / month base price</h4>
            <div className="features">
              <ul>
                {map(info.features, (feat, fi) => {
                  // Extra features listed in ApiAccess defs
                  return <li key={fi} dangerouslySetInnerHTML={{ __html: feat }}></li>
                })}
              </ul>
            </div>

            <div className='centered'>
              {tierButtons[key]}
            </div>
          </div>
        })}
      </div>
    </div>}
    <p className='small centered subtle' style={{marginTop: '1em' }}>
      ¹ Please see "Hybrid Monthly Pricing Model" below
    </p>
  </>
}


ApiTierSelector.propTypes = {
  plans: PropTypes.array.isRequired,
  selectedPlan: PropTypes.string,
  selectedApiTier: PropTypes.string,
  onClickApiTier: PropTypes.func.isRequired,
}


export default ApiTierSelector;
