import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray, map } from 'lodash';
import SEAnswer from 'components/SurveyEditor/SEAnswer';

const SEQuestion = props => (
  <div className='question'>
    <div className='row'>
      <div className='col-md-10'>
        <div className='number lead museo'>{props.index + 1}</div>
        <textarea name='question' className='form-control big'
          placeholder='Enter the survey question here'
          value={props.question}
          data-question-index={props.index}
          onChange={props.handlers.changeQuestionText}
          />
      </div>
      <div className='col-md-2 right'>
        <a href='#' title='Move this question one position up' tabIndex='-1'
          onClick={e => { props.handlers.moveQuestion(e, props.index, 'up') }}>
          <i className='fas fa-arrow-up big' aria-hidden='true'/>
        </a>
        <a href='#' title='Move this question one position down' tabIndex='-1'
          onClick={e => { props.handlers.moveQuestion(e, props.index, 'down') }}>
          <i className='fas fa-arrow-down big' aria-hidden='true'/>
        </a>
        <a href='#' title='Remove this question' tabIndex='-1'
          onClick={e => { props.handlers.removeQuestion(e, props.index) }} >
          <i className='fas fa-trash big' aria-hidden='true'/>
        </a>
      </div>
    </div>
    
    <div className='row'>
      <div className='col-md-4'>
        <input type='text' name='column' className='form-control'
          placeholder='Short column name (optional)'
          value={props.column}
          data-question-index={props.index}
          onChange={props.handlers.changeQuestionColumn}
          />
      </div>
      <div className='col-md-1'>
        <i className='fas fa-question-circle tip' rel='tooltip' aria-hidden='true'
          title="When viewing survey results on the site or as a spreadsheet, it can help to use a short name, eg. 'occupancy' instead of the full question"></i>
      </div>
      <div className='col-md-5'>
        <select className="form-control"
          value={props.answers === 'TEXTAREA' ? 'text' : 'multiple'}
          data-question-index={props.index}
          onChange={props.handlers.changeQuestionType}>
          <option value='multiple'>This is a multiple choice question</option>
          <option value='text'>Let surveyors enter free text</option>
        </select>
      </div>
    </div>

    {props.answers === 'TEXTAREA' && 
      <div className='row'>
        <div className='col-md-5'>
          <label>
            <input type='checkbox' className='free-required' checked={props.required} onInput={e => { props.handlers.changeQuestionRequired(e, props.index) }}/>
            Required
          </label>
        </div>
        <div className='col-md-5'>
          <select className="form-control"
            value={props.next && (props.next.disambiguation || props.next.index)}
            onChange={props.handlers.changeQuestionNext}
            data-question-index={props.index}>
            <option value="__DEFAULT__">Continue to next question</option>
            {map(props.nextQuestions, (nq, i) => (
              <option key={i} value={nq.disambiguation || nq.column}>{nq.question}</option>
            ))}
            <option value='__END__'>End survey</option>
          </select>
        </div>
      </div>
    }
    
    {isArray(props.answers) && 
      <div className='multi'>
        <h4>Answer options:</h4>
        <div className='answers'>
          {map(props.answers, (answer, i) => {
            return <SEAnswer
              key={i}
              index={i}
              questionIndex={props.index}
              {...answer}
              handlers={props.handlers}
              nextQuestions={props.nextQuestions}
              />
          })}
        </div>
        <div>
          <a href='#' className='btn btn-sm btn-primary'
            onClick={e => { props.handlers.addAnswer(e, props.index)}}>
            <i className='fas fa-plus' aria-hidden='true'/> Add an Answer
          </a>
        </div>
      </div>
    }
  </div>
)


SEQuestion.propTypes = {
  question:       PropTypes.string.isRequired,
  column:         PropTypes.string.isRequired,
  handlers:       PropTypes.objectOf(PropTypes.func).isRequired,
  index:          PropTypes.number.isRequired,
  next:           PropTypes.object,
  nextQuestions:  PropTypes.arrayOf(PropTypes.object).isRequired,
  answers:        PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}


export default SEQuestion;
