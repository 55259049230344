import React from 'react'

/**
 * Renders a panel with details about a specific add-on.
 *
 * @component
 * @param {Object} props - component props
 * @param {string} props.addonField - key for the add-on field
 * @param {string} props.addonDataKey - key for the add-on data
 * @param {Function} props.setShowAddonPanel - fn to toggle the panel
 * @param {Object} props.addonData - full add-on data object
 * @returns {JSX.Element} Rendered component
 */
const ParcelAddonPanel = ({
  addonField,
  addonDataKey,
  setShowAddonPanel,
  addonData,
}) => {
  // addonField and addonDataKey have been checked by ParcelDetailsField.jsx

  /** Get the add-on data for a specific field. */
  const getAddonDataForField = (field) => {
    if (!addonData || !addonDataKey) {
      debug(
        `No addonDataKey (${addonDataKey}) or addonData found for field: ${field}`
      )
      return null
    }
    return addonData[addonDataKey]
  }

  /** Add-on data for the current field. */
  const addonFieldData = getAddonDataForField(addonField)
  const { title, description, productLink, listItems, schemaLink } =
    addonFieldData
  if (!title || !description || !productLink || !listItems || !schemaLink) {
    debug(`Missing data for addonField: ${addonField}`)
    return null
  }
  return (
    <div className="addon-panel rounded padded margin-top-sm">
      <div className="flex-row-between margin-btm-md">
        <p className="bold margin-btm-none">{`Add-On: ${title}`}</p>
        <button
          className="close no-btn"
          title="Close"
          aria-label="Close"
          onClick={() => setShowAddonPanel(false)}
        >
          <i className="fas fa-times"></i>
        </button>
      </div>
      <div className="flex-col-start">
        <p className="small">{description}</p>
        <ul className="simple small">
          {listItems.map((item, index) => (
            <li className="simple" key={`addonDataKey - ${index}`}>
              {item}
            </li>
          ))}
        </ul>
        <p className="small">
          View the full
          <a href={schemaLink} target="_blank">
            {' '}
            {title} schema{' '}
          </a>
          or read more about the product below.
        </p>
      </div>
      <a
        id={`addon-badge-${addonDataKey}`}
        href={productLink}
        target="_blank"
        rel="noreferrer"
        className="btn btn-round btn-sm btn-primary btn-block margin-btm-md"
      >
        {title}
        <i className="fas fa-external-link-alt margin-left-md"></i>
      </a>
    </div>
  )
}

export default ParcelAddonPanel
