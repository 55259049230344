import React, { useEffect, useState } from 'react'
import { useMap } from 'react-map-gl'
import { useSelector } from 'react-redux'
import { thirdPartyLayers } from '../Layers/thirdPartyLayers'

// Redux
import { getCurrentFloatLayer, getThirdPartyLayer } from 'ducks/mapProperties'
import {getCurrentMapBoundaryPath} from 'ducks/boundaries'

const LayerVisibilityNotifierGL = ({ layerId, featureId }) => {
  const [isVisible, setIsVisible] = useState(false)
  const currentFloatLayer = useSelector(getCurrentFloatLayer)
  const currentThirdPartyLayer = useSelector(getThirdPartyLayer)
  const currentMapBoundaryPath = useSelector(getCurrentMapBoundaryPath)
  const currentZoom = useSelector((state) => state.mapProperties.zoomLevel)
  const mapAvailable = useSelector((state) => state.mapProperties.mapAvailable)
  const { current: map } = useMap()

  const thirdPartyLayer =
    thirdPartyLayers.find((layer) => layer.id === currentThirdPartyLayer) || {}

  const layerMinZoom =
    thirdPartyLayer?.sourceOpts?.minzoom ||
    thirdPartyLayer?.opts?.minZoom ||
    null

  const belowMinZoom = currentZoom > layerMinZoom
    
  const checkIfLayerRendered = () => {
    const featuresOnMap = map.queryRenderedFeatures({ layers: [featureId] });
    debug(`${featureId} featuresOnMap count`, featuresOnMap.length);

    setIsVisible(featuresOnMap.length === 0);
  }

  useEffect(() => {
    let timerId;

    if (mapAvailable && thirdPartyLayer?.id === layerId && belowMinZoom) {
      timerId = setTimeout(() => {
        checkIfLayerRendered();
      }, 3000);
    }
  
    return () => clearTimeout(timerId);
  }, [mapAvailable, currentFloatLayer, currentThirdPartyLayer, currentZoom, currentMapBoundaryPath])
  
  if (!mapAvailable) {
    return null
  }

  const notifierClassName = `zoom-notifier-gl ${
    isVisible ? '' : 'fade-out'
  }`

  if (isVisible && thirdPartyLayer?.id === layerId && belowMinZoom) {
    return (
      <div className={notifierClassName}>
        <span className="zoom-notifier-text centered">
          <i className="fas fa-layer-group margin-right-md"></i>
          {`No ${thirdPartyLayer.name} data available in this location`}
        </span>
      </div>
    )
  } else {
    return null
  }
}

export default LayerVisibilityNotifierGL