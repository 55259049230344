import React, { useEffect, useRef, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import withStore from 'components/withStore'
import Map, {
  NavigationControl,
  GeolocateControl,
  ScaleControl,
} from 'react-map-gl'
import mapboxgl from 'mapbox-gl'
import { isEmpty, isEqual } from 'lodash'

// Layers
import ParcelLayers from './Layers/ParcelLayers'
import HoverParcelLayer from './Layers/HoverParcelLayer'
import SelectedParcelLayer from './Layers/SelectedParcelLayer'
import BuildingsLayer from './Layers/BuildingsLayer'
import ThirdPartyLayer from './Layers/ThirdPartyLayer'
import WorkingBoundaryLayer from './Layers/WorkingBoundaryLayer'
import SubboundariesLayer from './Layers/SubboundariesLayer'
import QueryLayer from './Layers/QueryLayer'
import FocusAreasLayer from './Layers/FocusAreasLayer'
import FloatLabelsLayer from './Layers/FloatLabelsLayer'

// Controls
import ViewedPlaceControl from './MapControls/ViewedPlaceControl'
import MapControlsContainer from './MapControls/MapControlsContainer'
import DrawControl from './MapControls/MapOptions/DrawTools/DrawControl'
import ZoomInNotifierGL from './MapControls/ZoomInNotifierGL'
import ZoomInLocationReminderGL from './MapControls/ZoomInLocationReminderGL'
import LayerVisibilityNotifierGL from './MapControls/LayerVisibilityNotifierGL'
import ZoomDisplay from './MapControls/ZoomDisplay' // Admin only
import SearchFeedbackModal from '../Feedback/SearchFeedbackModal'
import HoverInfo from './MapControls/HoverInfo'

// Redux
import {
  setMapProperties,
  setZoomLevel,
  getVectorLayerRendered,
  getBaseLayerURL,
} from 'ducks/mapProperties'
import { fetchBoundariesIfNeeded } from 'ducks/boundaries'
import { RENDER_MAP } from 'ducks/actions'
import { getActivePlaceParcelPath, getWorkingBoundaryPath } from 'ducks/boundaries'
import { getSelectedParcelContext, getSelectedParcelPath } from 'ducks/parcels'
import { getActiveProjectRootPath, activeProjectPresent } from 'ducks/projects'
import { getActiveRules } from 'ducks/styles'
import { fetchFocusAreas } from 'ducks/focusAreas'
import { buildTilesQueryLayer } from 'ducks/filters'
import { setBaseLayerType, getCurrentFloatLayerMapId } from 'ducks/mapProperties'

// MISC
import { getHoverData, removeLayersAndSources, zoomToBoundary } from './mapUtilities'
import passGLMapEventToFlight from 'common/utils/passGLMapEventToFlight'
import mapSettings from './mapSettings'
import useElementVisibility from 'common/hooks/useElementVisibility'
import { getHashState, removeHashState } from 'common/utils/hashUtils'
import settings from 'common/settings'
import { isPaidUser } from 'common/util'

const glMapFlight = function () {
  // establish custom behavior/properties of the GLMap FlightJS component.
  // essentially handles what withFlight does for other React components, with some map specifics
  // GLMap did not play nicely with withFlight HOC
  // does not use 'this.node' since it's not defined until React component renders
  // also makes use of originalEvent to get the details.
  'use strict'

  this.handleEvent = function (e) {
    let name = e.originalEvent.detail.name,
      opts = e.originalEvent.detail.options
    debug('GLMap', name, opts)
    this.trigger(name, opts)
    e.stopImmediatePropagation()
  }

  this.after('initialize', function () {
    debug('GLMap init')
    this.cacheId = guid()
    this.on(
      document.getElementById('mapboxgl-map'),
      'glMapEvent',
      this.handleEvent
    )
  })
}

const GLMap = ({ initExtent, isGuest }) => {
  const dispatch = useDispatch()
  const mapRef = useRef()
  const previousMapRef = useRef(null)
  const divId = 'mapboxgl-map'
  const isTouchDevice = 'ontouchstart' in window
  const paidUser = isPaidUser()

  //TODO: look through these selectors to see if anything needs to be memoized or needs deep comparison
  const vectorLayerRendered = useSelector(getVectorLayerRendered)
  const baseLayerURL = useSelector(getBaseLayerURL)
  const selectedParcelPath = useSelector(getSelectedParcelPath)
  const workingBoundaryPath = useSelector(getWorkingBoundaryPath)
  const placeParcelPath = useSelector(getActivePlaceParcelPath)
  const selectedParcelContext = useSelector(getSelectedParcelContext)
  const mapAvailable = useSelector((state) => state.mapProperties.mapAvailable)
  const currentQuery = useSelector(
    (state) => state.filters.currentQuery,
    isEqual
  ) // deep equality check
  const queryLayer = useSelector((state) => state.filters.queryLayer)
  const activeRules = useSelector(getActiveRules)

  const activeProjectRootPath = useSelector(getActiveProjectRootPath)
  const hasActiveProject = useSelector(activeProjectPresent)

  const drawActive = useSelector((state) => state.mapProperties.drawActive)
  const currentTool = useSelector(
    (state) => state.mapProperties.currentDrawTool
  ) // null | 'measureDistance' | 'measureArea' | 'measureCoords'
  const typeaheadPlace = useSelector(
    (state) => state.mapProperties.typeaheadPlacePath
  )
  const activeFloatLayerMapId = useSelector(getCurrentFloatLayerMapId)

  // State
  const [cursorStyle, setCursorStyle] = useState('grab') // cursor style
  const [parcelSelected, setParcelSelected] = useState(false)
  const [viewedState, setViewedState] = useState(null) // current view info
  const [hoverData, setHoverData] = useState({
    title: '',
    subtitle: '',
    coords: [],
    visible: false,
  })
  const { layerIds } = mapSettings

  const marketingNavIsVisible = useElementVisibility('marketing-nav')

  // Set initial map properties
  useEffect(() => {
    // TODO: trying to remove setMapProperties completely, but if I do, the map no longer saves parcel color preferences?
    // not sure why, we aren't even passing anything in, and it wasn't doing anything related to color before.
    dispatch(setMapProperties())

    flight.component(glMapFlight).attachTo('#mapboxgl-map')

    // Announcement modal with dismiss logic. Commented out for future use.
    // const hideHelpOnLoad = localStorage.getItem('regridHideHelpOnLoad')
    // if (!hideHelpOnLoad) {
    //   dispatch(setHelpModalOpen(true))
    // }

    // load the user/group's focus areas into redux
    dispatch(fetchFocusAreas())

    /* reset cursor to pointer when a user clicks esc, in all situations */
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setCursorStyle('pointer')
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    if (window?.location?.hash?.substring(1)) {
      const base = getHashState('base')
      const action = getHashState('a')
      if (base) {
        dispatch(setBaseLayerType(base))
      }

      if (action === 'new-project') {
        removeHashState('a')
        passGLMapEventToFlight('project:new')
      }
    }
  }, [window?.location?.hash?.substring(1)])

  useEffect(() => {
    if (mapAvailable) {
      mapRef.current.resize()
    }
  }, [mapAvailable])

  useEffect(() => {
    // user selected a search result from typeahead
    if (typeaheadPlace) {
      zoomToBoundary(typeaheadPlace, mapRef.current, false)
    }
  }, [typeaheadPlace])

  useEffect(() => {
    // This is for exposing the mapbox map object to React components that aren't children of the Map
    // Since there isn't a single React app tree, hard to find a place to stick a provider for shared context.
    // TODO: has to be a better way to do this
    // possible relevant discussion here: https://github.com/reactjs/react-rails/issues/982
    const checkMapRefChange = setInterval(() => {
      if (previousMapRef.current !== mapRef.current) {
        if (previousMapRef !== mapRef?.current) {
          window.data.mapbox.currentMap = mapRef.current
          dispatch({
            type: RENDER_MAP,
          })
          previousMapRef.current = mapRef.current
        }
      }
    }, 500)

    return () => {
      clearInterval(checkMapRefChange)
    }
  }, [() => mapRef.current])

  useEffect(() => {
    if (!selectedParcelPath /* || !selectedParcelContext */) return
    const selectedParcelBoundary = selectedParcelPath.replace(/(\/)[^/]*$/, '')
    const insideBounds = selectedParcelContext?.some(
      (con) => con.path === workingBoundaryPath
    )
    setParcelSelected(true)
    if (selectedParcelContext && !hasActiveProject && !insideBounds) {
      // if a parcel is selected outside the current bounds/breadcrumbs, update url & breadcrumbs.
      // set state to indicate url change came from parcel selection
      dispatch(fetchBoundariesIfNeeded(selectedParcelBoundary))
      window.history.pushState({}, '', selectedParcelBoundary + window.location.hash)
    }

    //cleanup
    return () => {
      setParcelSelected(false)
    }
    // leaving workingBoundaryPath out of dependency array should only trigger on selectedParcelPath
    // but could throw linter errors
  }, [selectedParcelPath, selectedParcelContext, hasActiveProject])

  useEffect(() => {
    // do not zoom to boundary if source of path change is a parcel change.
    if (parcelSelected) return
    if (
      window.location.pathname &&
      // when the path is a specific map/project don't use that to zoom.
      !hasActiveProject &&
      mapAvailable
    ) {
      const currentPath = window.location.pathname.replace(/\/+$/, '')
      zoomToBoundary(currentPath, mapRef.current, false) // no animation when url path changed
    }
  }, [window.location.pathname, mapAvailable, parcelSelected, hasActiveProject])

  useEffect(() => {
    if (!mapAvailable) return
    if (
      !currentQuery ||
      isEmpty(currentQuery) ||
      isEqual(currentQuery, { operation: 'union' })
    ) {
      debug('not running buildTilesQueryLayer')
      // remove any existing query layers and sources if empty/cleared
      removeLayersAndSources(
        mapRef.current,
        /^query-layer-.*$/,
        /^query-source-.*$/
      )
      // if no query but there are style rules, create a tile layer for the current county.
      if (activeRules && activeRules.length > 0) {
        // get fields in style rules
        // TODO: move to memoized selector?
        const fields = activeRules.reduce((acc, rule) => {
          if ('field' in rule && 'source' in rule) {
            if (!acc[rule.source]) {
              acc[rule.source] = []
            }
            acc[rule.source].push(rule.field)
          }
          return acc
        }, {})

        const path = placeParcelPath || activeRules[0].path

        const query = {
          parcel: {
            [path]: true,
          },
          operation: 'union',
        }
        dispatch({
          type: 'UPDATE_QUERY',
          data: query,
        })
        // dispatch(buildTilesQueryLayer(query, fields))
      }
    } else {
      // if not empty, build a query tile layer
      if (activeRules && activeRules.length > 0) {
        // get fields in style rules
        // TODO: move to memoized selector?
        const fields = activeRules.reduce((acc, rule) => {
          if ('field' in rule && 'source' in rule) {
            if (!acc[rule.source]) {
              acc[rule.source] = []
            }
            acc[rule.source].push(rule.field)
          }
          return acc
        }, {})
        debug('dispatching buildTilesQueryLayer activeRules', activeRules)
        debug('dispatching buildTilesQueryLayer currentQuery', currentQuery)
        debug('buildTilesQueryLayer hasActiveProject', hasActiveProject)
        // these conditions determine how the map displays the query layers
        //(i.e. filters, style rules, datasets, and following are all query layers)
        // TODO: is there a better way to replicate some of the stuff from query.js?
        if (hasActiveProject) {
          let query = currentQuery

          query = { ...currentQuery, ...{ mapPath: window.location.pathname } }
          if (!query?.parcel?.[placeParcelPath]) {
            query = { ...query, ...{ parcel: { [placeParcelPath]: true } } }
          }
          if (query?.path?.startsWith('/us/neighborhoods/')) {
            // seems like if the mapPath remains in this situation, we see more parcels styled than we want
            delete query.mapPath
          }
          dispatch(buildTilesQueryLayer(query, fields))
        } else {
          dispatch(buildTilesQueryLayer(currentQuery, fields))
        }
      } else {
        debug('dispatching buildTilesQueryLayer activeRules', false)
        dispatch(buildTilesQueryLayer(currentQuery))
      }
    }
  }, [
    currentQuery,
    mapAvailable,
    activeRules,
    placeParcelPath,
    hasActiveProject,
  ])

  useEffect(() => {
    window.dispatchEvent(new Event('resize'))
  }, [marketingNavIsVisible])

  useEffect(() => {
    async function runEffect() {
      if (mapAvailable) {
        await dispatch(fetchBoundariesIfNeeded(activeProjectRootPath))
        await zoomToBoundary(activeProjectRootPath, mapRef.current, false)
      }
    }

    runEffect()
  }, [activeProjectRootPath, mapAvailable])

  useEffect(() => {
    if (drawActive && currentTool) {
      setCursorStyle('crosshair')
    } else {
      setCursorStyle('pointer')
    }
  }, [drawActive, currentTool])

  const handleZoom = (e) => {
    dispatch(setZoomLevel(e.viewState.zoom))
  }

  const handleClickByLayer = (event) => {
    console.count('handleClickByLayer')
    if (drawActive) {
      return
    }
    // when interactiveLayerIds are set, react-map-gl includes a features property on the event
    // (instead of having to use queryRenderedFeatures directly)
    // this avoids clickedFeatures including things in the basemap like "landuse"
    // see: https://visgl.github.io/react-map-gl/docs/api-reference/map#interactivelayerids
    const clickedFeatures = event.features

    // is this more efficient than the switch statement alone?
    if (!clickedFeatures || clickedFeatures.length === 0) {
      return
    }

    if (clickedFeatures.length > 0 && !drawActive) {
      // prioritize clicks on the vector parcel layer
      const clickedLayerId = vectorLayerRendered
        ? layerIds.vectorParcelsInteraction
        : clickedFeatures[0].layer.id

      // Layer based on the clickedLayerId
      switch (clickedLayerId) {
        case layerIds.focusAreasFill:
        case layerIds.subboundariesFill: {
          // if we are showing parcels, we don't want clicks on boundaries to do things
          if (vectorLayerRendered) return
          // SubboundariesLayer
          const clickedFeature = clickedFeatures.find(
            (feat) => feat.layer.id === clickedLayerId
          )

          const boundaryPath = clickedFeature.properties.path
          zoomToBoundary(boundaryPath, mapRef.current)
          break
        }
        case layerIds.vectorParcelsInteraction: {
          // VectorParcelLayer
          if (!vectorLayerRendered) return
          // make sure the selectedParcel layer is on top of the map
          mapRef.current.moveLayer(layerIds.parcelSelected)
          if (activeFloatLayerMapId && mapRef.current.getLayer(activeFloatLayerMapId)) {
            mapRef.current.moveLayer(activeFloatLayerMapId)
          } 
          const clickedParcel = clickedFeatures.find(
            (feat) => feat.layer.id === clickedLayerId
          )

          const parcelPath = clickedParcel.properties?.path?.replace(/\/+$/, '')
          if (!parcelPath) return
          passGLMapEventToFlight('property:request', { path: parcelPath })
          break
        }
        // Add more cases for different layers as needed
        // Layer must be listed within the interactiveLayerIds prop on the Map cmpt
        default:
          // Handle the default case if the clickedLayerId doesn't match any known layer
          break
      }
    }
  }

  // ref clickHandler because onClick listener is bound once at initialization,
  // and is not replaced with newer instances of the onClick handler passed with successive renders.
  const handleClickByLayerRef = useRef(handleClickByLayer)
  handleClickByLayerRef.current = handleClickByLayer // Update reference with every render

  // Draw Control
  let interactiveLayers = drawActive
    ? []
    : [
        layerIds.focusAreasFill,
        layerIds.subboundariesFill,
        layerIds.vectorParcelsInteraction,
      ]

  // ------- TEMP DEBUGGING ------- //
  // // useRef to remember the previous values
  // const prevDrawActiveRef = useRef();
  // const prevCurrentToolRef = useRef();

  // useEffect(() => {
  //   // Check if it's the initial mount, if so just set the current value
  //   if (prevDrawActiveRef.current === undefined) {
  //     prevDrawActiveRef.current = drawActive;
  //   }

  //   if (prevCurrentToolRef.current === undefined) {
  //     prevCurrentToolRef.current = currentTool;
  //   }

  //   // Check if the values have changed
  //   if (prevDrawActiveRef.current !== drawActive || prevCurrentToolRef.current !== currentTool) {
  //     debug(`\nDraw Watch Variables\ndrawActive: ${prevDrawActiveRef.current} => ${drawActive} \ncurrentTool: ${prevCurrentToolRef.current} => ${currentTool}`);

  //     // Update the refs with the current values for the next time
  //     prevDrawActiveRef.current = drawActive;
  //     prevCurrentToolRef.current = currentTool;
  //   }
  // }, [drawActive, currentTool]);

  // ------- END TEMP DEBUGGING ------- //

  const handleView = useCallback((e) => {
    const vs = e.viewState
    const precisionLevel = 2
    // limiting the coordinate precision here will reduce calls to at_point from the ViewedPlace control
    setViewedState({
      ...vs,
      longitude: parseFloat(vs.longitude.toFixed(precisionLevel)),
      latitude: parseFloat(vs.latitude.toFixed(precisionLevel)),
      zoom: parseFloat(vs.zoom.toFixed(precisionLevel)),
    })
  }, [])

  const handleHover = (e) => {
    if (isTouchDevice) return // no hover on touch devices
    const map = mapRef.current
    const features = e.features
    const parcelsLayer = layerIds.vectorParcelsInteraction
    const focusAreasLayer = layerIds.focusAreasFill
    const subboundariesLayer = layerIds.subboundariesFill
    const layers = vectorLayerRendered ? [parcelsLayer] : [focusAreasLayer, subboundariesLayer]
    const displayProperties = vectorLayerRendered ? ['address', 'owner'] : ['headline']
    const data = getHoverData(map, e, layers, displayProperties)
    const currentPath = data?.path
    const isUsa = currentPath ? isPathUSA(currentPath) : false // default to hidden if path is null
    const hideAddressAndOwner = !isUsa && !paidUser && vectorLayerRendered
    
    // No features found, reset hoverData and return
    // Anon/Starter in Canada only show hover on bounds, not parcels
    if (features.length === 0 || data?.title?.includes('not defined') || hideAddressAndOwner) {
      setHoverData({ title: '', subtitle: '', coords: [], visible: false })
      return
    }

    setHoverData(data)

    // handle hover styles when focus areas and sub-boundaries overlap
    const hoveredFocusArea = features.find(feature => feature.layer.id === layerIds.focusAreasFill)
    if (hoveredFocusArea) {
      // disable highlight on sub-boundaries layer
      map.getMap().setPaintProperty(layerIds.subboundariesOutlineHover, 'line-color', settings.styles.transparentValue)
    } else {
      map.getMap().setPaintProperty(layerIds.subboundariesOutlineHover, 'line-color', mapSettings.styles.street.subboundaries.lineHoverColor)
    }
  }

  return (
    <Map
      id={divId}
      mapboxAccessToken={window.data.mapbox.access_token}
      mapStyle={baseLayerURL}
      preserveDrawingBuffer={true}
      ref={mapRef}
      initialViewState={{
        bounds: initExtent || mapSettings.commonBounds.usBounds,
      }}
      attributionControl={false}
      cursor={cursorStyle}
      onClick={(event) => handleClickByLayerRef.current(event)} // ref clickHandler because onClick listener is bound once at initialization
      onZoomEnd={handleZoom}
      onMoveEnd={handleView}
      onMouseEnter={() => {
        if (!drawActive && cursorStyle !== 'pointer') {
          setCursorStyle('pointer')
        }
      }}
      onMouseMove={handleHover}
      onMouseLeave={() => {
        if (!drawActive && cursorStyle !== 'grab') {
          setCursorStyle('grab')
        }

        // reset data for HoverInfo
        setHoverData({ title: '', subtitle: '', coords: [], visible: false })
      }}
      interactiveLayerIds={interactiveLayers}
      // https://visgl.github.io/react-map-gl/docs/api-reference/map#interactivelayerids
    >
      <ParcelLayers />
      <BuildingsLayer />
      <ThirdPartyLayer />
      <SubboundariesLayer />
      <FloatLabelsLayer />
      <WorkingBoundaryLayer />
      {!queryLayer?.isLoading && queryLayer?.id && (
        <QueryLayer
          key={queryLayer?.id}
          url={queryLayer?.url}
          tileLayerId={queryLayer?.id}
        />
      )}
      <FocusAreasLayer />
      <SelectedParcelLayer />
      {!isTouchDevice && (<HoverParcelLayer />)}
      <MapControlsContainer />
      <NavigationControl
        visualizePitch={true}
        showCompass={true}
        position="bottom-right"
      />
      <GeolocateControl position="bottom-right" />
      <ScaleControl position="bottom-left" unit="imperial" />
      <ZoomDisplay map={mapRef.current} />
      <ViewedPlaceControl viewedState={viewedState} />
      <ZoomInNotifierGL />
      <ZoomInLocationReminderGL position="bottom-right"/>
      <LayerVisibilityNotifierGL layerId="zoning" featureId="vector-zoning-fill" />
      <DrawControl />
      <SearchFeedbackModal />
      {!isTouchDevice && (<HoverInfo hoverData={hoverData} />)}
    </Map>
  )
}

GLMap.propTypes = {
  initExtent: PropTypes.array.isRequired,
}

// Workaround for the transpilation issues on mapbox-gl V2: https://github.com/visgl/react-map-gl/issues/1266#issuecomment-753686953
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

export default withStore(GLMap)
