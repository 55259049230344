import React from 'react'
import { Layer, Source, useMap } from 'react-map-gl'
import mapSettings from '../mapSettings'

const WetlandsLayer = () => {
  const { current: map } = useMap()
  const { sourceIds, layerIds } = mapSettings
  const sourceLayer = 'wetlands'

  const fillLayer = {
    id: layerIds.wetlandsFill,
    source: sourceIds.wetlandsSource,
    'source-layer': sourceLayer,
    type: 'fill',
    minzoom: 10,
    maxzoom: 22,
    paint: {
      // colors from https://fwspublicservices.wim.usgs.gov/wetlandsmapservice/rest/services/Wetlands/MapServer/0
      'fill-color': [
        'match',
        ['get', 'wetland_type'],
        'Estuarine and Marine Deepwater',
        'rgba(0, 124, 136, 255)',
        'Estuarine and Marine Wetland',
        'rgba(102, 194, 165, 255)',
        'Freshwater Emergent Wetland',
        'rgba(127, 195, 28, 255)',
        'Freshwater Forested/Shrub Wetland',
        'rgba(0, 136, 55, 255)',
        'Freshwater Pond',
        'rgba(104, 140, 192, 255)',
        'Lake',
        'rgba(19, 0, 124, 255)',
        'Lakes',
        'rgba(19, 0, 124, 255)',
        'Other',
        'rgba(178, 134, 83, 255)',
        'Riverine',
        'rgba(1, 144, 191, 255)',
        /* other */ '#fff',
      ],
      'fill-opacity': [
        'interpolate',
        ['linear'],
        ['zoom'],
        // zoom is 12 (or less) -> opacity will be 0.7
        12,
        0.7,
        // zoom is 18 (or greater) -> opacity will be 0.3
        18,
        0.3,
      ],
    },
  }

  const outlineLayer = {
    id: layerIds,
    type: 'line',
    source: sourceIds.wetlandsSource,
    'source-layer': sourceLayer,
    paint: {
      'line-color': 'rgba(0, 0, 0, 255)',
      'line-width': 0.7,
      'line-opacity': 0.8,
    },
  }

  const labelLayer = {
    id: layerIds.wetlandsOutline,
    type: 'symbol',
    source: sourceIds.wetlandsSource,
    'source-layer': sourceLayer,
    minzoom: 12,
    layout: {
      'text-field': [
        'concat',
        ['get', 'wetland_type'],
        '\n',
        ['get', 'attribute'],
      ],
      'text-size': 14,
      'text-font': ['Arial Unicode MS Bold'],
    },
    paint: {
      'text-color': '#FFFFFF',
      'text-halo-color': '#000000',
      'text-halo-width': 2,
    },
  }

  return (
    <Source
      id={sourceIds.wetlandsSource}
      type="vector"
      tiles={[
        `${window.data.tileserver}/api/v1/static/wetlands/{z}/{x}/{y}.mvt&userToken=${window.data.tile_token}`,
      ]}
      maxzoom={14}
    >
      <Layer {...fillLayer} />
      <Layer {...outlineLayer} />
      <Layer {...labelLayer} />
    </Source>
  )
}

export default WetlandsLayer
