import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const PURPLE = 'hsl(304.3, 20%, 40%)';
const AVIALBLE_COLOR = '#68AF7E';
const UNAVAILABLE_COLOR = '#d8c147';

// Index of the geoid in the array of data sent by store/stats.json
const GEOID_INDEX = 2;

const CoverageMap = (props) => {
  const [coverage, setCoverage] = useState();
  const [selectedField, setSelectedField] = useState();

  const getCoverage = async () => {
    try {
      let response = await fetch('/coverage.json');
      let json = await response.json();
      return { success: true, data: json };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  }

  useEffect(() => {
    (async () => {
      let response = await getCoverage();
      if (response.success) {
        setCoverage(response.data);
        setSelectedField(31); // 31 = owner_pct
      }
    })();
  }, []);

  // console.log('xxx using coverage', coverage);

  // Init styles
  const counties = document.getElementsByClassName("county-shape");
  counties.forEach(county => {
    county.style.fill = "#D8D6DF";
    county.style.stroke = "#ffffff";
    county.style.strokeWidth = 0.1;
  })

  if (!coverage) { 
    return <h3>Coverage</h3>
  }

  // Style based on coverage
  coverage.data.forEach((county) => {
    const geoid = county[3]
    if (geoid == '21001') {
      // console.log('xxx county', county)
    }
    const svgElt = document.getElementById(geoid)
    const pct = county[selectedField] || 0 
    if (svgElt) {
      let color = "#D8D6DF";
      if (pct > 80) {
        color = "#80BC00"
      } else if (pct > 50)  {
        color = '#C0DF88'
      } else if (pct > 20) {
        color = '#BCC392'
      } 
      svgElt.style.fill = color;
    } 
  })

  const handleChange = (event) => {
    setSelectedField(event.target.value)
  }


  return <div>
    <h3>{coverage.headers[selectedField]} Coverage</h3>
    <select value={selectedField} onChange={handleChange}>
      {
        coverage.headers.map((field, idx) => <option key={idx} value={idx}>{field}</option>)
      }
    </select>
  </div>
}


export default CoverageMap;

/*
             width="555.22198"
       height="351.66797"
*/