import React from 'react';

const BigSpinner = () => {
  return (
    <div className="centered">
      <i className='fas fa-spinner fa-spin big'/>
    </div>
  );
};

export default BigSpinner;
